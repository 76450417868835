import axios from "axios";
import json from "../../../../../../config.json"

const bulkDeleteMessageThreadsService = async (number, selectedChatsPk) => {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
  const url = urlBase + `/itpvoice/v2/${id}/my-extension/chat/sms/${number}/delete-thread/bulk`;
  const data = { message_thread_ids: selectedChatsPk }
  
  const headers = { Authorization: "Bearer " + token.replace(/['"]+/g, "") };
  await axios.delete(url, { headers, data })
}

export default bulkDeleteMessageThreadsService;