import React, { useEffect, useState, useRef } from "react";
import { MenuRight, LoadingButton, CustomTableMain, SearchBoxFilter, AddButton, AlignedRow, ShowFilterButton } from "../../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { addOrEditContactListService, deleteContactListService } from "../services";
import { handleChangeSearch } from "../utils";
import GroupsIcon from '@mui/icons-material/Groups';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import getQueryString from "../../../../../utils/getQueryString";
import petitionGet from "../../../../../services/petitionGet";
import ModalDelete from "../ModalUtility/ModalDelete";
import "../../Contacts.css";

const ContactList = () => {
  const navigate = useNavigate()

  const loading = useRef(true)
  const filterInput = useRef("");
  const itemsPerPage = useRef("10");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });

  const [info, setInfo] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [listName, setListName] = useState("");
  const [loadingMenuButton, setLoadingMenuButton] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [allResult, setAllResult] = useState(null);
  const [loadingPagItems, setLoadingPagItems] = useState(false);
  const [searchValue, setSearchValue] = useState("")
  const [dataTable, setDataTable] = useState({
    columns: [ { name: "Contact List Name", key: "list_name" }, { name: "Contacts Involved", key: "contact_count" } ],
    actions: { title: "Actions", content: [] }
  });

  const redirectToPage = (url) => {
    navigate(url)
  }

  const handleChangeInput = (e) => {
    if (loading.current) return;
    const { value } = e.target
    setSearchValue(value)
    handleChangeSearch(value, pagination, petition, filterInput)
  };

  const activeMenu = (e) => {
    if (loading.current) return;
    
    if (e.list_name) {
      setInfo(e);
    }
    setOpenMenu(true);
  };

  const closeMenu = () => {
    setModalDelete(false);
    setListName("");
    setInfo(null);
    setOpenMenu(false);
  };

  const activeModalDelete = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const handleChange = (e) => {
    setListName(e.target.value);
  };

  const reloadData = (pag) => {
    closeMenu();
    const parameter = getQueryString(pag ? pag : pagination.current) + filterInput.current
    petition(parameter)

    if (pag) {
      pagination.current = pag;
    }
  };

  const changePage = (offset) => {
    setLoadingPagItems(true)

    const paginationTemp = {
      offset,
      limit: pagination.current.limit,
    };

    const parameter = getQueryString(paginationTemp) + filterInput.current;

    petition(parameter, true);
    pagination.current = paginationTemp;
  };

  const activeItemsPerPage = (data) => {
    setLoadingPagItems(true);
    const paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    const parameter = getQueryString(paginationTemp) + filterInput.current
    petition(parameter, true);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const saveFunctionMenu = async () => {
    await addOrEditContactListService(setLoadingMenuButton, listName, info, reloadData)
  };

  const deleteFunction = async () => {
    await deleteContactListService(setLoadingMenuButton, info, allResult, pagination, reloadData)
  };

  const petition = async (parameter, noLoading) => {
    try {
      if (!noLoading) loading.current = true
    
      const { data: result } = await petitionGet("contactList", { parameter })
      setAllResult(result);
      setDataTable({
        columns: [
          { name: "Contact List Name", key: "list_name" },
          { name: "Contacts Involved", key: "contact_count" },
        ],
        content: [...result.result],
        actions: {
          title: "Actions",
          content: [
            {
              icon: <FontAwesomeIcon icon={faPenToSquare} />,
              name: "Edit",
              handleClick: activeMenu,
            },
            {
              icon: <FontAwesomeIcon icon={faTrash} />,
              name: "Delete",
              handleClick: activeModalDelete,
            },
          ],
        },
      });

      loading.current = false
      setLoadingPagItems(false);
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (info && !modalDelete) {
      setListName(info.list_name);
    }
  }, [info]);

  useEffect(() => {
    petition(getQueryString(pagination.current));
  }, []);

  return (
    <div className="mt-4">
      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeMenu}
          loading={loadingMenuButton}
          modalFunction={deleteFunction}
          info={info}
          text="Are you sure you wish to delete this Contact List. Doing so will delete ALL Contacts within the list and cannot be undone!"
        />
      )}

      <AlignedRow noCentered m2 className="mt-4">
        <h3 className="itp-accordion-header-title">
          Contact List
        </h3>
      </AlignedRow>

      <AlignedRow noCentered m2>
        <h6 className="itp-accordion-header-subtitle">
          Group of contacts
        </h6>
      </AlignedRow>
      
      <CustomTableMain
        data={dataTable}
        loading={loading.current}
        loadingPagItems={loadingPagItems}
        module="contact list"
        noDataMessage={searchValue && !allResult.result.length ? "No results found" : ""}
        isBackend={true}
        m3={true}
        paginationData={parseInt(itemsPerPage.current)}
        totalPages={allResult?.total_pages}
        onPageChange={changePage}
        showSelectSingleCheckbox={true}
        onChangeItemsPerPage={activeItemsPerPage}
        offset={pagination.current.offset}
        actionsLeft={[
          {
            component: (
              <SearchBoxFilter 
                placeholder="Search Contact List"
                handleChange={handleChangeInput}
                value={searchValue}
              />
            )
          }
        ]}
        actionsRight={[
          {
            component: (
              <ShowFilterButton
                handleClick={() => redirectToPage("/Contacts")}
                className="itp-show-filterButton-responsive"
                name="Contacts"
                icon={<GroupsIcon className="tabs-icons" />}
              />
            )
          },
          {
            component: (
              <ShowFilterButton
                handleClick={() => redirectToPage("/Contacts/Tags")}
                className="itp-show-filterButton-responsive"
                name="Tags"
                icon={<LocalOfferIcon className="tabs-icons" />}
              />
            )
          },
          {
            component: (
              <AddButton 
                name="Add Contact List"
                handleClick={activeMenu}
              />
            )
          }
        ]}
      />

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title="Add New Contact List"
        subtitle="Add a contact list name which can appear for contacts"
        to="to Contact List"
      >
        {info?.is_default ? (
          <h6>It is not possible to edit the default list</h6>
        ) : (
          <>
            <Row className="itp-container-label-input">
              <Col
                xs={5}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                xxl={5}
                className="itp-label-input-col"
              >
                <label className="itp-label-input__label">Contact List Name</label>
              </Col>
              <Col
                xs={7}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                xxl={7}
                className="itp-label-input-col"
              >
                <input
                  type="text"
                  placeholder=": Enter Contact List Name"
                  className="form-control-itp itp-label-input__input"
                  name="list_name"
                  value={listName}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <button
              disabled={loadingMenuButton}
              className="btn-primary loading"
              onClick={saveFunctionMenu}
            >
              {loadingMenuButton && <LoadingButton />}
              Save
            </button>
          </>
        )}
      </MenuRight>
    </div>
  );
};

export default ContactList;
