import { Col, Row } from "reactstrap";
import { LoadingButton } from "../../../../../../../components";
import { useRef, useState } from "react";
import "./UploadFile.css"

const UploadFile = ({ addMediaFile, loadingButton }) => {
  const inputRef = useRef(null);

  const [reload, setReload] = useState(1);
  const [name, setName] = useState("")

  const handleChangeInputFile = () => {
    setTimeout(() => {
      setReload(reload + 1);
    }, [100]);
  };

  const handleChangeName = (e) => {
    const { value } = e.target
    setName(value)
  }

  const handleSave = () => {
    if (loadingButton) return;
    const file = inputRef.current.files[0]
    addMediaFile(name, file);
  };

  return (
    <>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Name</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter Name"
            className="form-control-itp itp-label-input__input"
            name="name"
            id="name"
            value={name}
            onChange={handleChangeName}
          />
        </Col>
      </Row>

      <Row>
        <Col className="d-flex col-12 pt-4 d-flex flex-column">
          <div className="d-flex align-items-center">
            <label
              className="btn-primary itp-upload-file__button"
              htmlFor="input_file"
            >
              Browse Files
            </label>

            <input
              onChange={handleChangeInputFile}
              id="input_file"
              className="d-none form-control"
              type="file"
              accept="audio/mp3,audio/wav"
              ref={inputRef}
            />
            <span>Only MP3 or WAV Files Are Allowed</span>
          </div>
          <label className="mt-3 itp-label-input__label itp-analytics-label-select">
            {inputRef.current && inputRef.current.files.length !== 0 && inputRef.current.files[0].name}
          </label>
        </Col>
      </Row>

      <button
        onClick={handleSave}
        className="btn-primary itp-lead-detail-button-save loading mb-4"
        disabled={!inputRef.current || inputRef.current.files.length === 0 || !inputRef.current.files[0] || !name}
      >
        {loadingButton && <LoadingButton />}
        Save
      </button>
    </>
  );
};

export default UploadFile;
