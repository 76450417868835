/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import queueMenuCallFlow from "../../../../../../assets/icons/queueMenuCallFlow.svg";
import timeScheduleCallFlow from "../../../../../../assets/icons/timeScheduleCallFlow.svg";
import directoryCallFlow from "../../../../../../assets/icons/directoryCallFlow.svg";
import switchCallFlow from "../../../../../../assets/icons/switchCallFlow.svg";
import voicemailCallFlow from "../../../../../../assets/icons/voicemailCallFlow.svg";
import userCallFlow from "../../../../../../assets/icons/userCallFlow.svg";
import devices from "../../../../../../assets/icons/devices.svg";
import phoneBlack from "../../../../../../assets/icons/phoneBlack.svg";
import languageSquare from "../../../../../../assets/icons/languageSquare.svg";
import smsAction from "../../../../../../assets/icons/smsAction.svg";
import Directory from "./Modules/Directory";
import Menu from "./Modules/Menu";
import Play from "./Modules/Play";
import Queues from "./Modules/Queues";
import Switches from "./Modules/Switches";
import TimeSchedule from "./Modules/TimeSchedule";
import User from "./Modules/User";
import Voicemails from "./Modules/Voicemails";
import MenuOptions from "./Modules/MenuOptions";
import SwitchesOptions from "./Modules/SwitchesOptions";
import Externaltransfer from "./Modules/ExternalTransfer";
import Language from "./Modules/Language"
import ChatBot from "./Modules/ChatBot";
import SendSMS from "./Modules/SendSMS";
import formatPhoneNumber from "../../../../../../utils/formatPhoneNumber";
import { updateChildRoute } from "../../utils";
import { capitalizeWords } from "../../../../../../utils/capitalizeWords";
import "./ChartMenuContent.css";

const ChartMenuContent = ({
  data,
  callFlowDetails,
  edit,
  activePanelCallFlows,
  closePanelCallFlows,
  timeGroups,
  isEnd,
  queues,
  switches,
  voicemails,
  users,
  plays,
  loadingCallFlowChart,
  updateAlertVariales,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [defaultTimeCondition, setDefaultTimeCondition] = useState(false);
  const [addNewBranch, setAddNewBranch] = useState(false);
  const [dataBranch, setDataBranch] = useState(null);
  const [secondModule, setSecondModule] = useState("")

  const selectIdForAddSubtitle = (module, sendData) => {
    switch (module) {
      case "user":
        return sendData.idUser;
      case "callflow":
        return sendData.idUser;
      case "externaltransfer":
        return sendData.number;
      case "language":
        return sendData.language;
      case "send_sms":
        return formatPhoneNumber(sendData.from_number);
      default:
        return sendData.id;
    }
  }

  const selectTitleModule = (module) => {
    if (module === "externaltransfer") {
      return "External Transfer"
    }

    if (module === "send_sms") {
      return "Send SMS"
    }

    return capitalizeWords(module)
  }

  const selectSubTitleModule = (
    module,
    id,
    queues,
    switches,
    voicemails,
    users,
    plays
  ) => {
    switch (module) {
      case "voicemail":
        const findVoicemail = voicemails.find((element) => element.pk === id);
        return findVoicemail?.label;

      case "queue":
        const findQueue = queues?.find((element) => element.pk === id);
        return findQueue?.queue_name;

      case "switch":
        const findSwitch = switches.find((element) => element.pk === id);
        return findSwitch?.name;

      case "user":
        const findUser = users.find((element) => element.pk === id);
        return findUser?.label;

      case "callflow":
        const findUserr = users.find((element) => element.pk === id);
        return findUserr?.label;

      case "play":
        const findPlay = plays.find((element) => element.pk === id);
        return findPlay?.name;

      case "externaltransfer":
        return id;

      case "language":
        const shortLanguages = {
          en: "English",
          es: "Spanish",
          fr: "French",
        }
    
        return shortLanguages[id] || "";

      case "send_sms":
        return id;

      default:
        return "";
    }
  };

  const onClickAction = (module) => {
    if (!dataBranch) {
      setSelectedOption(module);
    } else {
      setSecondModule(module)
    }
  };

  const backSelected = (second) => {
    if (!second) setSelectedOption("");
    setSecondModule("")
  };

  const saveDataBranch = (obj, clearOption) => {
    setDataBranch(obj);
    if (clearOption) setSelectedOption("")
  };

  const saveModule = (sendData, module) => {
    if (loadingCallFlowChart) {
      const messageError = "Wait until data loading is complete to save an action."
      updateAlertVariales("error", "Error", messageError, 3000)
      return;
    }

    if (data.isFirst === true) {
      let newGeneralObject = { ...callFlowDetails.current };
      const currentChild = newGeneralObject.child;

      if (!addNewBranch) {
        newGeneralObject.child = [
          {
            title: selectTitleModule(module),
            subtitle: selectSubTitleModule(
              module,
              selectIdForAddSubtitle(module, sendData),
              queues,
              switches,
              voicemails,
              users,
              plays
            ),
            cardClick: () => console.log("click card"),
            addClick: (element, end, edit) =>
              activePanelCallFlows(element, end, edit),
            icon: "",
            child: currentChild,
            branchName: null,
            data: sendData,
            module: module,
            route: `child`,
          },
        ];
      }

      const formatedData = updateChildRoute(newGeneralObject);
      callFlowDetails.current = formatedData;
      closePanelCallFlows();
    } else {
      const route = data.route;
      const segments = route.split(".");

      let newGeneralObject = { ...callFlowDetails.current };
      let pointer = newGeneralObject;

      for (const segment of segments) {
        pointer = pointer[segment];
      }

      const currentChild = pointer.child;

      if (!addNewBranch) {
        pointer.child = [
          {
            title:  selectTitleModule(module),
            subtitle: selectSubTitleModule(module, selectIdForAddSubtitle(module, sendData), queues, switches, voicemails, users, plays),
            cardClick: () => {},
            addClick: (element, end, edit) => activePanelCallFlows(element, end, edit),
            icon: "",
            child: currentChild,
            branchName: null,
            data: sendData,
            module: module,
            route: `${route}.child.0`,
          },
        ];
      } else {
        const newBranch = {
          title: selectTitleModule(module),
          subtitle: selectSubTitleModule(module, selectIdForAddSubtitle(module, sendData), queues, switches, voicemails, users, plays),
          cardClick: () => {},
          addClick: (element, end, edit) => activePanelCallFlows(element, end, edit),
          icon: "",
          child: [],
          branchName: dataBranch.name,
          isKeyObject: dataBranch.id,
          fatherModule: dataBranch ? true : false,
          data: sendData,
          module: module,
          route: `${route}.child.${currentChild.length}`,
        };

        pointer.child.push(newBranch);
      }

      const formatedData = updateChildRoute(newGeneralObject);
      callFlowDetails.current = formatedData;
      closePanelCallFlows();
    }
  };

  const editModule = (sendData, module) => {
    const route = data.route;
    const segments = route.split(".");
    let newGeneralObject = { ...callFlowDetails.current };
    let pointer = newGeneralObject;

    for (const segment of segments) {
      pointer = pointer[segment];
    }

    pointer.data = sendData;

    if (module) {
      pointer.module = module;
      pointer.subtitle = selectSubTitleModule(
        module,
        selectIdForAddSubtitle(module, sendData),
        queues,
        switches,
        voicemails,
        users,
        plays
      );
    }

    const formatedData = updateChildRoute(newGeneralObject);
    callFlowDetails.current = formatedData;
    closePanelCallFlows();
  };

  useEffect(() => {
    if (data) {
      if (data.module === "timecondition") {
        setAddNewBranch(true);
      } else if ((data.module === "menu" || data.module === "switch") && !edit ) {
        setAddNewBranch(true);
      } else if ((data.module === "menu" || data.module === "switch") && edit) {
        setAddNewBranch(false);
      } else {
        setAddNewBranch(false);
      }

      if (edit || data?.module === "timecondition") {
        setSelectedOption(data?.module);
      } else if (data.module === "menu" && !edit) {
        setSelectedOption("menuOption");
      } else if (data.module === "switch" && !edit) {
        setSelectedOption("switchOption");
      } else {
        setSelectedOption(null);
      }

      setDefaultTimeCondition(false);
    }
  }, [data]);

  const modules = {
    queue: {
      component: (
        <Queues
          saveModule={saveModule}
          edit={edit}
          data={data}
          editModule={editModule}
          queues={queues}
        />
      ),
      name: "Queue / Number",
      icon: queueMenuCallFlow,
    },
    menu: {
      component: (
        <Menu
          saveModule={saveModule}
          edit={edit}
          data={data}
          editModule={editModule}
        />
      ),
      name: "Menu",
      icon: queueMenuCallFlow,
    },
    menuOption: {
      component: (
        <MenuOptions 
          saveDataBranch={saveDataBranch} 
          data={data}
          dataBranch={dataBranch} 
        />
      ),
      name: "Menu",
      icon: queueMenuCallFlow,
    },
    timecondition: {
      component: (
        <TimeSchedule
          saveDataBranch={saveDataBranch}
          timeGroups={timeGroups}
          data={data}
          dataBranch={dataBranch}
        />
      ),
      name: "Check time schedule",
      icon: timeScheduleCallFlow,
    },
    directory: {
      component: <Directory saveModule={saveModule} />,
      name: "Directory",
      icon: directoryCallFlow,
    },
    switch: {
      component: (
        <Switches
          saveModule={saveModule}
          edit={edit}
          data={data}
          editModule={editModule}
          switches={switches}
        />
      ),
      name: "Switches",
      icon: switchCallFlow,
    },
    switchOption: {
      component: (
        <SwitchesOptions saveDataBranch={saveDataBranch} data={data} />
      ),
      name: "Switches",
      icon: switchCallFlow,
    },
    play: {
      component: (
        <Play
          saveModule={saveModule}
          edit={edit}
          data={data}
          editModule={editModule}
          plays={plays}
          loadingCallFlowChart={loadingCallFlowChart}
        />
      ),
      name: "Play",
      icon: devices,
    },
    voicemail: {
      component: (
        <Voicemails
          saveModule={saveModule}
          edit={edit}
          data={data}
          editModule={editModule}
          loadingCallFlowChart={loadingCallFlowChart}
        />
      ),
      name: "Voicemail",
      icon: voicemailCallFlow,
    },
    user: {
      component: (
        <User
          saveModule={saveModule}
          edit={edit}
          data={data}
          editModule={editModule}
          users={users}
          loadingCallFlowChart={loadingCallFlowChart}
        />
      ),
      name: "User",
      icon: userCallFlow,
    },
    callflow: {
      component: (
        <User
          saveModule={saveModule}
          edit={edit}
          data={data}
          editModule={editModule}
          users={users}
          loadingCallFlowChart={loadingCallFlowChart}
        />
      ),
      name: "Call Flow",
      icon: userCallFlow,
    },
    externaltransfer: {
      component: (
        <Externaltransfer
          saveModule={saveModule}
          edit={edit}
          data={data}
          editModule={editModule}
        />
      ),
      name: "External Transfer",
      icon: phoneBlack,
    },
    language: {
      component: (
        <Language 
          saveModule={saveModule}
          edit={edit}
          data={data}
          editModule={editModule}
        />
      ),
      name: "Language",
      icon: languageSquare,
    },
    chatbot: {
      component: (
        <ChatBot 
          saveModule={saveModule}
          edit={edit}
          data={data}
          editModule={editModule}
        />
      ),
      name: "Chatbot",
      imageComponent: <SmartToyOutlinedIcon />
    },
    send_sms: {
      component: (
        <SendSMS 
          saveModule={saveModule}
          edit={edit}
          data={data}
          editModule={editModule}
        />
      ),
      name: "Send SMS",
      icon: smsAction,
    }
  };

  return (
    <>
      {selectedOption && (
        <div className="mb-3">
          {!edit && (
            <CloseIcon onClick={() => backSelected()} className="itp-callFlow-menu-back-selected" />
          )}

          <div className="itp-callFlow-menu-container-actions mt-2">
            <div className="itp-callFlow-menu-action selected">
              {modules[selectedOption].imageComponent && (
                <>{modules[selectedOption].imageComponent}</>
              )}

              {modules[selectedOption].icon && (
                <img src={modules[selectedOption].icon} alt="icon" />
              )}
      
              <label className="itp-callFlow-menu-name">
                {modules[selectedOption].name}
              </label>
            </div>
          </div>

          <div className="mt-3">{modules[selectedOption].component}</div>
        </div>
      )}

      {secondModule && (
        <div className="mb-3">
          {!edit && (
            <CloseIcon onClick={() => backSelected(true)} className="itp-callFlow-menu-back-selected" />
          )}

          <div className="itp-callFlow-menu-container-actions mt-2">
            <div className="itp-callFlow-menu-action selected">
              {modules[secondModule].imageComponent && (
                <>{modules[secondModule].imageComponent}</>
              )}

              {modules[secondModule].icon && (
                <img src={modules[secondModule].icon} alt="icon" />
              )}
      
              <label className="itp-callFlow-menu-name">
                {modules[secondModule].name}
              </label>
            </div>
          </div>

          <div className="mt-3">{modules[secondModule].component}</div>
        </div>
      )}

      {(!selectedOption || dataBranch) && !secondModule && (
        <>
          {!isEnd && !addNewBranch ? (
            <>
              <div className="itp-callFlow-menu-container-actions">
                <div
                  onClick={() => onClickAction("queue")}
                  className="itp-callFlow-menu-action"
                >
                  <img src={queueMenuCallFlow} alt="queue" />
                  <label className="itp-callFlow-menu-name">
                    Queue / Number
                  </label>
                </div>
                <div
                  onClick={() => onClickAction("directory")}
                  className="itp-callFlow-menu-action"
                >
                  <img src={directoryCallFlow} alt="directory" />
                  <label className="itp-callFlow-menu-name">Directory</label>
                </div>
              </div>
              <div className="itp-callFlow-menu-container-actions mt-3">
                <div
                  onClick={() => onClickAction("play")}
                  className="itp-callFlow-menu-action"
                >
                  <img src={devices} alt="play" />
                  <label className="itp-callFlow-menu-name">Play</label>
                </div>
                <div
                  onClick={() => onClickAction("user")}
                  className="itp-callFlow-menu-action"
                >
                  <img src={userCallFlow} alt="user" />
                  <label className="itp-callFlow-menu-name">User</label>
                </div>
              </div>
              <div className="itp-callFlow-menu-container-actions mt-3">
                <div
                  onClick={() => onClickAction("externaltransfer")}
                  className="itp-callFlow-menu-action"
                >
                  <img src={phoneBlack} alt="external" />
                  <label className="itp-callFlow-menu-name">
                    External Transfer
                  </label>
                </div>
                <div
                  onClick={() => onClickAction("language")}
                  className="itp-callFlow-menu-action"
                >
                  <img src={languageSquare} alt="language" />
                  <label className="itp-callFlow-menu-name">
                    Language
                  </label>
                </div>
              </div>
              <div className="itp-callFlow-menu-container-actions mt-3">
                <div
                  onClick={() => onClickAction("chatbot")}
                  className="itp-callFlow-menu-action"
                >
                  <SmartToyOutlinedIcon />
                  <label className="itp-callFlow-menu-name">
                    Chatbot
                  </label>
                </div>
                <div
                  onClick={() => onClickAction("send_sms")}
                  className="itp-callFlow-menu-action"
                >
                  <img src={smsAction} alt="Send SMS" />
                  <label className="itp-callFlow-menu-name">
                    Send SMS
                  </label>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="itp-callFlow-menu-container-actions">
                <div
                  onClick={() => onClickAction("queue")}
                  className="itp-callFlow-menu-action"
                >
                  <img src={queueMenuCallFlow} alt="Queue" />
                  <label className="itp-callFlow-menu-name">
                    Queue / Number
                  </label>
                </div>
                <div
                  onClick={() => onClickAction("menu")}
                  className="itp-callFlow-menu-action"
                >
                  <img src={queueMenuCallFlow} alt="menu" />
                  <label className="itp-callFlow-menu-name">Menu</label>
                </div>
              </div>
              <div className="itp-callFlow-menu-container-actions mt-3">
                <div
                  onClick={() => {
                    if (defaultTimeCondition) {
                      onClickAction("timecondition");
                    } else {
                      saveModule({ id: "default", timezone: "default" }, "timecondition");
                      if (!loadingCallFlowChart) setDefaultTimeCondition(true);
                    }
                  }}
                  className="itp-callFlow-menu-action"
                >
                  <img src={timeScheduleCallFlow} alt="schedule" />
                  <label className="itp-callFlow-menu-name">
                    Check time schedule
                  </label>
                </div>
                <div
                  onClick={() => onClickAction("directory")}
                  className="itp-callFlow-menu-action"
                >
                  <img src={directoryCallFlow} alt="directory" />
                  <label className="itp-callFlow-menu-name">Directory</label>
                </div>
              </div>
              <div className="itp-callFlow-menu-container-actions mt-3">
                <div
                  onClick={() => onClickAction("switch")}
                  className="itp-callFlow-menu-action"
                >
                  <img src={switchCallFlow} alt="switches" />
                  <label className="itp-callFlow-menu-name">Switches</label>
                </div>
                <div
                  onClick={() => onClickAction("play")}
                  className="itp-callFlow-menu-action"
                >
                  <img src={devices} alt="play" />
                  <label className="itp-callFlow-menu-name">Play</label>
                </div>
              </div>
              <div className="itp-callFlow-menu-container-actions mt-3">
                <div
                  onClick={() => onClickAction("voicemail")}
                  className="itp-callFlow-menu-action"
                >
                  <img src={voicemailCallFlow} alt="voicemail" />
                  <label className="itp-callFlow-menu-name">Voicemail</label>
                </div>
                <div
                  onClick={() => onClickAction("user")}
                  className="itp-callFlow-menu-action"
                >
                  <img src={userCallFlow} alt="user" />
                  <label className="itp-callFlow-menu-name">User</label>
                </div>
              </div>
              <div className="itp-callFlow-menu-container-actions mt-3">
                <div
                  onClick={() => onClickAction("externaltransfer")}
                  className="itp-callFlow-menu-action"
                >
                  <img src={phoneBlack} alt="external" />
                  <label className="itp-callFlow-menu-name">
                    External Transfer
                  </label>
                </div>
                <div
                  onClick={() => onClickAction("language")}
                  className="itp-callFlow-menu-action"
                >
                  <img src={languageSquare} alt="language" />
                  <label className="itp-callFlow-menu-name">
                    Language
                  </label>
                </div>
              </div>
              <div className="itp-callFlow-menu-container-actions mt-3">
                <div
                  onClick={() => onClickAction("chatbot")}
                  className="itp-callFlow-menu-action"
                >
                  <SmartToyOutlinedIcon />
                  <label className="itp-callFlow-menu-name">
                    Chatbot
                  </label>
                </div>
                <div
                  onClick={() => onClickAction("send_sms")}
                  className="itp-callFlow-menu-action"
                >
                  <img src={smsAction} alt="Send SMS" />
                  <label className="itp-callFlow-menu-name">
                    Send SMS
                  </label>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ChartMenuContent;
