import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { LoadingButton } from "../../../index";

const ModalDelete = ({
  modal,
  closeModal,
  loading,
  modalFunction,
  totalContactts,
}) => {
  return (
    <Modal isOpen={modal ? true : false} className="info" centered>
      <ModalHeader>Delete Contact(s)</ModalHeader>
      <ModalBody>
        Are you sure to delete {totalContactts} contacts?
      </ModalBody>
      <ModalFooter>
        <button disabled={loading} className="btn-light" onClick={closeModal}>
          No
        </button>
        <button
          disabled={loading}
          className="btn-primary loading"
          onClick={modalFunction}
        >
          {loading && <LoadingButton />}
          Yes
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalDelete;
