import { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AlertNotificactions, MenuRight } from "../../../../components";
import { disabledIntegrationService, editFieldsService, enabledIntegrationService, fetchCustomerServices, fetchIntegrations } from "./services";
import { ActiveEditIntegration } from "./components";
import Skeleton from "react-loading-skeleton";
import "./Integrations.css";

const Integrations = () => {
  const [loading, setLoading] = useState(true);
  const [showErrorTime, setShowErrorTime] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [integrations, setIntegrations] = useState([{pk: 1}, {pk:2}, {pk:3}]);
  const [clickedItem, setClickedItem] = useState(null)
  const [openMenu, setOpenMenu] = useState(false)
  const [enabledIntegration, setEnabledIntegration] = useState(false)
  const [loadingMenu, setLoadingMenu] = useState(false)
  const [form, setForm] = useState({})
  const [inputs, setInputs] = useState([])
  const [loadingButton, setLoadingButton] = useState(false)
  const [loadingEnabledIntegration, setLoadingEnabledIntegration] = useState(false)

  const updateAlertVariables = (type, title, message, time) => {
    setAlertType(type);
    setAlertTitle(title);
    setAlertMessage(message);
    setShowErrorTime(time);

    setTimeout(() => {
      setShowErrorTime(false);
      setAlertType("");
      setAlertTitle("");
      setAlertMessage("");
    }, time + 300);
  };

  const handleClickItem = async (element) => {
    try {
      setLoadingMenu(true)
      setOpenMenu(true)

      await fetchCustomerServices(setEnabledIntegration, element, setLoadingMenu, setForm, setInputs, setClickedItem)
    } catch (error) {
      console.log(error)
      closeMenu()
      updateAlertVariables("error", "Error", "An error has ocurred", 3000)   
    }
  };

  const handleChangeForm = (e) => {
    const { name, value } = e.target

    const findInput = inputs.find((input) => input.key === name)
    if (findInput && findInput?.type	=== "boolean") {
      setForm({ ...form, [name]: !form[name] })
    } else {
      setForm({ ...form, [name]: value })
    }
  }

  const handleChangeActiveIntegration = async () => {
    setEnabledIntegration(!enabledIntegration)
    setLoadingEnabledIntegration(true)

    if (enabledIntegration) {
      await disabledIntegrationService(clickedItem, setEnabledIntegration, updateAlertVariables, setLoadingEnabledIntegration)
    } else {
      await enabledIntegrationService(clickedItem, setClickedItem, setEnabledIntegration, updateAlertVariables, setLoadingEnabledIntegration)
    }
  }

  const closeMenu = () => {
    setEnabledIntegration(false)
    setOpenMenu(false)
    setClickedItem(null)
    setLoadingMenu(false)
    setLoadingButton(false)
  }

  const returnDataForEditFields = () => {
    let data = { settings: { description: clickedItem.description, fields: {} } }

    inputs.forEach((input) => {
      data.settings.fields[input.key] = {
        label: input.label,
        required: input.required,
        type: input.type,
        value: form[input.key],
      }
    })

    return data
  }

  const returnBase64Image = (element) => {
    const base64 = `data:${element.logo_mime};base64,${element.logo}`

    return base64
  }

  const saveFunction = async () => {
    const data = returnDataForEditFields()
    await editFieldsService(data, setLoadingButton, updateAlertVariables, clickedItem, petition)
  }

  const petition = async (noLoading) => {
    await fetchIntegrations(setLoading, updateAlertVariables, setIntegrations, closeMenu, noLoading);
  };

  useEffect(() => {
    petition();
  }, []);

  return (
    <>
      <AlertNotificactions
        type={alertType}
        alertMessage={alertMessage}
        showTime={showErrorTime}
        title={alertTitle}
      />

      <Row className="ms-5 me-5 mt-5">
        {integrations.map((element) => (
          <Col 
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            xxl={3}
            key={element.pk}
            className="itp-integrations__card-container"
          >
            {loading ? (
              <Skeleton width="100%" height="10rem" />
            ) : (
              <Card
                onClick={() => handleClickItem(element)}
                className="itp-integrations__card-item"
              >
                <CardBody>
                  <div className="d-flex justify-content-center flex-column align-items-center gap-2">
                    {element?.logo && element?.logo_mime && (
                      <img 
                        src={returnBase64Image(element)} 
                        alt="Integration img" 
                        className="itp-integrations__card-item-img" 
                      />
                    )}

                    <span className="itp-integrations__card-item-name">
                      {element.formatedName}
                    </span>
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        ))}
      </Row>

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title="Integration Details"
        to="to Integrations List"
      >
        {openMenu && (
          <ActiveEditIntegration 
            loading={loadingMenu}
            enabledIntegration={enabledIntegration}
            handleChangeActiveIntegration={handleChangeActiveIntegration}
            form={form}
            inputs={inputs}
            handleChangeForm={handleChangeForm}
            loadingButton={loadingButton}
            saveFunction={saveFunction}
            loadingEnabledIntegration={loadingEnabledIntegration}
          />
        )}
      </MenuRight>
    </>
  );
};

export default Integrations;
