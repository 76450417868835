import petitionGet from "../../../../../../services/petitionGet";

const fetchQuestionListService = async (questionListId) => {
  if (questionListId) {
    const parameter = `/${questionListId}`
    const { data: result } = await petitionGet("salesLead", { parameter })
  
    result.result.label = result.result.name
    result.result.value = result.result.pk
  
    return result.result
  } else {
    return null
  }
}

export default fetchQuestionListService;