const getMessageErrorTryCatch = (error) => {
  if (error.message) {
    console.log("Mensaje de error:", error.message);
    return error.message
  }
  // Si es un objeto con propiedades específicas
  else if (error instanceof Error) {
    console.log("Error:", error.toString());
    return error.toString()
  }
  // Si es un objeto más complejo (como el de tu imagen)
  else if (error && typeof error === "object") {
    console.log("Detalles del error:", JSON.stringify(error, null, 2)); // Muestra el objeto completo
    if (error.Exception) {
      console.log("Mensaje de error específico:", error.Exception.message || "No message available");
      return error.Exception.message || "No message available"
    }
  } else {
    // Si no es un objeto estándar
    console.log("Error desconocido:", error);
    return "Unknown error"
  }
};

export default getMessageErrorTryCatch;
