import petitionGet from "../../../../../../services/petitionGet";

const fetchFunnelDetails = async (funnelId) => {
  if (funnelId) {
    const parameter = `/${funnelId}`
    const { data: result } = await petitionGet("funnels", { parameter })
    
    result.result.label =  result.result.name
    result.result.value =  result.result.pk
    delete result.result.stages
  
    return result.result
  } else {
    return null
  }
}

export default fetchFunnelDetails;