/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { MenuRight, Filter, CustomTableMain, SelectCheckbox, ShowFilterButton, AddButton, LoadingDots, LeadDetails } from "../../../components";
import { addContactLeadService, addLeadService, deleteLeadService, editContactService, editLeadService, fetchLeads, fetchMyExtension, updateFiltersService } from "./services";
import { changeColumn, generateDataTableTitles, getDataFilter, getDefaultColumnsSelected, getFilterModal, getFiltersActive, getLeadsFilter, processLeads } from "./utils";
import { options } from "./const/const";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddLead from "./AddLead/AddLead";
import ModalDelete from "./ModalUtility/ModalDelete";
import getQueryString from "../../../utils/getQueryString";
import "./Leads.css";

const Leads = () => {
  const myExtension = useRef({})
  const objectFilter = useRef({});
  const filterRef = useRef("");
  const firstLoad = useRef(true);
  const itemsPerPage = useRef("10");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });

  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [addLead, setAddLead] = useState(false);
  const [leadDetails, setLeadDetails] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState(null);
  const [loadingLead, setLoadingLead] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [info, setInfo] = useState(null);
  const [defaultSelectedOptions, setDefaultSelectedOptions] = useState([]);
  const [allResult, setAllResult] = useState(null);
  const [errorAddContact, setErrorAddContact] = useState("")
  const [errorEditContact, setErrorEditContact] = useState("")
  const [loadingPagItems, setLoadingPagItems] = useState(false)
  const [loadingDots, setLoadingDots] = useState(true)

  const selectSubTitleMenu = () => {
    if ((openFilter || leadDetails) && openMenu && !addLead) {
      return false;
    } else if (!openFilter && openMenu && addLead && !leadDetails) {
      return "Add the details asked to add new contact";
    }
  };

  const selectTitleMenu = () => {
    if (openFilter && openMenu && !addLead && !leadDetails) {
      return "Add Filters";
    } else if (!openFilter && openMenu && addLead && !leadDetails) {
      return "Add New Lead";
    } else if (!openFilter && openMenu && !addLead && leadDetails) {
      return "Lead Details";
    }
  };

  const closeMenu = (reload) => {
    setErrorEditContact("")
    setErrorAddContact("")
    setLeadDetails(null)
    setAddLead(false);
    setOpenFilter(false);
    setOpenMenu(false);
    setInfo(null);
    setModalDelete(false);

    if (reload) {
      reloadData(false);
    }
  };

  const reloadData = async (pag) => {
    closeMenu();

    const paginationTemp = pag || pagination.current
    const parameter = getQueryString(paginationTemp) + filterRef.current

    await updateLeadList(myExtension.current, parameter)
  };

  const activeAddLead = () => {
    if (loading) return;
    setAddLead(true);
    setOpenMenu(true);
  };

  const activeFilterDetails = () => {
    if (loading) return;
    setOpenFilter(true);
    setOpenMenu(true);
  };

  const activeLeadDetails = (e) => {
    setLeadDetails(e);
    setOpenMenu(true);
  };

  const activeModalDelete = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const showColumns = async (arrayColumns) => {
    const data = {
      leads_settings_display_columns: {
        columns: arrayColumns,
      },
    }
    try {
      await updateFiltersService(myExtension, data)
    } catch (error) {
      console.log(error)
    }
  };

  const changePage = async (offset) => {
    setLoadingPagItems(true)
    const paginationTemp = {
      limit: pagination.current.limit,
      offset: offset,
    };

    const parameter = getQueryString(paginationTemp) + filterRef.current

    await updateLeadList(myExtension.current, parameter, true)
    pagination.current = paginationTemp;
  };

  const refreshTable = async () => {
    if (loading || loadingPagItems) return;
    setLoadingPagItems(true)
    const parameter = getQueryString(pagination.current) + filterRef.current
    await updateLeadList(myExtension.current, parameter, true)
  }

  const activeItemsPerPage = async (data) => {
    setLoadingPagItems(true)
    const paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    const parameter = getQueryString(paginationTemp) + (filterRef.current || "");
    await updateLeadList(myExtension.current, parameter, true)
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const onSaveFunction = async (form, formLabels) => {
    setLoadingFilter(true);

    const filtersActive = getFiltersActive(formLabels)

    const { filterModal, parameter, paginationTemp } = getFilterModal(pagination, form, myExtension)

    try {
      const data = { crm_filters: { leads: form } }
      const newUserDetails = await updateFiltersService(myExtension, data)

      setLoadingFilter(false);
      closeMenu();
      firstLoad.current = false;

      getDataFilter(firstLoad, setDataFilter, myExtension, onSaveFunction, form)

      await updateLeadList(newUserDetails, parameter)

      objectFilter.current = filtersActive
      filterRef.current = filterModal;
      pagination.current = paginationTemp;
    } catch (error) {
      console.log(error)
    }
  };

  const addNewContactAndLead = async (form) => {
    await addContactLeadService(setErrorAddContact, setLoadingLead, form, addNewLead)
  };

  const addNewLead = async (form) => {
    await addLeadService(form, setLoadingLead, reloadData, setErrorAddContact)
  };

  const deleteLead = async () => {
    await deleteLeadService(info, setLoadingLead, allResult, reloadData, pagination)
  };

  const editContact = async (form) => {
    await editContactService(form, leadDetails, setErrorEditContact, setLoadingLead, reloadData)
  };

  const editLead = async (contact, lead) => {
    await editLeadService(setLoadingLead, leadDetails, lead, editContact, contact)
  }

  const onChangeViewColumn = (arrColumns) => {
    changeColumn(showColumns, setDataTable, arrColumns)
  }

  const updateDataTable = (newData, columns) => {
    setDataTable({
      columns: columns,
      content: [...newData],
      actions: {
        title: "Actions",
        content: [
          { name: "Edit Lead", handleClick: activeLeadDetails, icon: <FontAwesomeIcon icon={faPenToSquare} /> },
          { name: "Delete Lead", handleClick: activeModalDelete, icon: <FontAwesomeIcon icon={faTrash} /> },
        ],
      },
    });
  }

  const updateLeadList = async (myExtensionTemp, parameter, noUpdateLoading) => {
    if (!noUpdateLoading) setLoading(true);

    const noViewColumns = getDefaultColumnsSelected(myExtensionTemp, options, setDefaultSelectedOptions)
    const leads = await fetchLeads(parameter, setAllResult);
    const processedLeads = processLeads(leads);
    const columns = generateDataTableTitles(noViewColumns);
    updateDataTable(processedLeads, columns)

    setLoading(false);
    setLoadingPagItems(false)
    firstLoad.current = false;
  }

  const fetchData = async (parameter, myExtensionTemp) => {
    try {
      const noViewColumns = getDefaultColumnsSelected(myExtensionTemp, options, setDefaultSelectedOptions)
      const columns = generateDataTableTitles(noViewColumns);
      setDataTable({ columns, actions: { title: "Actions", content: [] } })
      setLoadingDots(false)
      setLoading(true)

      if (firstLoad.current && myExtensionTemp?.crm_filters?.leads) {  
        parameter = getLeadsFilter(myExtensionTemp, parameter, filterRef)
      }

      getDataFilter(firstLoad, setDataFilter, myExtensionTemp, onSaveFunction)
      await updateLeadList(myExtensionTemp, parameter)
    } catch (error) {
      console.log(error)
    }
  }
  
  useEffect(() => {
    fetchMyExtension(myExtension, fetchData, pagination, objectFilter)
  }, []);

  return (
    <div className={`itp-test itp-${itemsPerPage.current}-items`}>
      {loadingDots ? (
        <LoadingDots />
      ) : (
        <>
          {modalDelete && (
            <ModalDelete
              modal={modalDelete}
              closeModal={() => closeMenu(false)}
              loading={loadingLead}
              modalFunction={deleteLead}
            />
          )}

          <CustomTableMain 
            data={dataTable}
            loading={loading}
            loadingPagItems={loadingPagItems}
            actionsLeft={[
              {
                component: (
                  <SelectCheckbox
                    name="Display Columns"
                    options={options}
                    defaultSelected={defaultSelectedOptions}
                    checkFunction={onChangeViewColumn}
                    noMarginLeft={true}
                    style={{ marginLeft: "0" }}
                    loading={loading}
                  />
                ),
              },
              {
                component: <RefreshIcon className="cursor-pointer" onClick={refreshTable} />
              },
            ]}
            actionsRight={[
              {
                component: (
                  <ShowFilterButton 
                    handleClick={activeFilterDetails} 
                    activeFilters={objectFilter.current}
                  />
                ),
              },
              {
                component: (
                  <AddButton
                    handleClick={activeAddLead}
                    name="Add New Lead"
                  />
                ),
              },
            ]}
            columnConfig={{
              "Contact Name": {
                type: "rowLink",
                to: "/Contacts?",
                key: "contact.pk"
              },
              "Funnel": {
                type: "rowLink",
                to: "/Funnels?",
                key: "funnel_pipeline_stage.funnel.pk"
              }
            }}
            clickRow={activeLeadDetails}
            isBackend={true}
            paginationData={parseInt(itemsPerPage.current)}
            totalPages={allResult?.total_pages}
            onPageChange={changePage}
            showSelectSingleCheckbox={true}
            onChangeItemsPerPage={activeItemsPerPage}
            offset={pagination.current.offset}
          />

          <MenuRight
            show={openMenu}
            closeMenu={closeMenu}
            title={selectTitleMenu()}
            subtitle={selectSubTitleMenu()}
            to="to Leads"
          >
            {openMenu && openFilter && !addLead && !leadDetails && (
              <Filter info={dataFilter} loading={loadingFilter} />
            )}

            {openMenu && addLead && !openFilter && !leadDetails && (
              <AddLead
                loadingAddLead={loadingLead}
                addNewContactAndLead={addNewContactAndLead}
                addNewLead={addNewLead}
                errorAddContact={errorAddContact}
              />
            )}

            {openMenu && leadDetails && !openFilter && !addLead && ( 
             <LeadDetails
               leadDetails={leadDetails} 
               setLeadDetails={setLeadDetails} 
               loadingEdit={loadingLead} 
               errorEditContact={errorEditContact}
               editLead={editLead}
               myExtension={myExtension.current}
             />
            )}
          </MenuRight>
        </>
      )}
    </div>
  );
};

export default Leads;
