import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { AlertNotificactions, LoadingButton } from "../../../../components";
import deleteIcon from "../../../../assets/icons/deleteIcon.svg";
import petitionGet from "../../../../services/petitionGet";
import petitionDelete from "../../../../services/petitionDelete";
import petitionPost from "../../../../services/petitionPost";
import petitionPatch from "../../../../services/petitionPatch";
import "./Stages.css";
import Skeleton from "react-loading-skeleton";

const Stages = ({ funnelInfo, backFunction }) => {
  const fakestages = [{}, {}, {}];

  const [stages, setStages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState({});
  const [deletedStagesList, setDeletedStagesList] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [stepAddStage, setStepAddStage] = useState(1);
  const [showErrorTime, setShowErrorTime] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertType, setAlertType] = useState();
  const [alertMessage, setAlertMessage] = useState(
    "Unable to perform action Listen on this call"
  );

  const [newFormStage, setNewFormState] = useState({
    name: "",
    status: "",
  });

  const updateAlertVariables = (type, title, message, time) => {
    setAlertType(type);
    setAlertTitle(title);
    setAlertMessage(message);
    setShowErrorTime(time);

    setTimeout(() => {
      setShowErrorTime(false);
      setAlertType("");
      setAlertTitle("");
      setAlertMessage("");
    }, time);
  };

  const onChangeinput = (e, pk) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const removedStagesList = (stage) => {
    if (loading) return;

    let newSetValue = {};
    let newStages = stages.filter((element) => element.pk !== stage.pk);
    let newDeleted = deletedStagesList;

    if (!stage.added_frontend) {
      newDeleted.push(stage);
    }

    newStages.sort(compare).forEach((stage) => {
      newSetValue = {
        ...newSetValue,
        [`value_${stage.name}_${stage.pk}`]: value[
          `value_${stage.name}_${stage.pk}`
        ]
          ? value[`value_${stage.name}_${stage.pk}`]
          : stage.name,
      };
    });

    setValue(newSetValue);
    setStages(newStages);
    setDeletedStagesList(newDeleted);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const copyIems = [...stages];
    const [draggedItem] = copyIems.splice(source.index, 1);
    copyIems.splice(destination.index, 0, draggedItem);

    copyIems.forEach((stage, key) => {
      stage.stage_position = key;
    });

    setStages(copyIems);
  };

  const compare = (a, b) => {
    if (a.stage_position < b.stage_position) {
      return -1;
    }
    if (a.stage_position > b.stage_position) {
      return 1;
    }
    return 0;
  };

  const newStage = () => {
    if (newFormStage.name !== "") {
      const identifier = uuidv4();
      let newStages = stages;
      let newSetValue = {};

      const biggerNumber = newStages.reduce((higher, obj) => {
        return obj.stage_position > higher ? obj.stage_position : higher;
      }, 0);

      newStages.push({
        ...newFormStage,
        name: newFormStage.name,
        is_won: false,
        is_lost: false,
        is_lead: false,
        added_frontend: true,
        pk: identifier,
        id: identifier,
        stage_position: biggerNumber + 1,
        default_stage_position: biggerNumber + 1,
      });

      newStages.sort(compare).forEach((stage) => {
        newSetValue = {
          ...newSetValue,
          [`value_${stage.name}_${stage.pk}`]: value[
            `value_${stage.name}_${stage.pk}`
          ]
            ? value[`value_${stage.name}_${stage.pk}`]
            : stage.name,
        };
      });

      setStages(newStages);
      setValue(newSetValue);

      resetFormNewStage();
      selectStepAddStage("back");
    }
  };

  const resetFormNewStage = () => {
    setNewFormState({
      name: "",
      status: "",
    });
  };

  const selectDisabledButton = (stage) => {
    if (stage.is_won || stage.is_lead || stage.is_lost) return true;

    return false;
  };

  const lessThanThreeStages = () => {
    let jsxArray = [];
    let difference = 3 - stages.length;
    for (let i = 0; i < difference; i++) {
      jsxArray.push(
        <Row
          key={i}
          style={{ marginBottom: "12px" }}
          className="d-flex align-items-center"
        >
          <Col className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between">
              <input disabled type="text" className="form-control-itp" />
              <FontAwesomeIcon
                className="itp-stages-drag-icon"
                icon={faGripVertical}
              />
            </div>
          </Col>
          <Col style={{ flex: "0 0 auto", width: "4%", visibility: "hidden" }}>
            <img
              src={deleteIcon}
              alt="Delete"
              className="itp-stages-delete-icon"
            />
          </Col>
          <Col style={{ visibility: "hidden" }}></Col>
        </Row>
      );
    }

    return jsxArray;
  };

  const selectStepAddStage = (selector) => {
    if (loading) return;

    if (selector === "next") {
      setStepAddStage(stepAddStage + 1);
    } else if (selector === "back") {
      setStepAddStage(stepAddStage - 1);
    }
  };

  const editStage = async (stage) => {
    try {
      const data = {
        name: stage.name,
        is_won: stage.is_won,
        is_lead: stage.is_lead,
        is_lost: stage.is_lost,
        stage_position: stage.stage_position,
      };

      const funnel_id = funnelInfo.pk;
      const stage_id = stage.pk;

      const result = await petitionPatch("stage", {
        funnel_id,
        stage_id,
        data,
      });

      return result;
    } catch (error) {
      // console.log(`Error editing stage ${stage.pk}:`, error);
      console.log(stage);
      const errorObj = {
        ...error,
        stageAction: "editing",
        stageName: stage.name,
      };

      // Lanza el error para que Promise.all lo capture
      throw errorObj;
    }
  };

  const addStage = async (stage) => {
    try {
      const data = {
        name: stage.name,
        is_won: false,
        is_lead: false,
        is_lost: false,
        stage_position: stage.stage_position,
      };

      const result = await petitionPost("stage", {
        funnel_id: funnelInfo.pk,
        data,
      });

      return result;
    } catch (error) {
      console.log(`Error adding stage ${stage.pk}:`, error);
      console.log(stage);
      const errorObj = {
        ...error,
        stageAction: "adding",
        stageName: stage.name,
      };

      // Lanza el error para que Promise.all lo capture
      throw errorObj;
    }
  };

  const deleteStage = async (stage, i) => {
    try {
      const funnel_id = funnelInfo.pk;
      const stage_id = stage.pk;

      const result = await petitionDelete("stage", { funnel_id, stage_id });

      if (i === deletedStagesList.length - 1) {
        setLoadingButton(false);
      }

      return result;
    } catch (error) {
      console.log(`Error deleting stage ${stage.pk}:`, error);
      console.log(stage);
      const errorObj = {
        ...error,
        stageAction: "adding",
        stageName: stage.name,
      };

      // Lanza el error para que Promise.all lo capture
      throw errorObj;
    }
  };

  const saveFunction = async () => {
    try {
      if (loading) return;

      setLoadingButton(true);
      const promises = [];

      if (deletedStagesList.length > 0) {
        deletedStagesList.forEach((stage, i) => {
          promises.push(deleteStage(stage, i));
        });
      }

      stages.forEach((stage, i) => {
        if (stage.added_frontend) {
          const formValue = value[`value_${stage?.name}_${stage?.pk}`];
          const stageFormated = { ...stage, name: formValue };

          promises.push(addStage(stageFormated, i));
        } else if (
          value[`value_${stage.name}_${stage.pk}`] !== stage.name ||
          stage.stage_position !== stage.default_stage_position
        ) {
          const formValue = value[`value_${stage?.name}_${stage?.pk}`];
          const stageFormated = { ...stage, name: formValue };

          promises.push(editStage(stageFormated, i));
        }
      });

      await Promise.all(promises);
      await backFunction(false);
    } catch (error) {
      console.log(error);
      const status = error?.response?.status;

      if (status === 400) {
        const stageInfo = `Error with ${error?.stageName} ${error?.stageAction}`;
        const errorMsg = error?.response?.data?.error?.error_message;
        const customMsg = `${stageInfo}: ${errorMsg}`;
        updateAlertVariables("error", "Error", customMsg, 5000);
      } else {
        const errorMsg = "An error has ocurred, please try again";
        updateAlertVariables("error", "Error", errorMsg, 3000);
      }

      setLoadingButton(false);
    }
  };

  const petition = () => {
    setLoading(true);
    petitionGet("stagesAdmin", { funnel_id: funnelInfo.pk })
      .then(({ data: result }) => {
        let newSetValue = value;
        result.result.sort(compare).forEach((stage) => {
          stage.id = `${uuidv4()}`;
          stage.default_stage_position = stage.stage_position;

          newSetValue = {
            ...newSetValue,
            [`value_${stage.name}_${stage.pk}`]: stage.name,
          };

          delete stage.leads;
          delete stage.funnel;
        });

        setDeletedStagesList([]);
        setStages(result.result);
        setValue(newSetValue);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petition();
  }, []);

  return (
    <>
      <AlertNotificactions
        type={alertType}
        alertMessage={alertMessage}
        showTime={showErrorTime}
        title={alertTitle}
      />

      <div
        className={`itp-stages-add-container${
          stepAddStage === 2 ? " step2" : ""
        }`}
      >
        <label className="itp-stage-add-title">Add a Stage</label>

        {stepAddStage === 1 && (
          <div className="d-flex justify-content-between align-items-center">
            <label className="itp-stage-add-label">
              No stage is added in the funnel yet.
            </label>
            <button
              onClick={() => selectStepAddStage("next")}
              className="btn-primary itp-stage-add-button"
            >
              <FontAwesomeIcon icon={faPlus} className="itp-icon-plus" />
              Add Stage
            </button>
          </div>
        )}

        {stepAddStage === 2 && (
          <>
            <Row className="itp-container-label-input">
              <Col
                xs={5}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                xxl={5}
                className="itp-label-input-col"
              >
                <label className="itp-label-input__label">Stage Name</label>
              </Col>
              <Col
                xs={7}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                xxl={7}
                className="itp-label-input-col"
              >
                <input
                  type="text"
                  placeholder=": Add a name for the stage"
                  className="form-control-itp itp-label-input__input itp-add-stage-input"
                  name="name"
                  onChange={(e) => {
                    setNewFormState({ ...newFormStage, name: e.target.value });
                  }}
                  value={newFormStage.name}
                />
              </Col>
            </Row>
          </>
        )}
      </div>

      {stepAddStage === 2 && (
        <div className="itp-add-stage-buttons">
          <button
            className="btn-light itp-contact-button"
            onClick={() => selectStepAddStage("back")}
          >
            Back
          </button>
          <button className="btn-primary itp-contact-button" onClick={newStage}>
            Save
          </button>
        </div>
      )}

      {loading ? (
        <>
          {fakestages.map((item, i) => (
            <Row
              key={i}
              style={{ marginBottom: "12px" }}
              className="d-flex align-items-center"
            >
              <Col className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-between">
                  <Skeleton height={50} width="170px" />
                </div>
              </Col>
              <Col style={{ flex: "0 0 auto", width: "4%" }}>
                <img
                  src={deleteIcon}
                  alt="Delete"
                  onClick={() => removedStagesList(item)}
                  className="icon-hover itp-stages-delete-icon"
                />
              </Col>
              <Col>
                <Skeleton height={50} width="170px" />
              </Col>
            </Row>
          ))}
        </>
      ) : (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="element-column">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  {stages.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <Row
                            style={{ marginBottom: "12px" }}
                            className="d-flex align-items-center"
                          >
                            <Col className="d-flex justify-content-between align-items-center">
                              <div className="d-flex justify-content-between">
                                <input
                                  name={`value_${item.name}_${item.pk}`}
                                  value={
                                    value[`value_${item?.name}_${item?.pk}`] ||
                                    ""
                                  }
                                  onChange={(e) => onChangeinput(e, item.pk)}
                                  disabled={selectDisabledButton(item)}
                                  type="text"
                                  className="form-control-itp"
                                />
                                <FontAwesomeIcon
                                  className="itp-stages-drag-icon"
                                  icon={faGripVertical}
                                />
                              </div>
                            </Col>
                            <Col style={{ flex: "0 0 auto", width: "4%" }}>
                              {!item.is_lost &&
                                !item.is_lead &&
                                !item.is_won && (
                                  <img
                                    src={deleteIcon}
                                    alt="Delete"
                                    onClick={() => removedStagesList(item)}
                                    className="icon-hover itp-stages-delete-icon"
                                  />
                                )}
                            </Col>
                            <Col></Col>
                          </Row>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}

      {!loading &&
        stages.length < 3 &&
        stages.length > 0 &&
        lessThanThreeStages()}

      <button
        onClick={saveFunction}
        className="btn-primary itp-lead-detail-button-save loading"
        disabled={loadingButton || stages.length < 3}
      >
        {loadingButton && <LoadingButton />}
        Save Changes
      </button>
    </>
  );
};

export default Stages;
