import { Voice360Chat } from "../components";
import { AdvancedCallFlows, AI, Analytics, APIKeys, Billing, ContactList, Contacts, Conversations, Dashboard, Dlc, Forms, FunnelDetails, Funnels, GeneralSettings, Leads, LivePanel, LivePanelSettings, PhoneNumbers, SmartCampaigns, SupportCenter, Tags, Troubleshooting, Users, ViewLogs, Webhooks, WorkFlows, EditNumber, EditCallFlowNumbers, TimeGroupsDetails, QueueDetails, EditUSer, MySettings, CallHistory, Integrations, FollowTasks } from "../pages/private";
import { Test } from "../pages/public";

export const routes = [
  {
    url: "test",
    component: Test,
    public: false,
  },
  {
    url: "Analytics",
    component: Analytics,
    public: false,
  },
  {
    url: "Contacts",
    component: Contacts,
    public: false,
  },
  {
    url: "Contacts/Contact-List",
    component: ContactList,
    public: false,
  },
  {
    url: "Contacts/Tags",
    component: Tags,
    public: false,
  },
  {
    url: "Dashboard",
    component: Dashboard,
    public: false,
  },
  {
    url: "Funnels",
    component: Funnels,
    public: false,
  },
  {
    url: "Funnels/Funnel-Details",
    component: FunnelDetails,
    public: false,
  },
  {
    url: "Leads",
    component: Leads,
    public: false,
  },
  {
    url: "Live-Panel",
    component: LivePanel,
    public: false,
  },
  {
    url: "Smart-Campaigns",
    component: SmartCampaigns,
    public: false,
  },
  {
    url: "Smart-Campaigns/View-Logs",
    component: ViewLogs,
    public: false,
  },
  {
    url: "Smart-Campaigns/Follow-Tasks",
    component: FollowTasks,
    public: false,
  },
  {
    url: "Support-Center",
    component: SupportCenter,
    public: false,
  },
  // {
  //   url: "Conversations",
  //   component: Conversations,
  //   public: false,
  // },
  // {
  //   url: "Conversations",
  //   component: Voice360Chat,
  //   public: false,
  // },
  {
    url: "My-Inbox",
    component: Conversations,
    public: false,
  },
  {
    url: "My-Settings",
    component: MySettings,
    public: false,
  },
  {
    url: "General-Settings",
    component: GeneralSettings,
    public: false,
  },
  {
    url: "API-Keys",
    component: APIKeys,
    public: false,
  },
  //////////////////
  {
    url: "Forms",
    component: Forms,
    public: false,
  },
  {
    url: "10dlc",
    component: Dlc,
    public: false,
  },
  {
    url: "Webhooks",
    component: Webhooks,
    public: false,
  },
  {
    url: "Phone-Numbers",
    component: PhoneNumbers,
    public: false,
  },
  {
    url: "Phone-Numbers/Edit-Number",
    component: EditNumber,
    public: false,
  },
  {
    url: "Workflows",
    component: WorkFlows,
    public: false,
  },
  {
    url: "Advanced-Call-flows",
    component: AdvancedCallFlows,
    public: false,
  },
  {
    url: "Advanced-Call-flows/Edit-Numbers",
    component: EditCallFlowNumbers,
    public: false,
  },
  {
    url: "Advanced-Call-flows/Time-Group-Details",
    component: TimeGroupsDetails,
    public: false,
  },
  {
    url: "Advanced-Call-flows/Edit-Queue",
    component: QueueDetails,
    public: false,
  },
  {
    url: "Users",
    component: Users,
    public: false,
  },
  {
    url: "Users/Edit-User",
    component: EditUSer,
    public: false,
  },
  {
    url: "Live-Panel-Settings",
    component: LivePanelSettings,
    public: false,
  },
  {
    url: "Troubleshooting",
    component: Troubleshooting,
    public: false,
  },
  {
    url: "AI",
    component: AI,
    public: false,
  },
  {
    url: "Billing",
    component: Billing,
    public: false,
  },
  {
    url: "Call-History",
    component: CallHistory,
    public: false,
  },
  {
    url: "Integrations",
    component: Integrations,
    public: false,
  },
];
