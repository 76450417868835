/* eslint-disable react-hooks/exhaustive-deps */
import { CustomTableMain, LabelInput, LabelSelect, LoadingButton } from "../../../../../../../../components";
import Switch from "react-switch";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import petitionGet from "../../../../../../../../services/petitionGet";
import Skeleton from "react-loading-skeleton";
import "./AddEditAIAgent.css"

const AddEditAIAgent = ({
  info,
  form,
  handleChangeForm,
  loadingButton,
  handleChangeswitch,
  handleSave,
  loading,
  users,
  integrationsTable,
  personalities,
  arrInputsNotFilled,
}) => {
  const loadOptionFunnel = (inputValue, callback) => {
    const parameter = inputValue ? `?name=${inputValue}` : "?offset=0&limit=10";
    petitionGet("funnels", { parameter })
      .then(({ data: result }) => {
        result.result.forEach((funnel) => {
          funnel.label = funnel?.name || "";
          funnel.value = funnel?.pk || null;

          delete funnel.stages
        });

        callback(result.result);
      })
      .catch((error) => console.log(error));
  };

  const loadQuestionListOptions = async (inputValue, callback) => {
    const parameter = inputValue ? `?name=${inputValue}` : "?offset=0&limit=10";
    petitionGet("salesLead", { parameter })
      .then(({ data: result }) => {
        result.result.forEach((funnel) => {
          funnel.label = funnel.name;
          funnel.value = funnel.pk;
        });

        callback(result.result);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <LabelInput 
        error={arrInputsNotFilled.includes("name")} 
        label="Name" 
        loading={loading && info}
      >
        <input
          type="text"
          placeholder=": Enter ai agent name"
          className="form-control-itp itp-label-input__input"
          name="name"
          id="name"
          value={form.name}
          onChange={handleChangeForm}
        />
      </LabelInput>

      <LabelInput 
        error={arrInputsNotFilled.includes("description")} 
        label="Description" 
        loading={loading && info}
      >
        <input
          type="text"
          placeholder=": Enter ai description"
          className="form-control-itp itp-label-input__input"
          name="description"
          id="description"
          value={form.description}
          onChange={handleChangeForm}
        />
      </LabelInput>

      {((form.name && form.description) || (loading && info)) && (
        <>
          <LabelSelect label="Agent Type" loading={loading}>
            <select 
              className={`form-select-itp ${arrInputsNotFilled.includes("agent_type") ? "error" : ""}`}
              name="agent_type"
              id="agent_type"
              value={form.agent_type}
              onChange={handleChangeForm}
            >
              <option value="" disabled>Select Option</option>
              <option value="general">General</option>
              <option value="sales">Sales</option>
            </select>
          </LabelSelect>

          <div className="itp-ai__ai-agent-fields space-field">
            <div className="itp-ai__ai-agent-field">
              <label className="itp-edit-number-label-1">
                Appointment Scheduling
              </label>
              <label className="itp-edit-number-label-2">
                Ability to Create or Update appointments within User Assigned
                Calendars
              </label>
            </div>

            <div className="itp-ai__ai-agent-container-input">
              {loading ? (
                <Skeleton width="5rem" height="2rem" />
              ) : (
                <Switch
                  className="itp-custom-table-switch mt-3"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={form.ai_manage_appointments}
                  onChange={() => handleChangeswitch("ai_manage_appointments")}
                />
              )}
            </div>
          </div>

          {form.ai_manage_appointments && (
            <div className="itp-ai__ai-agent-fields space-field">
              <div className="itp-ai__ai-agent-field">
                <label className="itp-edit-number-label-1">User Calendars</label>
                <label className="itp-edit-number-label-2">
                  Select which user calendars to use for scheduling appointments
                </label>
              </div>

              <div className="itp-ai__ai-agent-container-input input-auto">
                {loading ? (
                  <Skeleton width="10rem" height="2.5rem" />
                ) : (
                  <Select
                    isMulti
                    onChange={(e) => handleChangeForm(e, "assigned_users")}
                    className={`basic-single ai-select-auto ${arrInputsNotFilled.includes("assigned_users") ? "error" : ""}`}
                    classNamePrefix={`select ${arrInputsNotFilled.includes("assigned_users") ? "error" : ""}`}
                    value={form.assigned_users}
                    isSearchable={true}
                    name="arrayUsersSelected"
                    placeholder="Select Option"
                    options={users}
                  />
                )}
              </div>
            </div>
          )}

          <div className="itp-ai__ai-agent-fields space-field">
            <div className="itp-ai__ai-agent-field">
              <label className="itp-edit-number-label-1">
                Appointment Scheduling Strategy
              </label>
              <label className="itp-edit-number-label-2">
                Select how we should schedule appointments when there is more
                than one user
              </label>
            </div>

            <div className="itp-ai__ai-agent-container-input">
              {loading ? (
                <Skeleton width="10rem" height="2.5rem" />
              ) : (
                <select
                  style={{ width: "10rem" }}
                  className={`form-select-itp ms-0 ${arrInputsNotFilled.includes("ai_calender_strategy") ? "error" : ""}`}
                  value={form.ai_calender_strategy}
                  name="ai_calender_strategy"
                  onChange={handleChangeForm}
                >
                  <option value="" disabled>
                    Select Option
                  </option>
                  <option value="round_robin">Round Robin</option>
                  <option value="lease_busy">Lease Busy</option>
                  <option value="random">Random</option>
                </select>
              )}
            </div>
          </div>

          <div className="itp-ai__ai-agent-fields space-field">
            <div className="itp-ai__ai-agent-field">
              <label className="itp-edit-number-label-1">FAQ</label>
              <label className="itp-edit-number-label-2">
                Ability to respond with answers from the FAQ if a question is
                asked related to your industry.
              </label>
            </div>

            <div className="itp-ai__ai-agent-container-input">
              {loading ? (
                <Skeleton width="5rem" height="2rem" />
              ) : (
                <Switch
                  className="itp-custom-table-switch mt-3"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={form.ai_faq}
                  onChange={() => handleChangeswitch("ai_faq")}
                />
              )}
            </div>
          </div>

          <div className="itp-ai__ai-agent-fields space-field">
            <div className="itp-ai__ai-agent-field w-90">
              <label className="itp-edit-number-label-1">Lead Management</label>
              <label className="itp-edit-number-label-2">
                Ability to Create, Update Leads, New leads will be assigned to
                Funnels and Stages which closely resemble the conversation flow.
                Ensure accurate descriptions and names are used in Funnels and
                Funnels Stages
              </label>
            </div>

            <div className="itp-ai__ai-agent-container-input">
              {loading ? (
                <Skeleton width="5rem" height="2rem" />
              ) : (
                <Switch
                  className="itp-custom-table-switch mt-3"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={form.ai_lead_manager}
                  onChange={() => handleChangeswitch("ai_lead_manager")}
                />
              )}
            </div>
          </div>

          {form.ai_lead_manager && (
            <>
              <div className="itp-ai__ai-agent-fields space-field">
                <div className="itp-ai__ai-agent-field">
                  <label className="itp-edit-number-label-1">
                    Lead Management Funnel
                  </label>
                  <label className="itp-edit-number-label-2">
                    Funnel to use for Lead Management
                  </label>
                </div>

                <div className="itp-ai__ai-agent-container-input">
                  {loading ? (
                    <Skeleton width="10rem" height="2.5rem" />
                  ) : (
                    <AsyncSelect
                      onChange={(e) => handleChangeForm(e, "ai_lead_manager_funnel_id")}
                      className={`basic-single ai-select ${arrInputsNotFilled.includes("ai_lead_manager_funnel_id") ? "error" : ""}`}
                      classNamePrefix={`select ${arrInputsNotFilled.includes("ai_lead_manager_funnel_id") ? "error" : ""}`}
                      value={form.ai_lead_manager_funnel_id}
                      isSearchable={true}
                      name="ai_lead_manager_funnel_id"
                      placeholder="Select Option"
                      loadOptions={loadOptionFunnel}
                      defaultOptions
                      cacheOptions
                    />
                  )}
                </div>
              </div>

              <div className="itp-ai__ai-agent-fields space-field">
                <div className="itp-ai__ai-agent-field">
                  <label className="itp-edit-number-label-1">
                    Lead Manager Screening Questions List
                  </label>
                  <label className="itp-edit-number-label-2">
                    Screening Questions List to use for Lead Management (none)
                  </label>
                </div>

                <div className="itp-ai__ai-agent-container-input">
                  {loading ? (
                    <Skeleton width="10rem" height="2.5rem" />
                  ) : (
                    <AsyncSelect
                      onChange={(e) => handleChangeForm(e, "ai_lead_manager_screening_questions_list_id")}
                      className={`basic-single ai-select ${arrInputsNotFilled.includes("ai_lead_manager_screening_questions_list_id") ? "error" : ""}`}
                      classNamePrefix={`select ${arrInputsNotFilled.includes("ai_lead_manager_screening_questions_list_id") ? "error" : ""}`}
                      value={form.ai_lead_manager_screening_questions_list_id}
                      isSearchable={true}
                      name="ai_lead_manager_screening_questions_list_id"
                      placeholder="Select Option"
                      loadOptions={loadQuestionListOptions}
                      defaultOptions
                      cacheOptions
                    />
                  )}
                </div>
              </div>
            </>
          )}

          <div className="itp-ai__ai-agent-fields space-field">
            <div className="itp-ai__ai-agent-field">
              <label className="itp-edit-number-label-1">Personalities</label>
              <label className="itp-edit-number-label-2">
                Ability to select a personality.
              </label>
            </div>

            <div className="itp-ai__ai-agent-container-input input-auto">
              {loading ? (
                <Skeleton width="10rem" height="2.5rem" />
              ) : (
                <Select
                  onChange={(e) => handleChangeForm(e, "personality_id")}
                  className={`basic-single ai-select ${arrInputsNotFilled.includes("personality_id") ? "error" : ""}`}
                  classNamePrefix={`select ${arrInputsNotFilled.includes("personality_id") ? "error" : ""}`}
                  value={form.personality_id}
                  isSearchable={true}
                  name="personality_id"
                  placeholder="Select Option"
                  options={personalities}
                />
              )}
            </div>
          </div>

          <label className="itp-edit-number-label-1 mt-4 mb-0">Integrations</label>
          <CustomTableMain 
            data={integrationsTable}
            loading={loading}
            paginationData={loading ? 3 : 1000}
            cardColClassName="itp-ai__ai-agent-integrations-table"
            noShowPagination
            m0
          />

        </>
      )}

      <button
        onClick={handleSave}
        className="btn-primary loading mt-4"
      >
        {loadingButton && <LoadingButton />}
        {info ? "Save" : "Add AI agent"}
      </button>
    </>
  );
};

export default AddEditAIAgent;
