import petitionPatch from "../../../../../services/petitionPatch";

const editContactService = async (setError, setLoading, tempData, contact_id, reloadData) => {
  try {
    setError("");
    setLoading(true);
    const data = { ...tempData, phone: tempData?.phone?.length > 0 ? `+${tempData.phone}` : null }

    await petitionPatch("contact", { data, contact_id });

    setLoading(false);
    reloadData(false, false);
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 400) {
      const messageError = error?.response?.data?.error?.error_message;
      setError(messageError);
    }

    setLoading(false);
  }
};

export default editContactService;
