export const countryList = [
  {
    alpha2: "DZ",
    value: "DZ",
    alpha3: "DZA",
    code: "012",
    name: "Algeria",
  },
  {
    alpha2: "AO",
    value: "AO",
    alpha3: "AGO",
    code: "024",
    name: "Angola",
  },
  {
    alpha2: "BJ",
    value: "BJ",
    alpha3: "BEN",
    code: "204",
    name: "Benin",
  },
  {
    alpha2: "BW",
    value: "BW",
    alpha3: "BWA",
    code: "072",
    name: "Botswana",
  },
  {
    alpha2: "BF",
    value: "BF",
    alpha3: "BFA",
    code: "854",
    name: "Burkina Faso",
  },
  {
    alpha2: "BI",
    value: "BI",
    alpha3: "BDI",
    code: "108",
    name: "Burundi",
  },
  {
    alpha2: "CM",
    value: "CM",
    alpha3: "CMR",
    code: "120",
    name: "Cameroon",
  },
  {
    alpha2: "CV",
    value: "CV",
    alpha3: "CPV",
    code: "132",
    name: "Cape Verde",
  },
  {
    alpha2: "CF",
    value: "CF",
    alpha3: "CAF",
    code: "140",
    name: "Central African Republic",
  },
  {
    alpha2: "TD",
    value: "TD",
    alpha3: "TCD",
    code: "148",
    name: "Chad",
  },
  {
    alpha2: "KM",
    value: "KM",
    alpha3: "COM",
    code: "174",
    name: "Comoros",
  },
  {
    alpha2: "CG",
    value: "CG",
    alpha3: "COG",
    code: "178",
    name: "Congo",
  },
  {
    alpha2: "CD",
    value: "CD",
    alpha3: "COD",
    code: "180",
    name: "Congo, the Democratic Republic of the",
  },
  {
    alpha2: "CI",
    value: "CI",
    alpha3: "CIV",
    code: "384",
    name: "Cote D'Ivoire",
  },
  {
    alpha2: "DJ",
    value: "DJ",
    alpha3: "DJI",
    code: "262",
    name: "Djibouti",
  },
  {
    alpha2: "EG",
    value: "EG",
    alpha3: "EGY",
    code: "818",
    name: "Egypt",
  },
  {
    alpha2: "GQ",
    value: "GQ",
    alpha3: "GNQ",
    code: "226",
    name: "Equatorial Guinea",
  },
  {
    alpha2: "ER",
    value: "ER",
    alpha3: "ERI",
    code: "232",
    name: "Eritrea",
  },
  {
    alpha2: "ET",
    value: "ET",
    alpha3: "ETH",
    code: "231",
    name: "Ethiopia",
  },
  {
    alpha2: "GA",
    value: "GA",
    alpha3: "GAB",
    code: "266",
    name: "Gabon",
  },
  {
    alpha2: "GM",
    value: "GM",
    alpha3: "GMB",
    code: "270",
    name: "Gambia",
  },
  {
    alpha2: "GH",
    value: "GH",
    alpha3: "GHA",
    code: "288",
    name: "Ghana",
  },
  {
    alpha2: "GN",
    value: "GN",
    alpha3: "GIN",
    code: "324",
    name: "Guinea",
  },
  {
    alpha2: "GW",
    value: "GW",
    alpha3: "GNB",
    code: "624",
    name: "Guinea-Bissau",
  },
  {
    alpha2: "KE",
    value: "KE",
    alpha3: "KEN",
    code: "404",
    name: "Kenya",
  },
  {
    alpha2: "LS",
    value: "LS",
    alpha3: "LSO",
    code: "426",
    name: "Lesotho",
  },
  {
    alpha2: "LR",
    value: "LR",
    alpha3: "LBR",
    code: "430",
    name: "Liberia",
  },
  {
    alpha2: "LY",
    value: "LY",
    alpha3: "LBY",
    code: "434",
    name: "Libyan Arab Jamahiriya",
  },
  {
    alpha2: "MG",
    value: "MG",
    alpha3: "MDG",
    code: "450",
    name: "Madagascar",
  },
  {
    alpha2: "MW",
    value: "MW",
    alpha3: "MWI",
    code: "454",
    name: "Malawi",
  },
  {
    alpha2: "ML",
    value: "ML",
    alpha3: "MLI",
    code: "466",
    name: "Mali",
  },
  {
    alpha2: "MR",
    value: "MR",
    alpha3: "MRT",
    code: "478",
    name: "Mauritania",
  },
  {
    alpha2: "MU",
    value: "MU",
    alpha3: "MUS",
    code: "480",
    name: "Mauritius",
  },
  {
    alpha2: "YT",
    value: "YT",
    alpha3: "MYT",
    code: "175",
    name: "Mayotte",
  },
  {
    alpha2: "MA",
    value: "MA",
    alpha3: "MAR",
    code: "504",
    name: "Morocco",
  },
  {
    alpha2: "MZ",
    value: "MZ",
    alpha3: "MOZ",
    code: "508",
    name: "Mozambique",
  },
  {
    alpha2: "NA",
    value: "NA",
    alpha3: "NAM",
    code: "516",
    name: "Namibia",
  },
  {
    alpha2: "NE",
    value: "NE",
    alpha3: "NER",
    code: "562",
    name: "Niger",
  },
  {
    alpha2: "NG",
    value: "NG",
    alpha3: "NGA",
    code: "566",
    name: "Nigeria",
  },
  {
    alpha2: "RE",
    value: "RE",
    alpha3: "REU",
    code: "638",
    name: "Reunion",
  },
  {
    alpha2: "RW",
    value: "RW",
    alpha3: "RWA",
    code: "646",
    name: "Rwanda",
  },
  {
    alpha2: "SH",
    value: "SH",
    alpha3: "SHN",
    code: "654",
    name: "Saint Helena",
  },
  {
    alpha2: "ST",
    value: "ST",
    alpha3: "STP",
    code: "678",
    name: "Sao Tome and Principe",
  },
  {
    alpha2: "SN",
    value: "SN",
    alpha3: "SEN",
    code: "686",
    name: "Senegal",
  },
  {
    alpha2: "SC",
    value: "SC",
    alpha3: "SYC",
    code: "690",
    name: "Seychelles",
  },
  {
    alpha2: "SL",
    value: "SL",
    alpha3: "SLE",
    code: "694",
    name: "Sierra Leone",
  },
  {
    alpha2: "SO",
    value: "SO",
    alpha3: "SOM",
    code: "706",
    name: "Somalia",
  },
  {
    alpha2: "ZA",
    value: "ZA",
    alpha3: "ZAF",
    code: "710",
    name: "South Africa",
  },
  {
    alpha2: "SS",
    value: "SS",
    alpha3: "SSD",
    code: "728",
    name: "South Sudan",
  },
  {
    alpha2: "SD",
    value: "SD",
    alpha3: "SDN",
    code: "729",
    name: "Sudan",
  },
  {
    alpha2: "SZ",
    value: "SZ",
    alpha3: "SWZ",
    code: "748",
    name: "Swaziland",
  },
  {
    alpha2: "TZ",
    value: "TZ",
    alpha3: "TZA",
    code: "834",
    name: "Tanzania, United Republic of",
  },
  {
    alpha2: "TG",
    value: "TG",
    alpha3: "TGO",
    code: "768",
    name: "Togo",
  },
  {
    alpha2: "TN",
    value: "TN",
    alpha3: "TUN",
    code: "788",
    name: "Tunisia",
  },
  {
    alpha2: "UG",
    value: "UG",
    alpha3: "UGA",
    code: "800",
    name: "Uganda",
  },
  {
    alpha2: "EH",
    value: "EH",
    alpha3: "ESH",
    code: "732",
    name: "Western Sahara",
  },
  {
    alpha2: "ZM",
    value: "ZM",
    alpha3: "ZMB",
    code: "894",
    name: "Zambia",
  },
  {
    alpha2: "ZW",
    value: "ZW",
    alpha3: "ZWE",
    code: "716",
    name: "Zimbabwe",
  },
  {
    alpha2: "AF",
    value: "AF",
    alpha3: "AFG",
    code: "004",
    name: "Afghanistan",
  },
  {
    alpha2: "AM",
    value: "AM",
    alpha3: "ARM",
    code: "051",
    name: "Armenia",
  },
  {
    alpha2: "AZ",
    value: "AZ",
    alpha3: "AZE",
    code: "031",
    name: "Azerbaijan",
  },
  {
    alpha2: "BH",
    value: "BH",
    alpha3: "BHR",
    code: "048",
    name: "Bahrain",
  },
  {
    alpha2: "BD",
    value: "BD",
    alpha3: "BGD",
    code: "050",
    name: "Bangladesh",
  },
  {
    alpha2: "BT",
    value: "BT",
    alpha3: "BTN",
    code: "064",
    name: "Bhutan",
  },
  {
    alpha2: "IO",
    value: "IO",
    alpha3: "IOT",
    code: "086",
    name: "British Indian Ocean Territory",
  },
  {
    alpha2: "BN",
    value: "BN",
    alpha3: "BRN",
    code: "096",
    name: "Brunei Darussalam",
  },
  {
    alpha2: "KH",
    value: "KH",
    alpha3: "KHM",
    code: "116",
    name: "Cambodia",
  },
  {
    alpha2: "CN",
    value: "CN",
    alpha3: "CHN",
    code: "156",
    name: "China",
  },
  {
    alpha2: "CX",
    value: "CX",
    alpha3: "CXR",
    code: "162",
    name: "Christmas Island",
  },
  {
    alpha2: "CC",
    value: "CC",
    alpha3: "CCK",
    code: "166",
    name: "Cocos (Keeling) Islands",
  },
  {
    alpha2: "GE",
    value: "GE",
    alpha3: "GEO",
    code: "268",
    name: "Georgia",
  },
  {
    alpha2: "HK",
    value: "HK",
    alpha3: "HKG",
    code: "344",
    name: "Hong Kong",
  },
  {
    alpha2: "IN",
    value: "IN",
    alpha3: "IND",
    code: "356",
    name: "India",
  },
  {
    alpha2: "ID",
    value: "ID",
    alpha3: "IDN",
    code: "360",
    name: "Indonesia",
  },
  {
    alpha2: "IR",
    value: "IR",
    alpha3: "IRN",
    code: "364",
    name: "Iran, Islamic Republic of",
  },
  {
    alpha2: "IQ",
    value: "IQ",
    alpha3: "IRQ",
    code: "368",
    name: "Iraq",
  },
  {
    alpha2: "JP",
    value: "JP",
    alpha3: "JPN",
    code: "392",
    name: "Japan",
  },
  {
    alpha2: "JO",
    value: "JO",
    alpha3: "JOR",
    code: "400",
    name: "Jordan",
  },
  {
    alpha2: "KZ",
    value: "KZ",
    alpha3: "KAZ",
    code: "398",
    name: "Kazakhstan",
  },
  {
    alpha2: "KP",
    value: "KP",
    alpha3: "PRK",
    code: "408",
    name: "North Korea",
  },
  {
    alpha2: "KR",
    value: "KR",
    alpha3: "KOR",
    code: "410",
    name: "South Korea",
  },
  {
    alpha2: "KW",
    value: "KW",
    alpha3: "KWT",
    code: "414",
    name: "Kuwait",
  },
  {
    alpha2: "KG",
    value: "KG",
    alpha3: "KGZ",
    code: "417",
    name: "Kyrgyzstan",
  },
  {
    alpha2: "LB",
    value: "LB",
    alpha3: "LBN",
    code: "422",
    name: "Lebanon",
  },
  {
    alpha2: "MO",
    value: "MO",
    alpha3: "MAC",
    code: "446",
    name: "Macao",
  },
  {
    alpha2: "MY",
    value: "MY",
    alpha3: "MYS",
    code: "458",
    name: "Malaysia",
  },
  {
    alpha2: "MV",
    value: "MV",
    alpha3: "MDV",
    code: "462",
    name: "Maldives",
  },
  {
    alpha2: "MN",
    value: "MN",
    alpha3: "MNG",
    code: "496",
    name: "Mongolia",
  },
  {
    alpha2: "MM",
    value: "MM",
    alpha3: "MMR",
    code: "104",
    name: "Myanmar",
  },
  {
    alpha2: "NP",
    value: "NP",
    alpha3: "NPL",
    code: "524",
    name: "Nepal",
  },
  {
    alpha2: "OM",
    value: "OM",
    alpha3: "OMN",
    code: "512",
    name: "Oman",
  },
  {
    alpha2: "PK",
    value: "PK",
    alpha3: "PAK",
    code: "586",
    name: "Pakistan",
  },
  {
    alpha2: "PS",
    value: "PS",
    alpha3: "PSE",
    code: "275",
    name: "Palestinian Territory, Occupied",
  },
  {
    alpha2: "PH",
    value: "PH",
    alpha3: "PHL",
    code: "608",
    name: "Philippines",
  },
  {
    alpha2: "QA",
    value: "QA",
    alpha3: "QAT",
    code: "634",
    name: "Qatar",
  },
  {
    alpha2: "SA",
    value: "SA",
    alpha3: "SAU",
    code: "682",
    name: "Saudi Arabia",
  },
  {
    alpha2: "SG",
    value: "SG",
    alpha3: "SGP",
    code: "702",
    name: "Singapore",
  },
  {
    alpha2: "LK",
    value: "LK",
    alpha3: "LKA",
    code: "144",
    name: "Sri Lanka",
  },
  {
    alpha2: "SY",
    value: "SY",
    alpha3: "SYR",
    code: "760",
    name: "Syrian Arab Republic",
  },
  {
    alpha2: "TW",
    value: "TW",
    alpha3: "TWN",
    code: "158",
    name: "Taiwan",
  },
  {
    alpha2: "TJ",
    value: "TJ",
    alpha3: "TJK",
    code: "762",
    name: "Tajikistan",
  },
  {
    alpha2: "TH",
    value: "TH",
    alpha3: "THA",
    code: "764",
    name: "Thailand",
  },
  {
    alpha2: "TR",
    value: "TR",
    alpha3: "TUR",
    code: "792",
    name: "Turkey",
  },
  {
    alpha2: "TM",
    value: "TM",
    alpha3: "TKM",
    code: "795",
    name: "Turkmenistan",
  },
  {
    alpha2: "AE",
    value: "AE",
    alpha3: "ARE",
    code: "784",
    name: "United Arab Emirates",
  },
  {
    alpha2: "UZ",
    value: "UZ",
    alpha3: "UZB",
    code: "860",
    name: "Uzbekistan",
  },
  {
    alpha2: "VN",
    value: "VN",
    alpha3: "VNM",
    code: "704",
    name: "Viet Nam",
  },
  {
    alpha2: "YE",
    value: "YE",
    alpha3: "YEM",
    code: "887",
    name: "Yemen",
  },
  {
    alpha2: "AX",
    value: "AX",
    alpha3: "ALA",
    code: "248",
    name: "Åland Islands",
  },
  {
    alpha2: "AL",
    value: "AL",
    alpha3: "ALB",
    code: "008",
    name: "Albania",
  },
  {
    alpha2: "AD",
    value: "AD",
    alpha3: "AND",
    code: "020",
    name: "Andorra",
  },
  {
    alpha2: "AT",
    value: "AT",
    alpha3: "AUT",
    code: "040",
    name: "Austria",
  },
  {
    alpha2: "BY",
    value: "BY",
    alpha3: "BLR",
    code: "112",
    name: "Belarus",
  },
  {
    alpha2: "BE",
    value: "BE",
    alpha3: "BEL",
    code: "056",
    name: "Belgium",
  },
  {
    alpha2: "BA",
    value: "BA",
    alpha3: "BIH",
    code: "070",
    name: "Bosnia and Herzegovina",
  },
  {
    alpha2: "BG",
    value: "BG",
    alpha3: "BGR",
    code: "100",
    name: "Bulgaria",
  },
  {
    alpha2: "HR",
    value: "HR",
    alpha3: "HRV",
    code: "191",
    name: "Croatia",
  },
  {
    alpha2: "CY",
    value: "CY",
    alpha3: "CYP",
    code: "196",
    name: "Cyprus",
  },
  {
    alpha2: "CZ",
    value: "CZ",
    alpha3: "CZE",
    code: "203",
    name: "Czech Republic",
  },
  {
    alpha2: "DK",
    value: "DK",
    alpha3: "DNK",
    code: "208",
    name: "Denmark",
  },
  {
    alpha2: "EE",
    value: "EE",
    alpha3: "EST",
    code: "233",
    name: "Estonia",
  },
  {
    alpha2: "FO",
    value: "FO",
    alpha3: "FRO",
    code: "234",
    name: "Faroe Islands",
  },
  {
    alpha2: "FI",
    value: "FI",
    alpha3: "FIN",
    code: "246",
    name: "Finland",
  },
  {
    alpha2: "FR",
    value: "FR",
    alpha3: "FRA",
    code: "250",
    name: "France",
  },
  {
    alpha2: "GE",
    value: "GE",
    alpha3: "GEO",
    code: "268",
    name: "Georgia",
  },
  {
    alpha2: "DE",
    value: "DE",
    alpha3: "DEU",
    code: "276",
    name: "Germany",
  },
  {
    alpha2: "GI",
    value: "GI",
    alpha3: "GIB",
    code: "292",
    name: "Gibraltar",
  },
  {
    alpha2: "GR",
    value: "GR",
    alpha3: "GRC",
    code: "300",
    name: "Greece",
  },
  {
    alpha2: "VA",
    value: "VA",
    alpha3: "VAT",
    code: "336",
    name: "Holy See (Vatican City State)",
  },
  {
    alpha2: "HU",
    value: "HU",
    alpha3: "HUN",
    code: "348",
    name: "Hungary",
  },
  {
    alpha2: "IS",
    value: "IS",
    alpha3: "ISL",
    code: "352",
    name: "Iceland",
  },
  {
    alpha2: "IE",
    value: "IE",
    alpha3: "IRL",
    code: "372",
    name: "Ireland",
  },
  {
    alpha2: "IL",
    value: "IL",
    alpha3: "ISR",
    code: "376",
    name: "Israel",
  },
  {
    alpha2: "IT",
    value: "IT",
    alpha3: "ITA",
    code: "380",
    name: "Italy",
  },
  {
    alpha2: "LV",
    value: "LV",
    alpha3: "LVA",
    code: "428",
    name: "Latvia",
  },
  {
    alpha2: "LI",
    value: "LI",
    alpha3: "LIE",
    code: "438",
    name: "Liechtenstein",
  },
  {
    alpha2: "LT",
    value: "LT",
    alpha3: "LTU",
    code: "440",
    name: "Lithuania",
  },
  {
    alpha2: "LU",
    value: "LU",
    alpha3: "LUX",
    code: "442",
    name: "Luxembourg",
  },
  {
    alpha2: "MK",
    value: "MK",
    alpha3: "MKD",
    code: "807",
    name: "Macedonia, the Former Yugoslav Republic of",
  },
  {
    alpha2: "MT",
    value: "MT",
    alpha3: "MLT",
    code: "470",
    name: "Malta",
  },
  {
    alpha2: "MD",
    value: "MD",
    alpha3: "MDA",
    code: "498",
    name: "Moldova, Republic of",
  },
  {
    alpha2: "MC",
    value: "MC",
    alpha3: "MCO",
    code: "492",
    name: "Monaco",
  },
  {
    alpha2: "ME",
    value: "ME",
    alpha3: "MNE",
    code: "499",
    name: "Montenegro",
  },
  {
    alpha2: "NL",
    value: "NL",
    alpha3: "NLD",
    code: "528",
    name: "Netherlands",
  },
  {
    alpha2: "NO",
    value: "NO",
    alpha3: "NOR",
    code: "578",
    name: "Norway",
  },
  {
    alpha2: "PL",
    value: "PL",
    alpha3: "POL",
    code: "616",
    name: "Poland",
  },
  {
    alpha2: "PT",
    value: "PT",
    alpha3: "PRT",
    code: "620",
    name: "Portugal",
  },
  {
    alpha2: "RO",
    value: "RO",
    alpha3: "ROU",
    code: "642",
    name: "Romania",
  },
  {
    alpha2: "RU",
    value: "RU",
    alpha3: "RUS",
    code: "643",
    name: "Russian Federation",
  },
  {
    alpha2: "SM",
    value: "SM",
    alpha3: "SMR",
    code: "674",
    name: "San Marino",
  },
  {
    alpha2: "RS",
    value: "RS",
    alpha3: "SRB",
    code: "688",
    name: "Serbia",
  },
  {
    alpha2: "SK",
    value: "SK",
    alpha3: "SVK",
    code: "703",
    name: "Slovakia",
  },
  {
    alpha2: "SI",
    value: "SI",
    alpha3: "SVN",
    code: "705",
    name: "Slovenia",
  },
  {
    alpha2: "ES",
    value: "ES",
    alpha3: "ESP",
    code: "724",
    name: "Spain",
  },
  {
    alpha2: "SE",
    value: "SE",
    alpha3: "SWE",
    code: "752",
    name: "Sweden",
  },
  {
    alpha2: "CH",
    value: "CH",
    alpha3: "CHE",
    code: "756",
    name: "Switzerland",
  },
  {
    alpha2: "TR",
    value: "TR",
    alpha3: "TUR",
    code: "792",
    name: "Turkey",
  },
  {
    alpha2: "UA",
    value: "UA",
    alpha3: "UKR",
    code: "804",
    name: "Ukraine",
  },
  {
    alpha2: "GB",
    value: "GB",
    alpha3: "GBR",
    code: "826",
    name: "United Kingdom",
  },
  {
    alpha2: "AI",
    value: "AI",
    alpha3: "AIA",
    code: "660",
    name: "Anguilla",
  },
  {
    alpha2: "AG",
    value: "AG",
    alpha3: "ATG",
    code: "028",
    name: "Antigua and Barbuda",
  },
  {
    alpha2: "AW",
    value: "AW",
    alpha3: "ABW",
    code: "533",
    name: "Aruba",
  },
  {
    alpha2: "BS",
    value: "BS",
    alpha3: "BHS",
    code: "044",
    name: "Bahamas",
  },
  {
    alpha2: "BB",
    value: "BB",
    alpha3: "BRB",
    code: "052",
    name: "Barbados",
  },
  {
    alpha2: "BZ",
    value: "BZ",
    alpha3: "BLZ",
    code: "084",
    name: "Belize",
  },
  {
    alpha2: "BM",
    value: "BM",
    alpha3: "BMU",
    code: "060",
    name: "Bermuda",
  },
  {
    alpha2: "VG",
    value: "VG",
    alpha3: "VGB",
    code: "092",
    name: "Virgin Islands, British",
  },
  {
    alpha2: "CA",
    value: "CA",
    alpha3: "CAN",
    code: "124",
    name: "Canada",
  },
  {
    alpha2: "KY",
    value: "KY",
    alpha3: "CYM",
    code: "136",
    name: "Cayman Islands",
  },
  {
    alpha2: "CR",
    value: "CR",
    alpha3: "CRI",
    code: "188",
    name: "Costa Rica",
  },
  {
    alpha2: "CU",
    value: "CU",
    alpha3: "CUB",
    code: "192",
    name: "Cuba",
  },
  {
    alpha2: "DM",
    value: "DM",
    alpha3: "DMA",
    code: "212",
    name: "Dominica",
  },
  {
    alpha2: "DO",
    value: "DO",
    alpha3: "DOM",
    code: "214",
    name: "Dominican Republic",
  },
  {
    alpha2: "SV",
    value: "SV",
    alpha3: "SLV",
    code: "222",
    name: "El Salvador",
  },
  {
    alpha2: "GL",
    value: "GL",
    alpha3: "GRL",
    code: "304",
    name: "Greenland",
  },
  {
    alpha2: "GD",
    value: "GD",
    alpha3: "GRD",
    code: "308",
    name: "Grenada",
  },
  {
    alpha2: "GP",
    value: "GP",
    alpha3: "GLP",
    code: "312",
    name: "Guadeloupe",
  },
  {
    alpha2: "GT",
    value: "GT",
    alpha3: "GTM",
    code: "320",
    name: "Guatemala",
  },
  {
    alpha2: "HT",
    value: "HT",
    alpha3: "HTI",
    code: "332",
    name: "Haiti",
  },
  {
    alpha2: "HN",
    value: "HN",
    alpha3: "HND",
    code: "340",
    name: "Honduras",
  },
  {
    alpha2: "JM",
    value: "JM",
    alpha3: "JAM",
    code: "388",
    name: "Jamaica",
  },
  {
    alpha2: "MQ",
    value: "MQ",
    alpha3: "MTQ",
    code: "474",
    name: "Martinique",
  },
  {
    alpha2: "MX",
    value: "MX",
    alpha3: "MEX",
    code: "484",
    name: "Mexico",
  },
  {
    alpha2: "NI",
    value: "NI",
    alpha3: "NIC",
    code: "558",
    name: "Nicaragua",
  },
  {
    alpha2: "PA",
    value: "PA",
    alpha3: "PAN",
    code: "591",
    name: "Panama",
  },
  {
    alpha2: "PR",
    value: "PR",
    alpha3: "PRI",
    code: "630",
    name: "Puerto Rico",
  },
  {
    alpha2: "KN",
    value: "KN",
    alpha3: "KNA",
    code: "659",
    name: "Saint Kitts and Nevis",
  },
  {
    alpha2: "LC",
    value: "LC",
    alpha3: "LCA",
    code: "662",
    name: "Saint Lucia",
  },
  {
    alpha2: "PM",
    value: "PM",
    alpha3: "SPM",
    code: "666",
    name: "Saint Pierre and Miquelon",
  },
  {
    alpha2: "VC",
    value: "VC",
    alpha3: "VCT",
    code: "670",
    name: "Saint Vincent and the Grenadines",
  },
  {
    alpha2: "BL",
    value: "BL",
    alpha3: "BLM",
    code: "652",
    name: "Saint Barthélemy",
  },
  {
    alpha2: "TT",
    value: "TT",
    alpha3: "TTO",
    code: "780",
    name: "Trinidad and Tobago",
  },
  {
    alpha2: "TC",
    value: "TC",
    alpha3: "TCA",
    code: "796",
    name: "Turks and Caicos Islands",
  },
  {
    alpha2: "US",
    value: "US",
    alpha3: "USA",
    code: "840",
    name: "United States of America",
  },
  {
    alpha2: "VI",
    value: "VI",
    alpha3: "VIR",
    code: "850",
    name: "Virgin Islands, U.S.",
  },
  {
    alpha2: "AS",
    value: "AS",
    alpha3: "ASM",
    code: "016",
    name: "American Samoa",
  },
  {
    alpha2: "AU",
    value: "AU",
    alpha3: "AUS",
    code: "036",
    name: "Australia",
  },
  {
    alpha2: "CK",
    value: "CK",
    alpha3: "COK",
    code: "184",
    name: "Cook Islands",
  },
  {
    alpha2: "FJ",
    value: "FJ",
    alpha3: "FJI",
    code: "242",
    name: "Fiji",
  },
  {
    alpha2: "PF",
    value: "PF",
    alpha3: "PYF",
    code: "258",
    name: "French Polynesia",
  },
  {
    alpha2: "GU",
    value: "GU",
    alpha3: "GUM",
    code: "316",
    name: "Guam",
  },
  {
    alpha2: "KI",
    value: "KI",
    alpha3: "KIR",
    code: "296",
    name: "Kiribati",
  },
  {
    alpha2: "MH",
    value: "MH",
    alpha3: "MHL",
    code: "584",
    name: "Marshall Islands",
  },
  {
    alpha2: "FM",
    value: "FM",
    alpha3: "FSM",
    code: "583",
    name: "Micronesia, Federated States of",
  },
  {
    alpha2: "NR",
    value: "NR",
    alpha3: "NRU",
    code: "520",
    name: "Nauru",
  },
  {
    alpha2: "NC",
    value: "NC",
    alpha3: "NCL",
    code: "540",
    name: "New Caledonia",
  },
  {
    alpha2: "NZ",
    value: "NZ",
    alpha3: "NZL",
    code: "554",
    name: "New Zealand",
  },
  {
    alpha2: "NU",
    value: "NU",
    alpha3: "NIU",
    code: "570",
    name: "Niue",
  },
  {
    alpha2: "NF",
    value: "NF",
    alpha3: "NFK",
    code: "574",
    name: "Norfolk Island",
  },
  {
    alpha2: "MP",
    value: "MP",
    alpha3: "MNP",
    code: "580",
    name: "Northern Mariana Islands",
  },
  {
    alpha2: "PW",
    value: "PW",
    alpha3: "PLW",
    code: "585",
    name: "Palau",
  },
  {
    alpha2: "PG",
    value: "PG",
    alpha3: "PNG",
    code: "598",
    name: "Papua New Guinea",
  },
  {
    alpha2: "PN",
    value: "PN",
    alpha3: "PCN",
    code: "612",
    name: "Pitcairn",
  },
  {
    alpha2: "WS",
    value: "WS",
    alpha3: "WSM",
    code: "882",
    name: "Samoa",
  },
  {
    alpha2: "SB",
    value: "SB",
    alpha3: "SLB",
    code: "090",
    name: "Solomon Islands",
  },
  {
    alpha2: "TL",
    value: "TL",
    alpha3: "TLS",
    code: "626",
    name: "Timor-Leste",
  },
  {
    alpha2: "TO",
    value: "TO",
    alpha3: "TON",
    code: "776",
    name: "Tonga",
  },
  {
    alpha2: "VU",
    value: "VU",
    alpha3: "VUT",
    code: "548",
    name: "Vanuatu",
  },
  {
    alpha2: "AR",
    value: "AR",
    alpha3: "ARG",
    code: "032",
    name: "Argentina",
  },
  {
    alpha2: "BO",
    value: "BO",
    alpha3: "BOL",
    code: "068",
    name: "Bolivia",
  },
  {
    alpha2: "BR",
    value: "BR",
    alpha3: "BRA",
    code: "076",
    name: "Brazil",
  },
  {
    alpha2: "CL",
    value: "CL",
    alpha3: "CHL",
    code: "152",
    name: "Chile",
  },
  {
    alpha2: "CO",
    value: "CO",
    alpha3: "COL",
    code: "170",
    name: "Colombia",
  },
  {
    alpha2: "EC",
    value: "EC",
    alpha3: "ECU",
    code: "218",
    name: "Ecuador",
  },
  {
    alpha2: "FK",
    value: "FK",
    alpha3: "FLK",
    code: "238",
    name: "Falkland Islands (Malvinas)",
  },
  {
    alpha2: "GF",
    value: "GF",
    alpha3: "GUF",
    code: "254",
    name: "French Guiana",
  },
  {
    alpha2: "GY",
    value: "GY",
    alpha3: "GUY",
    code: "328",
    name: "Guyana",
  },
  {
    alpha2: "PY",
    value: "PY",
    alpha3: "PRY",
    code: "600",
    name: "Paraguay",
  },
  {
    alpha2: "PE",
    value: "PE",
    alpha3: "PER",
    code: "604",
    name: "Peru",
  },
  {
    alpha2: "UY",
    value: "UY",
    alpha3: "URY",
    code: "858",
    name: "Uruguay",
  },
  {
    alpha2: "VE",
    value: "VE",
    alpha3: "VEN",
    code: "862",
    name: "Venezuela",
  },
];

export const orderedCountryList = [
  {
    alpha2: "CA",
    value: "CA",
    alpha3: "CAN",
    code: "124",
    name: "Canada",
    label: "Canada",
  },
  {
    alpha2: "US",
    value: "US",
    alpha3: "USA",
    code: "840",
    name: "United States of America",
    label: "United States of America",
  },
]