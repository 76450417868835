import { useState } from "react";
import { CallTranscription, MenuRight, AudioMessage } from "../../../../index";
import { formatDateTwo } from "../../../../../utils/getDate";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";

const RecordedCall = ({ element, isOther, chatWindowData }) => {
  const show = element.audio_message && (element.item_type !== "call" || (element.item_type === "call" && element.cdr && element.cdr.call_recording_filename)) ? true : false
  
  const [openMenu, setOpenMenu] = useState(false);
  const [menuTitle, setMenuTitle] = useState("")

  const activeOpenMenu = () => {
    if (element?.cdr?.callTranscription) {
      const date = formatDateTwo(element?.message_timestamp || "")
      const number = formatPhoneNumber(chatWindowData?.notIsSelf?.number || "")
      const title = `Transcription for ${number} on ${date}`
      setMenuTitle(title)
      setOpenMenu(true)
    }
  }

  const closeMenu = () => {
    setOpenMenu(false)
  }
  
  return show && (
    <>
      <AudioMessage
        audioUrl={element.audio_message}
        className={`recorder-call-message ${element?.cdr?.transcriptionPreview ? "" : "mb-4"}`}
        variant={isOther ? "secondary" : ""}
        externalFunction={activeOpenMenu}
        transcriptionPreview={element?.cdr?.transcriptionPreview || ""}
        downloadWhenClicked
      />

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title={menuTitle}
        to="to messages"
      >
        <CallTranscription 
          transcriptions={element?.call_transcription?.formatedTranscription || []}
          audioUrl={element.audio_message}
          callSummaryData={element?.call_transcription}
        />
      </MenuRight>
    </>
  )
};

export default RecordedCall;
