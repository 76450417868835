/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import useUndo from "use-undo";
import Skeleton from "react-loading-skeleton";
import "./styles.css";

export default function TextAreaVarSelect({
  options,
  onChangeTextArea,
  textAreaDefaultValue,
  loading,
}) {
  const [dropdownOpen, setDropdownOpen] = useState([]);
  const [textAreaValue, setTextAreaValue] = useState("");

  const [
    TextState,
    {
      set: setText,
      undo: undoText,
      redo: redoText,
      canUndo,
      canRedo,
    },
  ] = useUndo(textAreaDefaultValue);

  const { present: presentText } = TextState;

  const toggleDropdown = (index) => {
    const newDropdownOpen = [...dropdownOpen];
    newDropdownOpen[index] = !newDropdownOpen[index];
    setDropdownOpen(newDropdownOpen);
  };

  const onUndoClick = () => undoText();
  const onRedoClick = () => redoText();

  const handleOptionClick = (optionLabel) => {
    const textArea = document.querySelector("textarea");
    const cursorPos = textArea.selectionStart;

    const returnNewValueWithOptionLabel = () => {
      const start = textAreaValue.substring(0, cursorPos);
      const end = textAreaValue.substring(cursorPos);
      const newValue = start + optionLabel + end;
      textArea.focus();
      return newValue;
    };

    const newValue = returnNewValueWithOptionLabel();
    setText(newValue);
    setTextAreaValue(newValue);

    textArea.setSelectionRange(cursorPos + optionLabel.length, cursorPos + optionLabel.length);
  };

  useEffect(() => {
    if (textAreaDefaultValue) {
      setTextAreaValue(textAreaDefaultValue);
    }
  }, [textAreaDefaultValue]);

  // Llamar onChangeTextArea cada vez que el texto cambia, incluso con undo/redo
  useEffect(() => {
    onChangeTextArea(presentText);
  }, [presentText]);

  return (
    <>
      <div style={{ width: "100%" }}>
        <div
          style={{
            height: "30px",
            border: "1px solid #ccc",
            borderBottom: "none",
            background: "#F1F1F1",
            display: "flex",
          }}
        >
          <Button className="CustomDropDown undo" onClick={onUndoClick} disabled={!canUndo}>
            <UndoIcon />
          </Button>
          <Button className="CustomDropDown redo" onClick={onRedoClick} disabled={!canRedo}>
            <RedoIcon />
          </Button>
          {options &&
            options.map((element, index) => (
              <Dropdown
                isOpen={dropdownOpen[index]}
                disabled={loading}
                toggle={() => toggleDropdown(index)}
                key={index}
                className="dropDownTextAreaSelect"
              >
                <DropdownToggle caret className="CustomDropDown">
                  {element.title}
                </DropdownToggle>
                <DropdownMenu>
                  {element.options.map((group, i) => (
                    <React.Fragment key={i}>
                      <DropdownItem header>{group.title}</DropdownItem>
                      {group.options.map((option, index) => (
                        <DropdownItem key={index} onClick={() => handleOptionClick(option.value)}>
                          {option.label}
                        </DropdownItem>
                      ))}
                      {i !== element.options.length - 1 && <DropdownItem divider />}
                    </React.Fragment>
                  ))}
                </DropdownMenu>
              </Dropdown>
            ))}
        </div>

        {loading ? (
          <div className="w-100">
            <Skeleton height="7rem" width="100%" />
          </div>
        ) : (
          <textarea
            rows="10"
            style={{ width: "100%", border: "1px solid #ccc" }}
            value={textAreaValue}
            onChange={(e) => {
              setText(e.target.value);
              setTextAreaValue(e.target.value);
            }}
          ></textarea>
        )}
      </div>
    </>
  );
}
