import petitionPatch from "../../../../../services/petitionPatch";
import { fetchContactListDetails } from "../index";

const defaultFiltersService = async (myExtension) => {
  let myExtensionTemp = myExtension

  //se pregunta primero si existe el campo que guarda los filtros de contactos si no existe se retorna null
  if ( myExtension?.crm_filters?.contacts &&  Object.keys(myExtension?.crm_filters?.contacts).length > 0 ) {
    let filterActive = {};

    // si existen los campos added_after y added_before existen se unen y se crea un nuevo campo con el nombre Contact Added Within
    const { added_after, added_before } = myExtension?.crm_filters?.contacts;

    if (added_after && added_before) {
      filterActive = {
        ...filterActive,
        "Contact Added Within": `${added_after.replaceAll("-",  "/")}-${added_before.replaceAll("-", "/")}`,
      };
    }

    const objKeys = Object.keys(myExtension?.crm_filters?.contacts);

    //luego se aceden a las keys del objeto que guardan los filtros, excepto a la de contact list (si existe)
    objKeys.forEach((element) => {
      //aquí solo se preguntán si la key es diferente a added_after y added_before
      //ya que se trataron arriba y no se tratan en esta sección
      if (element !== "added_after" && element !== "added_before") {
        //guardamos en una constante el valor del campo al que accedemos por el nombre
        const value = myExtension?.crm_filters?.contacts[element];

        //si hay una key que tiene el prefijo custom_ y es mayor a 1 significa que es un custom field
        if (element.split("custom_").length > 1) {
          //aquí si el valor del custom field es true o false se cambia por Enabled o Disabled si o es booleano se queda el valor que está
          filterActive = {
            ...filterActive,
            [`${element.split("custom_")[1]}`]: typeof value === "boolean" ? value ? "Enabled" : "Disabled" : value,
          };
        } else {
          // si hay tags en los filtros se accede a sus nombres que están en un array y luego se unen con el caracter ", "
          if (element === "tags") {
            filterActive = {
              ...filterActive,
              Tags: value.map((tag) => tag.name).join(", "),
            };

            //si existe el campo de dnd_sms se coloca como nombre DND SMS
          } else if (element === "dnd_sms") {
            filterActive = {
              ...filterActive,
              "DND SMS": value === false ? "Disabled" : value === true ? "Enabled" : "",
            };
          } else if (element !== "contact_list_id") {
            filterActive = {
              ...filterActive,
              [`${element === "owner_id" ? "Only Show Contacts Assigned to Me" : element}`]: element === "owner_id" ? "Enabled" : value,
            };
          }
        }
      }
    });

    //luego verificamos si existe el filtro de contact list
    if (myExtension?.crm_filters?.contacts?.contact_list_id?.pk) {
      const pk = myExtension?.crm_filters?.contacts?.contact_list_id?.pk;
      const contactListDetails = await fetchContactListDetails(pk);

      if (contactListDetails) {
        myExtensionTemp = { 
          ...myExtensionTemp,
          crm_filters: {
            ...myExtensionTemp.crm_filters,
            contacts: {
              ...myExtensionTemp.crm_filters.contacts,
              contact_list_id: { ...contactListDetails },
            }
          }  
        }
  
        filterActive = {
          ...filterActive,
          "Contact List": contactListDetails.label,
        };
      } else {
        const data = {crm_filters: { contacts: myExtensionTemp.crm_filters.contacts }}
        delete data.crm_filters.contacts.contact_list_id

        const { data: result } = await petitionPatch("userDetails", { data })
        myExtensionTemp = { ...myExtensionTemp, ...result.result };
      }
    }

    return { filterActive, myExtensionTemp};
  } else {
    return { filterActive: null, myExtensionTemp};
  }
};

export default defaultFiltersService;
