import { convertTimeStapToTimeZoneOnFormated } from "../../../../../utils/getDate";
import { capitalizeWords } from "../../../../../utils/capitalizeWords";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";
import contacts from "../../../../../assets/icons/contacts.svg";
import formatCallTranscription from "../../../../../utils/formatCallTranscription";

const findUser = (call, key, keyUserObject, columnName) => {
  const id = call[key]
      
  if (id) {
    const name = `${call[keyUserObject]?.firstname || ""} ${call[keyUserObject]?.lastname || ""}`
    return name
  } else if (Object.keys(call?.contact).length) {
    call[columnName] = true
    return <><img className="me-1" src={contacts} alt="contact" />{" "}{call?.contact?.firstname || ""} {call?.contact?.lastname || ""}</>
  } else {
    const number = call.direction === "inbound" ? call.src : call.direction	=== "outbound" ? call.dst : ""
    return formatPhoneNumber(number)
  }
}

const findExtensionNumber = (call, key, keyUserObject) => {
  const id = call[key]
  
  if (id) {
    const extensionNumber = call[keyUserObject]?.presence_id || ""
    return extensionNumber
  } else if (Object.keys(call?.contact).length) {
    return formatPhoneNumber(call?.contact?.phone)
  } else {
    return null
  }
}

const processListCallHistory = (call, myExtension) => {
  const timeZone = myExtension?.time_zone

  call.datetime = convertTimeStapToTimeZoneOnFormated(call.calldate, timeZone);
  call.formatedDirection = capitalizeWords(call?.direction)

  call.fromNumber = findUser(call, "from_user_id", "from_user", "From");
  call.fromNumberHover = findExtensionNumber(call, "from_user_id", "from_user", "To")

  call.toNumber = findUser(call, "to_user_id", "to_user");
  call.toNumberHover = findExtensionNumber(call, "to_user_id", "to_user")

  call.call_transcription = formatCallTranscription(call)

  if (call.disposition === "ANSWERED") {
    call.style = {
      background: "#F6F6F9 0% 0% no-repeat padding-box",
      borderRadius: "12px",
      font: "normal normal normal 12px/16px Instrument Sans",
      letterSpacing: "0px",
      color: "#199F28",
      opacity: "1",
    }
  } else if (call.disposition === "FAILED") {
      call.style = {
        background: "rgba(255, 0, 0, 0.2) 0% 0% no-repeat padding-box",
        borderRadius: "12px",
        font: "normal normal normal 12px/16px Instrument Sans",
        letterSpacing: "0px",
        color: "#FF0000",
        opacity: "1",
      }
  } else {
    call.style = {
      background: "#1F3D571E 0% 0% no-repeat padding-box",
      borderRadius: "12px",
      font: "normal normal normal 12px/16px Instrument Sans",
      letterSpacing: "0px",
      color: "#3A3A3ACC",
      opacity: "1",
    }
  }
}

export default processListCallHistory;