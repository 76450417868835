import { DateTime } from "luxon";

const formatHourof24at12 = (date, spliter) => {
  if (date) {
    let h = parseInt(date.split(spliter || " ")[1].split(":")[0]);
    let minutes = date.split(spliter || " ")[1].split(":")[1];
    let formatHour = ((h + 11) % 12) + 1;

    return `${formatHour}:${minutes} ${h >= 12 ? "PM" : "AM"}`;
  } else {
    return "";
  }
};

const selectedMonths = (number) => {
  switch (true) {
    case number === "01" || number === "1":
      return "January";
    case number === "02" || number === "2":
      return "February";
    case number === "03" || number === "3":
      return "March";
    case number === "04" || number === "4":
      return "April";
    case number === "05" || number === "5":
      return "May";
    case number === "06" || number === "6":
      return "June";
    case number === "07" || number === "7":
      return "July";
    case number === "08" || number === "8":
      return "August";
    case number === "09" || number === "9":
      return "September";
    case number === "010" || number === "10":
      return "October";
    case number === "011" || number === "11":
      return "November";
    case number === "012" || number === "12":
      return "December";
    default:
      return "Error";
  }
};

const formatDate = (date, spliter) => {
  let numberMonth = date.split(spliter || " ")[0].split("-")[1];

  let day = date.split(spliter || " ")[0].split("-")[2];
  let month = selectedMonths(numberMonth).slice(0, 3);
  let year = date.split(spliter || " ")[0].split("-")[0];
  let hour = formatHourof24at12(date, spliter);

  return `${month} ${day}, ${year} at ${hour}`;
};

const formatDateTwo = (date, spliter) => {
  if (date) {
    let day = date.split(spliter || " ")[0].split("-")[2];
    let numberMonth = date.split(spliter || " ")[0].split("-")[1];
    let year = date.split(spliter || " ")[0].split("-")[0];
    let hour = formatHourof24at12(date, spliter);

    return `${numberMonth}/${day}/${year} ${hour}`;
  } else {
    return "";
  }
};

const convertLocalTimeToTimezoneTime = (timeZone) => {
  const currentDateTimeLocal = new Date();

  const currentDateTime = new Date(
    currentDateTimeLocal.toLocaleString("en-US", { timeZone: timeZone })
  );

  return currentDateTime;
};

const convertLocalTimeToTimezoneTimeOnFormated = (timeZone) => {
  const currentDateTimeLocal = new Date();

  const currentDateTime = new Date(
    currentDateTimeLocal.toLocaleString("en-US", { timeZone: timeZone })
  );

  const year = currentDateTime.getFullYear();
  const month = currentDateTime.getMonth() + 1;
  const day = currentDateTime.getDate();
  const hour = currentDateTime.getHours();
  const minutes = currentDateTime.getMinutes();

  const timeStap = `${year}-${
    month > 9 ? month : `0${month}`
  }-${day} ${hour}:${minutes}:00`;

  return timeStap;
};

const covertUtcTimeToTimeZoneTime = (date, timeZone) => {
  const utcDateTime = DateTime.fromISO(date, { zone: "utc" });

  const targetDateTime = utcDateTime.setZone(timeZone);

  return targetDateTime.toISO();
};

const convertTimeStapToTimeZoneOnFormated = (value, timeZone) => {
  let time = new Date(parseInt(value) * 1000);
  let timeZoneTemp = time.toLocaleString("en-US", { timeZone: timeZone ,year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false });

  const year = timeZoneTemp.split(",")[0].split("/")[2];
  const month = timeZoneTemp.split(",")[0].split("/")[0];
  const day = timeZoneTemp.split(",")[0].split("/")[1];
  const hour = timeZoneTemp.split(",")[1].split(" ")[1];

  const formatedDate = `${year}-${month}-${day} ${hour}`;

  return formatedDate;
};

const formatLocalTimeToTimeZoneFormatedInput = (timeZone) => {
  const date = new Date();
  const localDateToTimeZoneDate = new Date(
    date.toLocaleString("en-US", { timeZone: timeZone })
  );

  const year = localDateToTimeZoneDate.getFullYear();
  const month = (localDateToTimeZoneDate.getMonth() + 1)
    .toString()
    .padStart(2, "0"); // +1 porque los meses comienzan desde 0
  const day = localDateToTimeZoneDate.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const formatLocalTimeToTimeZoneFormatedInputYesterday = (timeZone) => {
  const date = new Date();
  const localDateToTimeZoneDate = new Date(
    date.toLocaleString("en-US", { timeZone: timeZone })
  );
  
  const yesterday = localDateToTimeZoneDate
  yesterday.setDate(yesterday.getDate() - 1);

  const dateFormattedYesterday = yesterday

  const year = dateFormattedYesterday.getFullYear();
  const month = (dateFormattedYesterday.getMonth() + 1)
    .toString()
    .padStart(2, "0"); // +1 porque los meses comienzan desde 0
  const day = dateFormattedYesterday.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const dateComparision = (dateString, timeZone) => {
  if (dateString && dateString !== "") {
    const currentDate = convertLocalTimeToTimezoneTime(timeZone);
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();
    const currentDateSpliter = `${currentYear}-${currentMonth}-${currentDay}`;

    const providedDate = dateString?.split(" ")[0];
    const providedYear = providedDate.split("-")[0];
    const providedMonth = providedDate.split("-")[1];
    const providedDay = providedDate.split("-")[2];

    const daysDifference = Math.floor(
      (Date.parse(currentDateSpliter) - Date.parse(providedDate)) /
        (1000 * 60 * 60 * 24)
    );

    let result;
    let isToday = false

    if (daysDifference === 0) {
      result = "Today";
      isToday= true
    } else if (daysDifference === 1) {
      result = "Yesterday";
    } else if (daysDifference > 1 && daysDifference <= 6) {
      // Si es mayor a 1 y menor o igual a 6, determina el nombre del día de la semana
      const dayNames = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];
      const dayOfWeek = dayNames[new Date(providedDate).getDay()];
      result = dayOfWeek;
    } else {
      result = `${providedMonth}/${providedDay}/${providedYear}`;
    }

    return { result, isToday };
  } else {
    return { result: "", isToday: false };;
  }
};

const formatDateAndHour = (date, timeZone, spliter) => {
  const { result, isToday } = dateComparision(date, timeZone)
  const hour = formatHourof24at12(date, spliter)

  return `${isToday ? "" : result} ${isToday ? hour : ""}`
}

const convertUtcDateToTimeZoneDate = (dateString, timeZone) => {
  // Crea un objeto de fecha a partir de la cadena en UTC
  const utcDate = new Date(dateString);

  // Verifica si la fecha es válida
  if (isNaN(utcDate)) {
      return "Fecha no válida";
  }

  // Utiliza Intl.DateTimeFormat para convertir a la zona horaria deseada
  const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: timeZone
  };

  const formatter = new Intl.DateTimeFormat('en-GB', options);

  const formattedDate = formatter.format(utcDate).replace(',', '');
  
  // Reformatear la fecha a "yyyy-mm-dd hh:mm:ss"
  const [datePart, timePart] = formattedDate.split(' ');
  const [day, month, year] = datePart.split('/');
  
  return `${year}-${month}-${day} ${timePart}`;
}

const formatDateToUSDate = (date) => {
  // date in format yyyy-mm-dd to mm-dd-yyy

  const month = date.split("-")[1]
  const day = date.split("-")[2]
  const year = date.split("-")[0]

  return `${month}/${day}/${year}`
}

const get7DaysBeforeADate = (date) => {
  //date is yyy-mm-dd format

  // Convert the string to a Date object
  let objDate = new Date(date);

  // Subtract 6 days
  objDate.setDate(objDate.getDate() - 6);

  // Format the date back to YYYY-MM-DD
  const year = objDate.getFullYear();
  const month = String(objDate.getMonth() + 1).padStart(2, '0'); // Months in JavaScript are base 0
  const day = String(objDate.getDate()).padStart(2, '0');

  // Final result in YYYY-MM-DD format
  const dateResult = `${year}-${month}-${day}`

  return dateResult
}

const converDateToTimeStap = (date) => {
  // Fecha en formato YYYY-MM-DD HH-MM-SS

  const formattedDate = date.replace(' ', 'T');

  // Creamos el objeto Date con la fecha y hora en formato ISO
  const objDate = new Date(formattedDate);

  // Obtenemos el timestamp en milisegundos
  const timestamp = objDate.getTime();

  return timestamp
}

export {
  formatHourof24at12,
  formatDate,
  selectedMonths,
  formatDateTwo,
  convertLocalTimeToTimezoneTime,
  covertUtcTimeToTimeZoneTime,
  convertLocalTimeToTimezoneTimeOnFormated,
  convertTimeStapToTimeZoneOnFormated,
  formatLocalTimeToTimeZoneFormatedInput,
  formatLocalTimeToTimeZoneFormatedInputYesterday,
  dateComparision,
  formatDateAndHour,
  convertUtcDateToTimeZoneDate,
  formatDateToUSDate,
  get7DaysBeforeADate,
  converDateToTimeStap,
};
