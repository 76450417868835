import petitionGet from "../../../../../../services/petitionGet";

const fetchCallflowNumbers = async (callFlowDetails, setLoading, setFormNumbersSwitch, setSelectedUsers, setData, setAllData) => {
  try {
    setLoading(true);

    const { data: result } = await petitionGet("viewNumbers")

    const numbersCopy = result.result.map((number) => ({ number }));
    
    let selectedUsersCopy = [];

    callFlowDetails?.numbers.forEach((element) => {
      const findOne = numbersCopy.find((elementTwo) => elementTwo.number.number === element);
      if (findOne) {
        selectedUsersCopy.push(findOne);
      }
    });

    let formSwitchs = {};

    numbersCopy.forEach((element) => {
      formSwitchs[element.number.number] = false;
    });

    selectedUsersCopy.forEach((element) => {
      formSwitchs[element.number.number] = true;
    });

    setFormNumbersSwitch(formSwitchs);
    setSelectedUsers(selectedUsersCopy);

    setData(numbersCopy);
    setAllData(numbersCopy);

    setLoading(false);
  } catch (error) {
    console.log(error)
  }
}

export default fetchCallflowNumbers;