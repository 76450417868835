/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Col } from "reactstrap";
import { VariableSizeList as List } from "react-window";
import { LoadMessages, MessageItem, MessageSeparator, UserWhoSentMessage } from "./components";
import "../ChatWindow.css";

const ChatWindowContent = ({
  messagesContainerRef,
  messages,
  timeZone,
  chatWindowData,
  activeModalDelete,
  loading,
  getMoreMessages,
  noHover,
  downloadVoicemail,
  error,
  loadingPage,
  loadAudio,
  deleteFollowupScheduleMessage,
  number,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0); // Posición actual del scroll

  // Función para calcular dinámicamente la altura de cada mensaje
  const getItemSize = (index) => {
    const element = messages[index];
    if (element?.separator) {
      return 50; // Altura para los separadores
    }
    return 200; // Altura para los mensajes
  };

  const handleScroll = () => {
    if (messagesContainerRef.current) {
      // Obtén la posición actual del scroll
      const currentScrollTop = messagesContainerRef.current.scrollTop;

      // Verifica si el scroll está en la parte superior
      if (currentScrollTop === 0 && !loading) {
        setScrollPosition(currentScrollTop); // Guarda la posición actual
        getMoreMessages(); // Obtén más mensajes
      }
    }
  };

  const download = (element) => {
    if (downloadVoicemail) {
      downloadVoicemail(element);
    }
  };

  const renderRow = ({ index, style }) => {
    const element = allMessages[index];
    return (
      <div className="virtua" style={{ ...style, height: "auto" }} key={element.pk}>
          {!loadingPage && (
            <MessageSeparator
              timeZone={timeZone}
              element={element}
              i={index}
              array={messages}
            />
          )}

          <div>
            <MessageItem
              index={index}
              element={element}
              chatWindowData={chatWindowData}
              messages={messages}
              noHover={noHover}
              activeModalDelete={activeModalDelete}
              download={download}
              loadingPage={loadingPage}
              loadAudio={loadAudio}
              deleteFollowupScheduleMessage={deleteFollowupScheduleMessage}
              number={number}
            />

            <UserWhoSentMessage
              element={element}
              chatWindowData={chatWindowData}
              index={index}
              messages={messages}
            />
          </div>
        </div>
    );
  };

  useEffect(() => {
    if (messagesContainerRef.current) {
      // Ajusta el scroll para mantener la posición
      messagesContainerRef.current.scrollTop = scrollPosition + 150 * 20; // Ajusta según altura y mensajes nuevos
    }
  }, [messages]); // Se ejecuta cuando los mensajes cambian

  const fakeMessages = [{other: true, pk: 1},{pk: 2},{other: true, pk: 3},{pk: 4},{other: true, pk: 5},{pk: 6},{other: true, pk: 7},{pk: 8},{other: true, pk: 9},{pk: 10},{other: true, pk: 11},{pk: 12},{other: true, pk: 13},{pk: 14},{other: true, pk: 15},{pk: 16},{other: true, pk: 17},{pk: 18},{other: true, pk: 19},{pk: 20}]
  const allMessages = loadingPage ? fakeMessages : messages

  return (
    <>
      {/* {messages && messages[0] && returnSeparator(messages[0], -1, messages)} */}
      <div className="itp-chat-window-content">  
        <LoadMessages loading={loading} error={error} />
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <List
            outerRef={messagesContainerRef}
            height={500} // Altura del viewport (ajusta según sea necesario)
            itemCount={allMessages.length} // Total de mensajes
            itemSize={getItemSize} // Altura de cada mensaje (ajusta según el contenido)
            width="100%" // Ancho de la lista
            className="itp-chat-window-content__list"
            onScroll={handleScroll} // Evento de scroll de react-window
          >
            {renderRow}
          </List>
        </Col>
      </div>
    </>
  );
};

export default ChatWindowContent;
