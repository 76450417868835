import React, { useEffect, useState } from "react";
import { LoadingButton } from "../../../../../../../components";
import petitionGet from "../../../../../../../services/petitionGet";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";

const User = ({ saveModule, edit, data, editModule, users, loadingCallFlowChart }) => {
  const [valueUser, setValueUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [form, setForm] = useState({
    flow: edit ? data.data.flow : "callflow",
    userId: edit ? data.data.idUser : "",
    timeout: edit ? data.data.timeout : "20",
    can_call_self: edit ? data.data.can_call_self : false,
  });

  const onChangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const saveVoicemail = () => {
    if (loadingCallFlowChart) return;

    setLoadingUser(true);

    petitionGet("allCallflows", { parameter: "&type=MainUserCallflow" })
      .then(({ data: result }) => {
        const callflowId = result.result.find((element) => element.owner_id === form.userId.toString());

        const sendData = {
          id: form.flow === "callflow" ? callflowId.pk : form.userId,
          idUser: form.userId,
          idCallflow: callflowId.pk,
          can_call_self: form.can_call_self,
          flow: form.flow,
          timeout: form.timeout,
        };

        if (edit) {
          editModule(sendData, form.flow === "callflow" ? "callflow" : "user")
        } else {
          saveModule(sendData, form.flow === "callflow" ? "callflow" : "user")
        }
        setLoadingUser(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (data) {
      if (edit) {
        const defaultUser = users.find(
          (element) => element?.pk === parseInt(data?.data?.idUser)
        );

        setValueUser(defaultUser)
      }
    }
  }, [data]);

  return (
    <>
      <div className="itp-callFlow-container-module">
        <label className="itp-label-input__label mb-2">User</label>
        {loadingCallFlowChart ? (
          <div className="w-100">
            <Skeleton width="100%" height="2.5rem" />
          </div>
        ) : (
          <Select
            onChange={(e) => {
              setValueUser(e);
              setForm({ ...form, userId: e.pk });
            }}
            className="basic-single"
            classNamePrefix="select"
            value={valueUser}
            isSearchable={true}
            name="queue"
            placeholder="Select a User"
            options={users}
          />
        )}

        <label className="itp-label-input__label mt-4">
          If the number is not available
        </label>
        {loadingCallFlowChart ? (
          <div className="w-100">
            <Skeleton width="100%" height="2.5rem" />
          </div>
        ) : (
          <select
            onChange={onChangeForm}
            value={form.flow}
            name="flow"
            className="form-select-itp"
          >
            <option value="" disabled>
              Select option
            </option>
            <option value="callflow">Go to User's Voicemail</option>
            <option value="custom">Custom option</option>
          </select>
        )}

        {!loadingCallFlowChart && form.flow === "custom" && (
          <>
            <label className="itp-label-input__label mt-4">Ring time</label>
            <input
              className="form-control-itp"
              value={form.timeout}
              name="timeout"
              onChange={onChangeForm}
              type="number"
            />

            <div
              style={{ gap: "12px" }}
              className="d-flex align-items-center mt-4"
            >
              <input
                className="form-check-input mt-1"
                type="checkbox"
                checked={form.can_call_self}
                onChange={() => {
                  setForm({
                    ...form,
                    can_call_self: !form.can_call_self,
                  });
                }}
              />
              <label className="itp-label-input__label">
                Allow call themselves
              </label>
            </div>
          </>
        )}

        <button
          onClick={saveVoicemail}
          className="btn-primary mt-4 loading"
          disabled={valueUser ? false : true}
        >
          {loadingUser && <LoadingButton />}
          Save
        </button>
      </div>
    </>
  );
};

export default User;
