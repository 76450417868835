// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.basic-single.ai-select-auto.error {
  border: 1px solid red;
  border-radius: 8px;
}

.basic-single.ai-select.error {
  border: 1px solid red;
  border-radius: 8px;
}

.select.error__control.css-13cymwt-control {
  border-color: red;
}

.select.error__control.select.error__control--is-focused.css-t3ipsp-control {
  border-color: red;
}
`, "",{"version":3,"sources":["webpack://./src/pages/private/WorkspaceSettings/AI/components/AIAgents/components/AddEditAIAgent/AddEditAIAgent.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".basic-single.ai-select-auto.error {\n  border: 1px solid red;\n  border-radius: 8px;\n}\n\n.basic-single.ai-select.error {\n  border: 1px solid red;\n  border-radius: 8px;\n}\n\n.select.error__control.css-13cymwt-control {\n  border-color: red;\n}\n\n.select.error__control.select.error__control--is-focused.css-t3ipsp-control {\n  border-color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
