const calculateAvgWordsPerMinute = (transcriptionData) => {
  // Extract details of the talk speed and total talk time of each participant
  const talkSpeed = transcriptionData?.ConversationCharacteristics?.TalkSpeed?.DetailsByParticipant || {};
  const talkTime = transcriptionData?.ConversationCharacteristics?.TalkTime?.DetailsByParticipant || {};

  let totalWords = 0;
  let totalTimeMillis = 0;

  // Iterate over all participants
  for (const participant in talkSpeed) {
    const wpm = talkSpeed[participant].AverageWordsPerMinute;
    const timeMillis = talkTime[participant].TotalTimeMillis;

    // Add words spoken and total time of each participant
    totalWords += (wpm / 60) * (timeMillis / 1000); // Convert WPM to total words
    totalTimeMillis += timeMillis; // Add the time of each participant
  }

  // Calculate your overall average words per minute
  const averageGeneralWPM = totalWords / (totalTimeMillis / 60000); // Convertir milisegundos a minutos

  return averageGeneralWPM.toFixed(2);
}

export default calculateAvgWordsPerMinute;