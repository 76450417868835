import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const Voice360Chat = () => {
  const [searchParams] = useSearchParams();
  const [isLoaded, setIsLoaded] = useState(false); // Estado para saber si ya cargó

  const handleOnLoad = () => {
    setIsLoaded(true)
  }

  const phone = searchParams.get("phone") || ""; // Obtiene el número de la query string

  const token = JSON.parse(localStorage.getItem("userDetails"))?.access_token;
  const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;
  const timeZone = JSON.parse(localStorage.getItem("userDetails"))?.time_zone

  const servicesUserObjects = JSON.parse(localStorage.getItem("userDetails"))?.services_user_objects
  const userObject = servicesUserObjects[id]

  const privLevel = userObject?.priv_level
  const userId = userObject?.pk

  useEffect(() => {
    if (isLoaded) setIsLoaded(false)
  }, [phone])

  return (
    <div>
      {console.log(process.env.PUBLIC_URL)}
      <iframe
        src={`/voice360-fe-chat/index.html?phone=${phone}&token=${token}&id=${id}&timeZone=${timeZone}&privLevel=${privLevel}&userId=${userId}`}
        // src={`/public/voice360-fe-chat/index.html?phone=${phone}&token=${token}&id=${id}&timeZone=${timeZone}&privLevel=${privLevel}&userId=${userId}`}
        title="Voice360 Chat"
        loading="lazy" // Forzar carga anticipada
        onLoad={handleOnLoad}
        style={{ 
          width: "100%", 
          height: "calc(100vh - 104px)",
          opacity: isLoaded ? 1 : 0, // Oculta el iframe mientras carga
          transition: "opacity 0.3s ease-in-out", // Transición suave
        }}
      ></iframe>
    </div>
  );
};

export default Voice360Chat;
