import "./UserWhoSentMessage.css"

const UserWhoSentMessage = ({ element, chatWindowData, index, messages }) => {
  const show = element.userWhoSentMessage
  const trueData = !!(element?.message_participant && chatWindowData?.numberChat)
  const isEqual = !!(element?.message_participant === chatWindowData?.numberChat)
  const isOther = !!(element?.other || (trueData && isEqual))

  if (show) {
    return (
      <div className={`itp-chat-window__user-sent-message${isOther ? "-other" : "-you"} ${index === messages.length - 1 ? "mb-4" : ""}`}>
        <span className="itp-chat-window__user-sent-message-content">{show}</span>
      </div>
    )
  }
}

export default UserWhoSentMessage;