import { defaultFiltersService } from "../index";
import petitionPatch from "../../../../../services/petitionPatch";

const changeContactListBatchcontactsService = async (myExtension, parameter, setLoading, selectAll, contact_list_id, arrayContacts, reloadData) => {
  try {
    setLoading(true)

    let data = {}
    
    if (selectAll) {
      data = {
        payload: {
          contact_list_id,
        },
        use_filters: true,
      };
    } else {
      data = {
        contacts: arrayContacts,
        payload: {
          contact_list_id,
        },
      };
    }

    await petitionPatch("updateMultipleContacts", { data, parameter })

    const { filterActive, myExtensionTemp } = await defaultFiltersService(myExtension.current)

    setLoading(false);
    reloadData(false, false, false, myExtensionTemp, filterActive);
  } catch (error) {
    console.log(error)
  }
}

export default changeContactListBatchcontactsService;