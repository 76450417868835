import React, { useEffect, useState } from "react";
import { UserAgent, Registerer } from "sip.js";
import onInviteCall from "./onInvite"
import { SessionDescriptionHandler } from "sip.js/lib/platform/web";


function SipRegistration({ sipconfiguration, agent, setAgent, setInvitationSession, setCallSessionsFunctionEnd, callAudio, setCallAudio, ringtone, enablePhone,handleAnswerOnInvite,sessionIncall }) {
  const [isMounted, setIsMounted] = useState(true);
  const [firstConnect, setFirstConnect] = useState(true);




const registerSip = async (enablePhonetemp) => {
  if (!enablePhonetemp) {
      unregisterSip();
      console.log("test0");
      return;
  }

  try {
      const uri = UserAgent.makeURI(`sip:${sipconfiguration.username}@${sipconfiguration.realm}`);

      const userAgentOptions = {
          authorizationPassword: sipconfiguration.password,
          authorizationUsername: sipconfiguration.username,
          transportOptions: sipconfiguration.transportOptions,
          logLevel: "debug",  // ✅ Enable debug logs
          delegate: {
              onInvite: (session) => { 
                  console.log("📞 Incoming call received at:", new Date().toISOString());
                  console.log("🔍 Invite Session Details:", session);
                  
                  onInviteCall(session, setInvitationSession, setCallSessionsFunctionEnd, callAudio, setCallAudio, ringtone, handleAnswerOnInvite, sessionIncall);
                  sipconfiguration.onIncomingCall();
              },
              onDisconnect: () => {
                  sipconfiguration.onUnregistered();
                  if (enablePhonetemp) {
                      setTimeout(() => {
                          console.log("registro6");
                          registerSip(); 
                      }, 5000);
                  }
              },
              onUnregistered: () => {
                  sipconfiguration.onUnregistered();
                  if (enablePhonetemp) {
                      setTimeout(() => {
                          registerSip(); 
                      }, 5000);
                  }
              },
          },
          uri,
          sessionDescriptionHandlerFactoryOptions: {
              constraints: {
                  audio: true,
                  video: false,
              },
              offerOptions: {
                  iceRestart: false,
              }
          },
          peerConnectionOptions: {
              rtcConfiguration: {
                  iceServers: [
                      { urls: "stun:stun.l.google.com:19302" }
                  ],
                  iceTransportPolicy: "relay",
              }
          },
          iceCheckingTimeout: 1000, 
          iceGatheringTimeout: 1000 
      };

      console.log ("--------------- Applying userAgentOptions ----------------");
      console.log("➡️ Full userAgentOptions:", userAgentOptions);

      const agentTemp = new UserAgent(userAgentOptions);
      console.log("✅ SIP UserAgent Created:", agentTemp);

      await agentTemp.start();
      const registerer = new Registerer(agentTemp);
      registerer.register();
      console.log("✅ SIP Registration Complete");

      sipconfiguration.onRegistered();

      if (isMounted) {
          setAgent(agentTemp);
          console.log("registro5", agentTemp);
      }
  } catch (error) {
      console.error("❌ SIP registration failed:", error);
  }
};





  const unregisterSip = async () => {
    try {
      // Detener y desconectar el agente SIP
      await agent.stop();
      // Realizar cualquier otra limpieza necesaria

      // setIsRegistered(false);
    } catch (error) {
      console.error("SIP unregistration failed:", error);
      // Manejar el error según sea necesario
    }
  };

  useEffect(() => {
    if (!firstConnect) {
      registerSip(enablePhone)
      // if (enablePhone) {
      console.log("registro1")
      //   registerSip(enablePhone)
      // } else {
      //   unregisterSip()
      // }
    }

  }, [enablePhone])

  useEffect(() => {
    if (!firstConnect) {
      registerSip();
      console.log("registro2")
      return () => {
        setIsMounted(false);
      };
    }
  }, [sipconfiguration, setAgent]);

  useEffect(() => {
    registerSip(enablePhone);
    console.log("registro3")
    setFirstConnect(false)

  }, [sipconfiguration.reconnected]);


  return null;
}

export default SipRegistration;