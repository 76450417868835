import { useState } from "react";
import { Col, Row } from "reactstrap";
import { LoadingButton } from "../../../../../../../components";

const EditGroupName = ({ info, loadingButton, editMediaGroup }) => {
  const [name, setName] = useState(info?.name || "");

  const handleChangeName = (e) => {
    const { value } = e.target;
    setName(value);
  };

  const handleSave = () => {
    if (loadingButton) return;
    editMediaGroup(name)
  }

  return (
    <>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Name</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter Name"
            className="form-control-itp itp-label-input__input"
            name="name"
            value={name}
            onChange={handleChangeName}
          />
        </Col>
      </Row>
      
      <button
        onClick={handleSave}
        className="btn-primary itp-lead-detail-button-save loading mb-4"
        disabled={!name ? true : false}
      >
        {loadingButton && <LoadingButton />}
        Save
      </button>
    </>
  );
};

export default EditGroupName;
