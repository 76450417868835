const selectSubTitleModule = (module, id, queues, switches, voicemails, users, plays, tempData) => {
  switch (module) {
    case "voicemail":
      if (tempData) return "Loading information..."

      const findVoicemail = voicemails.find((element) => element.pk === id);

      if (findVoicemail) {
        return findVoicemail?.label;
      } else {
        return "This Voicemail Was Deleted";
      }

    case "queue":
      if (tempData) return "Loading information..."

      const findQueue = queues?.find((element) => element.pk === id);

      if (findQueue) {
        return findQueue?.queue_name;
      } else {
        return "This Queue Was Deleted";
      }

    case "switch":
      if (tempData) return "Loading information..."

      const findSwitch = switches.find((element) => element.pk === id);

      if (findSwitch) {
        return findSwitch?.name;
      } else {
        return "This Switch Was Deleted";
      }

    case "user":
      if (tempData) return "Loading information..."

      const findUser = users.find((element) => element.pk === id);

      if (findUser) {
        return findUser?.label;
      } else {
        return "This User Was Deleted";
      }

    case "callflow":
      if (tempData) return "Loading information..."

      const findUserr = users.find((element) => element.pk === id);

      if (findUserr) {
        return findUserr?.label;
      } else {
        return "This User Was Deleted";
      }

    case "play":
      if (tempData) return "Loading information..."

      const findPlay = plays.find((element) => element.pk === id);
      
      if (findPlay) {
        return findPlay?.name;
      } else {
        return "This Media Was Deleted";
      }

    case "externaltransfer":
      if (tempData) return "Loading information..."

      return id;

    case "language":
      if (tempData) return "Loading information..."

      const shortLanguages = {
        en: "English",
        es: "Spanish",
        fr: "French",
      };

      return shortLanguages[id] || "";

    case "send_sms":
      if (tempData) return "Loading information..."
  
      return id;

    default:
      return "";
  }
};

export default selectSubTitleModule;
