/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, BrowserRouter as Router } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutRequest } from "./redux/actions/login";
import { getMyExtention } from "./redux/actions/myExtension";
import { Login, ForgetPassword, ChangePassword, SignUp } from "./pages/public";
import Index from "./routes/index";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { WebPhone } from "./components";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import petitionGet from "./services/petitionGet";
import petitionPost from "./services/petitionPost";

function App() {
  const params = new URL(document.location).searchParams;
  const token = params.get("token");

  const dispatch = useDispatch();
  const [showWebphone, setShowWebphone] = useState(false);
  const [unregister, setUnregister] = useState(false);
  const [webphoneData, setWebphoneData] = useState(false);
  const [sipconfiguration, setSipConfiguration] = useState(false);
  const [webphonePosition, setWebphonePosition] = useState({ x: 0, y: 0 });
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("");
  const [updateWebphone, setUpdateWebphone] = useState(1);

  const [isClickCall, setIsClickCall] = useState(false);
  const [numberToCallOnClick, setNumberToCallOnClick] = useState("");

  const onDismiss = () => setVisible(false);
  let path = window.location.pathname;
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("userDetails") !== null ? true : false
  );

  const json = require("./config.json");

  const wssServer = json.prod ? json.wssServer.prod : json.wssServer.dev;
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;

  const publicRoutes = [
    "/login",
    "/",
    "/reset-pw",
    "/Change-Password",
    "/Sign-Up",
  ]

  useEffect(() => {
    
    let userDetailsString = localStorage.getItem("userDetails");
    let webphoneDataString = localStorage.getItem("webphone");
    // console.log(webphoneDataString)
    if (webphoneDataString) {
      try {
        // Analizar la cadena JSON en un objeto JavaScript
        let webphoneData = JSON.parse(webphoneDataString);

        let userDetails = userDetailsString
          ? JSON.parse(userDetailsString)
          : { firstname: "", lastname: "" };

        setWebphoneData(webphoneData);
        // console.log(
        //   "------------------------------------------------>",
        //   webphoneData
        // );
        // Ahora puedes utilizar webphoneData en tu aplicación
        // console.log("Información del webphone:", webphoneData);

        // Ahora puedes utilizar webphoneData en tu aplicación
        let sipconfigurationTemp = {
          reconnected: 0,
          username: webphoneData.sip_username,
          password: webphoneData.sip_password,
          realm: webphoneData.realm,
          transportOptions: { server: wssServer },
          name: userDetails.firstname + " " + userDetails.lastname,
          enableCallWaiting: true,
          onInvite: () => {
            console.log("invite");
          },
          onDisconnect: () => {
            setMessage("Disconnected");
            setVisible(true);
            setVariant("danger");
            setUnregister(false)
          },
          onUnregistered: () => {
            setMessage("Unregistered");
            setVisible(true);
            setVariant("danger");
            setUnregister(false)
          },
          onRegistered: () => {
            setMessage("Registered");
            setVisible(true);
            setVariant("success");
            setUnregister(true)
          },
          maxCalls: 2,
          enableCall: true,
          onChangeEnableCall: () => {
            console.log("enable");
          },
          onChangeEnableCallWaiting: () => {
            console.log("enable Call waiting")
          },
          onIncomingCall: () => { setShowWebphone(true) },
          endPointToFindContactsToCall: petitionGet,
          endpointToWarmTransfer: petitionGet,
        };
        setSipConfiguration(sipconfigurationTemp);
        // console.log(sipconfigurationTemp)
        // Hacer lo que necesites con webphoneData
      } catch (error) {
        console.error("Error al analizar los datos del webphone:", error);
      }
    } else {
      if (window.location.pathname !== "/login") {
        setUpdateWebphone(updateWebphone + 1)
      }
      // console.log("No se encontraron datos del webphone en el localStorage.");
    }
  }, [updateWebphone]);

  useEffect(() => {
    if (variant !== "danger") {
      setTimeout(() => {
        // console.log("ppppppppppppppppppppp2");
        onDismiss();
      }, 5000);
    }
  }, [visible]);

  const handleDragStart = (e) => {
    const startX = e.clientX - webphonePosition.x;
    const startY = e.clientY - webphonePosition.y;

    const handleDragMove = (e) => {
      const newX = e.clientX - startX;
      const newY = e.clientY - startY;

      setWebphonePosition({ x: newX, y: newY });
    };

    const handleDragEnd = () => {
      window.removeEventListener("mousemove", handleDragMove);
      window.removeEventListener("mouseup", handleDragEnd);
    };

    window.addEventListener("mousemove", handleDragMove);
    window.addEventListener("mouseup", handleDragEnd);
  };

  const onLogout = () => {
    dispatch(logoutRequest());
  }; 
 
  const handleActiveCall = (boolean, number) => {
    setIsClickCall(boolean);
    setNumberToCallOnClick(number);
    setShowWebphone(true);
  };

  const handleCloseActiveCall = () => {
    setIsClickCall(false);
    setNumberToCallOnClick("");
  };

  useEffect(() => {
    const checkSession = () => {
      if (!localStorage.getItem("userDetails")) {
        setIsLoggedIn(false);
        onLogout();
      }
    };

    let params = new URL(document.location).searchParams;
    var logoutVar = params.get("logout");

    if (logoutVar === "true") onLogout();

    if (isLoggedIn && !token) {
      dispatch(getMyExtention());
    }

    window.addEventListener("storage", checkSession);
  }, []);

  const reconnectedAction = () => {
    console.log("reconnectedAction")
    setMessage("Trying to Connect")
    setVariant("info")
    setSipConfiguration({ ...sipconfiguration, reconnected: sipconfiguration.reconnected + 1 })
  }
  // </>
  if (isLoggedIn && !token) {
    return (
      <div className="App">
        <div
          style={{
            position: "absolute",
            width: "300px",
            zIndex: "1000",
            right: "0px",
          }}
        >
          <Alert
            variant={variant}
            className="alert-dismissible fade show"
            show={visible}
            onClose={onDismiss}
            dismissible={true}
          >
            {message === "Disconnected" || message === "Unregistered" ? (<>{message} <span onClick={() => { reconnectedAction() }} className="reconnectedAction">Reconnect</span></>) :  message }

          </Alert>

        </div>


        <Index
          setShowWebphone={setShowWebphone}
          handleActiveCall={handleActiveCall}
        />
        <div className="webphone-component">
          {sipconfiguration && (
            <WebPhone
              sipconfiguration={sipconfiguration}
              showWebphone={showWebphone && unregister }
              isClickCall={isClickCall}
              numberToCallOnClick={numberToCallOnClick}
              handleCloseActiveCall={handleCloseActiveCall}
              setShowWebphone={setShowWebphone}
            />
          )} 
        </div>
      </div>
    );
  } else {
    return (
      <div className="App">
        <Router>
          <Routes>
            <Route path="/login" element={(!isLoggedIn || token) && <Login />} />
            <Route
              path="/"
              element={(!isLoggedIn || token) && <Navigate to="/login" replace />}
            />
            <Route
              path="/reset-pw"
              element={(!isLoggedIn || token) && <ForgetPassword />}
            />

            <Route
              path="/Change-Password"
              element={(!isLoggedIn || token) && <ChangePassword />}
            />

            <Route
              path="/Sign-Up"
              element={(!isLoggedIn || token) && <SignUp />}
            />

            {!publicRoutes.includes(path) && (
                <Route
                  path={path}
                  element={(!isLoggedIn || token) && <Navigate to="/login" replace />}
                />
              )}
          </Routes>
        </Router>
      </div>
    );
  }
}

export default App;
