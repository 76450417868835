import petitionPatch from "../../../../../services/petitionPatch"

const applyBulkActionService = async (form, action, query, campaign_id, setLoadingModal, closeModal) => {
  try {
    setLoadingModal(true)
    const parameter = `?action=${action}${query}`
    const data = form

    await petitionPatch("smartCampaignLogs", { data, parameter, campaign_id })

    closeModal()
  } catch (error) {
    console.log(error)
    setLoadingModal(false)
  }
}

export default applyBulkActionService;