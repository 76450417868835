import petitionPatch from "../../../../../services/petitionPatch";

const sendToFunnelService = async (parameter, setLoading, selectAll, form, arrayContacts, reloadData) => {
  try {
    setLoading(true);

    let data = {};

    if (selectAll) {
      data = {
        move_to_funnel: {
          funnel_id: form.funnel_id.value,
          funnel_stage_id: form.funnel_pipeline_stage_id.value,
          lead_source: form.lead_source_id.value,
        },
        use_filters: true,
        payload: {},
      };
    } else {
      data = {
        contacts: arrayContacts,
        move_to_funnel: {
          funnel_id: form.funnel_id.value,
          funnel_stage_id: form.funnel_pipeline_stage_id.value,
          lead_source: form.lead_source_id.value,
        },
        payload: {},
      };
    }

    await petitionPatch("updateMultipleContacts", { data, parameter })

    setLoading(false);
    reloadData(false, false);
  } catch (error) {
    console.log(error);
  }
};

export default sendToFunnelService;
