const downloadTranscription = (transcriptions) => {
  const formatTranscriptForDownload = () => {
    return transcriptions
      .map((element) => {
        const name = element.ParticipantRole === "AGENT" ? "Agent" : "Customer";
        const time = element.Time;
        return `${name} (${time}) - ${element.Content}`;
      }).join("\n\n");
  };

  const content = formatTranscriptForDownload();
  const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'transcription.txt';
  link.click();
  URL.revokeObjectURL(url);
};

export default downloadTranscription;
