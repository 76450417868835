const defaultUserNumberColumns = [
  { name: "Name", key: "user" },
  { name: "Total Calls", key: "total_calls" },
  { name: "Completed Calls", key: "completed" },
  { name: "Missed Calls", key: "missed" },
  { name: "Missed %", key: "missed_percentage" },
  { name: "Total Duration", key: "duration" },
  { name: "Average Duration", key: "avg_duration" },
];

const defaultNumberColumns = [
  { name: "Number", key: "formatedNumber" },
  { name: "Total Calls", key: "total_calls" },
  { name: "Completed Calls", key: "completed" },
  { name: "Missed Calls", key: "missed" },
  { name: "Missed %", key: "missed_percentage" },
  { name: "Total Duration", key: "duration" },
  { name: "Average Duration", key: "avg_duration" },
]

const defaultQueueColumns = [
  { name: "Queue Name", key: "queueName" },
  { name: "Total Calls", key: "total_calls" },
  { name: "Answered Calls", key: "answered_calls" },
  { name: "Abandoned Calls", key: "abandoned_calls" },
  { name: "Missed Calls", key: "missed_calls" },
  { name: "Avg Call Duration", key: "avg_call_duration" },
  { name: "Avg Hold Time", key: "avg_hold_time" },
];

const defaultQueueMemberColumns = [
  { name: "User", key: "userName" },
  { name: "Total Calls", key: "total_calls" },
  { name: "Answered Calls", key: "answered_calls" },
  { name: "Missed Calls", key: "missed_calls" },
  { name: "Avg Call Duration", key: "avg_call_duration" },
];

const defaultUserNumberLogsColumns = [
  { name: "Date & Time", key: "datetime" },
  { name: "From", key: "fromNumber" },
  { name: "To", key: "toNumber" },
  { name: "Billable Time", key: "billsec" },
  { name: "Ring Time", key: "duration" },
  { name: "Disposition", key: "disposition" },
]

const defaultQueueLogsColumns = [
  { name: "Date", key: "dateFormated" },
  { name: "Caller ID", key: "formatedNumber" },
  { name: "Contact", key: "contactName" },
  { name: "Agent", key: "agentName" },
  { name: "Wait Time", key: "wait_time" },
  { name: "Duration", key: "duration" },
  { name: "Outcome", key: "outcome" },
  { name: "Actions", key: "actions" },
]

const defaultQueueMemberLogsColumns = [
  { name: "Date", key: "dateFormated" },
  { name: "Caller ID", key: "formatedNumber" },
  { name: "Contact", key: "contactName" },
  { name: "Queue Name", key: "queue_name" },
  { name: "Wait Time", key: "wait_time" },
  { name: "Duration", key: "duration" },
  { name: "Outcome", key: "outcome" },
  { name: "Actions", key: "actions" },
]

const defaultSMSColumns = [
  { name: "Phone", key: "formatedNumber" },
  { name: "Total Outbound", key: "total_outbound" },
  { name: "Total Inbound", key: "total_inbound" },
  { name: "Replies", key: "replies" },
  { name: "Reply Rate", key: "reply_rate" },
  { name: "Opt Outs", key: "opt_outs" },
  { name: "Errors", key: "errors" },
]

export const selectDefaultColumns = {
  Users: defaultUserNumberColumns,
  Numbers: defaultNumberColumns,
  Queues: defaultQueueColumns,
  "Queue Members": defaultQueueMemberColumns,
  userNumberLogs: defaultUserNumberLogsColumns,
  queueLogs: defaultQueueLogsColumns,
  queueMemberLogs: defaultQueueMemberLogsColumns,
  SMS: defaultSMSColumns,
};

export const typeReports = [
  { label: "User Report", value: "Users" },
  { label: "Number Report", value: "Numbers" },
  { label: "Queues Report", value: "Queues" },
  { label: "Queue Members Report", value: "Queue Members" },
  { label: "SMS Report", value: "SMS" },
]
