const isValidJson = (field) => {
  if (typeof field !== "string") {
    // If the field is not a string, it cannot be JSON
    return false;
  }

  try {
    JSON.parse(field);
    return true; // If JSON.parse() does not throw an error, it is valid JSON
  } catch (error) {
    return false; // If it throws an error, it is not valid JSON
  }
};

export default isValidJson;
