/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { AddButton, CustomTableMain, MenuRight, SearchBoxFilter } from "../../../components/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faPencilAlt, faThumbtack, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { myExtensionPetition } from "../../../services/myExtension.service"
import { useDynamicFunction } from "../../../contexts/DynamicFunctionContext";
import RefreshIcon from "@mui/icons-material/Refresh";
import ModalDelete from "./ModalUtility/ModalDelete";
import AddFunnel from "./AddFunnel/AddFunnel";
import Stages from "./Stages/Stages";
import petitionGet from "../../../services/petitionGet";
import petitionDelete from "../../../services/petitionDelete";
import petitionPost from "../../../services/petitionPost";
import getQueryString from "../../../utils/getQueryString";
import "./Funnels.css";

function Funnels() {
  const params = window.location.search

  const navigate = useNavigate();
  const { resetFunction, setIsArrowVisible } = useDynamicFunction();

  const myExtension = useRef({})
  const itemsPerPage = useRef("10");
  const searchInput = useRef("")
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });

  const [loading, setLoading] = useState(true);
  const [modalDelete, setModalDelete] = useState(false);
  const [info, setInfo] = useState(null);
  const [loadingFunnel, setLoadingFunnel] = useState(false);
  const [showAddFunnel, setShowAddFunnel] = useState(false);
  const [showEditStages, setShowEditStages] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [allResult, setAllResult] = useState(null);
  const [loadingPagItems, setLoadingPagItems] = useState(false);
  const [searchValue, setSearchValue] = useState("")
  const [dataTable, setDataTable] = useState({
    columns: [ { name: "Name", key: "name" }, { name: "Description", key: "description" } ],
    actions: { title: "Actions", content: [] },
  });

  const closeMenu = () => {
    setShowEditStages(null);
    setShowAddFunnel(false);
    setInfo(null);
    setModalDelete(false);
    setOpenMenu(false);
  };

  const activeDeleteFunnel = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const activeFunnelDetails = (e) => {
    const { priv_level, firstname, lastname } = myExtension.current
    navigate(`/Funnels/Funnel-Details?pk=${e.pk}&priv_level=${priv_level}&firstname=${firstname}&lastname=${lastname}`)
  }

  const activeAddFunnel = () => {
    if (loading) return;
    setOpenMenu(true);
    setShowAddFunnel(true);
  };

  const activeEditStages = (e) => {
    setShowEditStages(e);
    setOpenMenu(true);
  };

  const selectTitleMenu = () => {
    if (openMenu && showAddFunnel && !showEditStages) {
      return "Add New Funnel";
    } else if (openMenu && !showAddFunnel && showEditStages) {
      return "Stages of the funnel";
    }
  };

  const selectSubTitleMenu = () => {
    if (openMenu && showAddFunnel && !showEditStages) {
      return "Add the details asked to add new funnel";
    } else if (openMenu && !showAddFunnel && showEditStages) {
      return "View & edit the stages of this funnel";
    }
  };

  const reloadData = (pag) => {
    closeMenu();
    if (pag) {
      let parameter = getQueryString(pag) + searchInput.current;
      petition(parameter, false);
      pagination.current = pag;
    } else {
      let parameter = getQueryString(pagination.current) + searchInput.current;
      petition(parameter, false);
    }
  };

  const deleteFunnel = () => {
    setLoadingFunnel(true);
    petitionDelete("funnel", { funnel_id: info.pk })
      .then(({ data: result }) => {
        setLoadingFunnel(false);
        if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
          let paginationTemp = {
            limit: pagination.current.limit,
            offset: pagination.current.offset - pagination.current.limit,
          };
          reloadData(paginationTemp);
        } else {
          reloadData(false);
        }
      })
      .catch((error) => console.log(error));
  };

  const addFunnel = (form) => {
    setLoadingFunnel(true);
    petitionPost("funnel", { data: form })
      .then(({ data: result }) => {
        setLoadingFunnel(false);
        reloadData(false);
      })
      .catch((error) => console.log(error));
  };

  const changePage = (offset) => {
    setLoadingPagItems(true)
    const paginationTemp = {
      offset,
      limit: pagination.current.limit,
    };

    const parameter = getQueryString(paginationTemp) + searchInput.current;

    petition(parameter, true);
    pagination.current = paginationTemp;
  };

  const refreshTable = () => {
    if (loading || loadingPagItems) return;

    setLoadingPagItems(true)
    const parameter = getQueryString(pagination.current) + searchInput.current;
    petition(parameter, true);
  }

  const activeItemsPerPage = (data) => {
    setLoadingPagItems(true);
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    let parameter = getQueryString(paginationTemp) + searchInput.current;
    petition(parameter, true);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const handleChangeInput = (e) => {
    if (loading) return;
    const { value } = e.target
    setSearchValue(value)

    const inputSearch = value ? `&name=${value}` : ""
    const paginationTemp = { offset: 0, limit: pagination.current.limit }
    const parameter = getQueryString(paginationTemp) + inputSearch
    
    petition(parameter, true)
    pagination.current = paginationTemp
    searchInput.current = inputSearch
  }

  const petition = async (parameter, noLoading) => {
    if (!noLoading) setLoading(true);

    await petitionGet("funnels", { parameter })
        .then(({ data: result }) => {
          setAllResult(result);
          setDataTable({
            columns: [
              { name: "Name", key: "name" },
              { name: "Description", key: "description" },
            ],
            content: [...result.result],
            actions:
              myExtension.current.priv_level !== "admin"
                ? null
                : {
                    title: "Action",
                    content: [
                      {
                        name: "Pin Funnel",
                        handleClick: (e) => console.log(e),
                        icon: <FontAwesomeIcon icon={faThumbtack} />,
                      },
                      {
                        name: "Edit Stages",
                        handleClick: activeEditStages,
                        icon: <FontAwesomeIcon icon={faPencilAlt} />,
                      },
                      {
                        name: "Edit Details",
                        handleClick: activeFunnelDetails,
                        icon: <FontAwesomeIcon icon={faPenToSquare} />,
                      },
                      {
                        name: "Delete Funnel",
                        handleClick: activeDeleteFunnel,
                        icon: <FontAwesomeIcon icon={faTrash} />,
                      },
                    ],
                  },
          });
          setLoading(false);
          setLoadingPagItems(false);
        })
        .catch((error) => console.log(error));

  };

  useEffect(() => {
    const myExtensionData = async () => {
      try {
        const data = await myExtensionPetition()

        if (data && Object.keys(data).length > 0) {
          myExtension.current = data

          if (params) {
            const redirectPk = params.slice(1);
            const { priv_level, firstname, lastname } = data
            navigate(`/Funnels/Funnel-Details?pk=${redirectPk}&priv_level=${priv_level}&firstname=${firstname}&lastname=${lastname}`)
          } else {
            petition(getQueryString(pagination.current));
          }
        } 

      } catch(error) {
        console.log(error)
      }
    }

    setIsArrowVisible(false);
    resetFunction();
    myExtensionData()
  }, []);

  return (
    <div className={`itp-test itp-${itemsPerPage.current}-items`}>
      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeMenu}
          loading={loadingFunnel}
          modalFunction={deleteFunnel}
        />
      )}

      <CustomTableMain
        data={dataTable}
        loading={loading}
        loadingPagItems={loadingPagItems}
        clickRow={activeFunnelDetails}
        isBackend={true}
        paginationData={parseInt(itemsPerPage.current)}
        totalPages={allResult?.total_pages}
        onPageChange={changePage}
        showSelectSingleCheckbox={true}
        onChangeItemsPerPage={activeItemsPerPage}
        offset={pagination.current.offset}
        actionsLeft={[
          {
            component: (
              <SearchBoxFilter 
                placeholder="Search Funnel"
                handleChange={handleChangeInput}
                value={searchValue}
              />
            )
          },
          {
            component: <RefreshIcon className="cursor-pointer" onClick={refreshTable} />
          },
        ]}
        actionsRight={[
          {
            component: (
              <AddButton 
                name="Add New Funnel"
                handleClick={activeAddFunnel}
              />
            )
          }
        ]}
      />

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title={selectTitleMenu()}
        subtitle={selectSubTitleMenu()}
        to="to Funnels"
      >
        {openMenu && showAddFunnel && !showEditStages && (
          <AddFunnel addFunnel={addFunnel} loading={loadingFunnel} />
        )}

        {openMenu && !showAddFunnel && showEditStages && (
          <Stages funnelInfo={showEditStages} backFunction={reloadData} />
        )}
      </MenuRight>
    </div>
  );
}

export default Funnels;
