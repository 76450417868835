const handleChangeSwitchs = (name, form, setForm, setCurrentForm) => {
  setForm({ ...form, [name]: !form[name] });
  setCurrentForm({ ...form, [name]: !form[name] })

  const isSomeCallForwardSwitch = name === "callforward_enable" || name === "callforward_keep_caller_caller_id" || name === "callforward_call_confirmation"

  if (isSomeCallForwardSwitch) {
    if (name === "callforward_enable" && form.callforward_enable) {
      let data = {
        timeout: form.timeout,
        callforward_enable: false,
        callforward_number: "",
        callforward_keep_caller_caller_id: false,
        callforward_call_confirmation: false,
      };

      if (form.ring_strategy) data.ring_strategy = form.ring_strategy

      setForm({ ...form, ...data });
      setCurrentForm({ ...form, ...data })

      return data
    } else {
      let data = {
        timeout: form.timeout,
        callforward_enable: form.callforward_enable,
        callforward_number: form.callforward_number,
        callforward_keep_caller_caller_id: form.callforward_keep_caller_caller_id,
        callforward_call_confirmation: form.callforward_call_confirmation,
        [name]: !form[name],
      };

      if (form.ring_strategy) data.ring_strategy = form.ring_strategy

      return data
    }
  } else if (name === "ai_enabled" && form.ai_enabled) {
    const data = {
      ai_enabled: false,
      auto_followup: false,
    };

    setForm({
      ...form,
      ai_enabled: false,
      auto_followup: false,
    });

    setCurrentForm({
      ...form,
      ai_enabled: false,
      auto_followup: false,
    })

    return data
  } else {
    let data = {
      ...form,
      [name]: !form[name],
    };

    if (!data.ai_calender_strategy) delete data.ai_calender_strategy

    delete data.ring_strategy;
    delete data.caller_id_name;
    delete data.callerid_prefix;
    delete data.description;

    return data
  }
}

export default handleChangeSwitchs;