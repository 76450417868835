import petitionGet from "../../../../../../services/petitionGet";

const fetchAIAgentService = async (aIAgentId) => {
  if (aIAgentId) {
    const parameter = `/${aIAgentId}`
    const { data: result } = await petitionGet("aiAgents", { parameter })
    
    const defaultAIAgent = {
      label: result.result.name,
      value: result.result.pk,
    }

    return defaultAIAgent
  } else {
    return null
  }
}

export default fetchAIAgentService;