/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Card, CardBody } from "reactstrap";
import { MenuRight, CustomTableMain, AddButton, AlignedRow, AlertNotificactions } from "../../../../../../components";
import { useDynamicFunction } from "../../../../../../contexts/DynamicFunctionContext";
import { addOrEditDateTimeService, deleteDateTimeService, fetchTimeGroupDetails, fetchTimeRules, handleBlurTimeGroupNameService } from "../../services";
import { defaultFormDateTime } from "../../const";
import { formatDateTime } from "../../utils";
import { AddEditTimeDate } from "./components";
import moment from "moment-timezone";
import Skeleton from "react-loading-skeleton";
import useScreenSize from "../../../../../../hooks/useScreenSize";
import ModalDelete from "../ModalUtitlity/ModalDelete";
import "./TimeGroupsDetails.css";

const TimeGroupsDetails = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pk = searchParams.get("pk");
  const systemTimeZone = searchParams.get("systemTimeZone");

  const { setDynamicFunction } = useDynamicFunction();
  const navigate = useNavigate();

  const { width } = useScreenSize();

  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState(null);
  const [loadingDateTime, setLoadingDateTime] = useState(false);
  const [timeGroupName, setTimeGroupName] = useState({ name: "" });
  const [currentName, setCurrentName] = useState(timeGroupName);
  const [modalDelete, setModalDelete] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [formAddEditDateTime, setformAddEditDateTime] = useState(defaultFormDateTime);
  const [showErrorTime, setShowErrorTime] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertType, setAlertType] = useState()
  const [alertMessage, setAlertMessage] = useState("")
  const [currentForm, setCurrentForm] = useState(defaultFormDateTime)
  const [dataTable, setDataTable] = useState({
    columns: [
      { name: "Name", key: "name" },
      { name: "ID", key: "pk" },
      { name: "Start Date", key: "startDate" },
      { name: "End Date", key: "endDate" },
    ],
    actions: { title: "Actions", content: [] },
  });

  const updateAlertVariables = (type, title, message, time) => {
    setAlertType(type)
    setAlertTitle(title)
    setAlertMessage(message)
    setShowErrorTime(time)

    setTimeout(() => {
      setShowErrorTime(false)
      setAlertType("")
      setAlertTitle("")
      setAlertMessage("")
    }, time + 300)
  }

  const reloadFormAddEditDateTime = () => {
    setformAddEditDateTime(defaultFormDateTime);
  };

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setformAddEditDateTime({ ...formAddEditDateTime, [name]: value });
  };

  const handleChangeTimeGroupName = (e) => {
    const { value } = e.target;
    setTimeGroupName({ name: value });
  };

  const closeModal = () => {
    reloadFormAddEditDateTime();
    setOpenMenu(false);
    setInfo(null);
    setModalDelete(false);
  };

  const activeModalDelete = (e) => {
    setInfo(e);
    setModalDelete("other");
  };

  const activeAddDateTime = () => {
    if (loading) return;
    setOpenMenu("other");
  };

  const activeEditDateTime = (e) => {
    formatDateTime(e, setInfo, setformAddEditDateTime, setOpenMenu);
  };

  const reloadData = (noLoading) => {
    closeModal();
    petition(noLoading);
  };

  const validateDays = () => {
    const startDayValue = formAddEditDateTime.start_day_of_week;
    const endDayValue = formAddEditDateTime.end_day_of_week;

    if (startDayValue !== "" && endDayValue !== "") {
      if (parseInt(endDayValue) < parseInt(startDayValue)) {
        updateAlertVariables("error", "Error", "End day cannot be earlier than Start day.", 3000)
        setformAddEditDateTime(currentForm)
      } else {
        setCurrentForm(formAddEditDateTime)
      }
    } else {
      setCurrentForm(formAddEditDateTime)
    }
  };

  const validateDates = () => {
    const startDate = moment(formAddEditDateTime.start_date);
    const endDate = moment(formAddEditDateTime.end_date);

    if (startDate.isValid() && endDate.isValid()) {
      if (endDate.isBefore(startDate)) {
        updateAlertVariables("error", "Error", "End date cannot be earlier than Start date.", 3000)
        setformAddEditDateTime(currentForm)
      } else {
        setCurrentForm(formAddEditDateTime)
      }
    } else {
      setCurrentForm(formAddEditDateTime)
    }
  }

  const validateTimes = () => {
    const startTimeStr = formAddEditDateTime.start_time;
    const endTimeStr = formAddEditDateTime.end_time;

    if (startTimeStr && endTimeStr) {
      const numericCurrentStartTime = parseInt(startTimeStr.replaceAll(":", ""))
      const numericCurrentEndTime = parseInt(endTimeStr.replaceAll(":", ""))

      if (numericCurrentEndTime < numericCurrentStartTime) {
        updateAlertVariables("error", "Error", "End time cannot be earlier than Start time.", 3000)
        setformAddEditDateTime(currentForm)
      } else {
        setCurrentForm(formAddEditDateTime)
      }
    } else {
      setCurrentForm(formAddEditDateTime)
    }
  };

  const deleteDateTime = async () => {
    await deleteDateTimeService(setLoadingDateTime, info, reloadData);
  };

  const addEditDateTime = async () => {
    await addOrEditDateTimeService(setLoadingDateTime, formAddEditDateTime, pk, info, reloadData);
  };

  const handleBlurTimeGroupName = async () => {
    await handleBlurTimeGroupNameService(timeGroupName, currentName, setCurrentName, pk);
  };

  const petition = async (noLoading) => {
    try {
      if (!noLoading) setLoading(true);

      const timeRulesTemp = await fetchTimeRules(pk);

      setDataTable({
        columns: [
          { name: "Name", key: "name" },
          { name: "ID", key: "pk" },
          { name: "Start Date", key: "startDate" },
          { name: "End Date", key: "endDate" },
        ],
        content: [...timeRulesTemp],
        actions: {
          title: "Actions",
          content: [
            {
              name: "Edit time Dates",
              icon: <FontAwesomeIcon icon={faPenToSquare} />,
              handleClick: activeEditDateTime,
            },
            {
              name: "Delete",
              icon: <FontAwesomeIcon icon={faTrash} />,
              handleClick: activeModalDelete,
            },
          ],
        },
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    await fetchTimeGroupDetails(pk, setTimeGroupName, setCurrentName, petition);
  };

  useEffect(() => {
    validateDays();
    validateDates();
    validateTimes();
  }, [formAddEditDateTime])

  useEffect(() => {
    if (!pk || !systemTimeZone) {
      navigate("/Advanced-Call-flows");
    } else {
      setDynamicFunction(() => () => {
        navigate("/Advanced-Call-flows");
      });

      getData();
    }
  }, []);

  return (
    <>
      <AlertNotificactions 
        type={alertType}
        alertMessage={alertMessage}
        showTime={showErrorTime}
        title={alertTitle}
      />

      <div className="mt-5">
        {modalDelete && (
          <ModalDelete
            modal={modalDelete}
            closeModal={closeModal}
            loading={loadingDateTime}
            modalFunction={deleteDateTime}
            module="Time Date"
          />
        )}

        <AlignedRow>
          <h3 className="itp-accordion-header-title mb-3">
            Time Group :{" "}
            {!loading ? (
              currentName.name
            ) : (
              <Skeleton height="1rem" width="8rem" />
            )}
          </h3>
        </AlignedRow>

        <AlignedRow>
          <h6 className="itp-accordion-header-subtitle">
            Current TimeZone is: {systemTimeZone}
          </h6>
        </AlignedRow>

        <br></br>
        <div>
          <Row>
            <Col>
              <div className="itp-my-settings-separator-line"></div>
            </Col>
          </Row>
        </div>
        <br></br>

        <AlignedRow>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <label className="itp-edit-number-label-1">Name</label>
                  <label className="itp-edit-number-label-2">
                    Change the name of the specific time group
                  </label>
                </div>

                {loading ? (
                  <div className="itp-edit-number-input">
                    <Skeleton width="100%" height="2rem" />
                  </div>
                ) : (
                  <input
                    placeholder="Add Name"
                    className="form-control-itp itp-edit-number-input"
                    name="name"
                    value={timeGroupName.name}
                    onChange={handleChangeTimeGroupName}
                    onBlur={handleBlurTimeGroupName}
                  />
                )}
              </div>
            </CardBody>
          </Card>
        </AlignedRow>

        <div className="mt-5 mb-4">
          <CustomTableMain
            data={dataTable}
            loading={loading}
            module="time groups"
            m3={true}
            centered={width >= 1697}
            actionsLeft={[
              {
                component: (
                  <div className="d-flex flex-column gap-2">
                    <h3 className="itp-accordion-header-title">
                      Time Dates for group
                    </h3>
                    <h6 className="itp-accordion-header-subtitle">
                      Create & manage dates for this specific time group
                    </h6>
                  </div>
                ),
              },
            ]}
            actionsRight={[
              {
                component: (
                  <AddButton
                    handleClick={activeAddDateTime}
                    name="Add Time Date"
                  />
                ),
              },
            ]}
          />
        </div>

        <MenuRight
          show={openMenu}
          closeMenu={closeModal}
          title="Add Time Date"
          to=""
        >
          {openMenu && (
            <AddEditTimeDate
              formAddEditDateTime={formAddEditDateTime}
              handleChangeForm={handleChangeForm}
              loadingDateTime={loadingDateTime}
              addEditDateTime={addEditDateTime}
              timeZone={systemTimeZone}
            />
          )}
        </MenuRight>
      </div>
    </>
  );
};

export default TimeGroupsDetails;
