const combineObjects = (obj1, obj2) => {
  const result = {};

  const keys = Object.keys(obj1);

  keys.forEach((key) => {
    result[key] = obj2[key];
  });

  return result;
};

export default combineObjects;
