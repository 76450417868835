import { formatTime } from "../../utils";
import { convertUtcDateToTimeZoneDate } from "../../../../../utils/getDate";
import getQueryString from "../../../../../utils/getQueryString";
import petitionPost from "../../../../../services/petitionPost";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";
import formatCallTranscription from "../../../../../utils/formatCallTranscription";

const queueMemberLogsService = async (e, setSelectedFilter, filter, paginacion, timeframe, setAllResult, isChageItems, timeZone, selectedQueuesReport) => {
  setSelectedFilter(filter.split("=")[1]);
  const parameterPaginacion = getQueryString(paginacion).replaceAll("?", "&");

  const agent_id = e.agent;
  const parameter = `?start_date=${timeframe.timeStart}&end_date=${timeframe.timeEnd}${parameterPaginacion}${filter ? filter : ""}`;
  const data = { queue_ids: selectedQueuesReport.map((element) => element.pk) }

  const { data: result } = await petitionPost("queueMemberLogsReport", { agent_id, parameter, data })

  result.result.forEach((element) => {
    element.wait_time = formatTime(element.wait_time);
    element.duration = formatTime(element.duration);
    element.dateFormated = convertUtcDateToTimeZoneDate(element.date, timeZone);
    element.contactName = !element?.contact ? "Unknown number" : `${element?.contact?.firstname} ${element?.contact?.lastname || ""}`;
    element.formatedNumber = formatPhoneNumber(element.number)
    element.call_transcription = formatCallTranscription(element)
  });

  if ((result.total_pages !== undefined && result.offset !== undefined && result.items_per_page !== undefined && result.total_pages > 1) || isChageItems) {
    setAllResult(result);
  } else {
    setAllResult(null);
  }

  return result.result
}

export default queueMemberLogsService;