import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { LoadingButton } from "../../../../../components";

const ModalDesactiveCampaign = ({
  modal,
  closeModal,
  loading,
  modalFunction,
}) => {
  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>Desactive Campaign</ModalHeader>
      <ModalBody>
        This will cancel and permanently deactivate the campaign. All numbers
        will be unassigned from the campaign and you will no longer be able to
        use any texting services until the numbers have been assigned to a new
        campaign.
      </ModalBody>
      <ModalFooter>
        <button disabled={loading} className="btn-light" onClick={closeModal}>
          No
        </button>
        <button
          disabled={loading}
          className="btn-primary loading"
          onClick={modalFunction}
        >
          {loading && <LoadingButton />}
          Yes
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalDesactiveCampaign;
