import { useState } from "react";
import { MenuRight } from "../../../../index"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from '@mui/icons-material/Delete';
import ModalDelete from "./ModalDelete";
import petitionDelete from "../../../../../services/petitionDelete";

const FollowUpSchedule = ({ element, deleteFollowupScheduleMessage, number }) => {
  const [openMenu, setOpenMenu] = useState(false)
  const [followupsSchedule, setFollowupsSchedule] = useState([])
  const [modalDelete, setModalDelete] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [deleteIndividual, setDeleteIndividual] = useState(null)

  const handleClickView = () => {
    const followupScheduleTemp = element?.followUps || []
    setFollowupsSchedule(followupScheduleTemp)
    setOpenMenu(true)
  }

  const activeModalDelete = (element) => {
    if (element) setDeleteIndividual(element)
    setModalDelete(true)
  }

  const closeMenu = () => {
    setOpenMenu(false)
    setFollowupsSchedule([])
  }

  const closeModal = () => {
    setModalDelete(false)
    setDeleteIndividual(null)
  }

  const selectModalFunction = () => {
    if (deleteIndividual) {
      deleteFollowUp(deleteIndividual)
    } else {
      deleteAllFollowups()
    }
  }

  const deleteAllFollowups = async () => {
    try {
      setLoadingDelete(true)

      const threadID = element.message_thread_id
      await petitionDelete("followupTasks", { number, threadID })
    
      setLoadingDelete(false)
      closeMenu()
      deleteFollowupScheduleMessage()
    } catch (error) {
      console.log(error)
      setLoadingDelete(false)
    }
  }

  const deleteFollowUp = async (task) => {
    try {
      setLoadingDelete(true)
      const taskID = task.pk
      const threadID = element.message_thread_id
      await petitionDelete("followupTasks", { number, threadID, parameter: `/${taskID}` })
      closeModal()
      setFollowupsSchedule((prev) => prev.filter((el) => el.pk !== taskID))
    } catch (error) {
      console.log(error)
      setLoadingDelete(false)
    }
  }

  return (
    <>
      {element?.isFollowUpFake && (
        <div className="d-flex justify-content-between">
          <p className="itp-chat-window-content__message followup-schedule-content">
            {element.message_body}
          </p>

          <div onClick={handleClickView} className="d-flex gap-2 ms-5 cursor-pointer">
            <CalendarMonthIcon className="itp-chat-window-content__followup-schedule-icon" />
            <label className="itp-chat-window-content__message cursor-pointer">
              View When
            </label>
          </div>

          <div onClick={() => activeModalDelete()} className="d-flex gap-2 ms-3 cursor-pointer">
            <BlockIcon className="itp-chat-window-content__followup-schedule-icon" />
            <label className="itp-chat-window-content__message cursor-pointer">
              Cancel
            </label>
          </div>
        </div>
      )}

      {modalDelete && (
        <ModalDelete 
          modal={modalDelete}
          closeModal={closeModal}
          loading={loadingDelete}
          modalFunction={selectModalFunction}
        />
      )}

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title="Followup Schedule"
        to="to messages"
      >
        <ul className="schedule-container">
          {followupsSchedule.map((element) => (
            <li key={element.pk}>
              <div className="d-flex gap-2">
                <span className="schedule-item">{element?.scheduledDateFormated || ""}</span>
                <DeleteIcon onClick={() => activeModalDelete(element)} className="schedule-icon-delete" />
              </div>
            </li>
          ))}
        </ul>
      </MenuRight>
    </>
  );
};

export default FollowUpSchedule;
