import { AudioMessage } from '../../../index';
import { downloadTranscription } from '../../utils';
import DownloadIcon from '@mui/icons-material/Download';


const CallAudioTranscription = ({ audioType, customDownload, transcriptions, audioUrl }) => (
  <>
    <div onClick={() => downloadTranscription(transcriptions)} className='itp-call-transcription__download'>
      <DownloadIcon />
      <label className='itp-call-transcription__download-text'>
        Download Transcript
      </label>
    </div>

    <div className='itp-call-transcription__transcription'>
      <div className='itp-call-transcription__transcription-title-container'>
        <label className='itp-call-transcription__transcription-title'>Call recording</label>
      </div>

      <AudioMessage 
        audioUrl={audioUrl || ""}
        audioType={audioType}
        customDownload={customDownload}
        className="m-2"
        variant="secondary"
        downloadWhenClicked
        
      />

      {transcriptions.map((element, i) => (
        <div key={element.Id} className='itp-call-transcription__transcription-text-container'>
          <div className='d-flex justify-content-between align-items-center'>
            <label className={`itp-call-transcription__transcription-text-title ${element.ParticipantRole === 'AGENT' ? 'agent' : ''}`}>
              {element.ParticipantRole === 'AGENT' ? 'Agent' : 'Customer'}
            </label>
            <label className='itp-call-transcription__transcription-text-description mb-0 mt-0'>
              {element.Time}
            </label>
          </div>
          <p className='itp-call-transcription__transcription-text-description'>
            {element.Content}
          </p>
        </div>
      ))}
    </div>
  </>
)

export default CallAudioTranscription;