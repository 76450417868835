import petitionDelete from "../../../../../../services/petitionDelete";

const disabledIntegrationService = async (clickedItem, setEnabledIntegration, updateAlertVariables, setLoadingEnabledIntegration) => {
  try {
    const integrationId = clickedItem.customer_integration_id
    await petitionDelete("disabledIntegration", { integrationId })
    setLoadingEnabledIntegration(false)
  } catch (error) {
    console.log(error);
    const messageError = "An error has ocurred, please try again"
    setEnabledIntegration(true)
    updateAlertVariables("error", "Error", messageError, 4000)
    setLoadingEnabledIntegration(false)
  }
}

export default disabledIntegrationService;