import { Fragment, useState } from "react";
import { LabelInput, LabelSelect, LoadingButton } from "../index";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import "./InputsMenu.css"

const InputsMenu = ({ 
  inputs, 
  loading, 
  form, 
  handleChangeForm, 
  loadingButton, 
  saveFunction,
  className, 
}) => {
  const isTextOrNumberInput = ["text", "number", "password"];
  const isCheckboxInput = ["boolean", "checkbox"];
  const isSelectInput = ["select", "list"];

  const [showPassword, setShowPassword] = useState([])
  const [notFilledInput, setNotFilledInput] = useState([])

  const toggleShowPassword = (key) => {
    if (showPassword.includes(key)) {
      setShowPassword((prev) => prev.filter((el) => el !== key))
    } else {
      setShowPassword((prev) => ([...prev, key]))
    }
  }
  
  const handleChangeFormInput = (e) => {
    const { name, value } = e.target

    const findInput = inputs.find((input) => input.key === name)
    if (findInput?.type === "number" && !/^[0-9]*$/.test(value)) return;

    if (notFilledInput.includes(name)) {
      setNotFilledInput((prev) => prev.filter((key) => key !== name))
    }

    handleChangeForm(e)
  }

  const handleSave = () => {
    let notFilledInputTemp = []

    inputs.forEach((input) => {
      if ((isTextOrNumberInput.includes(input.type) || isSelectInput.includes(input.type)) && input?.required && !form[input.key]) {
        notFilledInputTemp.push(input.key)
      } else if (isCheckboxInput.includes(input.type) && input?.required && input?.inputOnlyTrueValue && !form[input.key]) {
        notFilledInputTemp.push(input.key)
      }
    })

    if (notFilledInputTemp.length) {
      setNotFilledInput(notFilledInputTemp)
    } else {
      saveFunction()
    }
  }

  return (
    <div className={className || ""}>
      {inputs.map((input) => (
        <Fragment key={input.key}>
          {isTextOrNumberInput.includes(input.type) && (
            <LabelInput loading={loading} label={input.label} error={notFilledInput.includes(input.key)}>
              <div className={input.type === "password" ? "d-flex" : ""}>
                <input
                  type={(input.type === "number" || showPassword.includes(input.key)) ? "text" : input.type}
                  placeholder={input?.placeholder || ""}
                  className="form-control-itp itp-label-input__input"
                  name={input.key}
                  value={form[input.key]}
                  onChange={handleChangeFormInput}
                />

                {input.type === "password" && (
                  <>
                    {showPassword.includes(input.key) ? (
                      <VisibilityOffIcon className="cursor-pointer" onClick={() => toggleShowPassword(input.key)} />
                    ) : (
                      <VisibilityIcon className="cursor-pointer" onClick={() => toggleShowPassword(input.key)} />
                    )}
                  </>
                )}
              </div>
            </LabelInput>
          )}

          {isCheckboxInput.includes(input.type) && (
            <LabelSelect label={input.label} loading={loading} error={notFilledInput.includes(input.key)}>
              <input
                checked={form[input.key]}
                type="checkbox"
                name={input.key}
                value={form[input.key]}
                onChange={handleChangeFormInput}
                className="form-check-input itp-inputs-menu__checkbox"
              />
            </LabelSelect>
          )}

          {isSelectInput.includes(input.type) && (
            <LabelSelect label={input.label} loading={loading} error={notFilledInput.includes(input.key)}>
              <select
                className={`form-select-itp ${notFilledInput.includes(input.key) ? "error" : ""}`}
                onChange={handleChangeFormInput}
                name={input.key}
                value={form[input.key]}
              >
                <option value="" disabled>
                  Select Option
                </option>
                {input.options.map((option) => (
                  <option key={option.valu} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </LabelSelect>
          )}
        </Fragment>
      ))}

      <button className="btn-primary loading mt-3" onClick={handleSave}>
        {loadingButton && <LoadingButton />}
        Save
      </button>
    </div>
  );
};

export default InputsMenu;
