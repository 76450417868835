// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-edit-callFlow-numbers-add-button {
  padding-top: 0 !important;
  width: 8rem !important;
  height: 2rem;
  margin-right: 2rem;
}

.itp-edit-callFlow-numbers__numbers {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.itp-edit-callFlow-numbers-switch {
  margin-right: 2rem;
}

.itp-editCallflowNumber__not-select-number {
  cursor: pointer;
}

.itp-editCallflowNumber__not-select-number:hover > .itp-edit-number-label-1 {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/WorkspaceSettings/AdvancedCallFlows/components/EditCallFlowNumbers/EditCallFlowNumbers.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".itp-edit-callFlow-numbers-add-button {\n  padding-top: 0 !important;\n  width: 8rem !important;\n  height: 2rem;\n  margin-right: 2rem;\n}\n\n.itp-edit-callFlow-numbers__numbers {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.itp-edit-callFlow-numbers-switch {\n  margin-right: 2rem;\n}\n\n.itp-editCallflowNumber__not-select-number {\n  cursor: pointer;\n}\n\n.itp-editCallflowNumber__not-select-number:hover > .itp-edit-number-label-1 {\n  text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
