const calculateGeneralSentiment = (transcriptionData) => {
  const overallSentiment = transcriptionData?.ConversationCharacteristics?.Sentiment?.OverallSentiment || {};

  // Add the feelings of all the participants
  let totalFeeling = 0;
  let quantityParticipants = 0;

  for (const participante in overallSentiment) {
    totalFeeling += overallSentiment[participante];
    quantityParticipants++;
  }

  // Calculate the average of the general sentiment
  const feelingGeneral = totalFeeling / quantityParticipants;

  // Determine sentiment value in text
  let feelingText = "NEUTRAL";
  if (feelingGeneral > 0) {
    feelingText = "POSITIVE";
  } else if (feelingGeneral < 0) {
    feelingText = "NEGATIVE";
  }

  return feelingText;
};

export default calculateGeneralSentiment;
