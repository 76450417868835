import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { LoadingButton } from "../../../../index";

const ModalDelete = ({
  modal,
  closeModal,
  loading,
  modalFunction,
}) => {
  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>Delete Followup Schedule</ModalHeader>
      <ModalBody>Are You Sure?</ModalBody>
      <ModalFooter>
        <button disabled={loading} className="btn-light" onClick={closeModal}>
          No
        </button>

        <button
          disabled={loading}
          onClick={modalFunction}
          className="btn-primary loading"
        >
          {loading && <LoadingButton />}
          Yes
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalDelete;
