// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-panel-right-content.open {
  top: 7rem;
  position: fixed;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 0.30000001192092896px solid #70707073;
  opacity: 1;
  height: calc(100% - 7.3rem);
  width: 30%;
  right: 0;
  transform: translateX(0%);
  transition: transform 0.2s;
  z-index: 3;
  overflow: auto;
  bottom: 0;
}

.itp-panel-right-content.hidden {
  transform: translateX(100%);
  transition: transform 0.2s;
  margin-top: 70px;
  position: fixed;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0;
  border: 0.30000001192092896px solid #70707073;
  opacity: 1;
  height: calc(100% - 70px);
  width: 30%;
  right: 0;
  z-index: 3;
  overflow: auto;
  bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/PanelRight/PanelRight.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,eAAe;EACf,+CAA+C;EAC/C,iCAAiC;EACjC,6CAA6C;EAC7C,UAAU;EACV,2BAA2B;EAC3B,UAAU;EACV,QAAQ;EACR,yBAAyB;EACzB,0BAA0B;EAC1B,UAAU;EACV,cAAc;EACd,SAAS;AACX;;AAEA;EACE,2BAA2B;EAC3B,0BAA0B;EAC1B,gBAAgB;EAChB,eAAe;EACf,+CAA+C;EAC/C,aAAa;EACb,6CAA6C;EAC7C,UAAU;EACV,yBAAyB;EACzB,UAAU;EACV,QAAQ;EACR,UAAU;EACV,cAAc;EACd,SAAS;AACX","sourcesContent":[".itp-panel-right-content.open {\n  top: 7rem;\n  position: fixed;\n  background: #ffffff 0% 0% no-repeat padding-box;\n  box-shadow: 0px 0px 6px #00000029;\n  border: 0.30000001192092896px solid #70707073;\n  opacity: 1;\n  height: calc(100% - 7.3rem);\n  width: 30%;\n  right: 0;\n  transform: translateX(0%);\n  transition: transform 0.2s;\n  z-index: 3;\n  overflow: auto;\n  bottom: 0;\n}\n\n.itp-panel-right-content.hidden {\n  transform: translateX(100%);\n  transition: transform 0.2s;\n  margin-top: 70px;\n  position: fixed;\n  background: #ffffff 0% 0% no-repeat padding-box;\n  box-shadow: 0;\n  border: 0.30000001192092896px solid #70707073;\n  opacity: 1;\n  height: calc(100% - 70px);\n  width: 30%;\n  right: 0;\n  z-index: 3;\n  overflow: auto;\n  bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
