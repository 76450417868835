import { Col, Row } from "reactstrap";
import { CollapsePanel } from "../../../../../../../../components";
import Switch from "react-switch";
import AsyncSelect from "react-select/async";
import Skeleton from "react-loading-skeleton";

const AI = ({ 
  form, 
  handleChangeswitch, 
  loading, 
  aIAgentValue, 
  handleChangeAIAgent, 
  loadAIAgentsValue, 
}) => (
  <>
    <br></br>
    <div>
      <Row>
        <Col>
          <div className="itp-my-settings-separator-line"></div>
        </Col>
      </Row>
    </div>
    <br></br>

    <CollapsePanel
      title="Artificial Intelligence"
      subtitle="Enable Artificial Intelligence to this number"
    >
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex flex-column">
          <label className="itp-edit-number-label-1">AI</label>
          <label className="itp-edit-number-label-2">Enable AI</label>
        </div>
        {loading ? (
          <Skeleton width="5rem" height="2rem" />
        ) : (
          <Switch
            className="itp-custom-table-switch mt-3"
            onColor="#626ed4"
            height={20} // Ajusta la altura según tus necesidades
            width={40}
            checked={form.ai_enabled}
            onChange={() => handleChangeswitch("ai_enabled")}
          />
        )}
      </div>

      {form.ai_enabled && (
        <>
          <div className="d-flex justify-content-between align-items-center flex-wrap mt-4">
            <div className="d-flex flex-column">
              <label className="itp-edit-number-label-1">Auto Followup</label>
              <label className="itp-edit-number-label-2">
                Enables automatic chat tracking. If a sent message does not
                receive a response within a set time period, the AI will
                automatically send a nother message.
              </label>
            </div>
            {loading ? (
              <Skeleton width="5rem" height="2rem" />
            ) : (
              <Switch
                className="itp-custom-table-switch mt-3"
                onColor="#626ed4"
                height={20} // Ajusta la altura según tus necesidades
                width={40}
                checked={form.auto_followup}
                onChange={() => handleChangeswitch("auto_followup")}
              />
            )}
          </div>

          <div className="d-flex justify-content-between align-items-center flex-wrap mt-4">
            <div className="d-flex flex-column">
              <label className="itp-edit-number-label-1">
                AI Agent
              </label>
              <label className="itp-edit-number-label-2">
                Select the AI agent 
              </label>
            </div>
            {loading ? (
              <Skeleton height="3rem" width="10rem" />
            ) : (
              <AsyncSelect
                onChange={handleChangeAIAgent}
                className="basic-single itp-edit-number-ai-select"
                classNamePrefix="select"
                value={aIAgentValue}
                isSearchable={true}
                name="ai_lead_manager_funnel_id"
                placeholder="Select Option"
                loadOptions={loadAIAgentsValue}
                defaultOptions
                cacheOptions
              />
            )}
          </div>
        </>
      )}
    </CollapsePanel>
  </>
);

export default AI;
