import React, { useState } from "react";
import Manually from "./options/Manually/Manually";
import Import from "./options/Import/Import";
import { LoadingButton } from "../../../../components";
import "./AddContact.css";

const AddContact = ({
  addFunction,
  loadingMenuButton,
  setImportContacts,
  setImportedContacts,
  setAddedContactListImort,
  customFields,
  form,
  setForm,
  error,
  setError,
  valueContactList,
  setValueContactList,
  optionButton,
  setOptionButton,
  back,
  myExtension,
  updateAlertVariables,
}) => {
  const [addContact, setAddContact] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);

  const handleChange = (e) => {
    setAddContact(e.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onChangeForm = (e) => {
    setError("")
    setForm({ ...form, [e.target.name]: e.target.value });

    if (
      e.target.name === "email" &&
      !validateEmail(e.target.value) &&
      e.target.value !== ""
    ) {
      setErrorEmail(true);
    } else if (
      e.target.name === "email" &&
      (validateEmail(e.target.value) || e.target.value === "")
    ) {
      setErrorEmail(false);
    }
  };

  const selectAddContact = () => {
    setOptionButton(addContact);
    if (addContact === "importContact") {
      setImportContacts(true);
    }
    setAddContact("");
  };

  return (
    <>
      <p className="itp-add-contact-subtitle">
        
      </p>

      {optionButton === "" && (
        <>
          
          <select
            onChange={handleChange}
            className="form-select-itp itp-add-contact-select"
          >
            <option value="">Select Option</option>
            <option value="addManually">Add Manually</option>
            <option value="importContact">Import Contact</option>
          </select>
          <button
            disabled={addContact === "" ? true : false}
            className="btn-primary itp-contact-button"
            onClick={selectAddContact}
          >
            Next
          </button>
        </>
      )}

      {optionButton === "addManually" && (
        <Manually
          form={form}
          setForm={setForm}
          valueContactList={valueContactList}
          setValueContactList={setValueContactList}
          onChangeForm={onChangeForm}
          errorEmail={errorEmail}
          error={error}
          setError={setError}
        />
      )}

      {optionButton === "importContact" && (
        <Import
          backFunction={back}
          setImportedContacts={setImportedContacts}
          setAddedContactListImort={setAddedContactListImort}
          customFields={customFields}
          myExtension={myExtension}
          updateAlertVariables={updateAlertVariables}
        />
      )}

      {optionButton === "addManually" && (
        <div className="itp-add-contact-buttons">
          <button
            className="btn-light itp-contact-button"
            onClick={back}
            disabled={loadingMenuButton}
          >
            Back
          </button>
          <button
            className="btn-primary itp-contact-button loading"
            onClick={optionButton === "addManually" ? addFunction : back}
            disabled={
              loadingMenuButton ||
              !form.firstname ||
              !form.contact_list_id ||
              (form.email && !validateEmail(form.email))
            }
          >
            {loadingMenuButton && <LoadingButton />}
            Save
          </button>
        </div>
      )}
    </>
  );
};

export default AddContact;
