import EnableAI from "./EnableAI/EnableAI";
import Industry from "./Industry/Industry";
import SubIndustries from "./SubIndustries/SubIndustries";
import FrequentlyAsked from "./FrequentlyAsked/FrequentlyAsked";
import SalesLead from "./SalesLead/SalesLead";
import FollowUps from "./FollowUps/FollowUps";
import AIAgents from "./AIAgents/AIAgents";

export {
  EnableAI,
  Industry,
  SubIndustries,
  FrequentlyAsked,
  SalesLead,
  FollowUps,
  AIAgents,
};
