// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-callFlow-menu-container-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.itp-callFlow-menu-action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #85858529;
  border: 0.30000001192092896px solid #70707073;
  border-radius: 8px;
  opacity: 1;
  width: 10rem;
  height: 4rem;
  /* cursor: pointer; */
}

.itp-callFlow-menu-action.selected {
  border: 0.3px solid var(--unnamed-color-002340);
  background: #0023400a 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #85858529;
  border: 0.30000001192092896px solid #002340;
  border-radius: 8px;
  opacity: 1;
}

.itp-callFlow-menu-action:hover {
  background-color: #0023400a;
}

.itp-callFlow-menu-name {
  font: normal normal normal 12px/12px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.itp-callFlow-menu-back-selected {
  color: #437097;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/WorkspaceSettings/AdvancedCallFlows/components/ChartMenuContent/ChartMenuContent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,+CAA+C;EAC/C,iCAAiC;EACjC,6CAA6C;EAC7C,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,+CAA+C;EAC/C,iDAAiD;EACjD,iCAAiC;EACjC,2CAA2C;EAC3C,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,oDAAoD;EACpD,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":[".itp-callFlow-menu-container-actions {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 1rem;\n}\n\n.itp-callFlow-menu-action {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background: #ffffff 0% 0% no-repeat padding-box;\n  box-shadow: 0px 0px 6px #85858529;\n  border: 0.30000001192092896px solid #70707073;\n  border-radius: 8px;\n  opacity: 1;\n  width: 10rem;\n  height: 4rem;\n  /* cursor: pointer; */\n}\n\n.itp-callFlow-menu-action.selected {\n  border: 0.3px solid var(--unnamed-color-002340);\n  background: #0023400a 0% 0% no-repeat padding-box;\n  box-shadow: 0px 0px 6px #85858529;\n  border: 0.30000001192092896px solid #002340;\n  border-radius: 8px;\n  opacity: 1;\n}\n\n.itp-callFlow-menu-action:hover {\n  background-color: #0023400a;\n}\n\n.itp-callFlow-menu-name {\n  font: normal normal normal 12px/12px Instrument Sans;\n  letter-spacing: 0px;\n  color: #3a3a3acc;\n  opacity: 1;\n}\n\n.itp-callFlow-menu-back-selected {\n  color: #437097;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
