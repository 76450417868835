/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { TextAreaVarSelect } from "../../../../../../../components";
import petitionGet from "../../../../../../../services/petitionGet";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import Switch from "react-switch";
import PhoneInput from "react-phone-input-2";

const SendSMS = ({ saveModule, edit, data, editModule }) => {
  const [loading, setLoading] = useState(true)
  const [numbers, setNumbers] = useState([])
  const [contactVariables, setContactVariables] = useState([])
  const [activeSwitch, setActiveSwitch] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [form, setForm] = useState({ 
    from_number: "",
    message_body: "",
    contact_sms_body: "",
    send_to_number: "",
  })

  const handleTabClick = (number) => {
    setActiveTab(number)
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  const handleChangeForm = (name, value) => {
    setForm({ ...form, [name]: value })
  }

  const handleChangeSwitch = () => {
    if (!activeSwitch) {
      setForm({ ...form, send_to_number: "" })
    }

    setActiveSwitch((prev) => !prev)
  }

  const saveSendSMS = () => {
    const formTemp = { ...form, from_number: form.from_number.number }
    if (edit) {
      editModule(formTemp, "send_sms");
    } else {
      saveModule(formTemp, "send_sms"); 
    }
  }

  const petition = async () => {
    try {
      const { data: result } = await petitionGet("viewNumbers");

      result.result.forEach((element) => {
        element.label = element?.number
        element.value = element?.number;
      });

      if (edit) {
        const findNumber = result.result.find((number) => number.number === data.data.from_number)
        const formTemp = { ...form, ...data.data, from_number: findNumber}
        setActiveSwitch(!data.data?.send_to_number)
        setForm(formTemp)
      }

      const { data: response } = await petitionGet("customFields")

      response.result.forEach((element) => {
        element.label = element.name;
        element.value = `{{contact.${element.name}}}`;
      });

      setContactVariables([
        {
          title: "Variables",
          options: [
            {
              title: "Contact",
              options: [
                {
                  label: "First Name",
                  value: "{{contact.firstname}}",
                },
                {
                  label: "Last Name",
                  value: "{{contact.lastname}}",
                },
                { label: "Phone", value: "{{contact.phone}}" },
                { label: "Email", value: "{{contact.email}}" },
                ...response.result,
              ],
            },
          ],
          dropdownOpen: false,
        },
      ]);

      setNumbers(result.result)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (data) petition()
  }, [data]);

  return (
    <>
      <label className="itp-label-input__label mt-4">Number to use</label>
      {loading ? (
        <div className="w-100">
          <Skeleton width="100%" height="2.5rem" />
        </div>
      ) : (
        <Select 
          onChange={(value) => handleChangeForm("from_number", value)}
          className="basic-single"
          classNamePrefix="select"
          value={form.from_number}
          isSearchable={true}
          name="queue"
          placeholder="Select Number"
          options={numbers}
        />
      )}

      <div className="itp-tab-menu mt-4">
        <div className="itp-tab-menu__tabs-container">
          <div className="itp-tab-menu__tabs">
            <div className={`itp-tab-menu__tab ${activeTab === 0 ? "active" : ""}`} onClick={() => handleTabClick(0)}>
              With Contact
            </div>
            <div className={`itp-tab-menu__tab ${activeTab === 1 ? "active" : ""}`} onClick={() => handleTabClick(1)}>
              Without Contact
            </div>
          </div>
        </div>
        <div className="itp-tab-menu__content">
          {activeTab === 0 && (
            <>
              <label className="itp-label-input__label mt-4">Message to send</label>
              <TextAreaVarSelect
                onChangeTextArea={(value) => handleChangeForm("contact_sms_body", value)}
                textAreaDefaultValue={form.contact_sms_body}
                loading={loading}
                options={contactVariables}
              />
            </>
          )}

          {activeTab === 1 && (
            <>
              <label className="itp-label-input__label mt-4">Message to send</label>
              <TextAreaVarSelect
                onChangeTextArea={(value) => handleChangeForm("message_body", value)}
                textAreaDefaultValue={form.message_body}
                loading={loading}
                options={[{ title: "Variables", options: [{ title: "Call", options: [{ label: "Caller", value: "{{caller.caller_id_number}}" }, { label: "Dialed Number", value: "{{caller.dnis}}" }] }] }]}
              />
            </>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-between alig-items-center mt-3">
        <label>Send message to Caller</label>
        <Switch
          uncheckedIcon={<Offsymbol />}
          className="mr-1"
          checkedIcon={<OnSymbol />}
          onColor="#626ed4"
          onChange={handleChangeSwitch}
          checked={activeSwitch}
        />
      </div>

      {!activeSwitch && (
        <>
          <label className="itp-label-input__label mt-3">Send Message to Number:</label>
          <PhoneInput
            enableSearch={true}
            onlyCountries={['us', 'ca']}
            preferredCountries={['us', 'ca']}
            country={'us'}
            value={form.send_to_number}
            onChange={(value) => handleChangeForm("send_to_number", value)}
          />
        </>
      )}

      <button 
        onClick={saveSendSMS} 
        className="btn-primary mt-4"
        disabled={!form.contact_sms_body.trim() || !form.message_body.trim() || !form.from_number || (!activeSwitch && form.send_to_number.length < 11)}
      >
        Save
      </button>
    </>
  )
}

export default SendSMS;