import { useCallback, useEffect, useRef, useState } from "react";

const useAudioRef = (setTimeProgress) => {
  const audioRef = useRef(null);
  const progressBarRef = useRef(null);
  const playAnimationRef = useRef();

  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
    progressBarRef.current.max = seconds;
  };

  const handleProgressChange = () => {
    audioRef.current.currentTime = progressBarRef.current.value;
  };

  const repeat = useCallback(() => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime;
      setTimeProgress(currentTime);

      progressBarRef.current.value = currentTime;
      progressBarRef.current.style.setProperty(
        "--range-progress",
        `${(currentTime / duration) * 100}%`
      );

      if (currentTime === duration) {
        // Cuando el audio termina, ajustar el progreso y reiniciar
        setIsPlaying(false);
        setTimeProgress(0)
        audioRef.current.currentTime = 0; // Reinicia el audio al inicio
        progressBarRef.current.value = 0; // Reinicia la barra de progreso
        progressBarRef.current.style.setProperty("--range-progress", `0%`); // Ajustar la barra a 0
      } else {
        playAnimationRef.current = requestAnimationFrame(repeat);
      }
    }
  }, [audioRef, duration, progressBarRef, setTimeProgress]);

  useEffect(() => {
    if (isPlaying && audioRef.current) {
      audioRef.current.play();
      playAnimationRef.current = requestAnimationFrame(repeat);
    } else if (!isPlaying && audioRef.current) {
      audioRef.current.pause();
      cancelAnimationFrame(playAnimationRef.current); // Cancelar la animación cuando se pausa
    }
  
    return () => {
      cancelAnimationFrame(playAnimationRef.current); // Cancelar la animación al desmontar
    };
  }, [isPlaying, repeat]);
  

  return {
    onLoadedMetadata,
    handleProgressChange,
    audioRef,
    progressBarRef,
    isPlaying,
    setIsPlaying,
    duration,
  };
};

export default useAudioRef;
