const CallSummary = () => (
  <div className="itp-call-transcription__call-summary">
    <h6 className="itp-call-transcription__call-summary-title">Call Summary</h6>
    <p className="itp-call-transcription__call-summary-description">
      The customer had a conversation with the agent. The agent asked for
      private information from the customer. The conversation ended with the
      agent making an inappropriate request and saying goodbye to the customer.
    </p>
  </div>
);

export default CallSummary;
