// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-chat-window__user-sent-message-other {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  position: relative;
  margin-top: -0.5rem;
}

.itp-chat-window__user-sent-message-you {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
  position: relative;
  margin-top: -0.5rem;
}

.itp-chat-window__user-sent-message-content {
  color: #6d28d9;
  font-family: "Source Sans Pro";
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ChatWindow/ChatWindowContent/components/UserWhoSentMessage/UserWhoSentMessage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,8BAA8B;EAC9B,eAAe;AACjB","sourcesContent":[".itp-chat-window__user-sent-message-other {\n  display: flex;\n  justify-content: flex-start;\n  margin-bottom: 0.5rem;\n  position: relative;\n  margin-top: -0.5rem;\n}\n\n.itp-chat-window__user-sent-message-you {\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 0.5rem;\n  position: relative;\n  margin-top: -0.5rem;\n}\n\n.itp-chat-window__user-sent-message-content {\n  color: #6d28d9;\n  font-family: \"Source Sans Pro\";\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
