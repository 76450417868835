import Analytics from "./Analytics/Analytics";
import Contacts from "./Contacts/Contacts";
import Dashboard from "./Dashboard/Dashboard";
import Funnels from "./Funnels/Funnels";
import Leads from "./Leads/Leads";
import LivePanel from "./LivePanel/LivePanel";
import SmartCampaigns from "./SmartCampaigns/SmartCampaigns";
import SupportCenter from "./SupportCenter/SupportCenter";
import Conversations from "./Conversations/Conversations";
import GeneralSettings from "./WorkspaceSettings/GeneralSettings/GeneralSettings";
import APIKeys from "./WorkspaceSettings/APIKeys/APIKeys";
import Forms from "./WorkspaceSettings/Forms/Forms";
import Dlc from "./WorkspaceSettings/10dlc/10dlc";
import Webhooks from "./WorkspaceSettings/Webhooks/Webhooks";
import PhoneNumbers from "./WorkspaceSettings/PhoneNumbers/PhoneNumber";
import WorkFlows from "./WorkspaceSettings/WorkFlows/WorkFlows";
import AdvancedCallFlows from "./WorkspaceSettings/AdvancedCallFlows/AdvancedCallFlows";
import Users from "./WorkspaceSettings/Users/Users";
import LivePanelSettings from "./WorkspaceSettings/LivePanelSettings/LivePanelSettings";
import Troubleshooting from "./WorkspaceSettings/Troubleshooting/Troubleshooting";
import AI from "./WorkspaceSettings/AI/AI";
import Billing from "./WorkspaceSettings/Billing/Billing";
import MySettings from "./MySettings/MySettings";
import CallHistory from "./CallHistory/CallHistory"
import Integrations from "./WorkspaceSettings/Integrations/Integrations";

//dynamic routes
import ContactList from "./Contacts/Tabs/ContactList/ContactList";
import Tags from "./Contacts/Tabs/Tags/Tags";
import FunnelDetails from "./Funnels/FunnelDetails/FunnelDetails";
import ViewLogs from "./SmartCampaigns/ViewLogs/ViewLogs";
import EditNumber from "./WorkspaceSettings/PhoneNumbers/components/EditNumber/EditNumber";
import EditCallFlowNumbers from "./WorkspaceSettings/AdvancedCallFlows/components/EditCallFlowNumbers/EditCallFlowNumbers";
import TimeGroupsDetails from "./WorkspaceSettings/AdvancedCallFlows/components/TimeGroupsDetails/TimeGroupsDetails";
import QueueDetails from "./WorkspaceSettings/AdvancedCallFlows/components/QueueDetails/QueueDetails"
import EditUSer from "./WorkspaceSettings/Users/components/EditUser/EditUSer";
import FollowTasks from "./SmartCampaigns/FollowTasks/FollowTasks"

export {
  Analytics,
  Contacts,
  Dashboard,
  Funnels,
  Leads,
  LivePanel,
  SmartCampaigns,
  SupportCenter,
  Conversations,
  GeneralSettings,
  APIKeys,
  Forms,
  Webhooks,
  Dlc,
  PhoneNumbers,
  WorkFlows,
  AdvancedCallFlows,
  Users,
  LivePanelSettings,
  Troubleshooting,
  AI,
  Billing,
  MySettings,
  CallHistory,
  Integrations,
  //dynamic routes
  ContactList,
  Tags,
  FunnelDetails,
  ViewLogs,
  EditNumber,
  EditCallFlowNumbers,
  TimeGroupsDetails,
  QueueDetails,
  EditUSer,
  FollowTasks,
};
