import { Col, Row } from "reactstrap";
import { DateRange } from "../../../../../components";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import { typeReports } from "../../const";

const SelectReport = ({
  timeframe,
  onChangeDate,
  handleChangeSelectReport,
  selectedReport,
  selectOnChangeSelect,
  selectValueinSelectedReport,
  selectOptionInSelectedReport,
  selectedUsers,
  selectedNumbers,
  selectedQueuesReport,
  selectedQueueMembersReport,
  activeDataReport,
  onChangeQueueReport,
  optionsQueuesReport,
  loading,
}) => {
  const disabledButton = () => {
    if (selectedReport === "" || loading) return true;

    if (!timeframe.timeStart || !timeframe.timeEnd) return true;

    if (selectedReport === "Users" && selectedUsers.length === 0) return true;

    if ((selectedReport === "Numbers" || selectedReport === "SMS") && selectedNumbers.length === 0) return true;

    if (selectedReport === "Queues" && selectedQueuesReport.length === 0) return true;

    if (selectedReport === "Queue Members" && (selectedQueueMembersReport.length === 0 || selectedQueuesReport.length === 0)) return true;

    return false;
  };

  return (
    <>
      <label className="itp-label-input__label">Set Date</label>
      <p className="itp-analytics-description mb-2">
        Set a date to run a report for
      </p>

      {loading ? (
        <Skeleton borderRadius="18px" height={40} width="20rem" />
      ) : (
        <DateRange
          timeStart={timeframe.timeStart}
          timeEnd={timeframe.timeEnd}
          onChangeDate={onChangeDate}
          loading={loading}
        />
      )}

      <div className="itp-analytics-container-select-report mt-4 mb-4">
        <label className="itp-label-input__label">Set Date</label>
        <Row className="itp-container-label-select">
          <Col
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
            className="itp-label-input-col"
          >
            <label className="itp-label-input__label itp-analytics-label-select">
              Select the report type you need
            </label>
          </Col>
          <Col
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
            className="itp-label-input-col"
          >
            {loading ? (
              <Skeleton height={50} width="100%" />
            ) : (
              <select
                className="form-select-itp"
                onChange={handleChangeSelectReport}
                name="selectedReport"
                value={selectedReport}
              >
                <option value="" disabled>Select Option</option>
                {typeReports.map((report) => (
                  <option key={report.value} value={report.value}>{report.label}</option>
                ))}
              </select>
            )}
          </Col>
        </Row>
      </div>

      {selectedReport && !loading && (
        <>
          {selectedReport === "Queue Members" && (
            <div className="itp-analytics-container-select-report mt-4 mb-4">
              <label className="itp-label-input__label">Queues</label>
              <Row className="itp-container-label-select">
                <Col
                  className="itp-label-input-col"
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={4}
                >
                  <label className="itp-label-input__label itp-analytics-label-select">
                    Select the Queues you need the filter for Queue Members report
                  </label>
                </Col>
                <Col
                  className="itp-label-input-col"
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                >
                  <Select
                    isMulti
                    onChange={onChangeQueueReport}
                    className="basic-single mb-4"
                    classNamePrefix="select"
                    value={selectedQueuesReport}
                    isSearchable={true}
                    name=""
                    placeholder="Select Queue(s)"
                    options={optionsQueuesReport}
                  />
                </Col>
              </Row>
            </div>
          )}

          <div className="itp-analytics-container-select-report mt-4 mb-4">
            <label className="itp-label-input__label">{selectedReport}</label>
            <Row className="itp-container-label-select">
              <Col
                className="itp-label-input-col"
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
              >
                <label className="itp-label-input__label itp-analytics-label-select">
                  Select the {selectedReport === "SMS" ? "numbers" : selectedReport} you need the report for
                </label>
              </Col>
              <Col
                className="itp-label-input-col"
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <Select
                  isMulti
                  onChange={selectOnChangeSelect}
                  className="basic-single mb-4"
                  classNamePrefix="select"
                  value={selectValueinSelectedReport()}
                  isSearchable={true}
                  name=""
                  placeholder={`Select a ${selectedReport}`}
                  options={selectOptionInSelectedReport()}
                />
              </Col>
            </Row>
          </div>
        </>
      )}

      <button
        disabled={disabledButton()}
        className="btn-primary loading itp-w-buton mb-2"
        onClick={activeDataReport}
      >
        Run Report
      </button>
    </>
  );
};

export default SelectReport;
