const Badge = ({ element, column, config }) => {
  const data = element[column.key]
  const styles = element[config.keyStyles];

  const handleClick = () => {
    if (typeof config.clickColumn === "function") config.clickColumn(element)
  }

  return <span onClick={handleClick} style={styles || {}}>{data}</span>;
};

export default Badge;
