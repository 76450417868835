const selectInputsNotFilled = (form, loadingMenu) => {
  let inputsNotFilled = []

  if (!form.name) {
    inputsNotFilled.push("name")
  }

  if (!form.description) {
    inputsNotFilled.push("description")
  }

  if (!form.agent_type && !loadingMenu) {
    inputsNotFilled.push("agent_type")
  }

  if (!form.ai_calender_strategy && !loadingMenu) {
    inputsNotFilled.push("ai_calender_strategy")
  }

  if (form.ai_manage_appointments && !form.assigned_users.length && !loadingMenu) {
    inputsNotFilled.push("assigned_users")
  }

  if (form.ai_lead_manager && !form.ai_lead_manager_funnel_id && !loadingMenu) {
    inputsNotFilled.push("ai_lead_manager_funnel_id")
  }

  if (form.ai_lead_manager && !form.ai_lead_manager_screening_questions_list_id && !loadingMenu) {
    inputsNotFilled.push("ai_lead_manager_screening_questions_list_id")
  }

  if (!form.personality_id && !loadingMenu) {
    inputsNotFilled.push("personality_id")
  }

  return inputsNotFilled
}

export default selectInputsNotFilled;