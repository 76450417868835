import petitionPatch from "../../../../../../services/petitionPatch"

const editAIService = async (field, formGeneralAI, setFormGeneralAI, currentGeneralForm, setCurrentGeneralForm, updateAlertVariables) => {
  try {
    const data = { [field]: formGeneralAI[field] }
    await petitionPatch("aiDetailsUpdate", { data })

    setCurrentGeneralForm(formGeneralAI)
  } catch (error) {
    console.log(error)
    const errorMessage = "An error has ocurred, please try again"
    setFormGeneralAI(currentGeneralForm)
    updateAlertVariables("error", "Error", errorMessage, 3000)
  }
}

export default editAIService;