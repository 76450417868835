export const branAlertTextNotCreated = "You are currently not registered for sending messages, click the button below"
export const brandAlertTextInProgress = "Your information is now being reviewed"
export const brandAlertTextRejected = "Your information was unable to be verified"
export const brandAlertTextApproved = "Congrats, your 10DLC Registration has been approved"
export const brandAlertTextErrorSuport = "You currently have an error"

export const brandCardTitleNotCreated = "Start your registration";
export const brandCardTitleInProgress = "Review in progress...";
export const brandCardTitleRejected = "Rejected";
export const brandCardTitleApproved = "Approved";
export const brandCardTitleErrorSuport = "An error has ocurred"

export const brandCardSubtitleNotCreated = "Registering allows you to send messages and comply with telecom regulations";
export const brandCardSubtitleInProgress = "Your information is being reviewed. Refresh this page for up to date information";
export const brandCardSubtitleRejected = "Your registration was rejected due to the following reason";
export const brandCardSubtitleApproved = "Your information has been approved. You can now proceed to create a campaign on this registration below";
export const brandCardSubtitleErrorSuport = "There was an error registering your company, please contact our support team"

export const brandButtonNameNotCreated = "Begin";
export const brandButtonNameInProgress = "Refresh";
export const brandButtonNameRejected = "Update";
export const brandButtonNameApproved = "View Details";
export const brandButtonErrorSuport = ""

export const campaignAlertTextNotCreated = "To ensure deliverability and compliance please complete the campaign registration below"
export const campaignCardTitleNotCreated = "Register you first campaign"
export const campaignCardSubtitleNotCreated = "Almost there... Now, click below to register your first campaign"
export const campaignButtonNameNotCreated = "Start Registration"

export const campaignAlerTextPending = "You have a campaign(s) pending verification, this process can take a few days"
export const campaignAlerTextRejected= "There was an error in one of your campigns. View the reason and resubmit"
export const campaignAlerTextApproved= "Congrats, your campaign was approved. Add more for different use cases"