// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-table-contact-list {
  margin-top: 16px;
  margin-bottom: 20px;
}

.select-checkbox-responsive.select-container {
  visibility: visible;
}

@media (max-width: 991px) {
  .select-checkbox-responsive.select-container {
    margin-top: 0;
  }
}

.btn-light.itp-show-filter-button.itp-show-filterButton-responsive, 
.btn-primary.itp-addButton-responsive,
.itp-container-search-input.itp-searchBoxFilter-responsive {
  visibility: visible;
}

@media (max-width: 847px) {
  .btn-light.itp-show-filter-button.itp-show-filterButton-responsive, 
  .btn-primary.itp-addButton-responsive {
    margin-top: 0.5rem;
  }
}

@media (max-width: 561px) {
  .itp-container-search-input.itp-searchBoxFilter-responsive {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}

.tabs-icons {
  font-size: 17px !important;
  margin-right: 0.25rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/Contacts/Contacts.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;;;EAGE,mBAAmB;AACrB;;AAEA;EACE;;IAEE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,qBAAqB;IACrB,kBAAkB;EACpB;AACF;;AAEA;EACE,0BAA0B;EAC1B,qBAAqB;AACvB","sourcesContent":[".container-table-contact-list {\n  margin-top: 16px;\n  margin-bottom: 20px;\n}\n\n.select-checkbox-responsive.select-container {\n  visibility: visible;\n}\n\n@media (max-width: 991px) {\n  .select-checkbox-responsive.select-container {\n    margin-top: 0;\n  }\n}\n\n.btn-light.itp-show-filter-button.itp-show-filterButton-responsive, \n.btn-primary.itp-addButton-responsive,\n.itp-container-search-input.itp-searchBoxFilter-responsive {\n  visibility: visible;\n}\n\n@media (max-width: 847px) {\n  .btn-light.itp-show-filter-button.itp-show-filterButton-responsive, \n  .btn-primary.itp-addButton-responsive {\n    margin-top: 0.5rem;\n  }\n}\n\n@media (max-width: 561px) {\n  .itp-container-search-input.itp-searchBoxFilter-responsive {\n    margin-bottom: 0.5rem;\n    margin-top: 0.5rem;\n  }\n}\n\n.tabs-icons {\n  font-size: 17px !important;\n  margin-right: 0.25rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
