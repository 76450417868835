import { useEffect, useRef, useState } from "react";
import { ContactInfo, FunnelStages, LabelInput, LeadSource } from "../index";
import { fetchContactDetails, fetchCustomFields } from "./services";
import "./LeadDetails.css";

const LeadDetails = ({ 
  leadDetails, 
  setLeadDetails, 
  loadingEdit, 
  errorEditContact,
  editLead,
  myExtension,
  loadingExternal,
}) => {
  const customFields = useRef([])
  const firstLoad = useRef(true)

  const [defaultLeadSource, setDefaultLeadSource] = useState(null);
  const [loading, setLoading] = useState(true);
  const [defaultFunnel, setDefaultFunnel] = useState(null)
  const [finishFetchFunnels, setFinishFetchFunnels] = useState(false)
  const [finishPetition, setFinishPetition] = useState(false)
  const [finishedAllPetitions, setFinishedAllPetitions] = useState(false)

  const [formLead, setFormLead] = useState({
    name: leadDetails?.name || "",
    amount: leadDetails?.amount || "",
    lead_source_id: leadDetails?.lead_source_id || "",
    funnel_pipeline_stage_id: leadDetails?.funnel_pipeline_stage_id || "",
    funnel_id: leadDetails?.funnel_pipeline_stage?.funnel?.pk || "",
  });

  const onchangeFormLead = (e) => {
    setFormLead({ ...formLead, [e.target.name]: e.target.value });
  };

  const onSaveChange = (contactForm) => {
    if (loading || !formLead.funnel_pipeline_stage_id) return;

    const formatedFormLEad = {
      ...formLead,
      amount: formLead?.amount || 0,
      name: formLead.name && formLead.name !== "Unassigned" ? formLead.name : ""
    }

    editLead(contactForm, formatedFormLEad);
  };

  const petition = async () => {
    try {
      setLoading(true);

      const customFieldsTemp = await fetchCustomFields()
      customFields.current = customFieldsTemp
    
      const contactID = leadDetails.contact.pk
      const contactDetails = await fetchContactDetails(contactID, customFieldsTemp, myExtension)
    
      setLeadDetails({ ...leadDetails, contact: contactDetails });
      firstLoad.current = false
      setFinishPetition(true)
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (finishPetition && finishFetchFunnels && finishedAllPetitions && !loadingExternal) {
      setLoading(false)
    }
  }, [finishPetition, finishFetchFunnels, finishedAllPetitions, loadingExternal])

  useEffect(() => {
    if (leadDetails) {
      setFormLead({
        name: leadDetails?.name || "",
        amount: leadDetails?.amount || "",
        lead_source_id: leadDetails?.lead_source_id || "",
        funnel_pipeline_stage_id: leadDetails?.funnel_pipeline_stage_id || "",
        funnel_id: leadDetails?.funnel_pipeline_stage?.funnel?.pk || "",
      })

      const defaultLeadSource = { 
        ...leadDetails.lead_source, 
        label: leadDetails?.lead_source?.name, 
        value: leadDetails?.lead_source?.pk, 
      }
      
      setDefaultLeadSource(defaultLeadSource)
  
      const defaultFunnel = {
        ...leadDetails?.funnel_pipeline_stage?.funnel,
        label: leadDetails?.funnel_pipeline_stage?.funnel?.name,
        value: leadDetails?.funnel_pipeline_stage?.funnel?.pk,
      }
      setDefaultFunnel(defaultFunnel)
  
      if (firstLoad.current) petition();
    }
  }, [leadDetails]);

  return (
    <>
      <ContactInfo
        contactDetails={leadDetails?.contact}
        customFields={customFields}
        editContact={onSaveChange}
        loadingEdit={loadingEdit}
        myExtension={myExtension}
        setAddedCustomField={() => {}}
        errorEditContact={errorEditContact}
        setFinishedAllPetitions={setFinishedAllPetitions}
        loadingExternal={loadingExternal}
      >
        <LeadSource 
          defaultLeadSource={defaultLeadSource}
          form={formLead}
          setForm={setFormLead}
          loading={loading}
        />

        <LabelInput label="Lead Name" loading={loading}>
          <input
            type="text"
            placeholder=": Enter a lead name"
            className="form-control-itp itp-label-input__input"
            name="name"
            value={formLead.name === "Unassigned" ? "" : formLead.name}
            onChange={onchangeFormLead}
          />
        </LabelInput>

        <LabelInput label="Amount" loading={loading}>
          <input
            type="text"
            placeholder=": Enter a amount"
            className="form-control-itp itp-label-input__input"
            name="amount"
            value={formLead.amount}
            onChange={onchangeFormLead}
          />
        </LabelInput>

        <FunnelStages 
          loading={loading}
          form={formLead}
          setForm={setFormLead}
          defaultFunnelId={leadDetails?.funnel_pipeline_stage?.funnel?.pk}
          defaultFunnel={defaultFunnel}
          setFinishFetchFunnels={setFinishFetchFunnels}
          defaultStageId={leadDetails?.funnel_pipeline_stage_id}
        />
      </ContactInfo>
    </>
  )
}

export default LeadDetails;