import petitionGet from "../../../../../../services/petitionGet"
import { formatIntegrationName } from "../../utils"

const fetchIntegrations = async (setLoading, updateAlertVariables, setIntegrations, closeMenu, noLoading) => {
  try {
    if (!noLoading) setLoading(true)
    
    const { data: result } = await petitionGet("integrations")
    result.result.forEach((element) => {
      element.formatedName = formatIntegrationName(element.name)
    })

    setIntegrations(result.result)
    setLoading(false)
    closeMenu()
  } catch (error) {
    console.log(error)
    updateAlertVariables("error", "Error", "An error has ocurred", 3000)
  }
}

export default fetchIntegrations;