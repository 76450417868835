/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDynamicFunction } from "../../../../contexts/DynamicFunctionContext";
import { AlignedRow, CustomTableMain } from "../../../../components";
import { convertUtcDateToTimeZoneDate } from "../../../../utils/getDate";
import { myExtensionPetition } from "../../../../services/myExtension.service";
import getQueryString from "../../../../utils/getQueryString";
import petitionGet from "../../../../services/petitionGet";

const FollowTasks = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pk = searchParams.get("pk");
  const name = searchParams.get("name");

  const { setDynamicFunction, setIsArrowVisible } = useDynamicFunction();
  const navigate = useNavigate();

  const sentFilter = useRef("")
  const itemsPerPage = useRef("10");
  const myExtension = useRef({})
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });

  const [loading, setLoading] = useState(true);
  const [allResult, setAllResult] = useState(null);
  const [loadingPagItem, setLoadingPagItem] = useState(false);
  const [sentValue, setSentValue] = useState("")
  const [dataTable, setDataTable] = useState({
    columns: [
        { name: "Scheduled Date", key: "dateFormated" },
        { name: "Message", key: "message" },
      ],
    content: []
  })

  const handleChangeFilter = (e) => {
    const { value } = e.target
    setSentValue(value)

    const filterValue = value ? `&sent=${value}` : ""

    const paginationTemp = {
      offset: 0,
      limit: pagination.current.limit,
    };
  
    const parameter = getQueryString(paginationTemp) + filterValue;
  
    petition(parameter, myExtension.current);
    pagination.current = paginationTemp;
    sentFilter.current = filterValue;
  }

  const changePage = (offset) => {
    setLoadingPagItem(true);

    const paginationTemp = {
      offset,
      limit: pagination.current.limit,
    };

    const parameter = getQueryString(paginationTemp) + sentFilter.current;

    petition(parameter, myExtension.current, true);
    pagination.current = paginationTemp;
  };

  const activeItemsPerPage = (data) => {
    setLoadingPagItem(true);

    const paginationTemp = {
      offset: 0,
      limit: data,
    };

    const parameter = getQueryString(paginationTemp) + sentFilter.current;

    petition(parameter, myExtension.current, true);
    pagination.current = paginationTemp;
  };

  const petition = async (parameter, myExtensionTemp, noLoading) => {
    try {
      if (!noLoading) setLoading(true);

      const { data: result } = await petitionGet("followTasks", { parameter, smart_campaign_id: pk })
      setAllResult(result)

      result.result.forEach((element) => {
        const timeZone = myExtensionTemp.time_zone
        element.dateFormated = convertUtcDateToTimeZoneDate(element.scheduled_date, timeZone);
        element.message = element?.smart_follow_up?.message || "Unassigned"
      })

      setDataTable({
        columns: [
          { name: "Scheduled Date", key: "dateFormated" },
          { name: "Message", key: "message" },
        ],
        content: [...result.result]
      })

      setLoading(false)
      setLoadingPagItem(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!pk || !name) {
      navigate("/Smart-Campaigns");
    } else {
      const myExtensionData = async () => {
        try {
          const data = await myExtensionPetition()
  
          if (data && Object.keys(data).length > 0) {
            setDynamicFunction(() => () => {
              navigate("/Smart-Campaigns");
            });
            setIsArrowVisible(true);

            myExtension.current = data
            const parameter = getQueryString(pagination.current) + sentFilter.current
            petition(parameter, data);
          } 
  
        } catch(error) {
          console.log(error)
        }
      }
  
      myExtensionData()
    }
  }, []);

  return (
    <>
      <div style={{ marginTop: "3rem" }}>
        <AlignedRow noCentered m1>
          <h3 className="itp-accordion-header-title">{name}</h3>
        </AlignedRow>

        <CustomTableMain
          data={dataTable}
          loading={loading}
          loadingPagItems={loadingPagItem}
          module={`follow ups ${sentValue === "true" ? "sent" : "not sent"}`}
          isBackend={true}
          totalPages={allResult?.total_pages}
          onPageChange={changePage}
          offset={pagination.current.offset}
          showSelectSingleCheckbox={true}
          onChangeItemsPerPage={activeItemsPerPage}
          paginationData={parseInt(itemsPerPage.current)}
          actionsLeft={[
            {
              component: (
                <select
                  onChange={handleChangeFilter}
                  value={sentValue}
                  disabled={loading || loadingPagItem}
                  className="form-select-itp itp-call-logs-select"
                >
                  <option value="">All</option>
                  <option value="false">Not Sent</option>
                  <option value="true">Sent</option>
                </select>
              ),
            },
          ]}
          columnConfig={{
            "Scheduled Date": {
              type: "date"
            }
          }}
        />
      </div>
    </>
  );
};

export default FollowTasks;
