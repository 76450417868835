import petitionPatch from "../../../../../../services/petitionPatch"

const editFieldsService = async (data, setLoadingButton, updateAlertVariables, clickedItem, petition) => {
  try {
    setLoadingButton(true)

    const integrationId = clickedItem.customer_integration_id

    await petitionPatch("integrationFields", { data, integrationId })
    await petition(true)
  } catch (error) {
    console.log(error)
    const messageError  ="An error has ocurred, plase try again"
    setLoadingButton(false)
    updateAlertVariables("error", "Error", messageError, 4000)
  }
}

export default editFieldsService;