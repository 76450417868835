import useScreenSize from "../../../../hooks/useScreenSize";

const CallDescription = ({ callSummaryData }) => {
  const { width } = useScreenSize();

  return (
    <>
      <div className="itp-call-transcription__call-description">
        <div className="d-flex flex-column">
          <label className="itp-call-transcription__call-description-name">
            Participants
          </label>
          <p className="itp-call-transcription__call-description-info">
            {callSummaryData?.numberofparticipants || 0}
          </p>
        </div>

        <div className="itp-call-transcription__call-description-separator ms-3"></div>

        <div className="d-flex flex-column ms-3">
          <label className="itp-call-transcription__call-description-name">
            Avg Words per Minute
          </label>
          <p className="itp-call-transcription__call-description-info">
            {callSummaryData?.wordsPerMinute || 0}
          </p>
        </div>

        {width > 417 && (
          <>
            <div className="itp-call-transcription__call-description-separator ms-3"></div>
            <div className="d-flex flex-column ms-3">
              <label className="itp-call-transcription__call-description-name">
                Overall Sentiment
              </label>
              <p className="itp-call-transcription__call-description-info light">
                {callSummaryData?.overalSentiment || ""}
              </p>
            </div>
          </>
        )}

        {width > 1740 && (
          <>
            <div className="itp-call-transcription__call-description-separator ms-3"></div>

            <div className="d-flex flex-column ms-3">
              <label className="itp-call-transcription__call-description-name">
                Personal Information Redacted
              </label>
              <p className="itp-call-transcription__call-description-info light">
                {callSummaryData?.personalInformationRedacted || ""}
              </p>
            </div>
          </>
        )}
      </div>

      {width < 418 && (
        <div className="itp-call-transcription__call-description small mt-2">
          <div className="d-flex flex-column">
            <label className="itp-call-transcription__call-description-name">
              Overall Sentiment
            </label>
            <p className="itp-call-transcription__call-description-info light">
              {callSummaryData?.overalSentiment || ""}
            </p>
          </div>
        </div>
      )}

      {width < 1741 && (
        <div className="itp-call-transcription__call-description small mt-2">
          <div className="d-flex flex-column">
            <label className="itp-call-transcription__call-description-name">
              Personal Information Redacted
            </label>
            <p className="itp-call-transcription__call-description-info light">
              {callSummaryData?.personalInformationRedacted || ""}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default CallDescription;
