import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ModalError = ({ modal, closeModal, message }) => (
  <Modal isOpen={modal ? true : false} className="info">
    <ModalHeader>Error</ModalHeader>
    <ModalBody>{message}</ModalBody>
    <ModalFooter>
      <button className="btn-light" onClick={closeModal}>
        Close
      </button>
    </ModalFooter>
  </Modal>
);

export default ModalError;
