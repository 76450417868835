// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-inputs-menu__checkbox {
  margin-top: 0.75rem !important;
  border-color: gray !important;
}`, "",{"version":3,"sources":["webpack://./src/components/InputsMenu/InputsMenu.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,6BAA6B;AAC/B","sourcesContent":[".itp-inputs-menu__checkbox {\n  margin-top: 0.75rem !important;\n  border-color: gray !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
