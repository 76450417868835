import { useState } from "react";
import { Col, Row } from "reactstrap";
import { LoadingButton } from "../../../../../../../components";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseIcon from "@mui/icons-material/Pause";

const RecordSound = ({ addMediaFile, loadingButton }) => {
  const [name, setName] = useState("");
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState("");
  const [mediaRecorder, setMediaRecorder] = useState([]);
  let audioChunks = [];

  const handleChangeName = (e) => {
    const { value } = e.target;
    setName(value);
  };

  const startRecording = () => {
    let mediaRecorderTemp;

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderTemp = new MediaRecorder(stream);

        mediaRecorderTemp.ondataavailable = (e) => {
          if (e.data.size > 0) {
            audioChunks.push(e.data);
          }
        };

        mediaRecorderTemp.onstop = () => {
          const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
          setAudioBlob(audioBlob);
          const audioUrl = URL.createObjectURL(audioBlob);
          setAudioUrl(audioUrl);
        };

        mediaRecorderTemp.start();
        setRecording(true);
        setMediaRecorder(mediaRecorderTemp);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder && recording) {
      mediaRecorder.stop();
      setRecording(false);
    }
  };

  const handleSave = () => {
    if (loadingButton) return;
    const file = new File([audioBlob], "audiofile.mp3", { type: "audio/mp3" });
    addMediaFile(name, file);
  };

  return (
    <>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Name</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter Name"
            className="form-control-itp itp-label-input__input"
            name="name"
            id="name"
            value={name}
            onChange={handleChangeName}
          />
        </Col>
      </Row>

      <label className="itp-label-input__label itp-analytics-label-select">
        To start recording click on play button and to stop click on pause
      </label>
      <div>
        {!recording ? (
          <PlayCircleOutlineIcon className="cursor-pointer" onClick={startRecording} />
        ) : (
          <PauseIcon className="cursor-pointer" onClick={stopRecording} />
        )}

        {audioBlob && (
          <div className="mt-2">
            <audio controls src={audioUrl}></audio>
          </div>
        )}
      </div>
      <button
        onClick={handleSave}
        className="btn-primary itp-lead-detail-button-save loading mb-4"
        disabled={!audioBlob || !audioUrl || !name}
      >
        {loadingButton && <LoadingButton />}
        Save
      </button>
    </>
  );
};

export default RecordSound;
