
import { setupRemoteMedia } from "./media"
import { SessionState } from "sip.js";

// const ringtone = new Audio(ringtoneSound);

// ringtone.loop = true;


/**
 * Handle inbound INVITE sessions (Invitations).
 */
export default function onInvite(
  invitation,
  setInvitationSession,
  setCallSessionsFunctionEnd,
  callAudio,
  setCallAudio,
  ringtone,
  handleAnswerOnInvite,
  sessionIncall
) {
  // Create an <audio> tag for playing or receiving call audio
  let callAudioTemp = new Audio();

  // If the invitation is in "Initial", let's store and ring
  if (invitation._state === "Initial") {
    console.log("Invitation state: Initial (onInvite Function)");
    setInvitationSession(invitation);

    // Attempt to play the ringtone
    ringtone.play()
      .then(() => {
        console.log("Reproducción exitosa (ringtone started).");
      })
      .catch(error => {
        console.error("Error al reproducir el sonido:", error);
      });
  }

  // Listen for state changes on the Invitation
  invitation.stateChange.addListener((state) => {
    console.log("Invitation state:", state);

    switch (state) {

      case SessionState.Initial:
        console.log("Invitation state: Initial");
        break;

      case SessionState.Establishing:
        console.log("Invitation state: Establishing");
        // Try to play the ringtone again (if user hasn't interacted yet, may get blocked)
        ringtone.play().catch(error => {
          console.warn("Failed to play ringtone:", error.message);
        });

        /**
         *  STEP 1: Add ICE event listeners here:
         *  By "Establishing", SIP.js should have created the SessionDescriptionHandler & PeerConnection.
         */
        const sdh = invitation.sessionDescriptionHandler;
        if (sdh && sdh.peerConnection) {
          const pc = sdh.peerConnection;

          pc.addEventListener("icegatheringstatechange", () => {
            console.log(
              "ICE Gathering State:",
              pc.iceGatheringState,
              new Date().toISOString()
            );
          });

          pc.addEventListener("iceconnectionstatechange", () => {
            console.log(
              "ICE Connection State:",
              pc.iceConnectionState,
              new Date().toISOString()
            );
          });
        }
        break;

      case SessionState.Established:
        console.log("Invitation state: Established");

        // Setup remote media now that the call is established
        setupRemoteMedia([invitation], callAudio);
        setCallAudio(callAudioTemp);

        // Stop the ringtone if it's playing
        ringtone.pause();

        // This is where you actually "answer" the call in your UI logic
        // handleAnswerOnInvite(...) typically calls .accept() earlier or manages call state
        handleAnswerOnInvite(invitation, sessionIncall.length - 1, false);

        break;

      case SessionState.Terminating:
      case SessionState.Terminated:
        console.log("Invitation state: Terminating/Terminated");
        setCallSessionsFunctionEnd(invitation);

        // Stop the ringtone
        ringtone.pause();
        break;

      default:
        throw new Error("Unknown session state.");
    }
  });
}