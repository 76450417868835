// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-date-range {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.3px solid #437097;
  border-radius: 18px;
  opacity: 1;
  display: flex;
  align-items: center;
  padding: 6px 0 6px 12px;
  width: 100%;
}

.date-range-label {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
  margin-right: 4px;
}

.date-range-separator {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
  border: 0;
  margin-left: 12px;
  margin-right: 12px;
}

.date-range-input {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
  border: 0;
  outline: none;
  background-color: transparent;
  padding: 0; /* Elimina el padding por defecto */
  margin: 0; /* Elimina el margen por defecto */
  min-width: 0; /* Asegúrate de que no haya un ancho mínimo que cause separación */
}`, "",{"version":3,"sources":["webpack://./src/components/DateRange/DateRange.css"],"names":[],"mappings":"AAAA;EACE,+CAA+C;EAC/C,2BAA2B;EAC3B,mBAAmB;EACnB,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,oDAAoD;EACpD,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,oDAAoD;EACpD,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;EACV,SAAS;EACT,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,oDAAoD;EACpD,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;EACV,SAAS;EACT,aAAa;EACb,6BAA6B;EAC7B,UAAU,EAAE,mCAAmC;EAC/C,SAAS,EAAE,kCAAkC;EAC7C,YAAY,EAAE,kEAAkE;AAClF","sourcesContent":[".container-date-range {\n  background: #ffffff 0% 0% no-repeat padding-box;\n  border: 0.3px solid #437097;\n  border-radius: 18px;\n  opacity: 1;\n  display: flex;\n  align-items: center;\n  padding: 6px 0 6px 12px;\n  width: 100%;\n}\n\n.date-range-label {\n  font: normal normal normal 12px/16px Instrument Sans;\n  letter-spacing: 0px;\n  color: #3a3a3a99;\n  opacity: 1;\n  margin-right: 4px;\n}\n\n.date-range-separator {\n  font: normal normal normal 12px/16px Instrument Sans;\n  letter-spacing: 0px;\n  color: #3a3a3a99;\n  opacity: 1;\n  border: 0;\n  margin-left: 12px;\n  margin-right: 12px;\n}\n\n.date-range-input {\n  font: normal normal normal 12px/16px Instrument Sans;\n  letter-spacing: 0px;\n  color: #3a3a3a99;\n  opacity: 1;\n  border: 0;\n  outline: none;\n  background-color: transparent;\n  padding: 0; /* Elimina el padding por defecto */\n  margin: 0; /* Elimina el margen por defecto */\n  min-width: 0; /* Asegúrate de que no haya un ancho mínimo que cause separación */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
