import { useState } from "react";
import { Col, Row } from "reactstrap";
import { TagComponent } from "../../../../../components";

const AddTagAction = ({ 
  keyTag, 
  title, 
  loading, 
  backStep, 
  nextStep, 
}) => {
  const key = keyTag ? keyTag : title === "Add Tags" ? "added_tags" : "removed_tags";

  const [tags, setTags] = useState([]);
  const [form, setForm] = useState({ [key]: [] });

  const handleTag = (newValue) => {
    setTags(newValue);

    let listPk = newValue.map((tag) => tag.pk);
    setForm({ [key]: listPk });
  };

  const saveFunction = () => {
    nextStep(form);
  };

  return (
    <>
      <Row>
        <Col>
          {!loading && <label className="itp-component-tag-label">Tags</label>}
          <TagComponent tags={tags} handleTag={handleTag} />
        </Col>
      </Row>

      <div className="d-flex gap-2 mt-4">
        <button disabled={loading} className="btn-light" onClick={backStep}>
          Back
        </button>

        <button
          disabled={!form[key]?.length || loading}
          className="btn-primary loading"
          onClick={saveFunction}
        >
          Save
        </button>
      </div>
    </>
  );
};

export default AddTagAction;
