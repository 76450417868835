// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-label-switch__label {
  color: #002340;
  text-align: left;
  font-family: "Instrument Sans";
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  opacity: 1;
}

.itp-label-switch__row {
  font-family: "Instrument Sans";
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin-top: 10px !important;
  margin-bottom: 0.5rem;
}

.itp-label-switch__switch-container {
  display: flex;
  gap: 2rem;
  align-items: center;
  padding-left: 0;
  justify-content: flex-end;
}

.itp-label-switch__switch .react-switch-handle {
  width: 17px !important;
  height: 17px !important;
  top: 1px !important;
  left: 1px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/LabelSwitch/LabelSwitch.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;EACV,2BAA2B;EAC3B,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".itp-label-switch__label {\n  color: #002340;\n  text-align: left;\n  font-family: \"Instrument Sans\";\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 20px;\n  letter-spacing: 0px;\n  opacity: 1;\n}\n\n.itp-label-switch__row {\n  font-family: \"Instrument Sans\";\n  font-weight: normal;\n  font-size: 12px;\n  line-height: 15px;\n  text-align: left;\n  letter-spacing: 0px;\n  color: #3a3a3acc;\n  opacity: 1;\n  margin-top: 10px !important;\n  margin-bottom: 0.5rem;\n}\n\n.itp-label-switch__switch-container {\n  display: flex;\n  gap: 2rem;\n  align-items: center;\n  padding-left: 0;\n  justify-content: flex-end;\n}\n\n.itp-label-switch__switch .react-switch-handle {\n  width: 17px !important;\n  height: 17px !important;\n  top: 1px !important;\n  left: 1px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
