import React, { useEffect, useState } from "react";
import { CustomTableMain, LoadingButton } from "../../../../../../../components";
import { convertUtcDateToTimeZoneDate } from "../../../../../../../utils/getDate";
import petitionGet from "../../../../../../../services/petitionGet";
import petitionDelete from "../../../../../../../services/petitionDelete";
import ModalDelete from "../../../ModalUtility/ModalDelete";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
import "../Import.css";

const Jobs = ({ refreshJobs, setRefreshJobs, myExtension }) => {
  const [loading, setLoading] = useState(true);
  const [infoContactError, setInfoContactError] = useState(null);
  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [modalDelete, setModalDelete] = useState(null);
  const [loadingUndo, setLoadingUndo] = useState(false);
  const [errorUndo, setErrorUndo] = useState("");

  const [dataTable, setDataTable] = useState({
    columns: [
      { name: "Status", key: "status" },
      { name: "Start Date", key: "convertedDate", isDate: true },
      { name: "Imported", key: "imported" },
      { name: "Not Imported", key: "not_imported" },
    ],
    actions: { title: "Actions", content: [] }
  });

  const [dataTableError, setDataTableError] = useState({
    columns: [
      { name: "Reason", key: "reason" },
      { name: "First Name", key: "firstname" },
      { name: "Last Name", key: "lastname" },
      { name: "Phone", key: "phone" },
      { name: "Email", key: "email" },
    ],
  });

  const showContactsWithError = (e) => {
    setInfoContactError(e);
  };

  const petitionContactsWithError = () => {
    setLoading(true);
    petitionGet("importJobsErrors", { jobs_id: infoContactError.pk })
      .then(({ data: result }) => {
        if (
          result.result.data.contacts_not_imported &&
          result.result.data.contacts_not_imported.length > 0
        ) {
          let dataContactsError = Object.keys(
            result.result.data.contacts_not_imported[0].csv_contact
          );

          dataContactsError.map((element) => {
            result.result.data.contacts_not_imported.map((contact) => {
              contact[element] = !contact.csv_contact[element]
                ? ""
                : contact.csv_contact[element];
            });
          });

          setDataTableError({
            columns: [
              { name: "Reason", key: "reason" },
              { name: "First Name", key: "firstname" },
              { name: "Last Name", key: "lastname" },
              { name: "Phone", key: "phone" },
              { name: "Email", key: "email" },
            ],
            content: [...result.result.data.contacts_not_imported],
          });
        } else {
          setDataTableError({
            columns: [
              { name: "Reason", key: "reason" },
              { name: "First Name", key: "firstname" },
              { name: "Last Name", key: "lastname" },
              { name: "Phone", key: "phone" },
              { name: "Email", key: "email" },
            ],
            content: [],
          });
        }

        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const back = () => {
    setLoading(true);
    setInfoContactError(null);
  };

  const activeUndoModal = (e) => {
    setModalDelete(e);
  };

  const closeModal = () => {
    setModalDelete(null);
    setErrorUndo("");
  };

  const returnJobStatus = (status) => {
    if (status === "importing") {
      return (
        <div className="d-flex align-items-center gap-2">
          <div>{status}</div>
          <div>
            <LoadingButton 
              className="mt-1"
              spinnerStyles={{ width: "12px", height: "12px" }}
            />
          </div>
        </div>
      )
    }

    return status
  }

  const undoContacts = () => {
    setLoadingUndo(true);
    setErrorUndo("");
    petitionDelete("undoContacsInJob", { job_id: modalDelete.pk })
      .then(({ data: result }) => {
        setLoadingUndo(false);
        closeModal();
        petition();
      })
      .catch((error) => {
        console.log(error);
        if (error?.response && error.response.status === 500) {
          const text = "An error occurred, please try again later.";
          setErrorUndo(text);
          setLoadingUndo(false);
        }
      });
  };

  const petition = (noLoading) => {
    if (!noLoading) setLoading(true);

    petitionGet("importJobs")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.validate = element.status === "completed" ? true : false;

          element.formatedStatus = returnJobStatus(element.status)

          element.convertedDate = element?.job_start_date
            ? convertUtcDateToTimeZoneDate(
                element?.job_start_date.replaceAll(" ", "T"),
                myExtension.time_zone
              )
            : "";
        });
        
        setDataTable({
          columns: [
            { name: "Status", key: "formatedStatus" },
            { name: "Start Date", key: "convertedDate" },
            { name: "Imported", key: "imported" },
            { name: "Not Imported", key: "not_imported" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "View Results",
                handleClick: showContactsWithError,
                icon: <RemoveRedEyeOutlinedIcon />,
              },
              {
                name: "Undo",
                handleClick: activeUndoModal,
                icon: <UndoOutlinedIcon />,
                shouldValidate: true,
                keyValidate: "validate",
              },
            ],
          },
        });
        setLoadingRefresh(false);
        setRefreshJobs(false);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (refreshJobs) {
      if (!loadingRefresh) {
        setLoadingRefresh(true);
        petition(true);
      }
    }
  }, [refreshJobs]);

  useEffect(() => {
    if (infoContactError) {
      petitionContactsWithError();
    }
  }, [infoContactError]);

  useEffect(() => {
    if (!infoContactError) {
      petition();
    }
  }, [infoContactError]);

  return (
    <>
      {infoContactError ? (
        <>
          <div className="itp-jobs-container-table">
            <CustomTableMain
              data={dataTableError}
              loading={loading || loadingRefresh}
              module="contacts with error"
              m0
            />
          </div>
          <button
            className="btn-light itp-button-jobs-error mt-4"
            onClick={back}
          >
            Back
          </button>
        </>
      ) : (
        <>
          {modalDelete && (
            <ModalDelete
              modal={modalDelete}
              closeModal={closeModal}
              loading={loadingUndo}
              modalFunction={undoContacts}
              error={errorUndo}
              title="Undo"
              text="Are you sure you wish to undo this import? This will delete all contacts that were imported in this job."
            />
          )}

          <CustomTableMain 
            data={dataTable}  
            loading={loading || loadingRefresh}
            module="jobs"
            m0
            columnConfig={{
              "Start Date": {
                type: "date"
              }
            }}
          />
        </>
      )}
    </>
  );
};

export default Jobs;
