import axios from "axios";
import json from "../../../../../config.json"

const convertArrBufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

const downloadAudioService = async (element) => {
  try {
    const token = JSON.parse(localStorage.getItem("userDetails"))?.access_token;
    const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
    const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;
    const url = `${urlBase}/itpvoice/v2/${id}/cdr/${element.pk}/download-recording?accept=audio/mpeg`
    const config = {
      headers: {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      },
      responseType: "arraybuffer", // Indica que esperamos datos binarios
    };

    const response = await axios.get(url, config);

    if (response.data) {
      const base64Audio = convertArrBufferToBase64(response.data);
      return {
        ...response,
        data: {
          data: response.data,
          result: base64Audio,
          contentType: "audio/mp3",
        },
      };
    }
  } catch (error) {
    console.log(error)
    return { status: "error" }
  }
}

export default downloadAudioService;