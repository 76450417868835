import React, { useEffect, useState } from "react";
import arrow from "../../assets/images/arrow.svg";
import "./TabMenu.css";

const TabMenu = ({ tabs, defaultTab, textBack, backFunction, setTab, changeTab, noUpdateTab, className }) => {
  const [activeTab, setActiveTab] = useState(defaultTab || 0);

  const handleTabClick = (index) => {
    if (index !== activeTab) {
      if (!noUpdateTab) setActiveTab(index);

      if(setTab){
        setTab(index)
      }
    }
  };

  const handleBack = () => {
    if(textBack && backFunction && setTab){
      if (!noUpdateTab) setActiveTab(defaultTab || tabs.length)
        
      backFunction()
    }
  }

  useEffect(() => {
    if(changeTab){
      setActiveTab(changeTab)
    }
  }, [changeTab])

  return (
    <div className={`itp-tab-menu ${className || "mt-4"}`}>
      <div className="itp-tab-menu__tabs-container">
        {textBack && (
          <div
            className={`itp_tab_menu__container-text-back${
              textBack && activeTab >= tabs.length ? " v-hidden" : ""
            }`}
            onClick={handleBack}
          >
            <img src={arrow} alt="Voice360" />
            <span className="itp_tab_menu__text-back">{textBack}</span>
          </div>
        )}
        <div className="itp-tab-menu__tabs">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`itp-tab-menu__tab ${
                activeTab === index ? "active" : ""
              }`}
              onClick={() => handleTabClick(index)}
            >
              {tab.title}
              {tab?.icon || ""}
            </div>
          ))}
        </div>
      </div>
      <div className="itp-tab-menu__content">
        {tabs[activeTab]?.component || ""}
      </div>
    </div>
  );
};

export default TabMenu;
