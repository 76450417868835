import formatPhoneNumber from "../../../../../../utils/formatPhoneNumber";

const selectIdForAddSubtitle = (info, tempData) => {
  switch (info.module) {
    case "user":
      if (tempData) return ""
      return parseInt(info.data.idUser);
    case "callflow":
      if (tempData) return ""
      return parseInt(info.data.idUser);
    case "externaltransfer":
      if (tempData) return ""
      return info.data.destination || info.data.number;
    case "language":
      if (tempData) return ""
      return info.data.language;
    case "send_sms":
      return formatPhoneNumber(info.data.from_number);
    case "send_email":
      return `${info.data?.to_emails[0]}${info.data?.to_emails?.length > 1 ? " ..." : ""}`
    default:
      return parseInt(info.data.id);
  }
};

export default selectIdForAddSubtitle;
