/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { AddButton, CollapsePanel, CustomTableMain, MenuRight } from "../../../../../../components";
import { AddEditAIAgent, EditPrompVariable } from "./components";
import { addAIAgentService, deleteAIAgentService, editAIAgentService, fetchCustomerIntegrations, fetchCustomPromptsOptions, fetchFunnelDetails, fetchPersonalities, fetchQuestionListService, fetchUsers } from "../../services";
import RateReviewIcon from '@mui/icons-material/RateReview';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import ModalDelete from "../../../ModalUtility/ModalDelete";
import petitionGet from "../../../../../../services/petitionGet";
import getQueryString from "../../../../../../utils/getQueryString";
import combineObjects from "../../../../../../utils/combineObjects";
import { selectInputsNotFilled } from "../../utils";

const AIAgents = ({ updateAlertVariables }) => {
  const pagination = useRef({
    offset: 0,
    limit: 10,
  })

  const [loading, setLoading] = useState(true)
  const [openMenu, setOpenMenu] = useState(false)
  const [info, setInfo] = useState(null)
  const [loadingButton, setLoadingButton] = useState(false)
  const [loadingPagItems, setLoadingPagItems] = useState(false)
  const [allResult, setAllResult] = useState({})
  const [modalDelete, setModalDelete] = useState(false)
  const [loadingMenu, setLoadingMenu] = useState(false)
  const [users, setUsers] = useState([])
  const [personalities, setPersonalities] = useState([])
  const [promptVariable, setPromptVariable] = useState("")
  const [arrInputsNotFilled, setArrInputsNotFilled] = useState([])
  const [customPromptsOptions, setCustomPromptsOptions] = useState([])
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [form, setForm] = useState({
    name: "",
    description: "",
    agent_type: "",
    ai_manage_appointments: false,
    ai_calender_strategy: "",
    ai_lead_manager: false,
    ai_lead_manager_funnel_id: null,
    ai_lead_manager_screening_questions_list_id: null,
    ai_faq: false,
    assigned_users: [],
    integrations: [],
    personality_id: null,
    message_prompt: "",
    call_prompt: "",
  })

  const [dataTable, setDataTable] = useState({
    columns: [
      { name: "Name", key: "name" },
    ],
    actions: { title: "Actions", content: [] },
  })

  const [integrationsTable, setIntegrationsTable] = useState({
    columns: [
      { name: "Integration", key: "name" },
    ],
    actions: { 
      title: "Enabled",
      isSwitch: true, 
      content: [] 
    },
  })

  const reloadForm = () => {
    setForm({
      name: "",
      description: "",
      agent_type: "",
      ai_manage_appointments: false,
      ai_calender_strategy: "",
      ai_lead_manager: false,
      ai_lead_manager_funnel_id: null,
      ai_lead_manager_screening_questions_list_id: null,
      ai_faq: false,
      assigned_users: [],
      integrations: [],
      personality_id: null,
      message_prompt: "",
      call_prompt: "",
    })
  }

  const closeMenu = () => {
    setLoadingButton(false)
    setLoadingMenu(false)
    setOpenMenu(false)
    setInfo(null)
    reloadForm()
    setModalDelete(false)
    setPromptVariable("")
  }

  const petitionEditMenu = async (element) => {
    try {
      setLoadingMenu(true)
      setOpenMenu(true)
      setInfo(element)
  
      const defaultFunnel = await fetchFunnelDetails(element?.ai_lead_manager_funnel_id)
      const defaultQuestionList = await fetchQuestionListService(element?.ai_lead_manager_screening_questions_list_id)
      const integrations = await fetchCustomerIntegrations(element)
      const { users, arrayUsersSelected } = await fetchUsers(element)
      const { personalities, personality } = await fetchPersonalities(element)
  
      const newForm = combineObjects(form, element)
      const updatedForm = {
        ...newForm,
        ai_lead_manager_funnel_id: defaultFunnel,
        ai_lead_manager_screening_questions_list_id: defaultQuestionList,
        assigned_users: arrayUsersSelected,
        integrations: integrations.filter((el) => el.checked).map((el) => el.pk),
        personality_id: personality,
      }
  
      setIntegrationsTable({
        columns: [{ name: "Integration", key: "name" }],
        content: [...integrations],
        actions: { 
          title: "Enabled",
          isSwitch: true, 
          content: [
            {
              keySwitch: "checked",
              handleClick: handleChangeIntegrationSwitch,
              type: "",
              switch: true,
            },
          ],
        },
      })
  
      setForm(updatedForm)
      setLoadingMenu(false)
      setUsers(users)
      setPersonalities(personalities)
    } catch (error) {
      console.log(error)
      updateAlertVariables("error", "Error", "An error has ocurred", 3000)
      closeMenu()
    }
  }

  const petitionAddMenu = async () => {
    try {
      setOpenMenu(true)
      setLoadingMenu(true)

      const integrations = await fetchCustomerIntegrations()
      const { users } = await fetchUsers()
      const { personalities } = await fetchPersonalities()

      setIntegrationsTable({
        columns: [{ name: "Integration", key: "name" }],
        content: [...integrations],
        actions: { 
          title: "Enabled",
          isSwitch: true, 
          content: [
            {
              keySwitch: "checked",
              handleClick: handleChangeIntegrationSwitch,
              type: "",
              switch: true,
            },
          ],
        },
      })

      setUsers(users)
      setPersonalities(personalities)
      setLoadingMenu(false)
    } catch (error) {
      console.log(error)
    }
  }

  const activeOpenMenu = async (element) => {
    if (element) {
      await petitionEditMenu(element)
    } else {
      await petitionAddMenu()
    }
  }

  const activeEditPromptFields = async (element, field) => {
    try {
      setOpenMenu(true)
      setLoadingMenu(true)
      setPromptVariable(field)
      setInfo(element)
      setForm({ ...form, [field]: element[field] || "" })

      const promptsOptions = await fetchCustomPromptsOptions()
      setCustomPromptsOptions(promptsOptions)
      setLoadingMenu(false)
    } catch (error) {
      console.log(error)
    }
  }

  const activeDeleteModal = (element) => {
    setInfo(element)
    setModalDelete(true)
  }

  const handleChangeIntegrationSwitch = (element) => {
    setIntegrationsTable((prev) => {
      const newContent = prev.content.map((el) => {
        if (el.pk === element.pk) el.checked = !el.checked
        return el
      })

      return { ...prev, content: newContent }
    })

    setForm((prev) => {
      if (prev.integrations.includes(element.pk)) {
        return { ...prev, integrations: prev.integrations.filter((el) => el !== element.pk) }
      } else {
        return { ...prev, integrations: [...prev.integrations, element.pk] }
      }
    })
  }

  const handleChangeForm = (e, field) => {
    const name = field || e.target.name
    const value = field ? e : e.target.value

    if (arrInputsNotFilled.includes(name)) {
      setArrInputsNotFilled((prev) => prev.filter((el) => el !== name))
    }

    setForm({ ...form, [name]: value })
  }

  const handleChangeswitch = (name) => {
    setForm({ ...form, [name]: !form[name] })
  }

  const changePage = async (offset) => {
    if (loading || loadingPagItems) return

    setLoadingPagItems(true)

    const paginationTemp = {
      offset: offset,
      limit: pagination.current.limit,
    };

    const parameter = getQueryString(paginationTemp)
    await petition(parameter, true);
    pagination.current = paginationTemp;
  }

  const addOrEditAIAgent = async () => {
    if (loadingButton) return;

    const inpustNotFilled = selectInputsNotFilled(form, loadingMenu)
    if (inpustNotFilled?.length > 0) {
      setArrInputsNotFilled(inpustNotFilled)
      return;
    }

    if (info) {
      await editAIAgentService(setLoadingButton, form, info, petition, pagination, updateAlertVariables)
    } else {
      await addAIAgentService(setLoadingButton, form, pagination, petition, updateAlertVariables)
    }
  }

  const deleteAIAgent = async () => {
    await deleteAIAgentService(setLoadingButton, info, pagination, allResult, petition)
  }

  const editPropmtVariable = async () => {
    if (loadingButton) return;
    await editAIAgentService(setLoadingButton, form, info, petition, pagination, updateAlertVariables, promptVariable)
  }

  const petition = async (parameter, noLoading) => {
    try {
      if (!noLoading) setLoading(true)
      
      const { data: result } = await petitionGet("aiAgents", { parameter })

      setAllResult(result)
      setDataTable({
        columns: [
          { name: "Name", key: "name" },
        ],
        content: [...result.result],
        actions: {
          title: "Actions",
          content: [
            {
              name: "Edit",
              icon: <FontAwesomeIcon icon={faPenToSquare} />,
              handleClick: activeOpenMenu,
            },
            {
              name: "Custom Message Prompt",
              icon: <RateReviewIcon className="itp-ai__ai-agents-prompt-icon" />,
              handleClick: (element) => activeEditPromptFields(element, "custom_message_prompt"),
            },
            {
              name: "Custom Voice Prompt",
              icon: <KeyboardVoiceIcon className="itp-ai__ai-agents-prompt-icon" />,
              handleClick: (element) => activeEditPromptFields(element, "custom_call_prompt"),
            },
            {
              name: "Delete",
              icon: <FontAwesomeIcon icon={faTrash} />,
              handleClick: activeDeleteModal,
            },
          ],
        },
      })

      setLoading(false)
      setLoadingPagItems(false)
      closeMenu()
      forceUpdate()
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    petition(getQueryString(pagination.current))
  }, [])

  return (
    <>
      <br></br>
      <div>
        <Row>
          <Col style={{ zIndex: "-1 " }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      {modalDelete && (
        <ModalDelete 
          modal={modalDelete}
          closeModal={closeMenu}
          loading={loadingButton}
          modalFunction={deleteAIAgent}
          module="AI Agent"
        />
      )}

      <CollapsePanel title="AI Agents" subtitle="Your own agents who can automate everything you wish">
        <CustomTableMain
          data={dataTable}
          m0={true}
          isBackend={true}
          loading={loading}
          loadingPagItems={loadingPagItems}
          paginationData={10}
          totalPages={allResult?.total_pages}
          onPageChange={changePage}
          offset={pagination.current.offset}
          module="AI Agents"
          actionsRight={[
            {
              component: (
                <AddButton
                  handleClick={() => activeOpenMenu()}
                  name="Add AI"
                />
              ),
            },
          ]}
        />
      </CollapsePanel>

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title="AI Agent Details"
        to=""
      >
        {openMenu && !promptVariable && (
          <AddEditAIAgent 
            info={info}
            form={form}
            handleChangeForm={handleChangeForm}
            handleChangeswitch={handleChangeswitch}
            loadingButton={loadingButton}
            handleSave={addOrEditAIAgent}
            loading={loadingMenu}
            users={users}
            integrationsTable={integrationsTable}
            personalities={personalities}
            arrInputsNotFilled={arrInputsNotFilled}
          />
        )}

        {openMenu && promptVariable && (
          <EditPrompVariable 
            promptVariable={promptVariable}
            form={form}
            handleChangeForm={handleChangeForm}
            editPropmtVariable={editPropmtVariable}
            loadingButton={loadingButton}
            loading={loadingMenu}
            customPromptsOptions={customPromptsOptions}
          />
        )}
      </MenuRight>
    </>
  );
};

export default AIAgents;
