const processForm = (info) => {
  const form = {
    description: info?.description || "",
    caller_id_name: info?.caller_id_name || "",
    callerid_prefix: info?.callerid_prefix || "",
    timeout: info.timeout !== null && info.timeout !== undefined ? info.timeout : "",
    ai_enabled: info?.ai_enabled || false,
    auto_followup: info?.auto_followup || false,
    ring_strategy: info?.ring_strategy || "",
    callforward_enable: info?.callforward_enable || false,
    callforward_number: info?.callforward_number || "",
    callforward_keep_caller_caller_id: info?.callforward_keep_caller_caller_id || false,
    callforward_call_confirmation: info?.callforward_call_confirmation || false,
  }

  return form
}

export default processForm;