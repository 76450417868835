import petitionGet from "../../../../../../services/petitionGet";

const fetchUsers = async (element) => {
  const { data: result } = await petitionGet("users")
  const users = result.result

  users.forEach((user) => {
    user.label = `${user.firstname || ""}${user.firstname ? (" " + user.lastname || "") : user.lastname || ""}`
    user.value = user.pk
  })

  const arrayUsersSelected = users.filter((user) => element?.assigned_users?.user_ids?.includes(user.pk))

  return { users, arrayUsersSelected }
}

export default fetchUsers;