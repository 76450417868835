import React, { useEffect, useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import { AlertNotificactions, ChatList, ChatWindow } from "../../../../components";
import { myExtensionPetition } from "../../../../services/myExtension.service";
import { addedTagsTocontactsService, bulkUnpinOrUnarchiveMessageThreadsService, deleteMessageService, findExistingMessageService, markReadService, messagesService, sendMessageService, threadListService, updateChatWindowDataService, webSocketService } from "./services";
import { findAndRemoveChatsAddedInFronEnd, findChatByNumber, moreChatList, resetChatList, scrollToBottom, selectParameterByDropdown, updateChatList } from "./utils";
import fetchTagsService from "../../../../services/fetchTagsService/fetchTagsService";
import getQueryString from "../../../../utils/getQueryString";
import useScreenSize from "../../../../hooks/useScreenSize";
import petitionPost from "../../../../services/petitionPost";
import petitionPatch from "../../../../services/petitionPatch";
import petitionDelete from "../../../../services/petitionDelete";
import json from "../../../../config.json";
import ringMessage from "../../../../assets/audio/ringmessage.mp3";
import ModalDelete from "../ModalUtility/ModalDelete";
import ModalError from "../ModalUtility/ModalError";
import ChatMenu from "./ChatMenu/ChatMenu";
import "../Conversations.css";

const Messages = ({ number, handleActiveCall }) => {
  const myExtension = useRef({})
  const messagesContainerRef = useRef(null);
  const selectParameter = useRef("");
  const chatListPagination = useRef({
    offset: 0,
    limit: 15,
  });
  const messagesPagination = useRef({
    offset: 0,
    limit: 20,
  });

  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;

  const chatList = useRef(null);
  const chatWindowData = useRef({});
  const { width } = useScreenSize();
  const [openChatWindow, setOpenChatWindow] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentChatOpen, setCurrentChatOpen] = useState("");
  const [loadingChatWindow, setLoadingChatWindow] = useState(false);
  const [messages, setMessages] = useState([]);
  const [showAlertForNewMessage, setShowAlertForNewMessage] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [addedNewChat, setAddedNewChat] = useState(false);
  const [loadingChatList, setLoadingChatList] = useState(false);
  const [loadingMoreMessages, setLoadingMoreMessages] = useState(false);
  const [errorMoreMessages, setErrorMoreMessages] = useState("");
  const [changedNumberOrContact, setChangedNumberOrContact] = useState(false);
  const [loadingSendMessages, setLoadingSendMessages] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [loadingAddOrDeletePinChat, setLoadingAddOrDeletePinChat] = useState(false);
  const [currentPk, setCurrentPk] = useState("");
  const [loadingArchiveOrUnarchiveChat, setLoadingArchiveOrUnarchiveChat] = useState(false);
  const [optionsTags, setOptionsTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [leastOnePinnedChat, setLeastOnePinnedChat] = useState(false)
  const [showReconnectModal, setShowReconnectModal] = useState(false);
  const [variant, setVariant] = useState("");
  const [message, setMessage] = useState("");
  const [showErrorTime, setShowErrorTime] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertType, setAlertType] = useState()
  const [alertMessage, setAlertMessage] = useState("")
  const [soundEnabled, setSoundEnabled] = useState(null)
  const [loadingChangeAI, setloadingChangeAI] = useState(false)
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const rindingMessage = new Audio(ringMessage);

  const updateAlertVariables = (type, title, message, time) => {
    setAlertType(type)
    setAlertTitle(title)
    setAlertMessage(message)
    setShowErrorTime(time)

    setTimeout(() => {
      setShowErrorTime(false)
      setAlertType("")
      setAlertTitle("")
      setAlertMessage("")
    }, time + 300)
  }

  const resetOffset = () => {
    chatListPagination.current = {
      offset: 0,
      limit: 15,
    };
  };

  const afterSendMessage = async (addedNewMessages, messageThreadId, result) => {
    await markReadService({}, number, messageThreadId);
    setMessages(addedNewMessages);

    if (addedNewChat) {
      chatWindowData.current = {
        ...chatWindowData.current,
        pk: result.data.result.message_thread_id,
      };
    }
    forceUpdate();
    setAddedNewChat(false)
    scrollToBottom(messagesContainerRef)
    setLoadingSendMessages(false);
    resetChatList(selectParameter, petition, number, chatListPagination)
  }

  const canExecutedChatWindow = (chat, forceOpen) => {
    const newMessageThreadId = chat?.notIsSelf?.message_thread_id
    const currentMessageThread = currentChatOpen?.message_thread_id
    const addedFronEnd = chat?.notIsSelf?.addedFronEnd

    return (newMessageThreadId !== currentMessageThread || forceOpen) && !addedFronEnd
  }

  const activeModalDelete = (message) => {
    if (!message) {
      setModalDelete(chatWindowData.current.pk);
    } else {
      setModalDelete(message);
    }
  };

  const closeModal = () => {
    setModalDelete(false);
    setModalError(false);
  };

  const toggleMenu = () => {
    if (loadingChatWindow || loading) return;

    setOpenMenu(!openMenu);
  };

  const petitionTag = async () => {
    try {
      const tagList = await fetchTagsService()
      setOptionsTags(tagList);
    } catch (error) {
      console.log(error);
    }
  };

  const activeChatWindow = async (chat, pk, noLoading, noUpdateChatList, forceOpen, openMenu) => {
    if (loadingChangeAI) return;
    setOpenMenu(() => false);
    forceUpdate()

    const pkCurrent = currentPk
    const currentAlertMessage = showAlertForNewMessage
    const currentChatList = chatList.current
    const currentChatWindowData = chatWindowData.current
    const currentMessagesPagination = messagesPagination.current
    const timeZone = myExtension.current.time_zone

    try {
      setCurrentPk(pk || "")

      if (canExecutedChatWindow(chat, forceOpen)) {
        findAndRemoveChatsAddedInFronEnd(chatList)
  
        setShowAlertForNewMessage(false);
        setOpenChatWindow(true);

        if (!noLoading) setLoadingChatWindow(true);
  
        const messageThreadId = chat?.notIsSelf?.message_thread_id;
  
        chatWindowData.current = await updateChatWindowDataService(chat);
        forceUpdate()
  
        const newMessages = await messagesService(chat, number, messageThreadId, "", timeZone)

        setCurrentChatOpen(chat?.notIsSelf);
        updateChatThreadRead(messageThreadId, newMessages, noUpdateChatList, openMenu);
        messagesPagination.current = { offset: 0, limit: 20 };
      }
    } catch (error) {
      console.log(error)
      const errorMessage = "An Error has occurred, please try again"
      updateAlertVariables("error", "Error", errorMessage, 5000)
      setLoadingChatWindow(false);
      scrollToBottom(messagesContainerRef)
      setCurrentPk(pkCurrent)
      setShowAlertForNewMessage(currentAlertMessage)
      chatList.current = currentChatList
      chatWindowData.current = currentChatWindowData
      messagesPagination.current = currentMessagesPagination
      forceUpdate()
    }
  };

  const updateChatThreadRead = async (pk, newMessages, noUpdateChatList, openMenu) => {
    if (pk) {
      try {
        await markReadService({}, number, pk)

        if (!noUpdateChatList) {
          chatList.current = updateChatList(pk, chatList)
        }
  
        setMessages(newMessages);
        forceUpdate();
        setLoadingChatWindow(false);
        scrollToBottom(messagesContainerRef)
  
        if (openMenu) {
          setOpenMenu(true)
        }
      } catch (error) {
        console.log(error)
      }
    }
  };

  const closeChatWindow = () => {
    setOpenChatWindow(false);
    setCurrentChatOpen("");
    setMessages([]);
    chatWindowData.current = {};
    messagesPagination.current = { offset: 0, limit: 20 };
  };

  const moreData = () => {
    moreChatList(chatList, forceUpdate, chatListPagination, petition, selectParameter, number)
  };

  const sendMessages = async (new_message, imgMessages, contactMessage) => {
    setLoadingSendMessages(true);
    let petitions = [];

    imgMessages.forEach((img) => {
      petitions.push(sendMessageService('', img.file, number, chatWindowData.current.numberChat));
    });

    Array.from(contactMessage).forEach((contact) => {
      petitions.push(sendMessageService('', contact, number, chatWindowData.current.numberChat));
    });

    try {
      await Promise.all(petitions);

      if (!new_message) {
        const query = getQueryString(messagesPagination.current);
        const timeZone = myExtension.current.time_zone
        const chat = chatWindowData.current
        const addedNewMessages = await messagesService(chat, number, false, query, timeZone);

        const messageThreadId = chatWindowData.current?.notIsSelf?.message_thread_id

        if (messageThreadId) {
          await afterSendMessage(addedNewMessages, messageThreadId)
        }
      } else {
        const result = await sendMessageService(new_message, null, number, chatWindowData.current.numberChat);
        const messageThreadId = result?.data?.result?.message_thread_id;
        const query = getQueryString(messagesPagination.current);

        const timeZone = myExtension.current.time_zone
        const chat = chatWindowData.current
        const addedNewMessages = await messagesService(chat, number, messageThreadId, query, timeZone);

        if (messageThreadId) {
          await afterSendMessage(addedNewMessages, messageThreadId, result)
        }
      }
    } catch (error) {
      console.log(error)
    }
  };

  const openChatWindowWhenAddNewChat = async (chat, messageThreadId) => {
    const timeZone = myExtension.current.time_zone
    const newMessages = await messagesService(chat, number, messageThreadId, "", timeZone)

    chatWindowData.current = chat;
    setCurrentChatOpen(chat?.notIsSelf);

    updateChatThreadRead(messageThreadId, newMessages, true);
    messagesPagination.current = { offset: 0, limit: 20 };
  };

  const addNewChat = async (dataNumber) => {
    const findOneChat = findChatByNumber(chatList.current, dataNumber);

    if (findOneChat) {
      const filterChatList = chatList.current.filter((chat) => chat.pk !== findOneChat.pk)
      const newChatList = [{...findOneChat}, ...filterChatList]
      chatList.current = newChatList
      forceUpdate()
      activeChatWindow(findOneChat);
      setAddedNewChat(true);
    } else {
      setOpenChatWindow(true);
      setLoadingChatWindow(true);

      const newChatNumber = typeof dataNumber === 'string' ? dataNumber : typeof dataNumber === 'object' ? dataNumber?.phone || '' : '';
      try {
        const { newChat, newChatList, result } = await findExistingMessageService(number, newChatNumber, chatList, dataNumber, number)
        
        if (result?.result && result?.result?.length) {
          chatList.current = newChatList;
          forceUpdate();
          setAddedNewChat(true);
          openChatWindowWhenAddNewChat(newChat, result?.result[0]?.message_thread_id);
        } else {
          chatWindowData.current = newChat;
          setCurrentChatOpen(newChat?.notIsSelf);
          setMessages([]);
          setShowAlertForNewMessage(false);
          setAddedNewChat(true);
          setLoadingChatWindow(false);
          chatList.current = newChatList;
          forceUpdate();
        }
      } catch (error) {
        console.log(error)
      }
    }
  };

  const deleteChat = async () => {
    setLoadingDelete(true);
    try {
      await petitionDelete("deleteThreadSms", { number, smsId: modalDelete })
      setLoadingDelete(false);
      closeModal();
      closeChatWindow();
      petition(number, "?offset=0&limit=15", false);
    } catch (error) {
      console.log(error)
    }
  };

  const deleteMessage = async () => {
    setLoadingDelete(true);

    try {
      const newMessages = await deleteMessageService(modalDelete, messages)

      setMessages(newMessages);
      forceUpdate();
      setLoadingDelete(false);
      closeModal();
      petition(number, "?offset=0&limit=15", true);
    } catch (error) {
      console.log(error)
      setLoadingDelete(false);
    }
  };

  const chatsSelectedByTheDropdown = async (value) => {
    setOpenMenu(false);
    setLoadingChatList(true);

    const parameter = selectParameterByDropdown(value, selectParameter, chatListPagination)

    try {
      const newChatList = await threadListService(number, parameter)
      const firstChatPinned = newChatList.find((chat) => chat.pinned)
      setLeastOnePinnedChat(firstChatPinned ? true : false)

      closeChatWindow();
      forceUpdate();
      chatList.current = newChatList;
      setLoadingChatList(false);
    } catch (error) {
      console.log(error)
      closeChatWindow();
      forceUpdate();
      chatList.current = "error";
      setLoadingChatList(false);
    }
  };

  const getMoreMessages = async () => {
    if (messages.length === messagesPagination.current.limit) {
      setLoadingMoreMessages(true);

      const paginationtemp = {
        offset: 0,
        limit: messagesPagination?.current?.limit + 20,
      };

      try {
        const thread = currentChatOpen?.message_thread_id
        const query = getQueryString(paginationtemp)
  
        const timeZone = myExtension.current.time_zone
        const chat = chatWindowData.current
        const newMessages = await messagesService(chat, number, thread, query, timeZone);
  
        setMessages(newMessages);
        setLoadingMoreMessages(false);
        messagesPagination.current = paginationtemp;
      } catch (error) {
        console.log(error)
        setLoadingMoreMessages(false);
        setErrorMoreMessages("Error loading more messages");

        setTimeout(() => {
          setErrorMoreMessages("");
        }, 1000);
      }
    }
  };

  const changeAiEnabled = async (value) => {
    try {
      setloadingChangeAI(true)

      const data = { ai_enabled: value };
      const thread_id = chatWindowData?.current?.pk

      await petitionPatch("aiEnabled", { data, thread_id })

      const newChatList = chatList.current.map((chat) => {
        if (chat.pk === thread_id) chat.ai_enabled = value
        return chat
      })

      const firstChatPinned = newChatList.find((chat) => chat.pinned)
      setLeastOnePinnedChat(firstChatPinned ? true : false)

      if (newChatList.length === 0) {
        closeChatWindow();
      }

      chatList.current = newChatList;
      chatWindowData.current = { ...chatWindowData?.current, ai_enabled: value };

      if (!value) setMessages((prev) => prev.filter((element) => !element?.isFollowUpFake))

      setloadingChangeAI(false)
      forceUpdate();
    } catch (error) {
      console.log(error)
      setloadingChangeAI(false)
    }
  };

  const downloadVoicemail = async (data) => {
    let fetchUrl = "";

    if (data?.cdr?.call_recording_filename) {
      fetchUrl = `${urlBase}/itpvoice/v2/${id}/my-extension/cdr/${data.cdr.pk}/download-recording`;
    } else {
      fetchUrl = `${urlBase}/itpvoice/v2/${id}/my-extension/voicemail-messages/${data.pk}`;
    }

    return new Promise(async (resolve, reject) => {
      try {
        const downloadFile = async () =>
          await fetch(fetchUrl, {
            headers: {
              Authorization: "Bearer " + token.replace(/['"]+/g, ""),
            },
          }).then((res) => res.blob());

        const createURL = (blob) => URL.createObjectURL(blob);

        const blob = await downloadFile();
        if (blob === 404) return setModalError(true);
        const create = createURL(blob);

        let link = document.createElement("a");
        link.download = "file.mp3";
        link.href = create;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };

  const addPinToMessageThreads = async (selectedItems, setSelectedItems) => {
    setLoadingAddOrDeletePinChat(true);
    setLeastOnePinnedChat(true)

    const currentChatList = chatList.current
    const selectedChatsPk = Array.from(selectedItems)

    try {
      const newChatList = chatList.current.map((chat) => {
        if (selectedChatsPk.includes(chat.pk)) chat.pinned = true
        return chat
      })

      chatList.current = newChatList
      setSelectedItems(new Set([]))
      forceUpdate()
  
      const data = { message_thread_ids: selectedChatsPk }
      await petitionPost("bulkPinMessageThreads", { number, data })
      const paginationtemp = { offset: 0, limit: 15 };
      const parameter = getQueryString(paginationtemp) + selectParameter.current;
  
      petition(number, parameter, true);
      chatListPagination.current = paginationtemp;
    } catch (error) {
      console.log(error)

      const prevChatList = currentChatList.map((chat) => {
        if (selectedChatsPk.includes(chat.pk)) chat.pinned = false
        return chat
      })
      chatList.current = prevChatList
      forceUpdate()

      setSelectedItems(selectedItems)
      setLeastOnePinnedChat((prev) => !prev)
      setLoadingAddOrDeletePinChat(false);
    }
  }

  const deletePinToMessageThreads = async (selectedItems, setSelectedItems) => {
    setLoadingAddOrDeletePinChat(true);
    setLeastOnePinnedChat(true)

    const currentChatList = chatList.current

    try {
      const selectedChatsPk = Array.from(selectedItems)
      const newChatList = chatList.current.filter((chat) => !selectedChatsPk.includes(chat.pk))
      const findPinChat = newChatList.find((element) => element.pinned)
      setLeastOnePinnedChat(findPinChat ? true : false)

      chatList.current = newChatList
      setSelectedItems(new Set([]))
      forceUpdate()

      await bulkUnpinOrUnarchiveMessageThreadsService(number, selectedChatsPk, "pin")
      const paginationtemp = { offset: 0, limit: 15 };
      const parameter = getQueryString(paginationtemp) + selectParameter.current;
      
      petition(number, parameter, true);
      chatListPagination.current = paginationtemp;
    } catch (error) {
      console.log(error)
      chatList.current = currentChatList;
      forceUpdate();
      setSelectedItems(selectedItems)
      setLeastOnePinnedChat((prev) => !prev)
      setLoadingAddOrDeletePinChat(false);
    }
  }

  const archiveMessageThreads = async (selectedItems, setSelectedItems) => {
    setLoadingArchiveOrUnarchiveChat(true)

    const currentChatList = chatList.current

    try {
      const selectedChatsPk = Array.from(selectedItems)
      const newChatList = chatList.current.filter((chat) => !selectedChatsPk.includes(chat.pk))
      const findPinChat = newChatList.find((element) => element.pinned)

      setLeastOnePinnedChat(findPinChat ? true : false)

      chatList.current = newChatList
      setSelectedItems(new Set([]))
      forceUpdate();

      const data = { message_thread_ids: selectedChatsPk }
      await petitionPost("bulkArchiveMessageThreads", { number, data })
      const paginationtemp = { offset: 0, limit: 15 };

      if (selectedChatsPk.includes(chatWindowData.current.pk)) {
        closeChatWindow()
      }

      const parameter = getQueryString(paginationtemp) + selectParameter.current;

      petition(number, parameter, true);
      chatListPagination.current = paginationtemp;
      setLoadingArchiveOrUnarchiveChat(false);
    } catch (error) {
      console.log(error)

      chatList.current = currentChatList;
      forceUpdate();
      setSelectedItems(selectedItems)
      setLoadingArchiveOrUnarchiveChat(false);
    }
  }

  const unarchiveMessageThreads = async (selectedItems, setSelectedItems) => {
    setLoadingArchiveOrUnarchiveChat(true)

    const currentChatList = chatList.current

    try {
      const selectedChatsPk = Array.from(selectedItems)
      const newChatList = chatList.current.filter((chat) => !selectedChatsPk.includes(chat.pk))
      const findPinChat = newChatList.find((element) => element.pinned)

      setLeastOnePinnedChat(findPinChat ? true : false)

      chatList.current = newChatList
      setSelectedItems(new Set([]))
      forceUpdate();

      await bulkUnpinOrUnarchiveMessageThreadsService(number, selectedChatsPk, "archive")
      const paginationtemp = { offset: 0, limit: 15 };

      if (selectedChatsPk.includes(chatWindowData.current.pk)) {
        closeChatWindow()
      }

      const parameter = getQueryString(paginationtemp) + selectParameter.current;

      petition(number, parameter, true);
      chatListPagination.current = paginationtemp;
      setLoadingArchiveOrUnarchiveChat(false);
    } catch (error) {
      console.log(error)

      chatList.current = currentChatList;
      forceUpdate();
      setSelectedItems(selectedItems)
      setLoadingArchiveOrUnarchiveChat(false);
    }
  }

  const addTagsToContacts = async (selectedItems) => {
    await addedTagsTocontactsService(tags, selectedItems, chatList, number, forceUpdate)
  }

  const onDismiss = () => {
    setVariant("")
    setShowReconnectModal(false)
  }

  const reconnectedAction = () => {
    setMessage("Trying to Connect")
    setVariant("info")

    connectWebSocket()
  }

  const petition = async (
    number,
    parameter,
    noLoading,
    activateChatWindow,
    thread,
    scroll
  ) => {
    if (!noLoading) setLoading(true);
    const currentChatList = chatList?.current || []

    try {
      let newChatList = await threadListService(number, parameter)

      if (scroll) {
        const newCurrentData = chatList.current.filter((chat) => !chat.notIsSelf.addedFronEnd);
        newChatList = newCurrentData.filter((chat) => !chat.isFake).concat(newChatList);
      } 
      
      chatList.current = newChatList
      forceUpdate();

      setLoading(false);

      if (activateChatWindow) {
        let findChat = newChatList.find((chat) => chat.pk === parseInt(thread));

        if (findChat) {
          activeChatWindow(findChat, false, false, true);
        }
      }

      if (newChatList.length === 0) {
        closeChatWindow();
      }

      const firstChatPinned = newChatList.find((chat) => chat.pinned === true)
      setLeastOnePinnedChat(firstChatPinned ? true : false)
      setLoadingAddOrDeletePinChat(false);
    } catch (error) {
      console.log(error)

      chatListPagination.current = { offset: parameter?.offset > 15 ? parameter?.offset - 15 : 0, limit: 15 }
      chatList.current = currentChatList.filter((chat) => !chat?.isFake)
      forceUpdate()

      const errorMessage = "An Error has occurred, please try again"
      updateAlertVariables("error", "Error", errorMessage, 5000)
      setLoadingAddOrDeletePinChat(false);
      setLoading(false)
    }
  };

  const connectWebSocket = (sound) => {
    return webSocketService(setMessage, setVariant, setShowReconnectModal, chatList, chatWindowData, forceUpdate, messagesContainerRef, rindingMessage, chatListPagination, number, myExtension, setMessages, setShowAlertForNewMessage, sound, selectParameter)
  }

  const petitions = async () => {
    selectParameter.current = ""
    chatList.current = null;
    setOpenChatWindow(false);
    setOpenMenu(false);
    setLoading(true);
    setCurrentChatOpen("");
    setMessages([]);
    forceUpdate();
    resetOffset();

    const parameter = getQueryString(chatListPagination.current) + selectParameter.current;

    await petitionTag();

    await petition(number, parameter);
  };

  useEffect(() => {
    if (changedNumberOrContact) {

      const newChatlist = chatList.current.map((element) => {
        if (element.pk === changedNumberOrContact.pk) {
          return changedNumberOrContact;
        } else {
          return element;
        }
      });

      chatList.current = newChatlist;
      chatWindowData.current = changedNumberOrContact;
      forceUpdate();
    }
  }, [changedNumberOrContact]);

  useEffect(() => {
    if (typeof soundEnabled === "boolean") {
      const webSocket = connectWebSocket(soundEnabled)

      return () => {
        webSocket.closeConnection();
      };
    }
  }, [soundEnabled]);

  useEffect(() => {
    const myExtensionData = async () => {
      try {
        const data = await myExtensionPetition()

        if (data && Object.keys(data).length > 0) {

          if (!data?.user_settings || data?.user_settings?.sound_enabled !== false) {
            setSoundEnabled(true);
          } else {
            setSoundEnabled(false);
          }

          myExtension.current = data
          petitions();
        } 

      } catch(error) {
        console.log(error)
      }
    }

    myExtensionData()
  }, [number]);

  //userWhoSentMessage
  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "300px",
          zIndex: "1000",
          right: "0px",
        }}
      >
        <Alert
          variant={variant}
          className="alert-dismissible fade show"
          show={showReconnectModal}
          onClose={onDismiss}
          dismissible={true}
        >
            {message === "Disconnected" ? (
              <>
                Disconnected 
                <span onClick={reconnectedAction} className="reconnectedAction">Reconnect</span>
              </>
            ) : (
              <>
                {message}
              </>
            )}
        </Alert>

        </div>

        <AlertNotificactions 
          type={alertType}
          alertMessage={alertMessage}
          showTime={showErrorTime}
          title={alertTitle}
        />

      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeModal}
          loading={loadingDelete}
          modalFunction={
            typeof modalDelete === "number" ? deleteChat : deleteMessage
          }
        />
      )}

      {modalError && <ModalError modal={modalError} closeModal={closeModal} />}

      <div className={`itp-all-chats ${(loading || loadingChatList) ? "v-hidden" : ""}}`}>
        <ChatList
          activeChatWindow={activeChatWindow}
          showChatList={!openChatWindow}
          chatList={chatList.current}
          moreData={moreData}
          showAlertForNewMessage={showAlertForNewMessage}
          addNewChat={addNewChat}
          onChageSelect={chatsSelectedByTheDropdown}
          loading={loading || loadingChatList}
          loadingAddOrDeletePinChat={loadingAddOrDeletePinChat}
          loadingArchiveOrUnarchiveChat={loadingArchiveOrUnarchiveChat}
          optionsTags={optionsTags}
          setOptionsTags={setOptionsTags}
          tags={tags}
          setTags={setTags}
          leastOnePinnedChat={leastOnePinnedChat}
          timeZone={myExtension.current?.time_zone}
          addTagsToContacts={addTagsToContacts}
          chatWindowData={chatWindowData.current}
          addPinToMessageThreads={addPinToMessageThreads}
          deletePinToMessageThreads={deletePinToMessageThreads}
          archiveMessageThreads={archiveMessageThreads}
          unarchiveMessageThreads={unarchiveMessageThreads}
        />

        {openChatWindow && (
          <div
            className={`all-container-chat${
              width > 1399 && openMenu ? " resize" : ""
            }`}
          >
            <ChatWindow
              width={width}
              openMenu={openMenu}
              toggleMenu={toggleMenu}
              loading={loadingChatWindow}
              messages={messages}
              setMessages={setMessages}
              chatWindowData={chatWindowData.current}
              timeZone={myExtension.current?.time_zone}
              number={number}
              sendMessages={sendMessages}
              loadingSendMessages={loadingSendMessages}
              messagesContainerRef={messagesContainerRef}
              activeModalDelete={activeModalDelete}
              deleteMessage={deleteMessage}
              loadingMoreMessages={loadingMoreMessages}
              errorMoreMessages={errorMoreMessages}
              getMoreMessages={getMoreMessages}
              changeAiEnabled={changeAiEnabled}
              pk={currentPk}
              downloadVoicemail={downloadVoicemail}
            />
          </div>
        )}

        <div
          className={`${
            width > 1399 && openMenu
              ? "all-container-menu"
              : width < 1400 && openMenu
              ? "all-container-menu-absolute"
              : "no-show"
          }`}
        >
          <ChatMenu
            openMenu={openMenu}
            toggleMenu={toggleMenu}
            chatWindowData={chatWindowData}
            chatList={chatList}
            number={number}
            timeZone={myExtension.current?.time_zone}
            handleActiveCall={handleActiveCall}
            myExtension={myExtension.current}
            changedNumberOrContact={changedNumberOrContact}
            setChangedNumberOrContact={setChangedNumberOrContact}
            updateAlertVariables={updateAlertVariables}
          />
        </div>
      </div>
    </>
  );
};

export default Messages;