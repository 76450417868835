/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { LoadingButton } from "../../../../components";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5";
import Step6 from "./Steps/Step6";
import Step7 from "./Steps/Step7";
import petitionGet from "../../../../services/petitionGet";
import "./AddCampaign.css";

const AddCampaign = ({
  form,
  setForm,
  addCampaign,
  loadingAddSmartCampaign,
  step,
  setStep,
  loading,
  numbers,
  edit,
  editCampaign,
  imgMessages,
  setImgMessages,
  optionsMessageBody,
  setOptionsMessageBody,
  handleSendMessage,
  loadingSend,
  updatedAlertStates,
  followUpsTable,
  setFollowUpsTable,
}) => {
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingPetition, setLoadingPetition] = useState(false);

  const statusArrayThatCannotBeEdited = [
    "in_progress",
    "completed",
    "paused",
    "starting",
  ];

  const handleChangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleBackStep = () => {
    setStep(step - 1);
  };

  const nextStep = () => {
    if (step < 7) {
      setStep(step + 1);
    } else {
      if (!edit) {
        addCampaign(false, true);
      } else {
        editCampaign();
      }
    }
  };

  const saveFunction = async () => {
    if (!edit) {
      setLoadingSave(true);
      await addCampaign(true);
      setLoadingSave(false);
    } else {
      setLoadingSave(true);
      await editCampaign(true);
      setLoadingSave(false);
    }
  };

  const disabledNextButton = () => {
    if (step === 1 && (!form.name || !form.campaign_type || (form.campaign_type && (!form.numbers.length || !form.campaign_strategy)))) {
      return true;
    }

    if ((step === 2 && !form.message_text && !form.mms_file && !form.mms_mime && !form.media_filename) || loadingSave) {
      return true;
    }

    if ((step === 3 && !form.contacts.length && !form.contact_list.length) || loadingSave) {
      return true;
    }

    if ((step === 4 && (!form.schedule_start_time || !form.schedule_end_time)) || loadingSave) {
      return true;
    }

    if ((step === 6 && !form.scheduled_date) || loadingSave) return true;

    if (loadingAddSmartCampaign || loadingSave) return true;
  };

  const petition = () => {
    setLoadingPetition(true);
    petitionGet("customFields")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.name;
          element.value = `{{contact.${element.name}}}`;
        });

        setOptionsMessageBody([
          {
            title: "Variables",
            options: [
              {
                title: "Contact",
                options: [
                  {
                    label: "First Name",
                    value: "{{contact.firstname}}",
                  },
                  {
                    label: "Last Name",
                    value: "{{contact.lastname}}",
                  },
                  { label: "Phone", value: "{{contact.phone}}" },
                  { label: "Email", value: "{{contact.email}}" },
                  ...result.result,
                ],
              },
            ],
            dropdownOpen: false,
          },
        ]);

        setLoadingPetition(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petition();
  }, []);

  return (
    <>
      <div className="d-flex flex-wrap">
        <div>
          <label className="itp-label-input__label mb-0">1. Setup</label>
          <div
            className={`itp-add-number-border campaign-wizard-border ${
              step === 1 ? "active" : ""
            }`}
          ></div>
        </div>
        <div className="itp-edit-number-container-second-step">
          <label className="itp-label-input__label mb-0">2. Message</label>
          <div
            className={`itp-add-number-border campaign-wizard-border ${
              step === 2 ? "active" : ""
            }`}
          ></div>
        </div>
        <div className="itp-edit-number-container-second-step">
          <label className="itp-label-input__label mb-0">3. Participants</label>
          <div
            className={`itp-add-number-border campaign-wizard-border long ${
              step === 3 ? "active" : ""
            }`}
          ></div>
        </div>
        <div className="itp-edit-number-container-second-step">
          <label className="itp-label-input__label mb-0">
            4. Contact Schedule
          </label>
          <div
            className={`itp-add-number-border campaign-wizard-border schedule ${
              step === 4 ? "active" : ""
            }`}
          ></div>
        </div>
        <div className="itp-edit-number-container-second-step">
          <label className="itp-label-input__label mb-0">
            5. Follow Ups
          </label>
          <div
            className={`itp-add-number-border campaign-wizard-border schedule ${
              step === 5 ? "active" : ""
            }`}
          ></div>
        </div>
        <div className="itp-edit-number-container-second-step">
          <label className="itp-label-input__label mb-0">6. Start Date</label>
          <div
            className={`itp-add-number-border campaign-wizard-border ${
              step === 6 ? "active" : ""
            }`}
          ></div>
        </div>
        <div className="itp-edit-number-container-second-step">
          <label className="itp-label-input__label mb-0">7. Preview</label>
          <div
            className={`itp-add-number-border campaign-wizard-border ${
              step === 7 ? "active" : ""
            }`}
          ></div>
        </div>
      </div>

      <div className="mt-4">
        {step === 1 && (
          <Step1
            form={form}
            setForm={setForm}
            handleChangeForm={handleChangeForm}
            numbers={numbers}
            loading={loading || loadingPetition}
          />
        )}

        {step === 2 && !loading && !loadingPetition && (
          <Step2
            form={form}
            setForm={setForm}
            handleChangeForm={handleChangeForm}
            imgMessages={imgMessages}
            setImgMessages={setImgMessages}
            optionsMessageBody={optionsMessageBody}
          />
        )}

        {step === 3 && !loading && !loadingPetition && <Step3 form={form} setForm={setForm} />}

        {step === 4 && !loading && !loadingPetition && (
          <Step4
            form={form}
            setForm={setForm}
            handleChangeForm={handleChangeForm}
            updatedAlertStates={updatedAlertStates}
            edit={edit}
          />
        )}

        {step === 5 && !loading && !loadingPetition && (
          <Step5 
            followUpsTable={followUpsTable}
            setFollowUpsTable={setFollowUpsTable}
          />
        )}

        {step === 6 && !loading && !loadingPetition && (
          <Step6 form={form} handleChangeForm={handleChangeForm} />
        )}

        {step === 7 && !loading && !loadingPetition && (
          <Step7
            form={form}
            handleSendMessage={handleSendMessage}
            loadingSend={loadingSend}
          />
        )}
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex gap-1 mt-4 mb-4">
          {step > 1 && (
            <button
              disabled={loadingSave}
              onClick={handleBackStep}
              className="btn-light"
            >
              Back
            </button>
          )}

          {(!edit || (edit && (step !== 7 || !statusArrayThatCannotBeEdited.includes(edit.status)))) && (
            <button
              disabled={disabledNextButton()}
              onClick={nextStep}
              className="btn-primary loading"
            >
              {loadingAddSmartCampaign && <LoadingButton />}
              {step === 7
                ? !edit
                  ? "Start Campaign"
                  : "Edit Campaign"
                : "Next"}
            </button>
          )}
        </div>

        {step > 1 &&
          (!edit || !statusArrayThatCannotBeEdited.includes(edit.status)) && (
            <button
              disabled={loadingSave}
              onClick={saveFunction}
              className="btn-primary loading"
            >
              {loadingSave && <LoadingButton />}
              Save
            </button>
          )}
      </div>
    </>
  );
};

export default AddCampaign;
