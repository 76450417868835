import petitionDelete from "../../../../../../services/petitionDelete";
import getQueryString from "../../../../../../utils/getQueryString";

const deleteAIAgentService = async (setLoadingButton, info, pagination, allResult, petition) => {
  try {
    setLoadingButton(true);
    await petitionDelete("aiAgent", { aiAgentID: info.pk });
    let parameter = getQueryString(pagination.current);

    if (allResult.result.length === 1 && pagination.current.offset > 0) {
      const paginationTemp = {
        offset: pagination.current.offset - pagination.current.limit,
        limit: pagination.current.limit,
      };

      parameter = getQueryString(paginationTemp);
      pagination.current = paginationTemp
    }

    petition(parameter, true);
  } catch (error) {
    console.log(error);
    setLoadingButton(false);
  }
};

export default deleteAIAgentService;
