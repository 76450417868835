import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import base64ToArrayBuffer from "../../../utils/base64ToArrayBuffer";

const useDownloadAudio = (audioUrl, customDownload, downloadWhenClicked, noFetch, audioType) => {
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [startDownload, setStartDownload] = useState(false);
  const [timeProgress, setTimeProgress] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [modal, setModal] = useState(false);
  const [audio, setAudio] = useState("")
  const [errorMessage, setErrorMessage] = useState("");

  const donwloadAudio = useCallback(async () => {
    setLoadingDownload(true);
    setErrorMessage(""); // Limpiar mensaje de error antes de intentar la descarga

    try {
      let response;
      if (customDownload) {
        response = await customDownload();
      } else {
        response = await axios.get(audioUrl);
      }

      if (response?.status === 200) {
        const base64Audio = response.data.result;
        const arrayBuffer = base64ToArrayBuffer(base64Audio);
        if (arrayBuffer) {
          const selectedType = audioType || response?.data?.contentType || "audio/mp3";
          setAudio(`data:${selectedType};base64,${base64Audio}`);
          setDisabled(false);
        } else {
          throw new Error("The downloaded file is not valid audio.");
        }
      } else {
        throw new Error("The request failed to get the audio.");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message || "Unexpected error when downloading audio.");
      setDisabled(true);
      setModal(true);
    } finally {
      setLoadingDownload(false);
      setStartDownload(false);
    }
  }, [audioUrl, customDownload, audioType]);

  useEffect(() => {
    if (!disabled) {
      setLoadingDownload(false);
    }
  }, [disabled, setLoadingDownload]);

  useEffect(() => {
    if (!downloadWhenClicked) {
      if (noFetch) {
        try {
        setAudio(audioUrl)
        setDisabled(false);
        } catch (error) {
          console.log(error)
          setDisabled(true);
          setModal(true);
        }
      } else {
        const checkAudioValidity = async () => {
          try {
            const response = await axios.get(audioUrl);
            if (response.status === 200) {
              const base64Audio = response.data.result
              const arrayBuffer = base64ToArrayBuffer(base64Audio);

              if (arrayBuffer) {

                // Carga el archivo de audio
                setAudio(`data:audio/mp3;base64,${response.data.result}`)

                setDisabled(false);
              }
            }
          } catch (error) {
            console.error(error);
          }
        };

        checkAudioValidity();
      }
    }
  }, [audioUrl, downloadWhenClicked, noFetch, setTimeProgress, setDisabled]);

  useEffect(() => {
    if (startDownload) {
      donwloadAudio();
    }
  }, [startDownload, donwloadAudio]);

  return {
    loadingDownload,
    setStartDownload,
    timeProgress,
    setTimeProgress,
    disabled,
    modal,
    setModal,
    audio,
    errorMessage,
  };
};

export default useDownloadAudio;
