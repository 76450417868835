// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-callFlow-container-module {
  background: #a2c7e630 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #85858529;
  border: 0.30000001192092896px solid #0023405c;
  border-radius: 8px;
  opacity: 1;
  width: 100%;
  padding: 6px 16px 12px 16px;
}

#skip_greeting {
  border: 1px solid #437097;
  border-radius: 4px;
}

#text-skip-greeting {
  background-color: #fff;
  color: #0c0c0c;
}

.send-email-help {
  position: relative;
}

.send-email-help:hover > .itp-header-click-icon-hover {
 visibility: visible;
}

.itp-header-click-icon-hover.send-email {
  font: normal normal normal 12px/15px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  padding: 8px;
  text-align: left;
  bottom: 1.5rem;
  width: 10rem;
  left: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/WorkspaceSettings/AdvancedCallFlows/components/ChartMenuContent/Modules/Modules.css"],"names":[],"mappings":"AAAA;EACE,iDAAiD;EACjD,iCAAiC;EACjC,6CAA6C;EAC7C,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;EACE,oDAAoD;EACpD,mBAAmB;EACnB,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,YAAY;EACZ,YAAY;AACd","sourcesContent":[".itp-callFlow-container-module {\n  background: #a2c7e630 0% 0% no-repeat padding-box;\n  box-shadow: 0px 0px 6px #85858529;\n  border: 0.30000001192092896px solid #0023405c;\n  border-radius: 8px;\n  opacity: 1;\n  width: 100%;\n  padding: 6px 16px 12px 16px;\n}\n\n#skip_greeting {\n  border: 1px solid #437097;\n  border-radius: 4px;\n}\n\n#text-skip-greeting {\n  background-color: #fff;\n  color: #0c0c0c;\n}\n\n.send-email-help {\n  position: relative;\n}\n\n.send-email-help:hover > .itp-header-click-icon-hover {\n visibility: visible;\n}\n\n.itp-header-click-icon-hover.send-email {\n  font: normal normal normal 12px/15px Instrument Sans;\n  letter-spacing: 0px;\n  color: #002340;\n  padding: 8px;\n  text-align: left;\n  bottom: 1.5rem;\n  width: 10rem;\n  left: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
