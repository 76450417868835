import { formatHourof24at12 } from '../../../../../utils/getDate';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import SmartToyIcon from '@mui/icons-material/SmartToy';

const CallLog = ({ element, isOther }) => {
  const show = element.item_type === "call" && (!element.audio_message || (element.cdr && element.cdr.call_recording_filename)) ? true  : false

  const selectorImage = {
    missed: <PhoneMissedIcon className={`itp-call-log-message-icon ${isOther ? "other" : ""}`} />,
    answeredIn: <PhoneCallbackIcon className={`itp-call-log-message-icon ${isOther ? "other" : ""}`} />,
    answeredOut: <PhoneForwardedIcon className={`itp-call-log-message-icon ${isOther ? "other" : ""}`} />,
    aiHandle: <SmartToyIcon className={`itp-call-log-message-icon ${isOther ? "other" : ""}`} />,
  };

  if (show) {
    return (
      <div className={`itp-chat-window-content__download-message-content ${element.isRecording ? "recording" : ""}`}>
        <div className="itp-chat-window-content__download-message-icon-text">
          <div className={`d-flex align-items-center gap-2 ${element.selectImage !== "aiHandle" ? "-mt-4" : ""}`}>
            {selectorImage[element.selectImage]}
            {element.selectImage === "aiHandle" && <span>AI</span>}
          </div>
          <div className="d-flex flex-column">
            <p className={`itp-chat-window-content__message ${isOther ? "other" : ""}`}>
              {element.status} {element.message_body}
            </p>
          </div>
        </div>
        <div>
          <span className={`itp-chat-window__message-date ${isOther ? "other" : ""}`}>
            {!element?.sending ? (
              <>
                {formatHourof24at12(element?.message_timestamp)}
              </>
            ) : (
              <TimerOutlinedIcon style={{ width: "16px", height: "16px" }} />
            )}
          </span>
        </div>
      </div>
    );
  }
};

export default CallLog;
