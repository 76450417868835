import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { AddTagAction, ConfirmAction, DeleteContactsAction, ExportToCsvAction, MoveContactsToFunnelAction, MoveToContactListAction } from "../components";

const ModalWizzard = ({
  modal,
  closeModal,
  modalActions,
  loading,
  title,
  allResult,
  valueLog,
  action,
}) => {
  const [step, setStep] = useState(0);

  const nextStep = (data) => {
    if (step === 0) {
      setStep(step + 1);
    } else {
      modalActions(data);
    }
  };

  const backStep = () => {
    if (step === 1) {
      setStep(step - 1);
    } else {
      closeModal();
    }
  };

  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>{title ? title : "Modal Wizzard"}</ModalHeader>
      <ModalBody>
        <div className="d-flex mb-3">
          <div>
            <label className="itp-label-input__label mb-0">
              1. Confirm
            </label>
            <div
              className={`itp-add-number-border ${step === 0 ? "active" : ""}`}
            ></div>
          </div>
          <div className="itp-edit-number-container-second-step">
            <label className="itp-label-input__label mb-0">
              2. {action}
            </label>
            <div className={`itp-add-number-border ${step === 1 ? "active" : ""}`}></div>
          </div>
        </div>

        {step === 0 && (
          <>
            <ConfirmAction 
              allResult={allResult}
              valueLog={valueLog}
              backStep={backStep}
              nextStep={nextStep}
            />
          </>
        )}

        {step === 1 && (
          <>
            {action === "Add tags" && (
              <>
                <AddTagAction 
                  keyTag="tags"
                  title="Add Tags"
                  loading={loading}
                  backStep={backStep} 
                  nextStep={nextStep}
                />
              </>
            )}

            {action === "Move contacts to list" && (
              <>
                <MoveToContactListAction 
                  backStep={backStep}
                  loading={loading}
                  nextStep={nextStep}
                />
              </>
            )}

            {action === "Delete contacts" && (
              <>
                <DeleteContactsAction 
                  backStep={backStep}
                  loading={loading}
                  nextStep={nextStep}
                />
              </>
            )}

            {action === "Move contacts to funnel" && (
              <>
                <MoveContactsToFunnelAction 
                  backStep={backStep}
                  nextStep={nextStep}
                  loadingSave={loading}
                />
              </>
            )}

            {action === "Export to csv" && (
              <>
                <ExportToCsvAction 
                  backStep={backStep}
                  loading={loading}
                  nextStep={nextStep}
                />
              </>
            )}
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalWizzard;
