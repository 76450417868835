/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import { AddButton, CustomTableMain, LabelInput, LabelSelect } from "../../../../../components";

const Step5 = ({ followUpsTable, setFollowUpsTable }) => {
  const intervalNumberOptions = Array.from({ length: 366 }, (_, i) => (i + 1).toString());

  const [showAddEditFollowUp, setShowAddEditFollowUp] = useState(false);
  const [disabledInputMessage, setDisabledInputMessage] = useState(false)
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [form, setForm] = useState({
    interval: "",
    interval_type: "",
    message: "",
    use_ai: false,
  });

  const backToTable = () => {
    setForm({
      interval: "",
      interval_type: "",
    })
    setShowAddEditFollowUp(false)
  }

  const activeAddOrEditFollowUp = (element) => {
    if (element) {
      setForm(element)
    }

    setShowAddEditFollowUp(true);
  };

  const deleteFollowUpToTable = (element) => {
    setFollowUpsTable((prev) => {
      const newContent = prev.content.filter((el) => el.pk !== element.pk)

      return { ...prev, content: [...newContent] }
    })
    forceUpdate()
  }

  const handleChangeForm = (e) => {
    const { name, value } = e.target;

    if (name === "interval" && (!value || value.match(/^\d+$/))) {
      setForm({ ...form, interval: value });
    } else if (name !== "interval") {
      setForm({ ...form, [name]: value });
    }
  };

  const handleChangeUseAI = () => {
    setDisabledInputMessage(!form.use_ai)

    if (form.use_ai) {
      setForm({ ...form, use_ai: false })
    } else {
      setForm({ ...form, use_ai: true, message: "" })
    }
  }

  const addOrEditAIFollowUp = () => {
    if (!form?.pk) {
      setFollowUpsTable((prev) => {
        const newFollowUp = { ...form, pk: uuidv4() }
        const newContent = [...prev.content, newFollowUp]

        return { ...prev, content: [...newContent] }
      })
    } else {
      setFollowUpsTable((prev) => {
        const newContent = prev.content.map((element) => {
          if (element.pk === form.pk) {
            element = { ...element, ...form }
          }

          return element
        })

        return { ...prev, content: [...newContent] }
      })
    }

    backToTable()
    forceUpdate()
  }

  useEffect(() => {
    setFollowUpsTable((prev) => {
      return {
        ...prev,
        actions: { 
          title: "Actions", 
          content: [
            {
              name: "Edit",
              icon: <FontAwesomeIcon icon={faPenToSquare} />,
              handleClick: activeAddOrEditFollowUp,
            },
            {
              name: "Delete",
              icon: <FontAwesomeIcon icon={faTrash} />,
              handleClick: deleteFollowUpToTable,
            },
          ] 
        }
      }
    })
  }, [])

  return (
    <>
      {!showAddEditFollowUp && (
        <CustomTableMain
          data={followUpsTable}
          module="follow ups"
          actionsRight={[
            {
              component: (
                <AddButton
                  name="Add Follow Up"
                  handleClick={() => activeAddOrEditFollowUp()}
                />
              ),
            },
          ]}
        />
      )}

      {showAddEditFollowUp && (
        <div className="itp-smartCampaigns__step-5__addEdit-follow-up">
          <button onClick={backToTable} className="btn-light mb-6">
            Back To Follow Ups Table
          </button>

          <Row className="itp-container-label-select itp-ai-followup__addSchedle-container">
            <Col
              xs={5}
              sm={5}
              md={5}
              lg={2}
              xl={2}
              xxl={2}
              className="itp-label-input-col"
            >
              <label className="itp-label-input__label mt-3">Interval</label>
            </Col>
            <Col
              xs={7}
              sm={7}
              md={7}
              lg={10}
              xl={10}
              xxl={10}
              className="itp-label-input-col"
            >
              <div className="d-flex gap-2 align-items-center">
                <select
                  className="form-select-itp"
                  name="interval"
                  value={form.interval}
                  onChange={handleChangeForm}
                >
                  <option value="" disabled>
                    Select Option
                  </option>
                  {intervalNumberOptions.map((number) => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
                </select>
                <select
                  className="form-select-itp"
                  name="interval_type"
                  value={form.interval_type}
                  onChange={handleChangeForm}
                >
                  <option value="" disabled>
                    Select Option
                  </option>
                  <option value="minute">Minute</option>
                  <option value="hour">Hour</option>
                  <option value="day">Day</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                </select>
              </div>
            </Col>
          </Row>

          <LabelInput label="Message">
            <input
              type="text"
              placeholder=": Enter a follow up message"
              className="form-control-itp itp-label-input__input"
              name="message"
              value={form.message}
              onChange={handleChangeForm}
              disabled={disabledInputMessage}
            />
          </LabelInput>

          <LabelSelect label="Use AI">
            <input
              type="checkbox"
              className="form-check-input"
              name="use_ai"
              checked={form.use_ai}
              onChange={handleChangeUseAI}
              style={{ border: "1px solid rgba(0, 0, 0, 0.4)" }}
            />
          </LabelSelect>

          <button
            className="btn-primary mb-"
            onClick={addOrEditAIFollowUp}
            disabled={!form.interval || !form.interval_type || (!form.use_ai && !form.message)}
          >
            {form?.pk ? "Edit" : "Add"}
          </button>
        </div>
      )}
    </>
  );
};

export default Step5;
