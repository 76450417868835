import petitionGet from "../../../../../services/petitionGet";
import downloadCSV from "../../../../../utils/downloadCSV";

const exportToCssService = async (query, campaign_id, setLoadingModal, closeModal) => {
  try {
    setLoadingModal(true);
    const parameter = query ? `?${query.slice(1)}` : "";

    const { data: result } = await petitionGet("exportToCsvContactsSmartCampaignsLogs", { parameter, campaign_id });

    const fileName = "contacts.csv"
    const dataCSV = result.result
    downloadCSV(dataCSV, fileName);
    closeModal();
  } catch (error) {
    console.log(error);
    setLoadingModal(false);
  }
};

export default exportToCssService;
