import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { LoadingButton } from "../index";
import petitionGet from "../../services/petitionGet";
import AsyncSelect from "react-select/async";

const ModalContactList = ({
  modal,
  closeModal,
  loading,
  modalFunction,
  title,
  noSaveButton,
}) => {
  const [valueContactList, setValueContactList] = useState(null)

  const loadOptions = (inputValue, callback) => {
    petitionGet("contactList", { parameter: `?name=${inputValue}` })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.list_name} (${element.contact_count})`;
          element.value = element.pk;
        });

        if (!valueContactList) {
          setValueContactList(result.result[0]);
        }
        callback(result.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>{title ? title : "Contact List"}</ModalHeader>
      <ModalBody>
        <label className="itp-add-contact-manually-label">Select Contact List</label>
        <AsyncSelect
        onChange={(e) => {
          setValueContactList(e);
        }}
        loadOptions={loadOptions}
        className="basic-single itp-add-contact-manually-select"
        classNamePrefix="select"
        value={valueContactList}
        isSearchable={true}
        name="contact_list_id"
        placeholder="Select Option"
        defaultOptions
        cacheOptions
      />
      </ModalBody>
      <ModalFooter>
        <button disabled={loading} className="btn-light" onClick={closeModal}>
          No
        </button>
        {!noSaveButton && (
          <button
            disabled={loading}
            className="btn-primary loading"
            onClick={() => modalFunction(valueContactList)}
          >
            {loading && <LoadingButton />}
            Yes
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ModalContactList;
