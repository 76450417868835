import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { itemsMenu, secondItemsMenu } from "../../const/itemsMenu";
import { useMenu, useMenuDispatch } from "../../contexts/MenuContext";
import petitionGet from "../../services/petitionGet";
import voiceLogo from "../../assets/images/Asset 2.png"
import json from "../../config.json";
import "./Sidebar.css";

function Sidebar({ items, navigate, refSidebar, toggleMenu }) {
  let path = window.location.pathname;
  path = path.split("/");
  path = path.length < 2 ? path[path.length - 1] : path[1]

  const myExtension = useSelector((state) => state.myExtension.userDetails);

  const token = JSON.parse(localStorage.getItem("userDetails"))?.access_token;
  const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;
  const timeZone = JSON.parse(localStorage.getItem("userDetails"))?.time_zone

  const servicesUserObjects = JSON.parse(localStorage.getItem("userDetails"))?.services_user_objects
  const userObject = servicesUserObjects[id]

  const privLevel = userObject?.priv_level
  const userId = userObject?.pk

  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [myInbox, setMyInbox] = useState({});
  const [currentItems, setCurrentItems] = useState(items);
  const activeNumber =
    window.location.search && window.location.search.slice(1, 7) === "number"
      ? window.location.search.slice(8)
      : "";

  const activeMyInbox =
    window.location.search && window.location.search.slice(1, 8) === "myInbox"
      ? true
      : false;

  const currentMenu = useMenu();
  const dispatchMenu = useMenuDispatch();

  const returnNumber = (number) => {
    let firstThreeNumbers = number.slice(2, 5);
    let secondThreNumbers = number.slice(5, 8);
    let lastFourtNumbers = number.slice(8);

    let formatedNumber = `+1 (${firstThreeNumbers}) ${secondThreNumbers}-${lastFourtNumbers}`;

    return formatedNumber;
  };

  const handleUserSettingsClick = () => {
    if (currentMenu === "default") {
      dispatchMenu({ type: "SET_USER_SETTINGS_MENU" });
    } else {
      dispatchMenu({ type: "SET_DEFAULT_MENU" });
    }
  };

  const handleTextoClick = () => {
    // Definir la URL a la que quieres redirigir
    const url = json.versionInfo.url;

    // Abrir la URL en una nueva pestaña
    window.open(url, '_blank');
  };

  const petition = async () => {
    await petitionGet("assignedNumbers")
      .then(({ data: result }) => {
        setPhoneNumbers(result.result);
        console.log("phoneNumbers", result.result)
      })
      .catch((error) => console.log(error));
  };

  const redirectToDashoard = () => {
    if (path !== "Dashboard") {
      dispatchMenu({ type: "SET_DEFAULT_MENU" });
      navigate("/Dashboard");
    }
  }

  const showSubItem = (element) => {
    if ((!element.prod || (element.prod && !json.prod)) && (!element.prepaid || (element.prepaid && myExtension?.voice_account?.prepaid_usage)) && (!element.key_permission || (element?.key_permission && myExtension[element?.key_permission]) || myExtension.priv_level === "admin")) {
      return true
    } else {
      return false
    }
  }

  const showItem = (element) => {
    if (!element.isTitle && (!element.prepaid || (element.prepaid && myExtension?.voice_account?.prepaid_usage)) && !element.inboxes && (!element.prod || (element.prod && !json.prod)) && (!element.validate || (element.validate && myExtension?.priv_level === "admin")) && (!element.key_permission || (element?.key_permission && myExtension[element?.key_permission]) || myExtension.priv_level === "admin")) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    setMyInbox(myExtension);
  }, [myExtension]);

  useEffect(() => {
    if (currentMenu === "default") {
      setCurrentItems(itemsMenu);

      // Call the petition function here to populate inboxes.
      setMyInbox(myExtension);
      petition();
    } else {
      setCurrentItems(secondItemsMenu);

      // If you wish, you can clear the inboxes here when not in the default menu.
      setMyInbox(null);
      setPhoneNumbers([]);
    }
  }, [currentMenu]);

  useEffect(() => {
    console.log(userObject)
    let isUrlSecondMenu = false;

    for (let i = 0; i < secondItemsMenu.length; i++) {
      if (secondItemsMenu[i]?.url === path) {
        isUrlSecondMenu= true
        break;
      }

      for (let j = 0; j < secondItemsMenu[i]?.items?.length; j++) {
        if (secondItemsMenu[i]?.items[j]?.url === path) {
          isUrlSecondMenu = true;
          break;
        }
      }

      if (isUrlSecondMenu) {
        break;
      }
    }

    if (isUrlSecondMenu) {
      dispatchMenu({ type: "SET_USER_SETTINGS_MENU" });
    }
  }, []);

  return (
    <div
      ref={refSidebar}
      className={`itp-sidebar${toggleMenu ? " hidden" : ""}`}
    >
      <div className="itp-sidebar-container-before">
        <Col className="itp-sidebar-containter">
          <div onClick={redirectToDashoard} className="itp-logo cursor-pointer">
            <img src={voiceLogo} alt="Voice360" className="itp-logo-img" />
          </div>
          {myExtension && Object.keys(myExtension).length > 0 && (
            <>
              {currentItems.map((element, i) => (
                <Fragment key={i}>
                {element.isTitle &&
                  !element.inboxes &&
                  // (!element.validate ||
                  //   (element.validate &&
                  //     myExtension &&
                  //     myExtension?.priv_level === "admin")) && 
                      (
                    <div>
                      <p
                        className={`itp-sidebar-title-section${element.start === true ? "" : " no-start"
                          }`}
                      >
                        {element.title}
                      </p>
                      {element.items.map((elementTwo) => (
                        <Fragment key={elementTwo.name}>
                          {showSubItem(elementTwo) && (
                              <Link
                                className="itp-sidebar-items"
                                to={`${elementTwo.url}${elementTwo.param || ""}`}
                              >
                                <Row
                                  style={elementTwo.style || {}}
                                  className={`itp-sidebar-row-item${elementTwo.url === path ? " active" : ""
                                    }`}
                                >
                                  <Col xl={2} className="itp-sidebar-icon">
                                    {elementTwo.icon}
                                  </Col>
                                  <Col className="itp-sidebar-col-span">
                
                                    <span className="itp-sidebar-span">
                                      {elementTwo.name}
                                    </span>
                                  </Col>
                                </Row>
                              </Link>
                            )}
                        </Fragment>
                      ))}
                    </div>
                  )}
                
                {showItem(element) && (
                    <Link
                      to={`${element.url}${element.param || ""}`}
                      className="itp-sidebar-items"
                      onClick={
                        element.name === "Settings"
                          ? handleUserSettingsClick
                          : () => { }
                      }
                    >
                      <Row
                        style={element.style || {}}
                        className={`itp-sidebar-row-item${path === element.url ? " active" : ""
                          }`}
                      >
                        <Col
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          className="itp-sidebar-icon"
                        >
                          {element.icon}
                        </Col>
                        <Col className="itp-sidebar-col-span">
                          <span className="itp-sidebar-span">{element.name}</span>
                        </Col>
                      </Row>
                    </Link>
                  )}
                </Fragment>
              ))}
            </>
          )}
          <Col className="itp-sidebar-inboxes">
            {myExtension && Object.keys(myExtension).length > 0 && myInbox && currentMenu === "default" && (
            <p className="itp-sidebar-inboxes-title">Inboxes</p>
            )}

            {myExtension && Object.keys(myExtension).length > 0 && myInbox && currentMenu === "default" && (
              <Link to={`My-Inbox?myInbox=true`} className="itp-sidebar-items">
                <Row
                  className={`itp-sidebar-container-notification ${activeMyInbox ? "active" : ""
                    }`}
                >
                  <Col
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    className="itp-sidebar-container-circle"
                  >
                    <div className="circle">M</div>
                  </Col>
                  <Col
                    xs={9}
                    sm={9}
                    md={9}
                    lg={9}
                    xl={9}
                    className="container-inboxes-data"
                  >
                    <p className="itp-sidebar-inboxes-subtitle">My Inbox</p>
                    <p className="itp-sidebar-inboxes-number">
                      {myInbox?.presence_id}
                    </p>
                  </Col>

                </Row>
              </Link>
            )}

            {myExtension && Object.keys(myExtension).length > 0 && currentMenu === "default" && (
              <>
              {phoneNumbers.map((element) => (
              <Link
                // to={`Conversations?number=${element.number}`}
                // to={`/Conversations?phone=${element.number}&token=${token}&id=${id}&timeZone=${timeZone}&privLevel=${privLevel}&userId=${userId}`}
                to={`/Conversations?phone=${element.number}`}
                key={element.number}
                className="itp-sidebar-items"
              >
                <Row
                  className={`itp-sidebar-container-notification ${activeNumber === element.number ? "active" : ""
                    }`}
                >
                  <Col
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    className="itp-sidebar-container-circle"
                  >
                    <div className="circle">P</div>

                  </Col>
                  <Col
                    xs={9}
                    sm={9}
                    md={9}
                    lg={9}
                    xl={9}
                    className="container-inboxes-data"
                  >
                    <p className="itp-sidebar-inboxes-subtitle">{element?.description || "Phone Number"}</p>
                    <p className="itp-sidebar-inboxes-number">
                      {returnNumber(element.number)}
                    </p>
                  </Col>

                </Row>
              </Link>
            ))}
              </>
            )}
          </Col>
        </Col>
      </div>
      <div onClick={handleTextoClick} className="version-property cursor-pointer">
        <p style={{ fontSize: "10px", marginBottom: "12px" }}>
          Version:{json.version}
        </p>
      </div>
    </div>
  );
}

export default Sidebar;
