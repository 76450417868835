import { markReadService, messagesService, threadListService } from "../index";
import { replaceAndMergeArrays, scrollToBottom } from "../../utils";
import json from "../../../../../../config.json";

const webSocketService = (setMessage, setVariant, setShowReconnectModal, chatList, chatWindowData, forceUpdate, messagesContainerRef, rindingMessage, chatListPagination, number, myExtension, setMessages, setShowAlertForNewMessage, sound, selectParameter) => {
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;

  let socket;
  let retryTimes = 0;
  const maxRetryTimes = 14;

  const newMessageProcess = async () => {
    try {
      await markReadService(chatWindowData, number);

      const chat = chatWindowData.current
      const timeZone = myExtension.current.time_zone
      const newMessages = await messagesService(chat, number, false, "", timeZone);

      setMessages(newMessages);
      forceUpdate();
      scrollToBottom(messagesContainerRef)

      const parameter = "?offset=0&limit=15" + selectParameter.current
      const newChatList = await threadListService(number, parameter);
      chatList.current = newChatList;
      forceUpdate();
      chatListPagination.current = {
        offset: 0,
        limit: 15,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const shouldPlayRingingMessage = (repeatSound, to_numbers_list) => {
    const chatListExists = chatList.current;
    const chatWindowNotCurrent = !chatWindowData.current;
    const chatWindowDifferentNumber = chatWindowData.current && chatWindowData.current.numberChat !== to_numbers_list[0];

    return sound && !repeatSound && chatListExists && (chatWindowNotCurrent || chatWindowDifferentNumber)
  };

  const initializeWebSocket = () => {
    const urlWebSocket = json.prod ? json.webSocket.prod : json.webSocket.dev;

    // Close existing connection if it exists
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.close();
    }

    // Initialize new connection
    socket = new WebSocket(`${urlWebSocket}`);
    attachEventHandlers();
  };

  // Function to attach event handlers to the socket
  const attachEventHandlers = async () => {
    socket.onopen = onOpen;
    socket.onmessage = onMessage;
    socket.onclose = onClose;
    socket.onerror = onError;
  };

  const onOpen = (e) => {
    if (!json.prod) {
      console.log("on Open", e);
      console.log(socket);
    }

    if (socket.readyState === WebSocket.OPEN) {
      retryTimes = 0; // Reset reconnection attempts
      socket.send(
        JSON.stringify({
          action: "login",
          payload: {
            jwt_token: token.replace(/['"]+/g, ""),
            account_id: id,
            phone_number: number,
          },
        })
      );
    }
  };

  const onMessage = async (e) => {
    retryTimes = 0;
    setMessage("");
    setVariant("");
    setShowReconnectModal(false);
    forceUpdate();

    let repeatSound = false; //Repeat sound play
    let payload;
    let result = JSON.parse(e.data);
    if (result.payload !== "Logged in" && typeof result !== "string") {
      payload = JSON.parse(result?.payload);
    }

    if (!json.prod) {
      console.log("-----------------websocket-messages-----------------------");
      console.log(payload);
    }

    const { message_type } = result || {};
    const { user_id, message_thread_pk, from_number, message_provider_id, to_numbers_list } = payload || {};
    const { fromNumber, numberChat } = chatWindowData.current || {};
    const { message_thread_id } = chatWindowData.current?.notIsSelf || {};

    if ((message_type === "sms" || message_type === "sms-status") && user_id === myExtension.current?.pk) {
      if (parseInt(message_thread_id) === message_thread_pk) {
        chatList.current.forEach(async (element) => {
          const pk = element.pk;

          //If you recive a message
          if (pk === message_thread_pk && message_thread_id) {
            await newMessageProcess();
          }

          //If you send a message
          if (fromNumber === from_number && from_number !== numberChat && message_provider_id && message_thread_id) {
            await newMessageProcess();
          }
        });
      } else {
        try {
          const parameter = "?offset=0&limit=15" + selectParameter.current
          const moreChatList = await threadListService(number, parameter);
          const newChatList = replaceAndMergeArrays(chatList.current, moreChatList);
          chatList.current = newChatList;

          setShowAlertForNewMessage(message_thread_pk);
          forceUpdate();

          if (shouldPlayRingingMessage(repeatSound, to_numbers_list)) {
            // rindingMessage.play();
          }

          repeatSound = true;
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const onClose = (e) => {
    if (!json.prod) {
      console.log("Conexión cerrada, intentando reconectar...", e, number);
    }
    attemptReconnection("close", number);
  };

  const onError = (e) => {
    if (!json.prod) {
      console.log("Error en WebSocket, intentando reconectar...", e);
    }
    attemptReconnection();
  };

  // Controlled reconnection attempt
  const attemptReconnection = (key, numerTemp) => {
    if (retryTimes > maxRetryTimes) {
      // Show modal upon reaching maximum reconnection time
      setMessage("Disconnected");
      setVariant("danger");
      setShowReconnectModal(true);
      forceUpdate();
      return; // Stop automatic reconnection
    }

    if (key !== "close" || (key === "close" && numerTemp && window.location.search.split("=")[1] && numerTemp === window.location.search.split("=")[1])) {
      retryTimes = retryTimes + 1;
      initializeWebSocket();
    }
  };

  // Start the connection for the first time
  initializeWebSocket();

  // Inside webSocketService, at the end of the function
  return {
    closeConnection: () => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.close();
      }
    },
  };
};

export default webSocketService;