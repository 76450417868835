import convertBlobToBase64 from "../../../../../services/convertBlobToBase64"
import petitionGet from "../../../../../services/petitionGet"

const downloadBase64Audio = async (element, key) => {
  try {
    const unique_id = key ? element[key] : element.unique_id
    const response = await petitionGet("downloadQueueMemberLogs", { unique_id }, false, "blob")

    if (response?.status === 200 && response?.data) {
      const blob = new Blob([response.data])
      const base64Audio = await convertBlobToBase64(blob)

      return {
        ...response,
        data: {
          data: response.data,
          result: base64Audio,
          contentType: "audio/mp3",
        },
      };
    } else {
      return { status: "error" }
    }
  } catch (error) {
    console.log(error)
    return { status: "error" }
  }
}

export default downloadBase64Audio;