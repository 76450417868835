import { capitalizeWords } from "../../../../../../utils/capitalizeWords";

const selectTitleModule = (info) => {
  if (info.isFirst === true) return info?.module;

  if (info?.module === "externaltransfer") return "External Transfer";

  if (info?.module === "send_sms") return "Send SMS";

  return capitalizeWords(info?.module);
}

export default selectTitleModule;