import { defaultVariables } from "../../../../../../const/defaultVariables";
import petitionGet from "../../../../../../services/petitionGet";

const fetchCustomPromptsOptions = async () => {
  const { data: result } = await petitionGet("customFieldAdmin");

  const customFieldsOptions = result.result.map((element) => {
    return { label: element.name, value: `{{contact.${element.name}}}` };
  });

  const variables = defaultVariables.map((element) => {
    if (element.title === "Contact") {
      element.options = [...element.options, ...customFieldsOptions]
    }

    return element
  })

  const customPromptsOptions = [
    {
      title: "Variables",
      options: [...variables],
      dropdownOpen: false,
    },
  ];

  return customPromptsOptions
};

export default fetchCustomPromptsOptions;
