import { InputsMenu } from "../../../../../../components";
import Skeleton from "react-loading-skeleton";
import Switch from "react-switch";

const ActiveEditIntegration = ({ 
  loading, 
  enabledIntegration, 
  handleChangeActiveIntegration,
  form,
  inputs,
  handleChangeForm,
  loadingButton,
  saveFunction,
  loadingEnabledIntegration,
 }) => {
  return (
    <>
      <div className="itp-ai__ai-agent-fields space-field">
        <div className="itp-ai__ai-agent-field">
          <label className="itp-edit-number-label-1">
            Enabled
          </label>
          <label className="itp-edit-number-label-2">
            Ability to enable or disable integration features
          </label>
        </div>

        <div className="itp-ai__ai-agent-container-input">
          {loading ? (
            <Skeleton width="5rem" height="2rem" />
          ) : (
            <Switch
              className="itp-custom-table-switch mt-3"
              onColor="#626ed4"
              height={20} // Ajusta la altura según tus necesidades
              width={40}
              checked={enabledIntegration}
              onChange={handleChangeActiveIntegration}
              disabled={loadingEnabledIntegration}
            />
          )}
        </div>
      </div>

      {enabledIntegration && (
        <InputsMenu 
          handleChangeForm={handleChangeForm}
          inputs={inputs} 
          loading={loading} 
          form={form} 
          loadingButton={loadingButton} 
          saveFunction={saveFunction} 
          className="mt-3"
        />
      )}
    </>
  );
};

export default ActiveEditIntegration;
