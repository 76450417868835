import { convertUtcDateToTimeZoneDate, formatDate } from "../../../../../../utils/getDate";
import petitionGet from "../../../../../../services/petitionGet";

const fetchFollowupMessagesScheduled = async (threadID, number, timeZone) => {
  const { data: result } = await petitionGet("followupTasks", { threadID, number })
  result.result.forEach((element) => {
    const scheduledDateInTimeZone = convertUtcDateToTimeZoneDate(element.scheduled_date, timeZone)
    element.scheduledDateFormated = formatDate(scheduledDateInTimeZone)
  })

  return result.result
}

export default fetchFollowupMessagesScheduled;