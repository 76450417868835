import petitionGet from "../../../../../../services/petitionGet";

const fetchPersonalities = async (element) => {
  const { data: result } = await petitionGet("personalities")
  const personalities = result.result

  personalities.forEach((el) => {
    el.label = el.name
    el.value = el.id
  })

  const personality = personalities.filter((el) => el.id === element?.personality_id)

  return { personalities, personality }
}

export default fetchPersonalities;