import { formatData, formatTime } from "../../utils";
import petitionPost from "../../../../../services/petitionPost";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";

const numberReportService = async (selectedNumbers, timeframe, timeZone, setTotalCalls, setXLabel, setYLabel) => {
  const data = { numbers: selectedNumbers.map((number) => number.number)};

  const timeStart = timeframe.timeStart
  const timeEnd = timeframe.timeEnd

  const newTimeStart = new Date(timeStart);
  const newTimeEnd = new Date(
    parseInt(timeEnd.split("-")[0]),
    parseInt(timeEnd.split("-")[1]) - 1,
    parseInt(timeEnd.split("-")[2]),
    23,
    59,
    59
  );

  const parameter = `?start_date=${newTimeStart.getTime() / 1000}&end_date=${newTimeEnd.getTime() / 1000}`;

  const { data: res } = await petitionPost("numberCallReports", { data, parameter })

  const newArrayRecords = res.result.numbers_report.map((element) => {
    element.duration = formatTime(element.duration);
    element.avg_duration = formatTime(element.avg_duration);
    element.formatedNumber = formatPhoneNumber(element.number)

    return element;
  });

  const formattedReport = formatData(res.result.per_hour_report, timeZone );

  let ObjectCalls = [];
  let objectHours = Object.keys(formattedReport);

  Object.keys(formattedReport).forEach((element) => {
    ObjectCalls.push(formattedReport[element]);
  });

  setTotalCalls(res.result.summary.total_calls);
  setXLabel(objectHours);
  setYLabel(ObjectCalls);

  return newArrayRecords
};

export default numberReportService;
