// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-textToSpeach__text-area {
  width: 100%;
  border: 1px solid #ccc;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/WorkspaceSettings/GeneralSettings/MediaAudioFile/components/TextToSpeach/TextToSpeach.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,sBAAsB;AACxB","sourcesContent":[".itp-textToSpeach__text-area {\n  width: 100%;\n  border: 1px solid #ccc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
