import React, { useEffect, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { logoutRequest } from "../../redux/actions/login";
import { Search } from "../index";
import { useMenu, useMenuDispatch } from "../../contexts/MenuContext";
import { useDynamicFunction } from "../../contexts/DynamicFunctionContext";
import phoneIcon from "../../assets/images/phoneIcon.svg";
import checkListIcon from "../../assets/images/checkListIcon.svg";
import calendarIcon from "../../assets/images/calendarIcon.svg";
import arrow from "../../assets/images/arrow.svg";
import json from "../../config.json";
import arrowleft from "../../assets/icons/arrow-left.svg";
import clock from "../../assets/icons/clock.svg";
import "./Header.css";

const Header = ({ refHEader, handleToggle, toggleMenu, setShowWebphone }) => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path.length < 2 ? path[path.length - 1] : path[1];

  const currentMenu = useMenu();
  const dispatch = useDispatch();
  const dispatchMenu = useMenuDispatch();
  const navigate = useNavigate();

  const { dynamicFunction, isArrowVisible } = useDynamicFunction();

  const myExtension = useSelector((state) => state.myExtension.userDetails);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isPrepaidEnabled, setIsPrepaidEnabled] = useState(myExtension?.voice_account?.prepaid_usage || false);
  const [prepaidBalance, setPrepaidBalance] = useState(myExtension?.voice_account?.prepaid_balance || false);

  const dataLocalStorage = JSON.parse(localStorage.getItem("userDetails"));

  const urlInWorkspace = [
    "General-Settings",
    "Billing",
    "API-Keys",
    "Webhooks",
    "10dlc",
    "Advanced-Call-flows",
    "Users",
    "Live-Panel-Settings",
    "Troubleshooting",
    "AI",
  ];

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const onLogout = () => {
    dispatch(logoutRequest());
  };

  const onProfile = () => {
    const access_token = dataLocalStorage.access_token;

    const linkPortal = json.prod ? json.portal.prod : json.portal.dev;
    window.location.href = linkPortal + `?access_token=${access_token}`;
  };

  const defaultFunction = () => {
    dispatchMenu({ type: "SET_DEFAULT_MENU" });
    navigate("/Dashboard");
  };

  const handleBackClick = () => {
    if (dynamicFunction) {
      dynamicFunction();
    } else {
      defaultFunction();
    }
  };

  const handleClickClockIcon = () => {
    if (path !== "Call-History") {
      dispatchMenu({ type: "SET_DEFAULT_MENU" });
      navigate("/Call-History");
    }
  };

  const redirectToCalendarPage = () => {
    dispatchMenu({ type: "SET_USER_SETTINGS_MENU" });
    navigate("/My-Settings?redirected=true");
  };

  const redirectToBilling = () => {
    dispatchMenu({ type: "SET_USER_SETTINGS_MENU" });
    navigate("/Billing");
  };

  const displayStyle = { display: isArrowVisible || currentMenu === "userSettings" ? "block" : "none"  };

  useEffect(() => {
    setIsPrepaidEnabled(myExtension?.voice_account?.prepaid_usage);
    setPrepaidBalance(myExtension?.voice_account?.prepaid_balance);
  }, [myExtension?.voice_account?.prepaid_usage, myExtension?.voice_account?.prepaid_balance]);

  return (
    <header ref={refHEader} className="itp-header">
      <div className="itp-content">
        <div className="itp-header-bar"></div>

        <div className="itp-left-section">
          <div
            onClick={handleBackClick}
            className="itp-tex-back-container"
            style={displayStyle}
          >
            <div className="itp-text-container-funnel-details">
              <img src={arrowleft} alt="arrow-left" />
            </div>
          </div>
          <div className="itp-text">
            {urlInWorkspace.includes(path)
              ? `workspace/${
                  currentMenu !== "default" && path === "Live-Panel-Settings"
                    ? "Live Panel"
                    : path.replaceAll("-", " ")
                }`
              : path.replaceAll("-", " ")}
          </div>
        </div>
        <div className="itp-profile">
          <div className="itp-search">
            {/* <input type="text" placeholder="Buscar..." />
                        <button type="button">Buscar</button> */}
            <Search placeholder="" disabled />
          </div>
          <div className="itp-header-bar"></div>
          <div className="itp-icons">
            <span
              onClick={handleToggle}
              className={`itp-icon menu${toggleMenu ? "" : " hidden"}`}
            >
              <img src={arrow} alt="Voice360" />
            </span>

            <div className="itp-header-container-clock-icon">
              <span
                className="itp-icon"
                onClick={() => setShowWebphone((prev) => !prev)}
              >
                <img src={phoneIcon} alt="Voice360" />
              </span>
              <div className="itp-header-click-icon-hover">
                <span className="itp-header-click-icon-name">Phone</span>
              </div>
            </div>

            <div className="itp-header-container-clock-icon">
              <span onClick={handleClickClockIcon} className="itp-icon">
                <img src={clock} alt="Clock" />
              </span>
              <div className="itp-header-click-icon-hover">
                <span className="itp-header-click-icon-name">Call History</span>
              </div>
            </div>

            {!json.prod && (
              <>
                <div className="itp-header-container-clock-icon">
                  <span className="itp-icon">
                    <img src={checkListIcon} alt="Voice360" />
                  </span>
                  <div className="itp-header-click-icon-hover">
                    <span className="itp-header-click-icon-name">Tasks</span>
                  </div>
                </div>
              </>
            )}

            <div className="itp-header-container-clock-icon">
              <span onClick={redirectToCalendarPage} className="itp-icon">
                <img src={calendarIcon} alt="Voice360" />
              </span>
              <div className="itp-header-click-icon-hover">
                <span className="itp-header-click-icon-name">Calendar</span>
              </div>
            </div>

            {isPrepaidEnabled && (
              <div
                onClick={redirectToBilling}
                className="itp-header-balance-container cursor-pointer"
              >
                <label className="itp-header-balance-text">Balance</label>
                <div>
                  <span className="itp-header-balance-number">
                    ${prepaidBalance}{" "}
                  </span>
                  <span className="itp-header-balance-usd">USD</span>
                </div>
              </div>
            )}
          </div>
          <div className="itp-header-bar"></div>
          <Dropdown
            as="li"
            className="nav-item dropdown header-profile"
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
          >
            <DropdownToggle
              variant=""
              as="a"
              className="nav-link i-false c-pointer"
              role="button"
              data-toggle="dropdown"
            >
              <div className="d-flex">
                <div className="itp-header__toggle-dropdown">
                  <FontAwesomeIcon
                    className="icon-hover"
                    icon={faUser}
                    style={{ color: "#6D28D9", fontSize: "20px" }}
                  />
                </div>
              </div>
            </DropdownToggle>
            <DropdownMenu
              align="right"
              className="mt-3  mt-lg-0 dropdown-menu dropdown-menu-end"
            >
              <Link
                to="#"
                onClick={onProfile}
                className="dropdown-item ai-icon"
              >
                <svg
                  id="icon-user1"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-primary me-1"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                  <circle cx={12} cy={7} r={4} />
                </svg>
                <span className="ms-2">Profile </span>
              </Link>

              <Link className="dropdown-item ai-icon" onClick={onLogout}>
                <svg
                  id="icon-logout"
                  xmlns="http://www.w3.org/2000/svg"
                  className="me-1"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{ color: "rgb(13, 110, 253)" }}
                >
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                  <polyline points="16 17 21 12 16 7" />
                  <line x1={21} y1={12} x2={9} y2={12} />
                </svg>
                <span className="ms-2">Logout </span>
              </Link>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </header>
  );
};

export default Header;
