const createNewChat = (result, dataNumber, number) => {
  const newChat = {
    ai_enabled: result?.result[0]?.ai_enabled === 1 ? true : false,
    last_message: result?.result[0]?.last_message || "Send a new message",
    last_updated: result?.result[0]?.last_updated?.replaceAll("T", " ") || "",
    pk: result?.result[0]?.message_thread_id || "",
    nameContact: typeof dataNumber === "string" ? null : `${dataNumber?.firstname} ${dataNumber?.lastname || ""}`,
    numberChat: typeof dataNumber === "string" ? dataNumber : dataNumber?.phone,
    isContact: typeof dataNumber === "string" ? false : true,
    firstname: typeof dataNumber === "string" ? null : dataNumber?.firstname,
    lastname: typeof dataNumber === "string" ? null : dataNumber?.lastname || "",
    notIsSelf: {
      message_thread_id: result?.result[0]?.message_thread_id || "",
      addedFronEnd: true,
      contact: typeof dataNumber === "string" ? null : { ...dataNumber, firstname: dataNumber?.firstname, lastname: dataNumber?.lastname || "",},
    },
    participants: [
      {
        is_self: false,
        message_thread_id: result?.result[0]?.message_thread_id?.toString() || "",
        number: typeof dataNumber === "string" ? dataNumber : dataNumber?.phone,
        pk: "",
        contact: dataNumber === "string" ? null : { ...dataNumber },
      },
      {
        is_self: true,
        message_thread_id: result?.result[0]?.message_thread_id?.toString() || "",
        number: number,
        pk: "",
      },
    ],
    thread_read: true,
  };

  return newChat;
};

export default createNewChat;
