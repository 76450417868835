import { useState } from "react";
import { Col, Row } from "reactstrap";
import { LoadingButton } from "../../../../../../../components";
import "./TextToSpeach.css"

const TextToSpeach = ({ loadingButton, addTextToSpeachFile }) => {
  const [name, setName] = useState("")
  const [form, setForm] = useState({
    language: "en",
    gender: "",
    tts_text: "",
  })

  const handleChangeForm = (e) => {
    const { name, value } = e.target
    setForm({ ...form, [name]: value })
  }

  const handleChangeName = (e) => {
    const { value } = e.target
    setName(value)
  }

  const handleSave = () => {
    if (loadingButton);
    addTextToSpeachFile(name, form)
  }

  return (
    <>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Name</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter Name"
            className="form-control-itp itp-label-input__input"
            name="name"
            id="name"
            value={name}
            onChange={handleChangeName}
          />
        </Col>
      </Row>

      <Row className="itp-container-label-select">
        <Col
          xs={6}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          xxl={6}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label itp-analytics-label-select">
            Gender
          </label>
        </Col>
        <Col
          xs={6}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          xxl={6}
          className="itp-label-input-col"
        >
          <select
            className="form-select-itp"
            onChange={handleChangeForm}
            name="gender"
            id="gender"
            value={form.gender}
          >
            <option value="" disabled>
              Select Option
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </Col>
      </Row>

      <label className="itp-label-input__label itp-analytics-label-select">
        Text
      </label>
      <textarea
        rows="10"
        style={{ width: "100%", border: "1px solid #ccc" }}
        value={form.tts_text}
        name="tts_text"
        onChange={handleChangeForm}
        className="itp-textToSpeach__text-area"
      />

      <button
        onClick={handleSave}
        className="btn-primary itp-lead-detail-button-save loading mb-4"
        disabled={!form.gender || !form.tts_text || !name}
      >
        {loadingButton && <LoadingButton />}
        Save
      </button>
    </>
  );
};

export default TextToSpeach;
