import React from "react";
import { Spinner } from "reactstrap";

const LoadingButton = ({ className, spinnerStyles = {} }) => {
  return (
    <div className={`d-flex justify-content-center align-items-center ${className || ""}`}>
      <Spinner
        style={{ marginRight: "0.5em", ...spinnerStyles }}
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    </div>
  );
};

export default LoadingButton;
