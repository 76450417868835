const ConfirmAction = ({ allResult, valueLog, backStep, nextStep }) => (
  <>
    <p>
      This action will be applied to the following contacts:
      {allResult.item_count}, which are currently in this status:{" "}
      {valueLog ? valueLog : "all logs"}
    </p>
    <div className="d-flex gap-2 mt-4">
      <button className="btn-light" onClick={backStep}>
        Close
      </button>

      <button className="btn-primary loading" onClick={() => nextStep()}>
        Next
      </button>
    </div>
  </>
);

export default ConfirmAction;
