import petitionGet from "../../../../../../services/petitionGet";
import { returnFormInputIntegration } from "../../utils";

const fetchCustomerServices = async (setEnabledIntegration, element, setLoadingMenu, setForm, setInputs, setClickedItem) => {
  const { data: result } = await petitionGet("customerIntegrations")

  const { formTemp, inputsTemp } = returnFormInputIntegration(element, result.result)

  const isActive = result.result.find((el) => el.integration_id === element.pk)

  setForm(formTemp)
  setInputs(inputsTemp)

  if (isActive) {
    setClickedItem({ ...element, customer_integration_id: isActive.pk })
    setEnabledIntegration(true)
  } else {
    setClickedItem(element)
  }
  
  setLoadingMenu(false)
}

export default fetchCustomerServices;