import petitionGet from "../../../../../services/petitionGet";
import { processListCallHistory } from "../../utils";

const fetchListCallHistory = async (timeFrame, myExtension) => {
  const parameter = `?start_date=${timeFrame.startDate}&end_date=${timeFrame.endDate}`
  const { data: result } = await petitionGet("callHistory", { parameter })

  const listCallHistory = result?.result?.user_reports[0]
  const allCalls = result?.result?.user_reports[0]?.total_calls

  listCallHistory.all_calls.forEach((call) => {
    processListCallHistory(call, myExtension)
  })

  listCallHistory.incoming_calls.forEach((call) => {
    processListCallHistory(call, myExtension)
  })

  listCallHistory.outgoing_calls.forEach((call) => {
    processListCallHistory(call, myExtension)
  })
    
  return { listCallHistory, allCalls }
}

export default fetchListCallHistory;