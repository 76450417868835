/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

const Directory = ({ saveModule, edit, data, editModule }) => {
  const [form, setForm] = useState({ language: "" })

  const onChangeForm = (e) => {
    setForm({ language: e.target.value })
  }

  const saveLanguage = () => {
    if (edit) {
      editModule(form, "language");
    } else {
      saveModule(form, "language"); 
    }
  };

  useEffect(() => {
    if (data) {
      if (edit) {
        setForm(data.data)
      }
    }
  }, [data]);

  return (
    <>
      <label className="itp-label-input__label mt-4">Language</label>
      <select
        onChange={onChangeForm}
        value={form.language}
        name="language"
        className="form-select-itp"
      >
        <option value="" disabled>
          Select option
        </option>
        <option value="en">English</option>
        <option value="es">Spanish</option>
        <option value="fr">French</option>
      </select>

      <button onClick={saveLanguage} className="btn-primary mt-4">
        Save
      </button>
    </>
  );
};

export default Directory;
