import { capitalizeWords } from "../../../../../../utils/capitalizeWords"

const formatIntegrationName = (name) => {
  if (!name) return ""

  const replacedName = name.replaceAll("_", " ")
  const capitalizedName = capitalizeWords(replacedName).replaceAll("Ai", "AI")

  return capitalizedName
}

export default formatIntegrationName;