// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-upload-file__button {
  color: white !important;
  margin-right: 1rem;
  margin-bottom: 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 8rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/WorkspaceSettings/GeneralSettings/MediaAudioFile/components/UploadFile/UploadFile.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;EAChB,wBAAwB;EACxB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".itp-upload-file__button {\n  color: white !important;\n  margin-right: 1rem;\n  margin-bottom: 0;\n  display: flex !important;\n  justify-content: center;\n  align-items: center;\n  width: 8rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
