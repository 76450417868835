import petitionGet from "../../../../../../services/petitionGet";
import formatPhoneNumber from "../../../../../../utils/formatPhoneNumber";

const selectUsedByCallFlowText = (element) => {
  if (element?.in_use && element?.in_use_by?.internal_use === false) {
    return element?.in_use_by?.name
  } else if (element?.in_use && element?.in_use_by?.internal_use === true) {
    return ""
  } else {
    return "Not assigned!"
  }
}

const selectUsedByCallFlowHover = (element) => {
  if (element?.in_use && element?.in_use_by?.internal_use === false) {
    return ""
  } else if (element?.in_use && element?.in_use_by?.internal_use === true) {
    return ""
  } else {
    return "This number is currently not used in an advanced callflow! Number will not function until this is done"
  }
}

const selectStylesToNotAssignedNumbers = (element) => {
  if (!element?.in_use || !element?.in_use_by || !Object.keys(element?.in_use_by)?.length) {
    return true
  } else {
    return false
  }
}

const fetchNumbers = async () => {
  const { data: result } = await petitionGet("viewNumbers");
  result.result.forEach((element) => {
    element.description = element?.description || "";
    element.statusReal = element.itpvoice_metadata ? element.itpvoice_metadata.state.replace("_", " ") : "null";
    element.formatedNumber = formatPhoneNumber(element.number)
    element.usedByCallFlow = selectUsedByCallFlowText(element)
    element.hoverKey = selectUsedByCallFlowHover(element)
    element.style = !selectStylesToNotAssignedNumbers(element) ? {} : {
      background: "rgba(255, 0, 0, 0.2) 0% 0% no-repeat padding-box",
      borderRadius: "12px",
      font: "normal normal normal 12px/16px Instrument Sans",
      letterSpacing: "0px",
      color: "#FF0000",
      opacity: "1",
    }
  });

  return result.result
};

export default fetchNumbers;
