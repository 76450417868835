import React, { useEffect } from "react";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ModalError from "./ModalError/ModalError";
import togglePlay from "./logic/togglePlay";
import formatTime from "./logic/formatTime";
import useDownloadAudio from "./hooks/useDownloadAudio";
import useAudioRef from "./hooks/useAudioRef";
import { LoadingButton } from "../index";
import "./AudioMessage.css";

const AudioMessage = ({
  audioUrl,
  noFetch,
  downloadWhenClicked,
  customDownload,
  audioType,
  className,
  variant,
  externalFunction,
  transcriptionPreview,
}) => {
  const {
    loadingDownload,
    setStartDownload,
    timeProgress,
    setTimeProgress,
    disabled,
    modal,
    setModal,
    audio,
    errorMessage,
  } = useDownloadAudio(audioUrl, customDownload, downloadWhenClicked, noFetch, audioType);

  const {
    onLoadedMetadata,
    handleProgressChange,
    audioRef,
    progressBarRef,
    isPlaying,
    setIsPlaying,
    duration,
  } = useAudioRef(setTimeProgress);

  const togglePlayFunction = () => {
    togglePlay(downloadWhenClicked, disabled, setStartDownload, isPlaying, setIsPlaying);
  };

  useEffect(() => {
    if (timeProgress === 0 && progressBarRef.current)  progressBarRef.current.value = "0";
  }, [timeProgress, duration, audioRef, progressBarRef]);

  return (
    <>
      {modal && (
        <ModalError
          modal={modal}
          closeModal={() => setModal(false)}
          message={errorMessage}
        />
      )}

      <div className="itp-audio-message-container">
        <div className={`itp-audio-message ${className || ""}`}>
          {!disabled && (
            <audio
              ref={audioRef}
              src={!disabled ? audio : "http://localhost:3000"}
              onLoadedMetadata={onLoadedMetadata}
            />
          )}

          <div onClick={togglePlayFunction} className={`itp-audio-message__play-content ${variant || ""}`} >
            {loadingDownload && (
              <div className="itp-audio-message__play-loading">
                <LoadingButton />
              </div>
            )}

            {!loadingDownload && (
              <>
                {isPlaying && (
                  <PauseIcon
                    className={`itp-audio-message__play-icon ${variant || ""}`}
                  />
                )}

                {!isPlaying && (
                  <PlayArrowIcon
                    className={`itp-audio-message__play-icon ${variant || ""}`}
                  />
                )}
              </>
            )}
          </div>

          <input
            type="range"
            ref={progressBarRef}
            max={duration} // Ajusta el valor máximo al de la duración del audio
            value={timeProgress} // Actualiza el valor con el tiempo actual del audio
            onChange={handleProgressChange}
            disabled={disabled}
            className={`itp-audio-message__input ${variant || ""}`}
          />

          <span className={`itp-audio-message__time-progress ${variant || ""}`}>
            {formatTime(timeProgress)}
          </span>
        </div>

        {transcriptionPreview && (
          <div className="itp-audio-message__transcript">
            <div className={`itp-audio-message__transcript-text ${variant || ""}`}>
              {transcriptionPreview}
            </div>
            <button
              onClick={externalFunction}
              className={`itp-audio-message__transcript-button ${variant || ""}`}
            >
              View Transcript
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default AudioMessage;
