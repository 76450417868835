import isValidJson from "./isValidJson";

const returnCallTranscription = (data) => {
  if (isValidJson(data?.call_transcription)) {
    return { transcription: data?.call_transcription };
  } else {
    return data?.call_transcription;
  }
};

export default returnCallTranscription;
