import { capitalizeWords } from "../../../../../../utils/capitalizeWords";
import petitionGet from "../../../../../../services/petitionGet";

const fetchCustomerIntegrations = async (element) => {
  const { data: result } = await petitionGet("customerIntegrations")
  const integrations = result.result

  integrations.forEach((el) => {
    const replacedName = el?.integration?.name.replaceAll("_", " ")
    const capitalizedName = capitalizeWords(replacedName).replaceAll("Ai", "AI")

    el.name =  capitalizedName
    el.checked = element?.integrations?.integration_ids?.includes(el.pk)
  })

  return integrations
}

export default fetchCustomerIntegrations;