/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { TextAreaVarSelect } from "../../../../../../../components";
import petitionGet from "../../../../../../../services/petitionGet";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";

const SendSMS = ({ saveModule, edit, data, editModule }) => {
  const [loading, setLoading] = useState(true)
  const [numbers, setNumbers] = useState([])
  const [form, setForm] = useState({ 
    from_number: "",
    message_body: "",
  })

  const handleChangeForm = (name, value) => {
    setForm({ ...form, [name]: value })
  }

  const saveSendSMS = () => {
    const formTemp = { ...form, from_number: form.from_number.number }
    if (edit) {
      editModule(formTemp, "send_sms");
    } else {
      saveModule(formTemp, "send_sms"); 
    }
  }

  const petition = async () => {
    try {
      const { data: result } = await petitionGet("viewNumbers");

      result.result.forEach((element) => {
        element.label = element?.number
        element.value = element?.number;
      });

      if (edit) {
        console.log(data)
        const findNumber = result.result.find((number) => number.number === data.data.from_number)
        const formTemp = { ...data.data, from_number: findNumber}
        setForm(formTemp)
      }

      setNumbers(result.result)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (data) petition()
  }, [data]);

  return (
    <>
      <label className="itp-label-input__label mt-4">Number to use</label>
      {loading ? (
        <div className="w-100">
          <Skeleton width="100%" height="2.5rem" />
        </div>
      ) : (
        <Select 
          onChange={(value) => handleChangeForm("from_number", value)}
          className="basic-single"
          classNamePrefix="select"
          value={form.from_number}
          isSearchable={true}
          name="queue"
          placeholder="Select Number"
          options={numbers}
        />
      )}

      <label className="itp-label-input__label mt-4">Message to send</label>
      <TextAreaVarSelect 
        onChangeTextArea={(value) => handleChangeForm("message_body", value)}
        textAreaDefaultValue={form.message_body}
        loading={loading}
      />

      <button 
        onClick={saveSendSMS} 
        className="btn-primary mt-4"
        disabled={!form.message_body || !form.from_number}
      >
        Save
      </button>
    </>
  )
}

export default SendSMS;