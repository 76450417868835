import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalError = ({ modal, closeModal, error, module, text, pre }) => {
  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>{(error || pre) ? "Error" : module ? module : ""}</ModalHeader>
      <ModalBody>{pre ? <pre>{pre}</pre> : error ? error : text ? text : "Error"}</ModalBody>
      <ModalFooter>
        <button className="btn-light" onClick={closeModal}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalError;
