import { useState } from "react";
import { LoadingButton, TextAreaVarSelect } from "../../../../../../../../components";
import Skeleton from "react-loading-skeleton";
import Switch from "react-switch";

const EditPrompVariable = ({
  promptVariable,
  form,
  handleChangeForm,
  editPropmtVariable,
  loadingButton,
  loading,
  customPromptsOptions,
}) => {
  const objSelectField = {
    custom_message_prompt: "Custom Message Prompt",
    custom_call_prompt: "Custom Voice Prompt",
  };

  const [activeSwitch, setActiveSwitchSwitch] = useState(form[promptVariable] ? true : false);

  const handleChangeSwitch = () => {
    if (activeSwitch) {
      handleChangeForm(null, promptVariable)
    }

    setActiveSwitchSwitch(!activeSwitch);
  };

  const handleChangeTextArea = (value) => {
    if (value) {
      handleChangeForm(value, promptVariable)
    }
  }

  return (
    <>
      <div className="itp-ai__ai-agent-fields -mt-4 mb-5">
        <div className="itp-ai__ai-agent-field">
          <label className="itp-edit-number-label-1">
            Enabled {objSelectField[promptVariable]}
          </label>
        </div>

        <div className="itp-ai__ai-agent-container-input">
          {loading ? (
            <Skeleton width="5rem" height="2rem" />
          ) : (
            <Switch
              className="itp-custom-table-switch mt-3"
              onColor="#626ed4"
              height={20}
              width={40}
              checked={activeSwitch}
              onChange={handleChangeSwitch}
            />
          )}
        </div>
      </div>

      {activeSwitch && (
        <>
          <label className="itp-edit-number-label-1 mb-2">
            {objSelectField[promptVariable]}
          </label>
          <TextAreaVarSelect
            options={customPromptsOptions}
            onChangeTextArea={handleChangeTextArea}
            textAreaDefaultValue={form[promptVariable] || ""}
            loading={loading}
          />
        </>
      )}

      <button
        disabled={loading}
        onClick={editPropmtVariable}
        className="btn-primary loading mt-4"
      >
        {loadingButton && <LoadingButton />}
        Edit {objSelectField[promptVariable]}
      </button>
    </>
  );
};

export default EditPrompVariable;
