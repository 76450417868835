const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result.split(",")[1]); // Obtén solo la parte base64
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(blob); // Lee el Blob como una URL en Base64
  });
};

export default convertBlobToBase64;
