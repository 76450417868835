/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { LoadingButton, ShowDateFormated, TagComponent, RowSelect, LabelInput, LabelSelect, Notes, CustomFields, ContactHistory } from "../index";
import { addTagService, fetchContactListDetails, fetchUserDetails, fetchUsers, searchContactListService } from "./services";
import { formatTags, processCustomfields, returnCheck } from "./utils";
import { ModalTagsColor } from "../index";
import { orderedCountryList } from "../../const/countryList";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import AsyncSelect from "react-select/async";

const ContactInfo = ({ 
  children, 
  contactDetails, 
  customFields, 
  editContact, 
  loadingEdit, 
  myExtension, 
  setAddedCustomField, 
  errorEditContact,
  setFinishedAllPetitions,
  loadingExternal, 
}) => {
  const firstLoad = useRef(true)

  const [tags, setTags] = useState(contactDetails?.tags ? [...contactDetails?.tags] : []);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [asignedUser, setAsignedUser] = useState(null);
  const [isLoadingSelectUsers, setIsLoadingSelectUsers] = useState(false);
  const [valueContactList, setValueContactList] = useState(null);
  const [countryValue, setCountryValue] = useState(null)
  const [openModalColor, setOpenModalColor] = useState(false);
  const [finishFetchNotes, setFinishFetchNotes] = useState(false);
  const [finishFetchCallHistory, setFinishFetchCallHistory] = useState(false)
  const [finishPetition, setFinishPetition] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [newTags, setNewTags] = useState([]);
  const [form, setForm] = useState({
    firstname: contactDetails?.firstname,
    lastname: contactDetails?.lastname || "",
    email: contactDetails?.email || "",
    phone: contactDetails?.phone || "",
    country: contactDetails?.country || "",
    city: contactDetails?.city || "",
    state: contactDetails?.state || "",
    zipcode: contactDetails?.zipcode || "",
    address: contactDetails?.address || "",
    dnd_sms: contactDetails?.dnd_sms || false,
  });

  const handleTag = async (newValue) => {
    if (newValue[newValue.length - 1]?.inputValue) {
      const tag = newValue[newValue.length - 1]
      setDisabled(true);
      await addTagService(tag, newTags, setNewTags, setDisabled)
      setTags(newValue);
    } else {
      setTags(newValue);
    }
  };

  const onchangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleChangeCountry = (e) => {
    setCountryValue(e)
    setForm({ ...form, country: e.value })
  }

  const onSaveChange = () => {
    if (loading || loadingExternal) return;

    let data = {
      tags: formatTags(tags, newTags),
      contact_list_id: valueContactList.value,
      firstname: form.firstname,
      lastname: form.lastname,
      ...form,
      phone: form.phone.length > 0 ? `+${form.phone}` : "",
    };

    if (asignedUser) data.owner_id = asignedUser.value;

    editContact(data);
  };

  const closeModal = () => {
    setOpenModalColor(false);
  };

  const loadOptions = async (inputValue, callback) => {
    if (isInitialLoad) {
      const options = await searchContactListService(""); // Carga inicial sin filtro
      setDefaultOptions(options);
      callback(options);
      setIsInitialLoad(false);
    } else {
      const options = await searchContactListService(inputValue);
      callback(options);
    }
  };

  const petition = async () => {
    try {
      setLoading(true);

      await fetchContactListDetails(contactDetails, setValueContactList);

      if (contactDetails?.owner_id) {
        await fetchUserDetails(contactDetails, setAsignedUser);
        firstLoad.current = false
        setFinishPetition(true);
      } else {
        firstLoad.current = false
        setFinishPetition(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (finishPetition && finishFetchNotes && finishFetchCallHistory) {
      if (setFinishedAllPetitions)  setFinishedAllPetitions(true)
      setLoading(false);
    }
  }, [finishFetchNotes, finishPetition, finishFetchCallHistory]);

  useEffect(() => {
    if (isLoadingSelectUsers) {
      fetchUsers(setUsers, setIsLoadingSelectUsers);
    }
  }, [isLoadingSelectUsers]);

  useEffect(() => {
    if (contactDetails) {
      const formTemp = {
        firstname: contactDetails?.firstname,
        lastname: contactDetails?.lastname || "",
        email: contactDetails?.email || "",
        phone: contactDetails?.phone || "",
        country: contactDetails?.country || "",
        city: contactDetails?.city || "",
        state: contactDetails?.state || "",
        zipcode: contactDetails?.zipcode || "",
        address: contactDetails?.address || "",
        dnd_sms: contactDetails?.dnd_sms || false,
      }

      const defaultcountry = orderedCountryList.find((element) => element.value === contactDetails?.country)
      setCountryValue(defaultcountry || null)
      setTags(contactDetails?.tags ? [...contactDetails?.tags] : [])
      processCustomfields(formTemp, customFields, contactDetails, setForm);
  
      if (firstLoad.current) petition();
    }
  }, [contactDetails]);

  return (
    <>
      {openModalColor && (
        <ModalTagsColor
          modal={openModalColor}
          closeModal={closeModal}
          title={"Add tag"}
          sendDatatoParent={handleTag}
          defaultTags={tags}
        />
      )}

      <Row>
        <Col xs={7} sm={7} md={7} lg={7} xl={7} xxl={7}>
          {!loading && !loadingExternal && <label className="itp-component-tag-label">Tag</label>}
          <TagComponent
            disabled={disabled}
            tags={tags}
            handleTag={handleTag}
            loadingData={loading || loadingExternal}
          />
        </Col>
        <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
          <div className="d-flex align-items-end h-100">
            <button
              className="btn-primary itp-add-button"
              onClick={() => {
                if (loading || loadingExternal) return;
                setOpenModalColor(!openModalColor);
              }}
            >
              <FontAwesomeIcon icon={faPlus} className="itp-icon-plus" /> Add
              tag
            </button>
          </div>
        </Col>
      </Row>

      <RowSelect
        label="Contact List"
        hoverInfo="Select the Contact List this Contact belongs to"
        loading={loading || loadingExternal}
        borderBottom={true}
      >
        <AsyncSelect
          onChange={(e) => {
            setValueContactList(e);
          }}
          className="basic-single mt-2 mb-2"
          classNamePrefix="select"
          value={valueContactList}
          isSearchable={true}
          name="contact_list_id"
          loadOptions={loadOptions}
          defaultOptions={defaultOptions}
          cacheOptions
          onFocus={() => {
            if (isInitialLoad) {
              loadOptions("", () => {}); // Carga inicial en foco
            }
          }}
        />
      </RowSelect>

      <LabelInput label="First Name" loading={loading || loadingExternal}>
        <input
          type="text"
          placeholder=": Enter contact First Name"
          className="form-control-itp itp-label-input__input"
          name="firstname"
          value={form.firstname}
          onChange={onchangeForm}
        />
      </LabelInput>

      <LabelInput label="Last Name" loading={loading || loadingExternal}>
        <input
          type="text"
          placeholder=": Enter contact Last Name"
          className="form-control-itp itp-label-input__input"
          name="lastname"
          value={form.lastname}
          onChange={onchangeForm}
        />
      </LabelInput>

      <LabelInput label="E-mail" loading={loading || loadingExternal}>
        <input
          type="text"
          placeholder=": Enter email address"
          className="form-control-itp itp-label-input__input"
          name="email"
          value={form.email}
          onChange={onchangeForm}
        />
      </LabelInput>

      <LabelSelect borderBottom={true} label="Phone Number" loading={loading || loadingExternal}>
        <PhoneInput
          enableSearch={true}
          country={"us"}
          value={form.phone}
          onChange={(e) => {
            setForm({ ...form, phone: e });
          }}
        />
      </LabelSelect>

      <LabelInput
        className="border-none mt-2"
        label="Last Activity"
        loading={loading || loadingExternal}
      >
        <div className="d-flex gap-1 align-items-center">
          <div>{contactDetails?.selectIcon}</div>
          <div>{contactDetails?.selectArrow}</div>

          {contactDetails?.keyDate && (
            <ShowDateFormated date={contactDetails?.keyDate} />
          )}
        </div>
      </LabelInput>

      <RowSelect borderBottom={true} label="Country" loading={loading || loadingExternal}>
        <Select
          onChange={handleChangeCountry}
          className="basic-single mt-2 mb-2"
          classNamePrefix="select"
          value={countryValue}
          isSearchable={true}
          name="country"
          options={orderedCountryList}
          placeholder="Select a Country"
        />
      </RowSelect>

      <LabelInput label="City" loading={loading || loadingExternal}>
        <input
          type="text"
          placeholder=": Enter City"
          className="form-control-itp itp-label-input__input"
          name="city"
          value={form.city || ""}
          onChange={onchangeForm}
        />
      </LabelInput>

      <LabelInput label="State" loading={loading || loadingExternal}>
        <input
          type="text"
          placeholder=": Enter State"
          className="form-control-itp itp-label-input__input"
          name="state"
          value={form.state || ""}
          onChange={onchangeForm}
        />
      </LabelInput>

      <LabelInput label="Address" loading={loading || loadingExternal}>
        <input
          type="text"
          placeholder=": Enter Address"
          className="form-control-itp itp-label-input__input"
          name="address"
          value={form.address}
          onChange={onchangeForm}
        />
      </LabelInput>

      <LabelInput label="zipcode" loading={loading || loadingExternal}>
        <input
          type="text"
          placeholder=": Enter Zip Code"
          className="form-control-itp itp-label-input__input"
          name="zipcode"
          value={form.zipcode}
          onChange={onchangeForm}
        />
      </LabelInput>

      <LabelSelect label="Opted out (SMS)" loading={loading || loadingExternal}>
        <input
          checked={returnCheck(form.dnd_sms) || false}
          name="dnd_sms"
          onChange={() => {
            setForm({
              ...form,
              dnd_sms: returnCheck(form.dnd_sms || false, true),
            });
          }}
          type="checkbox"
          className="form-check-input itp-custom-checkbox-contact-details"
        />
      </LabelSelect>

      <CustomFields
        customFields={customFields}
        form={form}
        setForm={setForm}
        onchangeForm={onchangeForm}
        loading={loading || loadingExternal}
        myExtension={myExtension}
        setAddedCustomField={setAddedCustomField}
      />

      <RowSelect
        label="Assigned User"
        hoverInfo="Select the assigned user for this contact"
        loading={loading || loadingExternal}
        borderBottom={true}
      >
        <Select
          onChange={(e) => setAsignedUser(e)}
          className="basic-single"
          classNamePrefix="select"
          name="owner_id"
          placeholder="Select a User"
          options={users}
          isLoading={isLoadingSelectUsers}
          isSearchable={true}
          value={asignedUser}
          onMenuOpen={() => {
            if (!users.length) {
              // Descargar opciones solo si no hay opciones cargadas
              setIsLoadingSelectUsers(true);
            }
          }}
        />
      </RowSelect>

      <div className="itp-contact-details-separator"></div>

      {children}

      <Notes
        contactId={contactDetails?.pk}
        setFinishFetchNotes={setFinishFetchNotes}
        loading={loading || loadingExternal}
        myExtension={myExtension}
      />

      <ContactHistory 
        contactId={contactDetails?.pk} 
        setFinishFetchCallHistory={setFinishFetchCallHistory}
        loading={loading || loadingExternal}
        myExtension={myExtension}
      />

      {errorEditContact && <p className="text-danger">{errorEditContact}</p>}

      <button
        onClick={onSaveChange}
        className="btn-primary itp-contact-detail-button-save loading mb-4"
      >
        {loadingEdit && <LoadingButton />}
        Save Changes
      </button>
    </>
  );
};

export default ContactInfo;
