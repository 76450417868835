import React from "react";
import { Col, Row } from "reactstrap";

const Step6 = ({ form, handleChangeForm }) => {
  return (
    <>
      <label className="itp-label-input__label">
        Set the date you want to send messages
      </label>
      <Row>
        <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <div className="container-date-range">
            <label className="date-range-label">Select Date:</label>
            <input
              type="date"
              className="date-range-input"
              name="scheduled_date"
              value={form.scheduled_date}
              onChange={handleChangeForm}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Step6;
