const downloadCSV = (dataCSV, fileName) => {
  try {
    const blob = new Blob([dataCSV], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log("error when downloading the csv", error);
  }
};

export default downloadCSV;
