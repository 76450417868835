import petitionGet from "../../../../../services/petitionGet";

const fetchContactListDetails = async (pk) => {
  const parameter = `/${pk}`
  const { data: result } = await petitionGet("contactList", { parameter })

  if (result?.result && Object.keys(result?.result)?.length) {
    result.result.label = `${result.result.list_name} (${result.result.contact_count})`
    result.result.value = result.result.pk

    return result.result
  } else {
    return null
  }
}

export default fetchContactListDetails;