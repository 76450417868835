
import { setupRemoteMedia, cleanupMedia } from "./media"
import { SessionState } from "sip.js";

// const ringtone = new Audio(ringtoneSound);

// ringtone.loop = true;


async function onInvite(invitation, setInvitationSession,setCallSessionsFunctionEnd,callAudio,setCallAudio,ringtone,handleAnswerOnInvite,sessionIncall) {
  let callAudioTemp = new Audio();
  // if (invitation) {
  console.log(invitation)
    if (invitation._state === "Initial") {
      console.log("Invitation state: Initial");
      setInvitationSession(invitation)
      // console.log("fffffffffffffffffffffffff2",invitation._id)
      ringtone.play()
        .then(() => console.log('Reproducción exitosa'))
        .catch(error => console.error('Error al reproducir el sonido:', error));

      console.log("sonó en el invitation._state === 'Initial'")

      if (invitation._state === "Terminated") {
        try {
          await ringtone.pause()
          console.log("Paused Success")
        } catch (error) {
          console.log("Paused Error", error)
        }
      }
    }
    
    invitation.stateChange.addListener((state) => {
      console.log("Invitation state:", state);
      switch (state) {
        case SessionState.Initial:
          console.log("Invitation state: Initial");
          break;
        case SessionState.Establishing:
          console.log("Invitation state: Establishing");
          ringtone.play().catch(error => console.warn('Failed to play ringtone:', error.message));
          console.log("sonó en el addListener en el Establishing")

          break;
        case SessionState.Established:
          console.log("Invitation state: Established");
          setupRemoteMedia([invitation], callAudio);
          setCallAudio(callAudioTemp)
          ringtone.pause()
          handleAnswerOnInvite(invitation,sessionIncall.length - 1,false)
          break;
        case SessionState.Terminating:
        case SessionState.Terminated:
          console.log("Invitation state: Terminating/Terminated"); 
          setCallSessionsFunctionEnd(invitation)
          ringtone.pause()
          break;
        default:
          throw new Error("Unknown session state.");
      }
    });
  // }
}

export default onInvite;