const returnFormInputIntegration = (element, customerIntegrations) => {
  let formTemp = {};
  let inputsTemp = [];
  let booleanInputs = [];

  const isActive = customerIntegrations.find((el) => el.integration_id === element.pk)
  const fields = element?.default_settings?.fields || {};
  const keys = Object.keys(fields);

  if (keys?.length) {
    keys.forEach((key) => {
      formTemp[key] = isActive?.settings?.fields[key]?.value || "";
      const objectField = { ...fields[key], key }

      if (fields[key].type === "boolean") {
        booleanInputs.push(objectField)
      } else {
        inputsTemp.push(objectField);
      }
    });
  }

  inputsTemp = [...inputsTemp, ...booleanInputs]

  return { formTemp, inputsTemp }
};

export default returnFormInputIntegration;
