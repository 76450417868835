import Skeleton from "react-loading-skeleton";
import { Col, Row } from "reactstrap";

const LabelSelect = ({ children, label, loading, borderBottom, error }) => (
  <Row className={`itp-container-label-select ${loading ? "loading" : ""} ${borderBottom ? "b-bottom" : ""}`}>
    <Col
      xs={5}
      sm={5}
      md={5}
      lg={5}
      xl={5}
      xxl={5}
      className="itp-label-input-col"
    >
      <label className={`${error ? "text-danger" : "itp-label-input__label"} mt-2 ${borderBottom ? "mb-3" : ""}`}>{label}</label>
    </Col>
    <Col
      xs={7}
      sm={7}
      md={7}
      lg={7}
      xl={7}
      xxl={7}
      className="itp-label-input-col"
    >
      {loading ? <Skeleton height={50} width="100%" /> : <>{children}</>}
    </Col>
  </Row>
);

export default LabelSelect;
