import { formatedMessageList } from "../../utils/index";
import { fetchFollowupMessagesScheduled } from "../index"
import petitionGet from "../../../../../../services/petitionGet";

const messagesService = async (chatWindowData, number, threadId, query, timeZone) => {
  const thread = threadId || chatWindowData?.notIsSelf?.message_thread_id
  const parameter = query || "?offset=0&limit=20"

  const response = await petitionGet("messageThread", { number, thread, parameter })
  const { data: result } = response
  const messages = formatedMessageList(result)

  let followUpSchedule = []

  if (chatWindowData?.ai_enabled) {
    followUpSchedule = await fetchFollowupMessagesScheduled(thread, number, timeZone)
  }

  if (followUpSchedule?.length) {
    const fakeMessage = {
      message_body: "AI Will Automatically Follow Up!",
      message_participant: number,
      message_timestamp: "",
      item_type: "",
      isFollowUpFake: true,
      followUps: followUpSchedule,
      message_thread_id: messages[0].message_thread_id,
    }

    const reversedMessage = messages.reverse()
    const totalMessage = [...reversedMessage, fakeMessage]
    return totalMessage
  } else {
    return messages.reverse()
  }
}

export default messagesService;