import React, { useState } from "react";

const Step3 = ({ form, setForm }) => {
  const [show, setShow] = useState(false);

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      setForm({ ...form, mms_media1: reader.result });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const downloadImg = () => {
    let a = document.createElement("a"); //Create <a>
    a.href = form.mms_media1; //Image Base64 Goes here
    a.download = "Image.png"; //File name Here
    a.click(); //Downloaded file
  };
  return (
    <>
      <h3>Multimedia Messages</h3>
      <br></br>
      {!form.mms_media1 ? (
        <label className="itp-label-input__label">
          If sending only MMS (images), please provide a sample 
          message that may be sent to a contact
        </label>
      ) : (
        <label className="itp-label-input__label">
          Image upload, to download{" "}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => {
              downloadImg();
            }}
          >
            click here
          </span>
          .
          <br />{" "}
          <span
            style={{ color: "#bd2130", cursor: "pointer" }}
            onClick={() => {
              setShow(true);
            }}
          >
            Replace Image
          </span>
        </label>
      )}

      <br></br>
      {(form.mms_media1 === null || show) && (
        <input
          className="form-control-itp"
          type="file"
          accept="image/*"
          capture="Examine..."
          onChange={(e) => {
            getBase64(e.target.files[0]);
          }}
        />
      )}
    </>
  );
};

export default Step3;
