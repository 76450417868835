import petitionPost from "../../../../../../services/petitionPost";

const enabledIntegrationService = async (clickedItem, setClickedItem, setEnabledIntegration, updateAlertVariables, setLoadingEnabledIntegration) => {
  try {
    const data = {
      integration_name: clickedItem.name,
      settings: {
        description: clickedItem.default_settings.description,
        fields: clickedItem.default_settings.fields,
      },
    };

    const { data: result} = await petitionPost("enabledIntegration", { data })

    setClickedItem({ ...clickedItem, customer_integration_id: result.result.pk })
    setLoadingEnabledIntegration(false)
  } catch (error) {
    console.log(error);
    const messageError = "An error has ocurred, please try again"
    setEnabledIntegration(false)
    updateAlertVariables("error", "Error", messageError, 4000)
    setLoadingEnabledIntegration(false)
  }
};

export default enabledIntegrationService;
