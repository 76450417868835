import React from 'react';
import searchIcon from "../../assets/images/searchIcon.svg"
import './Search.css';

const Search = ({ placeholder, disabled }) => {
  return (
    <div className="itp-input-container">
      <img src={searchIcon} alt="Search Icon" className="" />
      <input type="text" className="itp-input" placeholder={placeholder} disabled={disabled || false} />
    </div>
  );
};

export default Search;