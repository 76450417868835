import petitionPatch from "../../../../../../services/petitionPatch";

const handleChangeAIAgentService = async (e, number, aIAgentValue, setAIAgentValue, updateAlertVariables) => {
  const currentQuestionListValue = aIAgentValue;

  try {
    setAIAgentValue(e);

    const data = { ai_agent_id: e.value };

    await petitionPatch("numberEdit", { number, data });
  } catch (error) {
    console.log(error);
    setAIAgentValue(currentQuestionListValue);
    updateAlertVariables("error", "Error", "An error has ocured", 3000);
  }
};

export default handleChangeAIAgentService;
