const DeleteContactsAction = ({ backStep, loading, nextStep }) => (
  <>
    <p className="itp-label-input__label mt-4">
      Are you sure to delete contacts?
    </p>

    <div className="d-flex gap-2 mt-4">
      <button disabled={loading} className="btn-light" onClick={backStep}>
        Back
      </button>

      <button
        disabled={loading}
        className="btn-primary loading"
        onClick={() => nextStep()}
      >
        Save
      </button>
    </div>
  </>
);

export default DeleteContactsAction;
