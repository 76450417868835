import { fetchCustomFields } from "../index"
import petitionPatch from "../../../../services/petitionPatch"

const editCustomFieldService = async (infoCustomField, closeModal, customFields, setModalDelete, setError) => {
  try {
    const custom_field_id = infoCustomField.pk
    const data = { name: document.getElementById(infoCustomField.pk).value }

    await petitionPatch("customField", { data, custom_field_id })

    const fieldData = document.getElementsByClassName('itp-edit-contact-custom-field-actions')

    for (let index = 0; index < fieldData.length; index++) {
      const element = fieldData[index];
      element.style.visibility = "hidden";
    }    

    await fetchCustomFields(customFields)
    closeModal()
  } catch (error) {
    console.log(error)

    if (error?.response?.status === 400 && error?.response?.data?.error?.error_message) {
      setModalDelete(true)
      setError(error?.response?.data?.error?.error_message)
    }
  }
}

export default editCustomFieldService;