import { useState } from "react";
import petitionGet from "../../../../../services/petitionGet";
import AsyncSelect from "react-select/async";

const MoveToContactListAction = ({ backStep, loading, nextStep }) => {
  const [valueContactList, setValueContactList] = useState(null);

  const loadOptions = (inputValue, callback) => {
    petitionGet("contactList", { parameter: `?name=${inputValue}` })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.list_name} (${element.contact_count})`;
          element.value = element.pk;
        });

        if (!valueContactList) {
          setValueContactList(result.result[0]);
        }
        callback(result.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveFunction = () => {
    nextStep(valueContactList)
  }

  return (
    <>
      <label className="itp-add-contact-manually-label">
        Select Contact List
      </label>
      <AsyncSelect
        onChange={(e) => setValueContactList(e)}
        loadOptions={loadOptions}
        className="basic-single itp-add-contact-manually-select"
        classNamePrefix="select"
        value={valueContactList}
        isSearchable={true}
        name="contact_list_id"
        placeholder="Select Option"
        defaultOptions
        cacheOptions
      />

      <div className="d-flex gap-2 mt-4">
        <button disabled={loading} className="btn-light" onClick={backStep}>
          Back
        </button>

        <button
          disabled={loading || !valueContactList}
          className="btn-primary loading"
          onClick={saveFunction}
        >
          Save
        </button>
      </div>
    </>
  );
};

export default MoveToContactListAction;
