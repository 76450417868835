import petitionPatch from "../../../../../../services/petitionPatch";
import getQueryString from "../../../../../../utils/getQueryString";

const editAIAgentService = async (setLoadingButton, form, info, petition, pagination, updateAlertVariables, prompt) => {
  try {
    setLoadingButton(true);

    let data = {}

    if (!prompt) {
      data = {
        ...form,
        assigned_users: { user_ids: form.assigned_users.map((user) => user.pk) },
        integrations: { integration_ids: form.integrations },
        personality_id: form.personality_id.value
      };
    } else {
      data = { [prompt]: form[prompt] === "" ? null : form[prompt] }
    }

    if (form.ai_lead_manager_funnel_id) {
      data["ai_lead_manager_funnel_id"] = form.ai_lead_manager_funnel_id.pk;
    } else {
      delete data.ai_lead_manager_funnel_id;
    }

    if (form.ai_lead_manager_screening_questions_list_id) {
      data["ai_lead_manager_screening_questions_list_id"] = form.ai_lead_manager_screening_questions_list_id.pk;
    } else {
      delete data.ai_lead_manager_screening_questions_list_id;
    }

    await petitionPatch("aiAgent", { aiAgentID: info.pk, data })
    const parameter = getQueryString(pagination.current);
    petition(parameter, true);
  } catch (error) {
    console.log(error);
    updateAlertVariables("error", "Error", "An error has ocurred, please try again", 3000);
    setLoadingButton(false);
  }
};

export default editAIAgentService;
