/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPenToSquare, faPlay, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "reactstrap";
import { MenuRight, CustomTableMain, CollapsePanel, DropdownButton, AlertNotificactions, AudioMessage } from "../../../../../components";
import { addMediaGroupService, fetchMediDetails } from "./services";
import { EditGroupName, RecordSound, TextToSpeach, UploadFile } from "./components";
import petitionGet from "../../../../../services/petitionGet";
import petitionPut from "../../../../../services/petitionPut";
import petitionPost from "../../../../../services/petitionPost";
import petitionPatch from "../../../../../services/petitionPatch";
import ModalDelete from "../../ModalUtility/ModalDelete";
import petitionDelete from "../../../../../services/petitionDelete";
import json from "../../../../../config.json"

const MediaAudioFile = ({
  loading,
  setCompletedRequests,
  openMenu,
  setOpenMenu,
}) => {
  const [info, setInfo] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [loadingButton, setLoadingButton] = useState(false)
  const [showErrorTime, setShowErrorTime] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertType, setAlertType] = useState()
  const [alertMessage, setAlertMessage] = useState("")
  const [modalDelete, setModalDelete] = useState(false)
  const [base64Audio, setBase64Audio] = useState("")
  const [loadingplayAudio, setLoadingplayAudio] = useState(false)
  const [showPlayAudio, setShowPlayAudio] = useState(false)

  const [dataTableMedia, setDataTableMedia] = useState({
    columns: [{ name: "Name", key: "name" }],
    actions: { title: "Actions", content: [] },
  });

  const updateAlertVariables = (type, title, message, time) => {
    setAlertType(type)
    setAlertTitle(title)
    setAlertMessage(message)
    setShowErrorTime(time)

    setTimeout(() => {
      setShowErrorTime(false)
      setAlertType("")
      setAlertTitle("")
      setAlertMessage("")
    }, time + 300)
  }

  const closeMenu = () => {
    setInfo(null);
    setOpenMenu(false);
    setLoadingButton(false);
    setSelectedOption("")
    setModalDelete(false)
    setShowPlayAudio(false)
  };

  const handleClickOption = (action) => {
    if (loading) return;
    setSelectedOption(action)
    setOpenMenu(5);
  };

  const activeEditGroupName = (e) => {
    setInfo(e)
    setOpenMenu(5);
  }

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(5);
  };

  const selectTitle = () => {
    if (info && !showPlayAudio) {
      return "Edit Name"
    } else if (info && showPlayAudio) {
      return `playing ${info.name} audio`
    } else {
      return "Add Media File"
    }
  }

  const activePlayAudio = async (e) => {
    try {
      setOpenMenu(5)
      setShowPlayAudio(true)
      setInfo(e)
      setLoadingplayAudio(true)
  
      const media_group_id = e.pk
      const mediaDetails = await fetchMediDetails(media_group_id)

      const englishFile = mediaDetails.media_files.find((el) => el.language === "en")

      if (!englishFile) {
        const message = "The audio file does not exist"
        updateAlertVariables("error", "Error", message, 3000)
        closeMenu()
        return;
      }
      
      const media_filename = englishFile.original_file

      const { data: result } = await petitionGet("mediaGroupsAudioFile", { media_group_id, media_filename })
  
      const type = englishFile.original_file.split(".")[1]
      const audio = `data:audio/${type};base64,${result.result}`
        
      setBase64Audio(audio)
      setLoadingplayAudio(false)

    } catch (error) {
      console.log(error)
      const message = "En Error has ocurred"
      updateAlertVariables("error", "Error", message, 3000)
      closeMenu()
    }
  }

  const addUploadedAndRecordedFile = async (name, file) => {
    try {
      setLoadingButton(true)
      const newMediaGroup = await addMediaGroupService(name)

      const formData = new FormData();
      formData.append("language", "en");
      formData.append("file", file);
      await petitionPut("uploadFile", { media_group_id: newMediaGroup.pk, data: formData })

      setDataTableMedia((prev) => ({ ...prev, content: [...prev.content, newMediaGroup]}));
      closeMenu();
    } catch (error) {
      console.log(error)
      setLoadingButton(false)
      updateAlertVariables("error", "Error", "An error has ocurred, please try again", 3000)
    }
  }

  const addTextToSpeachFile = async (name, data) => {
    try {
      setLoadingButton(true)
      const newMediaGroup = await addMediaGroupService(name)

      await petitionPost("mediaTTS", { media_group_id: newMediaGroup.pk, data })

      setDataTableMedia((prev) => ({ ...prev, content: [...prev.content, newMediaGroup]}));
      closeMenu();
    } catch (error) {
      console.log(error)
      setLoadingButton(false)
      updateAlertVariables("error", "Error", "An error has ocurred, please try again", 3000)
    }
  }

  const editMediaGroup = async (name) => {
    try {
      setLoadingButton(true)
      const data = { name }
      const { data: result } = await petitionPatch("mediaGroup", { media_group_id: info.pk, data })
  
      setDataTableMedia((prev) => {
        const newContent = prev.content.map((el) => {
          if (el.pk === info.pk) el = result.result
          return el
        })
  
        return { ...prev, content: [...newContent] }
      });
      closeMenu();
    } catch (error) {
      console.log(error)
      setLoadingButton(false)
      updateAlertVariables("error", "Error", "An error has ocurred, please try again", 3000)
    }
  }

  const deleteMediaGroup = async () => {
    try {
      setLoadingButton(true)
      await petitionDelete("mediaGroup", { media_group_id: info.pk })
  
      setDataTableMedia((prev) => ({ ...prev, content: prev.content.filter((el) => el.pk !== info.pk)}));
      closeMenu();
    } catch (error) {
      console.log(error)
      setLoadingButton(false)
      updateAlertVariables("error", "Error", "An error has ocurred, please try again", 3000)
    }
  }

  const download = async (element) => {
    try {
      const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
      const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
      const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;

      const media_group_id = element.pk
      const mediaDetails = await fetchMediDetails(media_group_id)

      const englishFile = mediaDetails.media_files.find((el) => el.language === "en")

      if (!englishFile) {
        const message = "The audio file does not exist"
        updateAlertVariables("error", "Error", message, 3000)
        closeMenu()
        return;
      }
  
      const downloadFile = async () =>
        await fetch(
          `${urlBase}/itpvoice/v2/${id}/media-groups/${englishFile.pk}/${englishFile.original_file}`,
          { headers: { Authorization: "Bearer " + token.replace(/['"]+/g, "") } }
        ).then((res) => res.blob());
  
      const createURL = (blob) => URL.createObjectURL(blob);
  
      const blob = await downloadFile();
      const create = createURL(blob);
  
      let link = document.createElement("a");
      link.download = `audio.mp3`;
      link.href = create;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error)

      if (error?.response?.status === 404) {
        updateAlertVariables("error", "Error", "The audio file does not exist", 3000)
      } else {
        updateAlertVariables("error", "Error", "An error has ocurred, please try again", 3000)
      }
    }
  };

  const petitionMedia = () => {
    petitionGet("mediaGroups")
      .then(({ data: result }) => {
        setDataTableMedia({
          columns: [{ name: "Name", key: "name" }],
          content: [...result.result],
          actions: {
            title: "Edit",
            content: [
              {
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                name: "Edit",
                handleClick: activeEditGroupName,
              },
              {
                icon: <FontAwesomeIcon icon={faPlay} />,
                name: "Play",
                handleClick: activePlayAudio,
              },
              {
                icon: <FontAwesomeIcon icon={faDownload} />,
                name: "Download",
                handleClick: download,
              },
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeDeleteModal,
              },
            ],
          },
        });

        setCompletedRequests((prevStata) => prevStata + 1);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petitionMedia();
  }, []);

  return (
    <>
      <AlertNotificactions 
        type={alertType}
        alertMessage={alertMessage}
        showTime={showErrorTime}
        title={alertTitle}
      />

      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeMenu}
          loading={loadingButton}
          modalFunction={deleteMediaGroup}
          module="Media File"
        />
      )}

      <MenuRight
        show={openMenu === 5}
        closeMenu={closeMenu}
        loading={loadingplayAudio}
        title={selectTitle()}
        to=""
      >
        {openMenu === 5 && info && !showPlayAudio && (
          <EditGroupName 
            info={info}
            editMediaGroup={editMediaGroup}
            loadingButton={loadingButton}
          />
        )}

        {openMenu === 5 && selectedOption === "Upload File" && (
          <UploadFile 
            addMediaFile={addUploadedAndRecordedFile}
            loadingButton={loadingButton}
          />
        )}

        {openMenu === 5 && selectedOption === "Text To Speach" && (
          <TextToSpeach 
            addTextToSpeachFile={addTextToSpeachFile}
            loadingButton={loadingButton}
          />
        )}

        {openMenu === 5 && selectedOption === "Record Sound" && (
          <RecordSound 
            addMediaFile={addUploadedAndRecordedFile}
            loadingButton={loadingButton}
          />
        )}

        {openMenu && info && showPlayAudio && (
          <>
            <AudioMessage 
              audioUrl={base64Audio}
              variant="secondary"
              noFetch
            />
          </>
        )}
      </MenuRight>

      <br></br>
      <div>
        <Row>
          <Col style={{ zIndex: "-1" }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        id="mediaFiles"
        title="Media Files"
        subtitle="Upload media files (mp3 or wav) that can be used for Music on Hold or Menus inside Callflows"
      >
        <CustomTableMain
          data={dataTableMedia}
          m0={true}
          loading={loading}
          module="Media Files"
          actionsRight={[
            {
              component: (
                <DropdownButton
                  name="Add Media"
                  actions={[
                    { name: "Upload File", handleClick: handleClickOption },
                    { name: "Text To Speach", handleClick: handleClickOption },
                    { name: "Record Sound", handleClick: handleClickOption },
                  ]}
                />
              ),
            },
          ]}
        />
      </CollapsePanel>
    </>
  );
};

export default MediaAudioFile;
