import petitionPost from "../../../../../../services/petitionPost"
import getQueryString from "../../../../../../utils/getQueryString"

const addAIAgentService = async (setLoadingButton, form, pagination, petition, updateAlertVariables) => {
  try {
    setLoadingButton(true)

    let data = {
      name: form.name,
      description: form.description,
      agent_type: form.agent_type,
      ai_manage_appointments: form.ai_manage_appointments,
      ai_calender_strategy: form.ai_calender_strategy,
      ai_lead_manager: form.ai_lead_manager,
      ai_faq: form.ai_faq,
      assigned_users: { user_ids: form.assigned_users.map((user) => user.pk) },
      integrations: { integration_ids: form.integrations },
      personality_id: form.personality_id.value,
      prompt_variables: {}
    }

    if (form.ai_lead_manager_funnel_id) {
      data["ai_lead_manager_funnel_id"] = form.ai_lead_manager_funnel_id.pk;
    } else {
      delete data.ai_lead_manager_funnel_id;
    }

    if (form.ai_lead_manager_screening_questions_list_id) {
      data["ai_lead_manager_screening_questions_list_id"] = form.ai_lead_manager_screening_questions_list_id.pk;
    } else {
      delete data.ai_lead_manager_screening_questions_list_id;
    }
    
    await petitionPost("aiAgent", { data })
    const parameter = getQueryString(pagination.current)
    petition(parameter, true)
  } catch (error) {
    console.log(error)
    updateAlertVariables("error", "Error", "An error has ocurred, please try again", 3000)
    setLoadingButton(false)
  }
}

export default addAIAgentService;