// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-integrations__card-item.card {
  cursor: pointer;
  border: 1px solid rgba(109, 40, 217, 0.3);
  box-shadow: 0px 4px 8px #949494;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transición suave */
}

.itp-integrations__card-item.card:hover {
  background-color: rgba(67, 112, 151, 0.2);
  transform: scale(1.05); /* Crece un 5% */
  box-shadow: 0px 8px 16px #949494; /* Sombra más intensa al hacer hover */
}

.itp-integrations__card-item.card:hover {
  background-color: rgba(67, 112, 151, 0.2);
}

.itp-integrations__card-item-name {
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: 0px;
  color: #6D28D9;
}

.itp-integrations__card-container {
  margin-top: 1rem !important;
}

.itp-integrations__card-item-img {
  width: 7rem;
  height: 7rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/WorkspaceSettings/Integrations/Integrations.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,yCAAyC;EACzC,+BAA+B;EAC/B,qDAAqD,EAAE,qBAAqB;AAC9E;;AAEA;EACE,yCAAyC;EACzC,sBAAsB,EAAE,gBAAgB;EACxC,gCAAgC,EAAE,sCAAsC;AAC1E;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".itp-integrations__card-item.card {\n  cursor: pointer;\n  border: 1px solid rgba(109, 40, 217, 0.3);\n  box-shadow: 0px 4px 8px #949494;\n  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transición suave */\n}\n\n.itp-integrations__card-item.card:hover {\n  background-color: rgba(67, 112, 151, 0.2);\n  transform: scale(1.05); /* Crece un 5% */\n  box-shadow: 0px 8px 16px #949494; /* Sombra más intensa al hacer hover */\n}\n\n.itp-integrations__card-item.card:hover {\n  background-color: rgba(67, 112, 151, 0.2);\n}\n\n.itp-integrations__card-item-name {\n  font-family: Montserrat;\n  font-size: 14px;\n  letter-spacing: 0px;\n  color: #6D28D9;\n}\n\n.itp-integrations__card-container {\n  margin-top: 1rem !important;\n}\n\n.itp-integrations__card-item-img {\n  width: 7rem;\n  height: 7rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
