import petitionPost from "../../../../../services/petitionPost";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";

const smsReportService = async (selectedNumbers, timeframe) => {
  const data = { phone_numbers: selectedNumbers.map((number) => number.number)};

  const timeStart = timeframe.timeStart + "T00:00:00"
  const timeEnd = timeframe.timeEnd + "T23:59:59"

  const parameter = `?start_date=${timeStart}&end_date=${timeEnd}`

  const { data: result } = await petitionPost("smsCallReports", { data, parameter })

  result.result.forEach((number) => {
    number.formatedNumber = formatPhoneNumber(number.Phone)
  })

  return result.result
}

export default smsReportService;